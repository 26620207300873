import { Transition } from 'history';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { history } from 'Router';
import { LocationState } from 'types';
import useLeaveExerciseState from './useLeaveExerciseState';
import useNewVersionState from './useNewVersionState';

interface LeaveExerciseBlockadeProps {
  ignorePath?: (path: string) => boolean;
  ignoreBlockade: boolean;
  onImmediateLeave?: () => any;
  shouldBlock: boolean;
}

const useLeaveExerciseBlockade = ({
  ignorePath,
  ignoreBlockade,
  onImmediateLeave,
  shouldBlock,
}: LeaveExerciseBlockadeProps) => {
  const { isNewVersion } = useNewVersionState();

  const blockHandler = useRef<any>();
  const {
    open: leaveModalOpen,
    openLeaveModal,
    setCanLeave,
    setLeaveState,
  } = useLeaveExerciseState();
  const navigate = useNavigate();

  const unBlockHandler = useCallback(() => {
    if (blockHandler.current) {
      blockHandler.current();
      blockHandler.current === undefined;
      setLeaveState({ path: '' });
    }
  }, [setLeaveState]);

  useEffect(() => {
    blockHandler.current = history.block((tx: Transition) => {
      const {
        location: { pathname, search, state },
        retry,
      } = tx;
      const path = `${pathname}${search}`;
      const { leave: forceLeave, newProduct } = (state || {}) as LocationState;

      setLeaveState({ path, state: { leave: forceLeave, newProduct } });

      if (
        !leaveModalOpen &&
        shouldBlock &&
        !forceLeave &&
        !ignoreBlockade &&
        !isNewVersion &&
        !ignorePath?.(pathname)
      ) {
        if (onImmediateLeave) {
          onImmediateLeave();
        } else {
          openLeaveModal();

          return false;
        }
      }
      unBlockHandler();
      retry();
    });

    return () => {
      blockHandler.current();
      blockHandler.current === undefined;
    };
  }, [
    isNewVersion,
    shouldBlock,
    ignoreBlockade,
    unBlockHandler,
    setLeaveState,
    leaveModalOpen,
    ignorePath,
    navigate,
    onImmediateLeave,
    openLeaveModal,
  ]);

  useEffect(() => {
    if (shouldBlock) {
      setCanLeave(!shouldBlock);
      setLeaveState({ path: '' });
    }
  }, [setCanLeave, setLeaveState, shouldBlock]);

  useEffect(() => {
    if (ignoreBlockade || isNewVersion) {
      setCanLeave(ignoreBlockade);
      setLeaveState({ path: '' });
      unBlockHandler();
    }
  }, [
    isNewVersion,
    ignoreBlockade,
    setCanLeave,
    setLeaveState,
    unBlockHandler,
  ]);
};

export default useLeaveExerciseBlockade;
