import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  IUniversitiesData,
  IUniversitiesVar,
  UNIVERSITIES,
} from '@quesmed/types-rn/resolvers/query';
import { IUniversity } from '@quesmed/types-rn/models';

import { compareRankNameAsc } from 'utils';

export const useUniversity = (universityId?: number) => {
  const { data, loading } = useQuery<IUniversitiesData, IUniversitiesVar>(
    UNIVERSITIES
  );

  const { universities = [] } = data || {};

  const universityList = useMemo(
    () => [...universities].sort(compareRankNameAsc),
    [universities]
  );

  const userUniversity = useMemo(
    () => universityList.find(({ id }) => Number(id) === universityId),
    [universityList, universityId]
  );

  return { universityList, userUniversity, loading };
};

export const getUniversityOptionLabel = (
  university: IUniversity | string
): string => {
  if (typeof university === 'string') {
    return university;
  }

  return university.name;
};
