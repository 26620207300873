import React from 'react';
import { styled } from '@mui/material/styles';

import { Button, ButtonProps } from 'components/Button';
import { constSize } from 'utils';

const StyledButton = styled(Button)(({ theme: { palette, spacing } }) => ({
  ...constSize(spacing(14), spacing(12)),
  borderRadius: '100px',

  '&.MuiLoadingButton-root.MuiButton-outlinedSecondary': {
    backgroundColor: palette.background.paper,

    '&:hover': {
      backgroundColor: palette.background.paper,
    },
  },
}));

interface AudioVideoButtonProps extends ButtonProps {
  active: boolean;
}

const AudioVideoButton = ({
  active,
  ...props
}: AudioVideoButtonProps): JSX.Element => (
  <StyledButton
    color={active ? 'secondary' : 'primary'}
    secondary={active}
    {...props}
  />
);

export default AudioVideoButton;
