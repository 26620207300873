export const selectionIsEmpty = (selection: Selection) => {
  /**
   * This function will tell if selection is empty or not
   */
  const position = selection?.anchorNode?.compareDocumentPosition(
    selection?.focusNode as Node
  );

  return position === 0 && selection.focusOffset === selection.anchorOffset;
};
