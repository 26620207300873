import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { UtilPopup } from 'components/UtilPopup';
import { NoteEditIcon } from 'components/Icons';
import { Nullable, Utils } from 'types';
import { notesStorage } from './notesStorage';
import { Caption } from 'components/Typography';

const MAX_MOBILE_WIDTH = '330px';
const MAX_WIDTH = '380px';
const MAX_MOBILE_HEIGHT = '255px';
const MAX_HEIGHT = '365px';

const NotesWrapper = styled(Box)(
  ({ theme: { breakpoints, spacing, typography, palette } }) => ({
    width: MAX_MOBILE_WIDTH,
    height: MAX_MOBILE_HEIGHT,
    margin: spacing(2, 4),

    [breakpoints.up('md')]: {
      width: MAX_WIDTH,
      height: MAX_HEIGHT,
      margin: spacing(2, 6),
    },

    textarea: {
      background: 'transparent',
      width: '100%',
      height: '100%',
      ...typography.body1,
      color: palette.text.primary,
      border: 'none',
      resize: 'none',
    },
  })
);

const Footer = styled(Caption)(({ theme: { spacing } }) => ({
  padding: spacing(4),
  textAlign: 'center',
  width: '100%',
  display: 'block',
}));

interface UtilNotesProps {
  onClose?: () => void;
}
const { getNotes, setNotes: setStorageNotes } = notesStorage();

export const UtilNotes = ({ onClose }: UtilNotesProps): JSX.Element => {
  const textareaRef = useRef<Nullable<HTMLTextAreaElement>>(null);
  const [notes, setNotes] = useState<string>(getNotes());
  const handleTextAreaChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setNotes(value);
      setStorageNotes(value);
    },
    []
  );

  const handleClose = useCallback(() => {
    const userNotes = textareaRef?.current?.textContent || '';
    setStorageNotes(userNotes);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <UtilPopup
      disableBottomPadding
      icon={<NoteEditIcon />}
      onClose={handleClose}
      title={Utils.Notes}
    >
      <>
        <NotesWrapper>
          <textarea
            onChange={handleTextAreaChange}
            placeholder="Write your notes here..."
            ref={textareaRef}
            value={notes}
          />
        </NotesWrapper>
        <Footer>
          *Please note that notes won&apos;t be saved once session is ended
        </Footer>
      </>
    </UtilPopup>
  );
};

export default UtilNotes;
