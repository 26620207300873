import React, { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import Collapse from '@mui/material/Collapse';

const StyledBox = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    ...typography.button,
    color: palette.text.disabled,

    '&.popper-header': {
      padding: spacing(2, 0, 4, 3),
      color: palette.text.secondary,
    },
  })
);

interface NavigationHeaderProps extends BoxProps {
  expanded?: boolean;
}

export const NavigationHeader = forwardRef<typeof Box, NavigationHeaderProps>(
  ({ children, expanded = true, ...props }, ref): JSX.Element => (
    <Collapse collapsedSize={0} in={expanded} unmountOnExit>
      <StyledBox ref={ref} {...props}>
        <Fade in={expanded} unmountOnExit>
          <span>{children}</span>
        </Fade>
      </StyledBox>
    </Collapse>
  )
);

NavigationHeader.displayName = 'NavigationHeader';
