import { useMediaQueries } from '@react-hook/media-query';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DEVICE_SIZE } from 'types';
import { Breakpoint } from 'theme';

export const useMediaScreen = (
  width: string,
  minmax: 'min' | 'max' = 'min'
) => {
  const { matchesAll } = useMediaQueries({
    screen: 'screen',
    width: `(${minmax}-width: ${width})`,
  });

  return matchesAll;
};

export function useIsDesktop() {
  const theme = useTheme();

  return { isDesktop: useMediaQuery(theme.breakpoints.up('xl')) };
}
export function useIsTablet() {
  const theme = useTheme();

  return { isTablet: useMediaQuery(theme.breakpoints.between('md', 'xl')) };
}
export function useIsMobile() {
  const theme = useTheme();

  return { isMobile: useMediaQuery(theme.breakpoints.down('md')) };
}

export const useIsInRange = (min: Breakpoint, max: Breakpoint) => {
  const theme = useTheme();

  const isBiggerThan = useMediaQuery(theme.breakpoints.up(min));
  const isSmallerThan = useMediaQuery(theme.breakpoints.down(max));

  return isBiggerThan && isSmallerThan;
};

export function useDeviceSize() {
  const { isDesktop } = useIsDesktop();
  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();
  if (isDesktop) {
    return DEVICE_SIZE.DESKTOP;
  }
  if (isTablet) {
    return DEVICE_SIZE.TABLET;
  }
  if (isMobile) {
    return DEVICE_SIZE.MOBILE;
  }

  return DEVICE_SIZE.UNKNOW;
}
