import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface NewVersionState {
  isNewVersion: boolean;
  setIsNewVersion: (valeu: boolean) => void;
}

const newVersionState = create<NewVersionState>()(set => ({
  isNewVersion: false,
  setIsNewVersion: isNewVersion => set({ isNewVersion }),
}));

const useNewVersionState = () => newVersionState(state => state, shallow);

export default useNewVersionState;
