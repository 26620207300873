import React, { memo, useCallback, useEffect } from 'react';

import { PermissionsModal } from 'components/Permissions';
import { ConductPolicy } from 'components/ConductPolicy';
import { useAuth } from 'Auth';
import { Children } from 'types';
import useGroupStudyGuardState from './useGroupStudyGuardState';
import { useAVPermissions } from 'components/Agora';
import { usePrevious } from 'hooks';
import { CircularProgress } from 'components/CircularProgress';

interface GroupStudyGuardProps {
  blockContent?: boolean;
  permissionOptional?: boolean;
  children?: Children;
  checkTOC?: boolean;
  checkAudio?: boolean;
  checkVideo?: boolean;
  noLoading?: boolean;
}

const GroupStudyGuard = ({
  blockContent = false,
  permissionOptional,
  checkTOC,
  checkAudio,
  checkVideo,
  children,
  noLoading = false,
}: GroupStudyGuardProps): JSX.Element => {
  const { setGuardActive } = useGroupStudyGuardState();
  const {
    audioAdmitted,
    videoAdmitted,
    checkAudioPermissions,
    checkVideoPermissions,
  } = useAVPermissions();

  const { tokenDecoded } = useAuth();
  const { tocAccepted } = tokenDecoded || {};

  const showTOC = Boolean(checkTOC && !tocAccepted);

  const showPermissionModal =
    Boolean((checkAudio && !audioAdmitted) || (checkVideo && !videoAdmitted)) &&
    !showTOC;

  const prev = usePrevious(showPermissionModal);

  const showLoading = !noLoading && !showPermissionModal && !showTOC;

  const anyReasonToBlock = showTOC || showPermissionModal || showLoading;

  const showContent = !blockContent || (blockContent && !anyReasonToBlock);

  const handleCheckPermissions = useCallback(async () => {
    if (checkAudio) {
      await checkAudioPermissions();
    }

    if (checkVideo) {
      await checkVideoPermissions();
    }
  }, [checkAudio, checkAudioPermissions, checkVideo, checkVideoPermissions]);

  useEffect(() => {
    if (prev && !showPermissionModal) {
      window.location.reload();
    }
  }, [showPermissionModal, prev]);

  useEffect(() => {
    handleCheckPermissions();
  }, [handleCheckPermissions]);

  useEffect(() => {
    setGuardActive(anyReasonToBlock);
  }, [anyReasonToBlock, setGuardActive]);

  return (
    <>
      {showTOC ? <ConductPolicy open={!tocAccepted} /> : null}
      {showPermissionModal ? (
        <PermissionsModal
          onCheckAgain={handleCheckPermissions}
          open={showPermissionModal}
          permissionOptional={permissionOptional}
          variant={!audioAdmitted ? 'microphone' : 'camera'}
        />
      ) : null}
      {showLoading ? (
        <CircularProgress description="Waiting for permissions" />
      ) : null}
      {showContent && children ? children : null}
    </>
  );
};

export default memo(GroupStudyGuard);
