import { WebSocketLink } from '@quesmed/types-rn/utils';

import { SUBSCRIPTION_ENDPOINT } from 'config/constants';
import { localStorageTyped } from 'utils';

const { getItem } = localStorageTyped<string>('usertoken');

export const subscriptionLink = new WebSocketLink({
  url: SUBSCRIPTION_ENDPOINT,
  connectionParams: {
    Authorization: getItem() ? `Bearer ${getItem()}` : '',
  },
});
