import React from 'react';
import { mdiHelpCircleOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const HelpCircleOutlineIcon = (props: IconProps) => (
  <Icon path={mdiHelpCircleOutline} {...props} />
);

export default HelpCircleOutlineIcon;
