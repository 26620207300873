import dayjs, { OpUnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Nullable } from 'types';

dayjs.extend(relativeTime);

type DateType = Date | number;

export const getRelativeTime = (
  date: DateType,
  fromDate?: Nullable<DateType>,
  unit?: OpUnitType
) => {
  const targetDate = dayjs(date);
  const fromDateObj = fromDate ? dayjs(fromDate) : dayjs();

  if (unit) {
    const roundedTargetDate = targetDate.startOf(unit);

    return roundedTargetDate.from(fromDateObj, true);
  }

  return targetDate.from(fromDateObj, true);
};
