import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ECancelReasons } from '@quesmed/types-rn/models';

import { H4 } from 'components/Typography';
import { Button } from 'components/Button';
import { SwapHorizontalIcon } from 'components/Icons';
import SubscriptionHeader from './SubscriptionHeader';
import { setProductPeriod } from 'utils';
import { useCancelSubscription } from 'components/Settings/useCancelSubscription';
import {
  CancelationInput,
  SubscriptionCancelationModal,
} from './SubscriptionCancelationModal';
import { AppProductType } from 'types';
import { CURRENCY } from 'config/constants';

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  disply: 'flex',
  flexDirection: 'column',
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: spacing(2),
  padding: spacing(8),

  '& .MuiBox-root:only-child': {
    position: 'relative',
    height: '100%',
  },
}));

const StyledHeaderWrapper = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),

    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
);

const StyledPrice = styled(Box)({
  '& .MuiTypography-h4': {
    display: 'inline',
  },
});

const StyledButtonWrapper = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    gap: spacing(2),
    flexDirection: 'column',
    marginTop: spacing(6),
    justifyContent: 'flex-end',

    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  })
);

interface SubscriptionCardProps {
  duration?: number;
  price?: number;
  renewsDate: number | Date | null;
  subscription: AppProductType;
  subscriptionId: number;
  onChangePlan: () => void;
  isCanceled: boolean;
  canBeUpdated: boolean;
}

const SubscriptionCard = ({
  duration,
  onChangePlan,
  price,
  renewsDate,
  subscription,
  subscriptionId,
  isCanceled,
  canBeUpdated,
}: SubscriptionCardProps): JSX.Element => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { cancelSubscription, loading } = useCancelSubscription();

  const handleCancelSubscriptionReason = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelSubscription = async ({ comment }: CancelationInput) => {
    await cancelSubscription({
      variables: {
        subscriptionId: Number(subscriptionId),
        feedback: ECancelReasons.OTHER,
        comment,
      },
    });
    setIsCancelModalOpen(false);
  };

  const handleRenewSubscription = () => {
    cancelSubscription({
      variables: {
        subscriptionId: Number(subscriptionId),
      },
    });
  };

  return (
    <StyledBox>
      <StyledHeaderWrapper>
        <SubscriptionHeader
          canBeUpdated={canBeUpdated}
          isCanceled={isCanceled}
          renewsDate={renewsDate}
          subscription={subscription}
        />
        {price && duration && (
          <StyledPrice>
            <H4>
              {CURRENCY}
              {price}
            </H4>
            /{setProductPeriod(duration)}
          </StyledPrice>
        )}
      </StyledHeaderWrapper>
      <StyledButtonWrapper>
        {canBeUpdated ? (
          <Button
            color="secondary"
            loading={loading}
            onClick={
              isCanceled
                ? handleRenewSubscription
                : handleCancelSubscriptionReason
            }
            secondary
          >
            {isCanceled ? 'Turn on auto-renewal' : 'Cancel subscription'}
          </Button>
        ) : null}
        <Button color="secondary" onClick={onChangePlan} secondary>
          change plan <SwapHorizontalIcon />
        </Button>
      </StyledButtonWrapper>
      <SubscriptionCancelationModal
        isOpen={isCancelModalOpen}
        loading={loading}
        onClose={() => setIsCancelModalOpen(false)}
        onSubmit={handleCancelSubscription}
      />
    </StyledBox>
  );
};
export default SubscriptionCard;
