import React from 'react';
import { mdiAlertCircleOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const AlertCircleOutlineIcon = (props: IconProps) => (
  <Icon path={mdiAlertCircleOutline} {...props} />
);

export default AlertCircleOutlineIcon;
