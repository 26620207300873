/**
 *
 * Helper that returns typed getter & setter for session storage
 * @param key (string) The session storage key to which the getter and setter are connected
 */
export function sessionStorageTyped<T>(key: string) {
  function setItem(item: T) {
    sessionStorage.setItem(key, JSON.stringify(item));
  }
  function getItem() {
    const dataString = sessionStorage.getItem(key);
    if (!dataString) return null;

    return JSON.parse(dataString) as T;
  }
  function removeItem() {
    sessionStorage.removeItem(key);
  }

  return { setItem, getItem, removeItem };
}
