import React, { forwardRef, ReactNode, useRef } from 'react';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { FormLabel } from 'components/FormLabel';
import { RadioBoxBlankIcon, RadioBoxMarkedIcon } from 'components/Icons';
import { Nullable, QuestionAnswerStatus } from 'types';
import { assignInputRef } from 'utils/index';

export const StyledRadio = styled(MuiRadio)(({ theme: { palette } }) => ({
  '.MuiSvgIcon-root': {
    color: palette.icon.main,
  },

  '&.Mui-checked': {
    '.MuiSvgIcon-root': {
      color: palette.primary.main,
    },
  },
}));

const StyledRadioGroupWrapper = styled(Box)(({ theme: { spacing } }) => ({
  '&.with-images-wrapper .MuiFormGroup-root': {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: spacing(4),
  },
}));

const StyledRadioButton = styled(Box)(({ theme: { palette, spacing } }) => ({
  '&.with-image-button': {
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: spacing(2),
    padding: spacing(3, 2.5),
    display: 'flex',
    flexDirection: 'column',

    ':hover': {
      cursor: 'pointer',
    },

    '&:has(.Mui-checked)': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
}));

export interface RadioOption {
  label: string;
  value: string | number | boolean;
  image?: JSX.Element;
  status?: QuestionAnswerStatus;
  complementaryContent?: ReactNode;
}

export interface RadioProps
  extends MuiRadioProps,
    Pick<FormControlLabelProps, 'labelPlacement'> {
  label?: string | ReactNode;
  value?: string | number | boolean;
  image?: JSX.Element;
  labelSx?: FormControlLabelProps['sx'];
  status?: QuestionAnswerStatus;
  complementaryContent?: ReactNode;
  disabled?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      label,
      labelPlacement,
      labelSx,
      value,
      image,
      className,
      complementaryContent,
      disabled,
      ...rest
    },
    ref
  ) => {
    const radioRef = useRef<Nullable<HTMLInputElement>>(null);

    const handleComponentClick = () => {
      if (radioRef.current) {
        radioRef.current.click();
      }
    };

    return (
      <StyledRadioButton
        className={clsx(className, {
          'with-image-button': Boolean(image),
        })}
        onClick={handleComponentClick}
      >
        {image}
        <FormControlLabel
          control={
            <StyledRadio
              checkedIcon={<RadioBoxMarkedIcon />}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={disabled}
              icon={<RadioBoxBlankIcon />}
              inputRef={assignInputRef(radioRef, ref)}
              {...rest}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
          sx={labelSx}
          value={value}
        />
        {complementaryContent}
      </StyledRadioButton>
    );
  }
);

Radio.displayName = 'Radio';

export interface RadioGroupProps
  extends MuiRadioGroupProps,
    Pick<FormControlLabelProps, 'labelPlacement'> {
  id: string;
  label?: ReactNode;
  options: RadioOption[];
  component?: typeof Radio;
  readOnly?: boolean;
  disabled?: boolean;
}

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      id,
      label: title,
      labelPlacement,
      options,
      value: sourceValue,
      name,
      component: Component = Radio,
      readOnly,
      className,
      disabled,
      ...props
    },
    ref
  ) => (
    <FormControl>
      {title ? <FormLabel id={id}>{title}</FormLabel> : null}
      <MuiRadioGroup
        aria-labelledby={id}
        name={name}
        value={sourceValue}
        {...props}
      >
        {options.map(
          ({ label, value, image, status, complementaryContent }) => (
            <Component
              className={className}
              complementaryContent={complementaryContent}
              disabled={disabled}
              image={image ? image : undefined}
              key={label}
              label={label}
              labelPlacement={labelPlacement}
              readOnly={readOnly}
              ref={ref}
              status={status}
              value={value}
            />
          )
        )}
      </MuiRadioGroup>
    </FormControl>
  )
);

RadioGroup.displayName = 'RadioGroup';

export type FormRadioGroupProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> &
  RadioGroupProps;

export function FormRadioGroup<T extends FieldValues>({
  control,
  defaultValue,
  rules,
  name,
  shouldUnregister,
  options,
  ...rest
}: FormRadioGroupProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onBlur, onChange, ref, value },
        fieldState: { error },
      }) => {
        const withImages = options.every(option => 'image' in option);

        return (
          <StyledRadioGroupWrapper
            className={clsx({ 'with-images-wrapper': withImages })}
          >
            <RadioGroup
              {...rest}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              options={options}
              ref={ref}
              value={value}
            />
            {error ? (
              <FormHelperText error={Boolean(error)}>
                {error.message}
              </FormHelperText>
            ) : null}
          </StyledRadioGroupWrapper>
        );
      }}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
