import React from 'react';
import { mdiCheckCircle } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CheckCircleIcon = (props: IconProps) => (
  <Icon path={mdiCheckCircle} {...props} />
);

export default CheckCircleIcon;
