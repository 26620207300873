import React, { forwardRef } from 'react';
import { mdiProgressHelper } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ProgressInitialIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <Icon path={mdiProgressHelper} ref={ref} {...props} />
);

ProgressInitialIcon.displayName = 'ProgressInitialIcon';

export default ProgressInitialIcon;
