import {
  EProductType,
  ETopicType,
  EUserLearningStatus,
} from '@quesmed/types-rn/models';

import { KnowledgeLibraryTopicType } from './types';

export const TOPIC_TYPE_ORDER: KnowledgeLibraryTopicType[] = [
  ETopicType.CLINICAL,
  ETopicType.DATA_INTERPRETATION,
  ETopicType.PRE_CLINICAL,
  ETopicType.ANATOMY,
  ETopicType.SJT,
];

export const STATION_TYPE_ORDER: EProductType[] = [
  EProductType.HISTORY_TAKING,
  EProductType.COMMUNICATION_SKILLS,
  EProductType.EXAMINATION,
  EProductType.ABCDE,
  EProductType.PROCEDURE,
  EProductType.INTERPRETATION,
];

export const PACES_TYPE_ORDER: EProductType[] = [
  EProductType.ABDOMINAL,
  EProductType.RESPIRATORY,
  EProductType.CARDIOVASCULAR,
  EProductType.NEUROLOGY,
  EProductType.COMMUNICATION,
  EProductType.CONSULTATION,
];

export const STATUS_ORDER = [
  EUserLearningStatus.URGENT,
  EUserLearningStatus.REVISING,
  EUserLearningStatus.COMPLETED,
  EUserLearningStatus.UNREAD,
];

export const OSCE_SEARCH_LABEL = 'Search station by name...';
export const QBANK_SEARCH_LABEL = 'Search concept by name...';
export const VIDEO_SEARCH_LABEL = 'Search video by name...';
