import React from 'react';
import { Box, styled } from '@mui/material';
import { Calculator as CalculatorComponent } from 'react-mac-calculator';

import { CloseIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';

const CALCULATOR_WIDTH = '256px';
const CALCULATOR_HEIGHT = '412px';
const DISPLAY_HEIGHT = '132px';
const KEYPAD_HEIGHT = '280px';
const KEY_WIDTH = '64px';
const KEY_HEIGHT = '56px';
const ZERO_KEY_WIDTH = '128px';

const CalculatorWrapper = styled(Box)(
  ({ theme: { spacing, typography, palette, shape, elevation } }) => ({
    position: 'relative',
    borderRadius: shape.borderRadius,
    overflow: 'hidden',
    border: `1px solid ${palette.stroke.main}`,
    fontFeatureSettings: "'tnum' on, 'Inum' on",

    '& .calculator': {
      width: CALCULATOR_WIDTH,
      height: CALCULATOR_HEIGHT,
      boxShadow: elevation[0],
    },

    '& .calculator-display': {
      height: DISPLAY_HEIGHT,
      background: palette.calculator.result,
      color: palette.calculator.contrastText,
      ...typography.h3,

      '& .calculator-display__auto-scaling': {
        padding: spacing(6, 6, 2, 6),
        display: 'block',
        width: '100%',
        textAlign: 'right',
        bottom: 0,
      },
    },

    '& .calculator-keypad': {
      width: '100%',
      height: KEYPAD_HEIGHT,
    },

    '& .input-keys': {
      width: '100%',
    },

    '& .calculator .calculator-key': {
      width: KEY_WIDTH,
      height: KEY_HEIGHT,
      textAlign: 'center',
      borderColor: palette.calculator.borderColor,
      ...typography.h5,
    },

    '& .digit-keys .key-0': {
      width: ZERO_KEY_WIDTH,
      paddingLeft: 0,
    },

    '& .digit-keys .key-dot': {
      fontSize: '10px', // font size is too big by default for this key
    },

    '& .function-keys .calculator-key': {
      background: palette.calculator.utils,
      color: palette.calculator.contrastText,

      '&:hover': {
        background: palette.calculator.utilsHover,
      },

      '&:active': {
        background: palette.calculator.utilsActive,
      },
    },

    '& .digit-keys .calculator-key': {
      background: palette.calculator.numbers,
      color: palette.calculator.contrastText,
      '&:hover': {
        background: palette.calculator.numbersHover,
      },

      '&:active': {
        background: palette.calculator.numbersActive,
      },
    },

    '& .operator-keys .calculator-key': {
      background: palette.calculator.functions,

      '&:hover': {
        background: palette.calculator.functionsHover,
      },

      '&:active': {
        background: palette.calculator.functionsActive,
      },
    },
  })
);

const ClosePopupButton = styled(IconButton)(({ theme: { spacing } }) => ({
  position: 'absolute',
  top: spacing(3),
  right: spacing(2),
  zIndex: 1,
  padding: 0,
}));

interface UtilCalculatorProps {
  onClose: () => void;
}

export const UtilCalculator = ({
  onClose,
}: UtilCalculatorProps): JSX.Element => {
  return (
    <CalculatorWrapper>
      <ClosePopupButton icon={<CloseIcon />} onClick={onClose} />
      <CalculatorComponent />
    </CalculatorWrapper>
  );
};

export default UtilCalculator;
