import { useCallback } from 'react';
import { IStartOsceMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';

import { Nullable, OnRequestComplete } from 'types';
import useBuildMarksheetMutation from './useBuildMarksheetMutation';
import usePrestartMarksheetMutation from './usePrestartMarksheetMutation';
import useStartMarksheetMutation from './useStartMarksheetMutation';

const useStartSoloStudy = (
  onStartComplete?: OnRequestComplete<IStartOsceMarksheetData>
) => {
  const { buildLoading, buildMarksheet } = useBuildMarksheetMutation();
  const { prestartLoading, prestartMarksheet } = usePrestartMarksheetMutation();
  const { startLoading, startMarksheet } =
    useStartMarksheetMutation(onStartComplete);

  const loading = buildLoading || prestartLoading || startLoading;

  const startSoloStudy = useCallback(
    async (
      osceStationId: number,
      stationTime: number,
      readingTime: number,
      feedbackTime: number,
      initialOsceMarksheetId?: Nullable<number>,
      isTimed = true
    ) => {
      if (loading) {
        return;
      }

      const buildData = await buildMarksheet(
        osceStationId,
        initialOsceMarksheetId ? initialOsceMarksheetId : undefined
      );

      const { id } = buildData.data?.restricted.buildOsceMarksheet || {};

      const osceMarksheetId = Number(id);

      await prestartMarksheet(
        osceMarksheetId,
        stationTime,
        readingTime,
        feedbackTime,
        isTimed
      );

      await startMarksheet(osceMarksheetId);
    },
    [buildMarksheet, loading, prestartMarksheet, startMarksheet]
  );

  return { loading, startSoloStudy };
};

export default useStartSoloStudy;
