import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import Button, { ButtonProps } from './Button';
import { constSize } from 'utils';

const outlinedClass = '&.MuiLoadingButton-root.MuiButton-outlinedSecondary';

const StyledButton = styled(Button)(
  ({ theme: { breakpoints, palette, spacing, transitions, typography } }) => ({
    padding: spacing(4),
    borderRadius: '9px',
    backgroundColor: palette.background.paper,
    ...constSize('100%', spacing(14)),
    ...typography.body1Medium,
    textTransform: 'none',
    letterSpacing: 'normal',
    color: palette.text.primary,
    transition: transitions.create(['border-color', 'color']),
    justifyContent: 'flex-start',

    '&.role': {
      ...constSize('100%', spacing(38)),
    },

    [`.bottom-bar ${outlinedClass}`]: {
      padding: 0,
      ...constSize(spacing(12)),
      justifyContent: 'center',
    },

    [breakpoints.up('xl')]: {
      ...constSize('100%', spacing(38)),
      justifyContent: 'center',
    },

    [outlinedClass]: {
      borderColor: palette.stroke.main,
      backgroundColor: palette.background.paper,
    },

    [`${outlinedClass}:hover`]: {
      backgroundColor: palette.background.paper,
      borderColor: palette.stroke.dark,
      color: palette.secondary.dark,
    },

    [`${outlinedClass}.Mui-disabled`]: {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      color: palette.secondary.light,
      borderColor: palette.stroke.main,
      backgroundColor: palette.background.paper,
    },

    [`${outlinedClass}.active, ${outlinedClass}.inactive`]: {
      pointerEvents: 'none',
    },

    [`${outlinedClass}.active:not(.MuiLoadingButton-loading)`]: {
      borderColor: palette.success.main,
      backgroundColor: palette.success.extraLight,
    },
  })
);

const CardButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, color = 'secondary', isBottomBar, ...props },
    ref
  ): JSX.Element => (
    <StyledButton
      className={clsx({ 'bottom-bar': isBottomBar })}
      color={color}
      ref={ref}
      secondary
      {...props}
    >
      {children}
    </StyledButton>
  )
);

CardButton.displayName = 'CardButton';

export default CardButton;
