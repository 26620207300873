import React from 'react';
import { mdiCreditCardOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CreditCardOutlineIcon = (props: IconProps) => (
  <Icon path={mdiCreditCardOutline} {...props} />
);

export default CreditCardOutlineIcon;
