import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

import { Body, H4 } from 'components/Typography';

const Container = styled(Box)(({ theme: { breakpoints, mixins } }) => ({
  width: '100%',
  height: `calc(100dvh - ${mixins.appBar.mobile.minHeight}px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  [breakpoints.up('md')]: {
    height: `calc(100dvh - ${mixins.appBar.desktop.minHeight}px)`,
  },
}));

const InfoBox = styled(Body)(({ theme: { mixins, spacing, breakpoints } }) => ({
  textAlign: 'center',
  width: '100%',
  margin: '0 auto',
  maxWIdth: `${mixins.content.maxWidth}px`,
  padding: spacing(4),

  [breakpoints.up('md')]: {
    padding: spacing(6),
  },

  [breakpoints.up('xl')]: {
    padding: spacing(8),
  },
}));

interface NoDataFoundProps {
  text?: string;
}

const NoDataFound = ({
  text = 'Data not found',
}: NoDataFoundProps): JSX.Element => (
  <Container>
    <H4>Not Found</H4>
    <InfoBox bold>{text}</InfoBox>
  </Container>
);

export default NoDataFound;
