import React from 'react';
import { EAppType } from '@quesmed/types-rn/models';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Modal, ModalProps } from 'components/Modal';
import { QuesmedProductData } from 'types';
import { ProductItem } from 'components/ProductItem';

const ProductsContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: spacing(4),

    '& > *': {
      width: '100%',
    },

    [breakpoints.up('sm')]: {
      paddingBottom: spacing(8),
    },
  })
);

interface DemoModalProps extends ModalProps {
  app: EAppType;
  products: QuesmedProductData[];
}

const DemoModal = ({
  open,
  onClose,
  app,
  products,
  ...props
}: DemoModalProps): JSX.Element => (
  <Modal
    danger
    maxWidth="sm"
    noPaddingY
    onClose={onClose}
    open={open}
    showCloseButton
    showControls={false}
    sizeVariant="sm"
    title="Try out demo"
    {...props}
  >
    <ProductsContainer>
      {products.map(({ active, value, name }) => (
        <ProductItem
          active={active}
          app={app}
          expired={false}
          helperText="Try out for free"
          key={value}
          label={name}
          locked
          onTryOut={onClose}
          product={value}
        />
      ))}
    </ProductsContainer>
  </Modal>
);

export default DemoModal;
