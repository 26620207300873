import { ForwardedRef, MutableRefObject } from 'react';

import { Nullable } from 'types/general';

export const assignInputRef =
  <T = HTMLInputElement>(
    ref: MutableRefObject<Nullable<T>>,
    forwardedRef: ForwardedRef<T>
  ) =>
  (element: T) => {
    if (forwardedRef && typeof forwardedRef === 'function') {
      forwardedRef(element);
    }
    if (forwardedRef && typeof forwardedRef !== 'function') {
      (forwardedRef as MutableRefObject<Nullable<T>>).current = element;
    }
    ref.current = element;
  };
