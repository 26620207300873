import { useCallback } from 'react';
import { EMarksheetAction, EMarksheetState } from '@quesmed/types-rn/models';
import { MODIFY_MARKSHEET_STATE } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { MODIFY_MARKSHEET_STATE_FRAGMENT } from '@quesmed/types-rn/resolvers/fragments';

import useModifyMarksheetMutation from './useModifyMarksheetMutation';

const useChangeMarksheetState = (marksheetId?: number) => {
  const [modify, { loading }] = useModifyMarksheetMutation(
    MODIFY_MARKSHEET_STATE,
    MODIFY_MARKSHEET_STATE_FRAGMENT
  );

  const changeMarksheetState = useCallback(
    async (state: EMarksheetState) => {
      if (marksheetId) {
        return modify({
          variables: {
            input: {
              action: EMarksheetAction.STATE_CHANGE,
              marksheetId,
              state,
            },
          },
        });
      }
    },
    [marksheetId, modify]
  );

  return { changeMarksheetState, loading };
};

export default useChangeMarksheetState;
