import React from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';

import { IconButton } from 'components/IconButton';
import { CloseIcon } from 'components/Icons';

interface SnackbarCloseButtonProps {
  snackbarKey: SnackbarKey;
}

const SnackbarCloseButton = ({
  snackbarKey,
}: SnackbarCloseButtonProps): JSX.Element => {
  const { closeSnackbar } = useSnackbar();

  const handleClose = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButton onClick={handleClose} size="small">
      <CloseIcon />
    </IconButton>
  );
};

export default SnackbarCloseButton;
