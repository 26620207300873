import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface ContentProps extends BoxProps {
  expanded: 0 | 1;
  open: 0 | 1;
}

const ContentBox = styled(Box)<ContentProps>(
  ({ expanded, open, theme: { breakpoints, mixins, transitions } }) => ({
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    paddingTop: `${mixins.appBar.mobile.minHeight}px`,
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),

    [breakpoints.up('md')]: {
      paddingTop: `${mixins.appBar.desktop.minHeight}px`,
    },

    [breakpoints.up('xl')]: {
      width: open
        ? `calc(100% - ${
            mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth
          }px)`
        : '100%',
      marginLeft: open
        ? mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth
        : 0,
    },
  })
);

const Content = ({ children, ...props }: ContentProps): JSX.Element => (
  <ContentBox {...props}>{children}</ContentBox>
);

export default Content;
