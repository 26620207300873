import React from 'react';
import { mdiCards } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FlashcardsIcon = (props: IconProps) => (
  <Icon path={mdiCards} {...props} />
);

export default FlashcardsIcon;
