import React from 'react';

import PlatformController from 'context/PlatformContext';
import { AppStateProviders } from 'context/AppState/AppState';
import { ThemeProvider } from 'theme/ThemeProvider';
import { AuthController } from 'Auth';
import { ApolloProvider } from 'Apollo/ApolloProvider';
import { Router } from 'Router';
import AppContentController from './AppContentController';
import { NewVersionController } from 'components/NewVersionController';
import { OnlineStatus } from 'components/OnlineStatus';
import { SnackbarProvider } from 'components/Snackbar';
import QuizStatusController from './QuizStatusController';

const App = (): JSX.Element => (
  <ApolloProvider>
    <ThemeProvider>
      <SnackbarProvider>
        <AuthController />
        <Router>
          <AppStateProviders>
            <QuizStatusController />
            <PlatformController />
            <AppContentController />
          </AppStateProviders>
          <OnlineStatus />
        </Router>
        <NewVersionController />
      </SnackbarProvider>
    </ThemeProvider>
  </ApolloProvider>
);

export default App;
