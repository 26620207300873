import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  ADD_PRODUCT_FEEDBACK,
  IAddProductFeedbackData,
  IAddProductFeedbackVar,
  IUpdateUserSettingsData,
  IUpdateUserSettingsVar,
  optimisticAddProductFeedback,
  optimisticUpdateUserSettings,
  UPDATE_USER_SETTINGS,
  updateCacheOnUpdateUserSettings,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';
import { useGetUser } from 'hooks/useGetUser';

interface UpdateUserSettingsOptions {
  successMsg?: string;
  errorMsg?: string;
  successCallback?: () => void;
}

const useUpdateUserSettings = () => {
  const { user } = useGetUser();
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserSettingsMutation, { loading }] = useMutation<
    IUpdateUserSettingsData,
    IUpdateUserSettingsVar
  >(UPDATE_USER_SETTINGS, {
    update: updateCacheOnUpdateUserSettings,
  });

  const [addProductFeedback] = useMutation<
    IAddProductFeedbackData,
    IAddProductFeedbackVar
  >(ADD_PRODUCT_FEEDBACK);

  const updateUserSettings = useCallback(
    async (
      input: IUpdateUserSettingsVar['input'],
      options?: UpdateUserSettingsOptions
    ) => {
      const { successMsg, errorMsg, successCallback } = options || {};

      return updateUserSettingsMutation({
        variables: {
          input,
        },
        optimisticResponse: user?.settings
          ? optimisticUpdateUserSettings(user.settings)
          : undefined,
        onCompleted: () => {
          if (successCallback) {
            successCallback();
          }

          if (successMsg) {
            enqueueSnackbar(successMsg);
          }
        },
        onError: () => {
          if (errorMsg) {
            enqueueSnackbar(errorMsg);
          }
        },
      });
    },
    [updateUserSettingsMutation, user, enqueueSnackbar]
  );

  const updateProductFeedback = useCallback(
    async (
      input: IAddProductFeedbackVar,
      options?: UpdateUserSettingsOptions
    ) => {
      const { successMsg, errorMsg, successCallback } = options || {};

      return addProductFeedback({
        variables: input,
        optimisticResponse: optimisticAddProductFeedback,
        onCompleted: () => {
          if (successCallback) {
            successCallback();
          }

          if (successMsg) {
            enqueueSnackbar(successMsg);
          }
        },
        onError: () => {
          if (errorMsg) {
            enqueueSnackbar(errorMsg);
          }
        },
      });
    },
    [addProductFeedback, enqueueSnackbar]
  );

  return { updateUserSettings, updateProductFeedback, loading };
};

export default useUpdateUserSettings;
