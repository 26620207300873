import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { localStorageTyped } from 'utils';
import { DEFAULT_TEXT_SCALE } from 'config/constants';

const { getItem, setItem } = localStorageTyped<number>('readingTextScale');

interface MarkdownReadingScale {
  scale: number;
  setScale: (value: number) => void;
  resetScale: () => void;
}

const useMarkdownReadingStore = create<MarkdownReadingScale>()(set => ({
  scale: getItem() || DEFAULT_TEXT_SCALE,
  setScale: (scale: number) => {
    set({ scale });
    setItem(scale);
  },
  resetScale: () => {
    set({ scale: DEFAULT_TEXT_SCALE });
    setItem(DEFAULT_TEXT_SCALE);
  },
}));

export const useMarkdownTextScale = () =>
  useMarkdownReadingStore(state => state, shallow);
