import React from 'react';
import { mdiHuman } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const AccessibilityIcon = (props: IconProps) => (
  <Icon path={mdiHuman} {...props} />
);

export default AccessibilityIcon;
