import { onError } from '@apollo/client/link/error';

import { SnackBar } from 'types';
import { handleGraphQLErrors } from './handleGraphQLErrors';
import { handleNetworkError } from './handleNetworkError';

export const errorLink = (snackbar: SnackBar) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const { variables, operationName } = operation || {};

    const details = { operationName, variables };

    if (graphQLErrors && graphQLErrors.length > 0) {
      handleGraphQLErrors(graphQLErrors, snackbar, details);
    }

    if (networkError) {
      handleNetworkError(networkError, snackbar, details);
    }
  });
