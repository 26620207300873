import React, { ComponentType, useEffect } from 'react';
import { useStats } from 'react-instantsearch';
import isNil from 'lodash/isNil';

import { Children } from 'types';
import { IconProps } from 'components/Icons';
import { ExpandableSection } from 'components/ExpandableSection';

export interface SearchResultSectionProps {
  icon: ComponentType<IconProps>;
  title: string;
  children?: Children;
  count?: number;
  asLink?: boolean;
  onClick?: () => void;
  setCount: (value: number) => void;
}

export const SearchResultSection = ({
  children,
  count,
  icon,
  title,
  onClick,
  asLink = false,
  setCount,
}: SearchResultSectionProps): JSX.Element => {
  const { nbHits } = useStats();

  const resultsCount = isNil(count) ? nbHits : count;
  useEffect(() => {
    if (!isNil(resultsCount)) {
      setCount(resultsCount);
    }
  }, [resultsCount, setCount]);

  return (
    <ExpandableSection
      expanded={Boolean(children)}
      icon={icon}
      onToggle={onClick}
      size="small"
      title={`${title} (${resultsCount})`}
      variant={asLink ? 'link' : 'normal'}
    >
      {children}
    </ExpandableSection>
  );
};

export default SearchResultSection;
