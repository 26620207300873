import { useEffect, useRef, useState } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { useIsTabActive } from 'hooks/useIsTabActive';
import {
  clearTimeoutSafely,
  getSubscriptions,
  localStorageTyped,
  olderThan,
  parseDate,
  round,
  values,
} from 'utils';
import { Nullable, ProductState } from 'types';
import { useAuth } from './useAuth';
import useRenewToken from './useRenewToken';
import { logoutUser } from './logoutUser';

const ACTIVE_INTERVAL = 2; // hours
const TOKEN_RENEW_MARGIN = 43200; //12 hours
const TOKEN_RENEW_MIN = 10; // 10 sec
const EXPIRATION_CHECK_INTERVAL = 1000 * 60 * 60 * 6; // 6 hours

const { getItem, setItem } = localStorageTyped<number>('tokenRenewalCheckTime');

interface LastTokenCheckTime {
  checkTime: number | null;
  setCheckTime: (value: number) => void;
}

const useLastTokenCheckTimeState = create<LastTokenCheckTime>()(set => ({
  checkTime: getItem(),
  setCheckTime: (checkTime: number) => {
    set({ checkTime });
    setItem(checkTime);
  },
}));

const useCheckTimeInterval = () =>
  useLastTokenCheckTimeState(state => state, shallow);

const useTokenCheckRenewal = (
  checkInterval: number,
  lastCheckTime: number | null
) => {
  const [shouldRenew, setShouldRenew] = useState(false);

  const timeout = useRef<number>();

  useEffect(() => {
    if (!lastCheckTime) {
      return;
    }

    const diff = checkInterval - (new Date().getTime() - lastCheckTime);

    timeout.current = window.setTimeout(() => {
      setShouldRenew(true);
      clearTimeoutSafely(timeout.current);
    }, diff);

    return () => {
      clearTimeoutSafely(timeout.current);
      setShouldRenew(false);
    };
  }, [lastCheckTime, checkInterval]);

  return shouldRenew;
};

export const useShouldRenewToken = () => {
  const isActive = useIsTabActive();
  const { isAuthenticated, tokenDecoded } = useAuth();
  const { renewToken } = useRenewToken({
    onError: () => logoutUser(),
  });
  const { checkTime, setCheckTime } = useCheckTimeInterval();
  const renewTimeoutRef = useRef<Nullable<number>>(null);

  const shouldRenewToken = useTokenCheckRenewal(
    EXPIRATION_CHECK_INTERVAL,
    checkTime
  );

  useEffect(() => {
    if (tokenDecoded) {
      const { exp } = tokenDecoded;
      const expirationTime = new Date(parseDate(exp)).getTime();
      const timeDiff = round((expirationTime - new Date().getTime()) / 1000);

      if (timeDiff < TOKEN_RENEW_MARGIN) {
        if (timeDiff >= TOKEN_RENEW_MIN) {
          renewToken();
        } else {
          logoutUser();
        }
      } else {
        const timeout = (timeDiff - TOKEN_RENEW_MARGIN) * 1000;
        renewTimeoutRef.current = window.setTimeout(renewToken, timeout);
      }

      return () => {
        clearTimeoutSafely(renewTimeoutRef.current);
      };
    }
  }, [renewToken, tokenDecoded]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isActive &&
      (!checkTime || olderThan(checkTime, ACTIVE_INTERVAL))
    ) {
      renewToken();

      setCheckTime(new Date().getTime());
    }
  }, [isActive, isAuthenticated, checkTime, setCheckTime, renewToken]);

  useEffect(() => {
    if (shouldRenewToken) {
      const productAboutToEnd = values(getSubscriptions(tokenDecoded)).some(
        state => state === ProductState.AboutToExpired
      );

      if (productAboutToEnd) {
        renewToken();
      }

      setCheckTime(new Date().getTime());
    }
  }, [tokenDecoded, shouldRenewToken, setCheckTime, renewToken]);
};
