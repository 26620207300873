import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { EMarksheetAction } from '@quesmed/types-rn/models';
import {
  IBuildConfigData,
  MODIFY_BUILDER_CONFIG,
  optimisticModifyMarksheetBuilderConfig,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { BUILDER_CONFIG_FIELDS } from '@quesmed/types-rn/resolvers/fragments';

import useModifyMarksheetMutation from './useModifyMarksheetMutation';

const useChangeBuilderConfig = (marksheetId?: number) => {
  const client = useApolloClient();
  const [modify, { loading }] = useModifyMarksheetMutation(
    MODIFY_BUILDER_CONFIG,
    BUILDER_CONFIG_FIELDS
  );

  const changeBuilderConfig = useCallback(
    async (config: IBuildConfigData) => {
      if (marksheetId) {
        return modify({
          variables: {
            input: {
              action: EMarksheetAction.BUILDER_CONFIG_SELECTION,
              marksheetId,
              preStartData: {
                builderConfig: {
                  ...config,
                },
              },
            },
          },
          optimisticResponse: optimisticModifyMarksheetBuilderConfig(
            marksheetId,
            config,
            client
          ),
        });
      }
    },
    [client, marksheetId, modify]
  );

  return { changeBuilderConfig, loading };
};

export default useChangeBuilderConfig;
