import React, {
  ComponentType,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import {
  ENotificationActionType,
  ENotificationType,
} from '@quesmed/types-rn/models';
import { styled } from '@mui/material';
import clsx from 'clsx';

import SourceAvatar from 'components/Avatar';
import {
  BellOutlineIcon,
  CommentsIcon,
  IconProps,
  InfoIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from 'components/Icons';
import { constSize } from 'utils';

interface AvatarProps {
  icon:
    | ForwardRefExoticComponent<
        Omit<IconProps, 'ref'> & RefAttributes<SVGSVGElement>
      >
    | ComponentType<IconProps>;
  noBackground?: boolean;
  className?: string;
}

const AVATAR_SIZE = '48px';

export const AvatarContainer = styled(SourceAvatar)(
  ({ theme: { palette } }) => ({
    borderRadius: '100px',
    position: 'relative',
    ...constSize(AVATAR_SIZE),
    overflow: 'visible',

    '& .MuiSvgIcon-root': {
      fill: palette.primary.contrastText,
      background: 'transparent',
    },

    '&.comment': {
      backgroundColor: palette.primary.main,
    },
    '&.announcement': {
      backgroundColor: palette.success.dark,
    },
    // TODO: Added if case of using this types in future - check if types are correct
    '&.payment, &.maintenance': {
      fill: palette.warning.contrastText,
    },
    '&.friend_request': {
      backgroundColor: palette.success.dark,
    },
  })
);

export const Avatar = ({ icon: Icon, className }: AvatarProps): JSX.Element => {
  return <AvatarContainer className={className} fallback={<Icon />} />;
};

interface NotificationAvatarProps {
  actionType: ENotificationActionType;
  loading?: boolean;
  className?: string;
  notificationType: ENotificationType;
}

const getAvatarIcon = (
  type: ENotificationActionType
):
  | ForwardRefExoticComponent<
      Omit<IconProps, 'ref'> & RefAttributes<SVGSVGElement>
    >
  | ComponentType<IconProps> => {
  switch (type) {
    case ENotificationActionType.REPLY:
      return CommentsIcon;
    case ENotificationActionType.LIKE:
      return ThumbUpIcon;
    case ENotificationActionType.DISLIKE:
      return ThumbDownIcon;
    case ENotificationActionType.REQUESTED:
    case ENotificationActionType.ACCEPTED:
    case ENotificationActionType.FEATURE:
      return BellOutlineIcon;
    case ENotificationActionType.INFORMATION:
      return InfoIcon;

    default:
      return BellOutlineIcon;
  }
};

const NotificationAvatar = ({
  actionType,
  notificationType,
  className,
}: NotificationAvatarProps): JSX.Element => {
  const icon = getAvatarIcon(actionType);
  const avatarBackgroundClassName = notificationType.toLocaleLowerCase();

  return (
    <Avatar
      className={clsx(className, avatarBackgroundClassName)}
      icon={icon}
    />
  );
};

export default NotificationAvatar;
