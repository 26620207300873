import React, { forwardRef } from 'react';
import { mdiInformationOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const InfoIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon path={mdiInformationOutline} ref={ref} {...props} />
));

InfoIcon.displayName = 'InfoIcon';

export default InfoIcon;
