import { SyntheticEvent } from 'react';

import { ToggleOptions } from 'types/index';

export enum PresetsTopicType {
  PRE_DEFINED = 1,
  CUSTOM = 2,
}

export type PresetsType =
  | PresetsTopicType.PRE_DEFINED
  | PresetsTopicType.CUSTOM;

export interface TopicPresetsBuilderProps<T> {
  activeCategory: PresetsType;
  categoryOptions: ToggleOptions<PresetsType>;
  onToggleCategory: (event: SyntheticEvent, value: T) => void;
  openLinkModal: boolean;
  setOpenLinkModal: (status: boolean) => void;
}

export enum PresetTabLabel {
  PRE_DEFINED = 'Pre-defined presets',
  CUSTOM = 'Custom presets',
}

export enum ModalVariant {
  RENAME = 'rename',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  LINK = 'link',
  EDIT = 'edit',
  RESET = 'reset',
}
