import { create } from 'zustand';

interface SearchState {
  searchString: string;
  searchPerformed: boolean;
  resetSearch: boolean;
}

export enum SearchKey {
  Global,
  KnowledgeLibrary,
  Video,
}

const initialSearchState: SearchState = {
  searchString: '',
  searchPerformed: false,
  resetSearch: false,
};

interface SearchStore {
  searchState: Map<SearchKey, SearchState>;
  clearSearchState: (key: SearchKey) => void;
  getResetSearch: (key: SearchKey) => boolean;
  getSearchPerformed: (key: SearchKey) => boolean;
  getSearchState: (key: SearchKey) => SearchState;
  getSearchString: (key: SearchKey) => string;
  initSearchState: (key: SearchKey) => void;
  resetSearchState: (key: SearchKey) => void;
  setResetSearch: (key: SearchKey, resetSearch: boolean) => void;
  setSearchPerformed: (key: SearchKey, searchPerformed: boolean) => void;
  setSearchString: (key: SearchKey, searchString: string) => void;
}

export const useSearchState = create<SearchStore>()((set, get) => ({
  searchState: new Map(),
  clearSearchState: key =>
    set(({ searchState }) => {
      searchState.delete(key);

      return {
        searchState: new Map(searchState),
      };
    }),
  getResetSearch: key =>
    get().searchState.get(key)?.resetSearch || initialSearchState.resetSearch,
  getSearchPerformed: key =>
    get().searchState.get(key)?.searchPerformed ||
    initialSearchState.searchPerformed,
  getSearchString: key =>
    get().searchState.get(key)?.searchString || initialSearchState.searchString,
  getSearchState: key => get().searchState.get(key) || initialSearchState,
  initSearchState: key =>
    set(({ searchState }) => {
      const previousOrInitital = searchState.get(key) || initialSearchState;

      return {
        searchState: new Map(searchState).set(key, previousOrInitital),
      };
    }),
  resetSearchState: key =>
    set(({ searchState }) => ({
      searchState: new Map(searchState).set(key, initialSearchState),
    })),
  setResetSearch: (key, resetSearch) =>
    set(({ searchState }) => {
      const previousOrInitital = searchState.get(key) || initialSearchState;

      return {
        searchState: new Map(searchState).set(key, {
          ...previousOrInitital,
          resetSearch,
        }),
      };
    }),
  setSearchPerformed: (key, searchPerformed) =>
    set(({ searchState }) => {
      const previousOrInitital = searchState.get(key) || initialSearchState;

      return {
        searchState: new Map(searchState).set(key, {
          ...previousOrInitital,
          searchPerformed,
        }),
      };
    }),
  setSearchString: (key, searchString) =>
    set(({ searchState }) => {
      const previousOrInitital = searchState.get(key) || initialSearchState;

      return {
        searchState: new Map(searchState).set(key, {
          ...previousOrInitital,
          searchString,
        }),
      };
    }),
}));
