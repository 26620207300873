import { useMutation } from '@apollo/client';
import { termsAndConditions } from '@quesmed/types-rn';
import {
  ITocAcceptData,
  ITocAcceptVar,
  TOC_ACCEPT,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Markdown } from 'components/Markdown';
import { Modal, ModalProps } from 'components/Modal';
import { paths } from 'Router';
import { ButtonOnClickHandler } from 'types';
import { useAuth } from 'Auth';

const { dashboard } = paths;

type ConductPolicyPolicyProps = Pick<ModalProps, 'open'>;

const ConductPolicy = ({ ...props }: ConductPolicyPolicyProps): JSX.Element => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [accept, { loading }] = useMutation<ITocAcceptData, ITocAcceptVar>(
    TOC_ACCEPT,
    {
      onCompleted: ({ restricted }) => {
        setToken(restricted.tocAccept);
      },
      onError: () => {
        navigate(dashboard);
      },
    }
  );

  const handleDecline = () => {
    navigate(dashboard);
  };

  const handleAccept: ButtonOnClickHandler = () => {
    accept();
  };

  return (
    <Modal
      cancelLabel="Decline"
      closeOnBackdrop={false}
      closeOnEscape={false}
      loading={loading}
      onClose={handleDecline}
      onSubmit={handleAccept}
      showCloseButton={false}
      submitLabel="Accept"
      title="Conduct Policy"
      {...props}
    >
      <Markdown text={termsAndConditions} />
    </Modal>
  );
};

export default ConductPolicy;
