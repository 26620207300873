import { useEffect } from 'react';
import { create } from 'zustand';
import { ETheme } from '@quesmed/types-rn/models';

import { DEFAULT_TEXT_SCALE } from 'config/constants';
import { useGetUser } from './useGetUser';
import { noop } from 'utils';

interface ThemeState {
  theme: ETheme;
  setTheme: (value: ETheme) => void;
}
const initialState = {
  theme: ETheme.AUTO,
  setTheme: noop,
};

const useThemeStore = create<ThemeState>()(set => ({
  ...initialState,
  setTheme: theme => set({ theme }),
}));

export const useThemeState = () => useThemeStore(state => state);

const useUserSettings = () => {
  const { user } = useGetUser();
  const { theme, setTheme } = useThemeState();
  const { productSettings, globalSettings } = user?.settings || {};
  const {
    disabledColors,
    expandedReading,
    readingTextScale = DEFAULT_TEXT_SCALE,
    theme: userTheme,
  } = globalSettings || {};
  const { examDate, nextFeedbackDate } = productSettings || {};

  useEffect(() => {
    setTheme(userTheme || ETheme.AUTO);
  }, [userTheme, setTheme]);

  return {
    disabledColors,
    expandedReading,
    readingTextScale,
    theme,
    examDate,
    nextFeedbackDate,
  };
};

export default useUserSettings;
