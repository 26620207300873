import React from 'react';
import { mdiStethoscope } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const StationIcon = (props: IconProps) => (
  <Icon path={mdiStethoscope} {...props} />
);

export default StationIcon;
