import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { useEffect, useMemo } from 'react';
import { INotification } from '@quesmed/types-rn/models';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs/plugin/utc';

import { TWENTY_FOUR_HOURS } from 'config/constants';
import { H5 } from 'components/Typography';
import { Notification } from './Notification';
import {
  Date,
  Drawer,
  HeaderDivider,
  NotificationGroup,
  NotificationList,
  NotificationsContainer,
  NotificationsHeader,
  NotificationsHeading,
  NotificationsToggle,
} from './Notifications.styles';
import useNotifications from './useNotifications';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { CloseIcon } from 'components/Icons';
import NotificationsEmpty from './NotificationsEmpty';
import NotificationSkeleton from './NotificationSkeleton';
import { entries, parseDate } from 'utils';
import useNotificationsState from './useNotificationsState';

dayjs.extend(dayjsPluginUTC);

const LAST_24_HOURS_KEY = 'Last 24 hours';

interface GroupedNotifications {
  [date: string]: INotification[];
}

type GroupedNotificationsArray = [string, INotification[]][];

const checkIsLast24Hours = (date: Date | number) =>
  dayjs().diff(dayjs(date)) < TWENTY_FOUR_HOURS;

const getNotificationsGroupedByDate = (notifications: INotification[]) => {
  const groupedNotifications: GroupedNotifications = {};
  notifications?.forEach((notification = {} as INotification) => {
    const createdAtDate = parseDate(notification.createdAt);
    const date = dayjs(createdAtDate).format('MMM DD YYYY');
    const key = checkIsLast24Hours(createdAtDate) ? LAST_24_HOURS_KEY : date;

    groupedNotifications[key] = groupedNotifications[key] || [];
    groupedNotifications[key].push(notification);
  });

  return entries(groupedNotifications);
};

export const Notifications = (): JSX.Element => {
  const {
    notifications = [] as INotification[],
    loading,
    markAllAsRead,
    markAllAsReadLoading,
  } = useNotifications();
  const { open, showBadge, hideBadge, closeNotifications } =
    useNotificationsState();

  const notReadNotifications = notifications?.filter(({ read }) => !read) || [];
  const anyNotReadNotifications = notReadNotifications.length > 0;

  const notificationsCount = notifications && notifications?.length;
  const notReadCount = notReadNotifications.length;

  useEffect(() => {
    if (anyNotReadNotifications) {
      showBadge();
    } else {
      hideBadge();
    }
  }, [anyNotReadNotifications, hideBadge, showBadge]);

  const notificationsGroupedByDate: GroupedNotificationsArray = useMemo(
    () => getNotificationsGroupedByDate(notifications),
    [notifications]
  );

  return (
    <Drawer
      anchor="right"
      disableScrollLock
      hideBackdrop
      onClose={closeNotifications}
      open={open}
    >
      <ClickAwayListener onClickAway={closeNotifications}>
        <NotificationsContainer>
          <NotificationsHeader>
            <NotificationsHeading>
              <H5>Notifications</H5>
              <IconButton onClick={closeNotifications}>
                <CloseIcon />
              </IconButton>
            </NotificationsHeading>
            {!loading && notReadCount ? (
              <NotificationsToggle>
                <Button
                  color="secondary"
                  loading={markAllAsReadLoading}
                  onClick={markAllAsRead}
                  rounded
                  secondary
                >
                  Mark all as read ({notReadCount})
                </Button>
              </NotificationsToggle>
            ) : null}
            <HeaderDivider />
          </NotificationsHeader>
          <NotificationList>
            {loading && <NotificationSkeleton />}
            {!loading && notificationsCount ? (
              notificationsGroupedByDate.map(([date, notifications]) => (
                <NotificationGroup key={date}>
                  <Date>{date}</Date>
                  {notifications.map(({ id, ...rest }) => (
                    <Notification
                      key={id}
                      markAllAsReadLoading={markAllAsReadLoading}
                      notification={{ id, ...rest }}
                    />
                  ))}
                </NotificationGroup>
              ))
            ) : (
              <NotificationsEmpty />
            )}
          </NotificationList>
        </NotificationsContainer>
      </ClickAwayListener>
    </Drawer>
  );
};
