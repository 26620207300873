import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { Location } from 'types';

interface LeveQuestionsState {
  open: boolean;
  canLeave: boolean;
  leaveState: Location;
  openLeaveModal: () => void;
  closeLeaveModal: () => void;
  setLeaveState: (valeu: Location) => void;
  setCanLeave: (value: boolean) => void;
}
const inititalState: Pick<
  LeveQuestionsState,
  'open' | 'canLeave' | 'leaveState'
> = {
  open: false,
  canLeave: true,
  leaveState: { path: '' },
};

const useLeaveeState = create<LeveQuestionsState>()(set => ({
  ...inititalState,
  openLeaveModal: () => set({ open: true }),
  closeLeaveModal: () => set({ open: false }),
  setLeaveState: leaveState => set({ leaveState }),
  setCanLeave: canLeave => set({ canLeave }),
}));

const useLeaveExerciseState = () => useLeaveeState(state => state, shallow);

export default useLeaveExerciseState;
