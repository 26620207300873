import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import {
  SkeletonColumn,
  SkeletonContainer,
  SkeletonItem,
} from './Notifications.styles';

const NotificationSkeleton = () => (
  <SkeletonContainer>
    {[...Array(7).keys()].map(item => (
      <SkeletonItem key={item}>
        <Skeleton height={48} variant="circular" width={48} />
        <SkeletonColumn>
          <Skeleton height={40} variant="rectangular" />
          <Skeleton height={15} variant="rectangular" />
          <Skeleton height={15} variant="rectangular" />
        </SkeletonColumn>
      </SkeletonItem>
    ))}
  </SkeletonContainer>
);

export default NotificationSkeleton;
