import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { alpha, CircularProgress } from '@mui/material';
import { clsx } from 'clsx';

export interface ButtonProps extends LoadingButtonProps {
  contrast?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  rounded?: boolean;
  pressed?: boolean;
  isBottomBar?: boolean;
}

const StyledButton = styled(LoadingButton)<ButtonProps>(
  ({ theme: { palette, spacing, transitions } }) => ({
    height: spacing(10),
    borderRadius: '6px',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(2),
    padding: spacing(3, 4),
    transition: transitions.create([
      'border-color',
      'color',
      'background-color',
    ]),

    '& .MuiSvgIcon-root': {
      margin: 0,
    },

    '&.MuiButton-sizeLarge': {
      height: spacing(12),
    },

    '&:hover': {
      boxShadow: 'none',
    },

    '& .hot-key': {
      margin: spacing(0, 3),
      transition: transitions.create(['opacity']),
    },

    '&.Mui-disabled': {
      '&:not(.MuiLoadingButton-loading):not(.MuiButton-outlined):not(.MuiButton-text)':
        {
          backgroundColor: palette.secondary.light,
          color: palette.primary.contrastText,
        },

      '&.MuiLoadingButton-root.MuiButton-outlined': {
        color: palette.secondary.light,
        borderColor: palette.secondary.light,
      },

      '&.MuiLoadingButton-root.MuiButton-text': {
        color: palette.secondary.light,
      },
    },

    '&.MuiLoadingButton-root.MuiButton-contained': {
      backgroundColor: palette.primary.main,

      '&:hover': {
        backgroundColor: palette.primary.dark,
      },

      '&.MuiLoadingButton-loading': {
        color: alpha(palette.primary.contrastText, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.primary.contrastText,
        },

        '& .hot-key': {
          opacity: 0.3,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-outlined': {
      color: palette.primary.main,
      borderColor: palette.primary.main,

      '&:hover': {
        borderColor: palette.primary.dark,
        color: palette.primary.dark,
      },

      '&.MuiLoadingButton-loading': {
        borderColor: alpha(palette.primary.main, 0.3),
        color: alpha(palette.primary.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.primary.main,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-text': {
      color: palette.primary.main,

      '&:hover': {
        color: palette.primary.dark,
      },

      '&.MuiLoadingButton-loading': {
        color: alpha(palette.primary.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.primary.main,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-containedSecondary': {
      backgroundColor: alpha(palette.secondary.main, 0.15),
      color: palette.secondary.main,

      '&:hover': {
        backgroundColor: alpha(palette.secondary.dark, 0.15),
        color: palette.secondary.dark,
      },

      '&.MuiLoadingButton-loading': {
        color: alpha(palette.secondary.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.secondary.contrastText,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-outlinedSecondary': {
      borderColor: alpha(palette.secondary.main, 0.15),
      color: palette.secondary.main,

      '&:hover': {
        borderColor: alpha(palette.secondary.dark, 0.15),
        color: palette.secondary.dark,
      },

      '&.MuiLoadingButton-loading': {
        borderColor: alpha(palette.secondary.main, 0.15),
        color: alpha(palette.secondary.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.secondary.main,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-textSecondary': {
      color: palette.secondary.main,

      '&:hover': {
        color: palette.secondary.dark,
      },

      '&.MuiLoadingButton-loading': {
        color: alpha(palette.secondary.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.secondary.main,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-containedError': {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText,

      '&:hover': {
        backgroundColor: palette.error.dark,
      },
    },

    '&.MuiLoadingButton-root.MuiButton-outlinedError': {
      borderColor: palette.error.main,
      color: palette.error.main,

      '&:hover': {
        borderColor: palette.error.dark,
        color: palette.error.dark,
      },

      '&.MuiLoadingButton-loading': {
        borderColor: alpha(palette.error.main, 0.3),
        color: alpha(palette.error.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.error.main,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-textError': {
      color: palette.error.main,

      '&:hover': {
        color: palette.error.dark,
      },

      '&.MuiLoadingButton-loading': {
        color: alpha(palette.error.main, 0.3),

        '& .MuiCircularProgress-root': {
          color: palette.error.main,
        },
      },
    },

    '&.MuiLoadingButton-root.MuiButton-text:hover': {
      backgroundColor: 'transparent',
    },

    '&.MuiLoadingButton-root.MuiButton-outlined:hover': {
      backgroundColor: 'transparent',
    },

    '&.rounded': {
      borderRadius: '25px',
    },

    '&.pressed': {
      background: palette.background.default,
    },

    '&.contrast': {
      '&.MuiLoadingButton-root.MuiButton-contained': {
        backgroundColor: palette.contrast.main,
        color: palette.contrast.contrastText,

        '&:hover': {
          backgroundColor: palette.contrast.dark,
        },

        '&.MuiLoadingButton-loading': {
          borderColor: alpha(palette.contrast.main, 0.15),
          color: alpha(palette.contrast.contrastText as string, 0.3),

          '& .MuiCircularProgress-root': {
            color: palette.contrast.contrastText,
          },
        },
      },

      '&.MuiLoadingButton-root.MuiButton-text': {
        backgroundColor: 'transparent',
        color: palette.mockTest.contrastText,

        '&:hover': {
          backgroundColor: 'transparent',
          color: alpha(palette.mockTest.contrastText as string, 0.6),
        },

        '&.MuiLoadingButton-loading': {
          color: alpha(palette.mockTest.contrastText as string, 0.2),

          '& .MuiCircularProgress-root': {
            color: palette.mockTest.contrastText,
          },
        },

        '&.Mui-disabled': {
          color: alpha(palette.mockTest.contrastText as string, 0.2),
        },
      },
    },
  })
);

const getButtonVariant = (
  variant: LoadingButtonProps['variant'],
  contrast?: boolean,
  secondary?: boolean,
  tertiary?: boolean
): LoadingButtonProps['variant'] => {
  if (variant === 'text') {
    return variant;
  }

  if (contrast) {
    return 'contained';
  }

  if (variant) {
    return variant;
  }

  if (secondary) {
    return 'outlined';
  }

  if (tertiary) {
    return 'text';
  }

  return 'contained';
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      contrast,
      danger,
      disabled,
      color,
      loading,
      secondary,
      tertiary,
      variant,
      rounded,
      className,
      pressed,
      ...props
    },
    ref
  ) => (
    <StyledButton
      className={clsx(className, { rounded, pressed, contrast })}
      color={danger ? 'error' : color}
      disabled={disabled || loading}
      loading={loading}
      loadingIndicator={<CircularProgress size={24} />}
      ref={ref}
      variant={getButtonVariant(variant, contrast, secondary, tertiary)}
      {...props}
    />
  )
);

Button.displayName = 'Button';

export default Button;
