import React from 'react';

import { Modal } from 'components/Modal';
import {
  AppProductType,
  ButtonOnClickHandler,
  quesmedProductNames,
} from 'types';
import { Body } from 'components/Typography';

export interface MissedProductModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: ButtonOnClickHandler;
  product: AppProductType;
}

const MissedProductModal = ({
  open,
  onClose,
  onSubmit,
  product,
}: MissedProductModalProps): JSX.Element => {
  const productName = quesmedProductNames[product];

  return (
    <Modal
      noPaddingY
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      showCloseButton
      submitLabel="See Pricing"
      title={productName}
    >
      <Body component="p">{`You will need to subscribe to the ${productName} to access  resources.`}</Body>
      <Body component="p">{`If you have just subscribed to the ${productName} platform, please logout and back in again to activate your new subscription.`}</Body>
    </Modal>
  );
};

export default MissedProductModal;
