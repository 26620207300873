import React from 'react';
import MuiListItemButton, {
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import { Spacing } from '@mui/system';

export type ListItemButtonProps<
  C extends React.ElementType = React.ElementType
> = MuiListItemButtonProps<C, { component?: C }> & {
  expanded: number;
  nested: 1 | 0;
};

const setNestedPadding = (nested: 1 | 0, spacing: Spacing) =>
  nested ? spacing(2, 3, 2, 12) : spacing(3, 4);

export const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>(
  ({
    expanded,
    nested,
    theme: { palette, shape, spacing, transitions, typography },
  }) => ({
    '&.MuiListItemButton-root': {
      boxSizing: 'border-box',
      borderRadius: shape.borderRadius,
      padding: expanded ? setNestedPadding(nested, spacing) : spacing(3),
      display: 'flex',
      justifyContent: expanded ? 'flex-start' : 'center',
      height: spacing(nested ? 9 : 12),
      color: palette.text.primary,
      ...typography.body1Medium,
      transition: transitions.create(['color', 'background-color', 'padding'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      '&:hover': {
        backgroundColor: palette.toggle.light,

        '& .MuiSvgIcon-root': {
          fill: palette.text.primary,
        },
      },

      '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: expanded ? spacing(2) : 0,
        transition: transitions.create(['margin'], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      '& .MuiSvgIcon-root': {
        fill: palette.icon.main,
      },

      '&.active': {
        backgroundColor: palette.toggle.light,
      },

      '&.locked': {
        cursor: 'not-allowed',

        '&:hover': {
          backgroundColor: palette.background.paper,

          '& .MuiSvgIcon-root': {
            fill: palette.icon.main,
          },
        },
      },

      '&.collapse-button': {
        margin: spacing(0, -2),
        '&.MuiListItemButton-root': {
          borderRadius: 0,
          height: '64px',
          boxSizing: 'border-box',
          paddingBottom: spacing(5),
        },
      },
    },
  })
);
