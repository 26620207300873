import React from 'react';
import { EOsceRoles } from '@quesmed/types-rn/models';

import { StationRoleName, StationRoles } from 'types';
import {
  ActorRoleIcon,
  CandidateRoleIcon,
  ExaminerRoleIcon,
  ObserverRoleIcon,
} from 'components/Icons';

export const STATION_ROLES: StationRoles = {
  [EOsceRoles.CANDIDATE]: {
    name: StationRoleName.Candidate,
    icon: <CandidateRoleIcon />,
  },
  [EOsceRoles.ACTOR]: {
    name: StationRoleName.Actor,
    icon: <ActorRoleIcon />,
  },
  [EOsceRoles.EXAMINER]: {
    name: StationRoleName.Examiner,
    icon: <ExaminerRoleIcon />,
  },
  [EOsceRoles.OBSERVER]: {
    name: StationRoleName.Observer,
    icon: <ObserverRoleIcon />,
  },
};
