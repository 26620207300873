import { IPreset } from '@quesmed/types-rn/models';

import { PresetTabLabel } from './types';

export const categoryOptions = [
  {
    value: 1,
    label: PresetTabLabel.PRE_DEFINED,
  },
  {
    value: 2,
    label: PresetTabLabel.CUSTOM,
  },
];

export const QUESTION_LEGEND = ['Correct', 'Incorrect', 'Not Answered'];

export const FLASHCARDS_LEGEND = [
  'Confident',
  'To review',
  'To learn',
  'Not Answered',
];

export const PRESETS_HEAD_ROW = {
  initial: 'Date',
  exercise: 'Preset Name',
  legendBox: QUESTION_LEGEND,
  type: 'Preset Type',
  actions: 'Actions',
};

export const defaultPreset: IPreset = {
  id: 0,
  createdAt: 0,
  updatedAt: 0,
  userId: 0,
  name: 'placeholder',
  entitlement: {
    index: 0,
    id: 0,
    name: '',
  },
  likes: 0,
  entitlementId: 0,
  correctQuestions: 0,
  incorrectQuestions: 0,
  completedQuestions: 0,
  totalQuestions: 0,
  yellowCards: 0,
  greenCards: 0,
  redCards: 0,
  completedCards: 0,
  totalCards: 0,
  concepts: [],
};
