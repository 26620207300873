import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Children } from 'types';

const SearchBlockHeader = styled(Box)(({ theme: { palette, typography } }) => ({
  ...typography.button,
  color: palette.text.disabled,
}));

const SearchBlockContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
}));

interface SearchResultBlockProps {
  count: number;
  title: string;
  children: Children;
}

const SearchResultBlock = ({
  title,
  count,
  children,
}: SearchResultBlockProps): JSX.Element => (
  <SearchBlockContainer>
    <SearchBlockHeader>{`${title} ${count}`}</SearchBlockHeader>
    {children}
  </SearchBlockContainer>
);

export default SearchResultBlock;
