import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import SearchResult from './SearchResult';
import SearchFallback from './SearchFallback';
import {
  KnowledgeLibraryIcon,
  StationIcon,
  VideoTrainingsIcon,
} from 'components/Icons';
import { PathName, VIDEO_PRODUCTS } from 'types';
import {
  PROD_SEARCH_PREFIX,
  SearchScopeIndex,
  TEST_SEARCH_PREFIX,
  Title,
} from './type';
import SearchResultBlock from './SearchResultBlock';
import SearchSummary from './SearchSummary';
import QustionSearchResult from './QustionSearchResult';
import FlashcardsSearchResult from './FlashcardsSearchResult';
import StationsSearchResult from './StationsSearchResult';
import { useDemo } from 'Auth';
import { useSnackbar } from 'components/Snackbar';
import locales from 'locales';
import { isDev } from 'config/constants';
import { usePlatform } from 'context/PlatformState';

const { demo } = locales.common;

export const FALLBACK_TITLE = "Find what you're looking for";
export const FALLBACK_TEXT =
  "Search for practice and learning materials to improve your knowledge. Start typing above and click 'Enter' to get started.";

const SEARCH_BOX_HEIGHT = 82;
const RESULTS_HEIGHT = 56;
const PADDING = 16;

const SearchResultsContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    maxHeight: `calc(100% - ${SEARCH_BOX_HEIGHT}px - ${RESULTS_HEIGHT}px + ${PADDING}px)`,
    overflowY: 'auto',
    padding: spacing(6, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(6),

    '&.hidden': {
      display: 'none',
    },

    [breakpoints.up('md')]: {
      padding: spacing(6, 4),
      maxHeight: '536px',
    },
  })
);

export interface SearchResultsProps {
  onClose: () => void;
  searchString: string;
  showQuestions: boolean;
  showStations: boolean;
  showFlashcards: boolean;
  showVideos: boolean;
  showPaces: boolean;
  hideSearchModal: () => void;
  showSearchModal: () => void;
  searchPerformed?: Boolean;
}

const SearchResults = ({
  onClose,
  searchString,
  showQuestions,
  showFlashcards,
  showStations,
  showVideos,
  showPaces,
  hideSearchModal,
  showSearchModal,
  searchPerformed,
}: SearchResultsProps): JSX.Element => {
  const { product } = usePlatform();
  const prefix = isDev ? TEST_SEARCH_PREFIX : PROD_SEARCH_PREFIX;
  const [videosCount, setVideosCount] = useState(0);
  const [stationsCount, setStationsCount] = useState(0);
  const [flashcardsCount, setFlashcardsCount] = useState(0);
  const [quesbookCount, setQuesbookCount] = useState(0);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [firstSearchPerformed, setFirstSearchPerformed] = useState(false);
  const isDemo = useDemo();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const showVideosResultsOnNonVideosViews = Boolean(
    product && VIDEO_PRODUCTS.includes(product)
  );

  const handleDemoResultClicks = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (isDemo) {
      event.stopPropagation();
      enqueueSnackbar(demo.search);
    }
  };

  const totalCount =
    videosCount +
    flashcardsCount +
    quesbookCount +
    questionsCount +
    stationsCount * 2;

  // it is a hack for UI glitches to not display clear search
  // fallback before the first search results appear
  useEffect(() => {
    let timeout = 0;
    if ((totalCount || searchPerformed) && !firstSearchPerformed) {
      timeout = window.setTimeout(() => {
        setFirstSearchPerformed(true);
      }, 150);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [totalCount, firstSearchPerformed, searchPerformed]);

  return (
    <>
      {!totalCount ? (
        <SearchFallback
          text={!firstSearchPerformed ? FALLBACK_TEXT : ''}
          title={!firstSearchPerformed ? FALLBACK_TITLE : ''}
          variant="with-padding"
          withFallback={firstSearchPerformed}
        />
      ) : (
        <SearchSummary count={totalCount} searchString={searchString} />
      )}
      <SearchResultsContainer
        className={clsx({ hidden: !totalCount })}
        onClickCapture={handleDemoResultClicks}
      >
        {showQuestions ? (
          <>
            <SearchResultBlock
              count={questionsCount + flashcardsCount}
              title={Title.PracticeMaterials}
            >
              <QustionSearchResult
                hideSearchModal={hideSearchModal}
                indexName={`${prefix}${SearchScopeIndex.Questions}`}
                onSearchClose={onClose}
                searchString={searchString}
                setCount={setQuestionsCount}
                showSearchModal={showSearchModal}
              />
              {showFlashcards && (
                <FlashcardsSearchResult
                  hideSearchModal={hideSearchModal}
                  indexName={`${prefix}${SearchScopeIndex.Flashcards}`}
                  onSearchClose={onClose}
                  searchString={searchString}
                  setCount={setFlashcardsCount}
                  showSearchModal={showSearchModal}
                />
              )}
            </SearchResultBlock>
            <SearchResultBlock
              count={quesbookCount + videosCount}
              title={Title.LearningMaterials}
            >
              <SearchResult
                icon={KnowledgeLibraryIcon}
                indexName={`${prefix}${SearchScopeIndex.Quesbook}`}
                onItemClick={onClose}
                setCount={setQuesbookCount}
                title={PathName.KnowledgeLibrary}
                withHints
              />
              {showVideosResultsOnNonVideosViews && (
                <SearchResult
                  icon={VideoTrainingsIcon}
                  indexName={`${prefix}${SearchScopeIndex.Videos}`}
                  onItemClick={onClose}
                  setCount={setVideosCount}
                  title={PathName.VideoLibrary}
                  withHints
                />
              )}
            </SearchResultBlock>
          </>
        ) : null}
        {showVideos ? (
          <SearchResultBlock
            count={videosCount}
            title={Title.LearningMaterials}
          >
            <SearchResult
              icon={VideoTrainingsIcon}
              indexName={`${prefix}${SearchScopeIndex.Videos}`}
              onItemClick={onClose}
              setCount={setVideosCount}
              title={PathName.VideoLibrary}
              withHints
            />
          </SearchResultBlock>
        ) : null}
        {showStations ? (
          <>
            {!showPaces ? (
              <SearchResultBlock
                count={stationsCount}
                title={Title.PracticeMaterials}
              >
                <StationsSearchResult
                  hideSearchModal={hideSearchModal}
                  indexName={`${prefix}${SearchScopeIndex.Oscebook}`} // stations are taken always from algolia oscebook even for PACES
                  onSearchClose={onClose}
                  setCount={setStationsCount}
                  showSearchModal={showSearchModal}
                  title={PathName.Stations}
                />
              </SearchResultBlock>
            ) : null}
            <SearchResultBlock
              count={stationsCount + videosCount}
              title={Title.LearningMaterials}
            >
              <SearchResult
                icon={StationIcon}
                indexName={`${prefix}${
                  showPaces
                    ? SearchScopeIndex.Pacebook
                    : SearchScopeIndex.Oscebook
                }`}
                onItemClick={onClose}
                setCount={setStationsCount}
                title={PathName.KnowledgeLibrary}
                withHints
              />
              <SearchResult
                icon={VideoTrainingsIcon}
                indexName={`${prefix}${SearchScopeIndex.Videos}`}
                onItemClick={onClose}
                setCount={setVideosCount}
                title={PathName.VideoLibrary}
                withHints
              />
            </SearchResultBlock>
          </>
        ) : null}
      </SearchResultsContainer>
    </>
  );
};

export default SearchResults;
