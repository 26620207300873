import React, { useCallback, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { isNil, kebabCase } from 'lodash';
import { EAppType, EProductType } from '@quesmed/types-rn/models';
import partition from 'lodash/partition';

import { useAuth } from 'Auth';
import {
  APP_SPECIFIC_PATHS,
  AppProductType,
  Nullable,
  PRODUCT_SPECIFIC_PATHS,
  productsApp,
  QuesmedAppData,
  QuesmedAppNames,
  QuesmedProductData,
} from 'types';
import { MissedProductModal } from 'components/MissedProductModal';
import { usePlatform } from 'context/PlatformContext';
import {
  isCommonAuthenticatedRoute,
  isMRCPRoute,
  isOsceRoute,
  isPacesRoute,
  isQbankRoute,
  keys,
  values,
} from 'utils';
import { paths } from 'Router';
import { AppsIcon, ChevronUpAndDownIcon } from 'components/Icons';
import { Body } from 'components/Typography';
import { NavigationPopperMenu } from './NavigationItem';
import NavigationToolitp from './NavigationTooltip';
import { useHover, useLeaveExerciseState, usePrevious } from 'hooks';

const { appsManagement } = paths;

const APPS_MANAGEMENT_LINK = [
  {
    icon: AppsIcon,
    label: 'Apps management',
    path: appsManagement,
  },
];

interface SwitcherProps extends BoxProps {
  expanded: number;
}

const setProductForPath = (app: EAppType, pathname: string) => {
  if (app === EAppType.CM_UKMLA) {
    const qBankSpecificRoute = isQbankRoute(pathname);
    const osceSpecificRoute = isOsceRoute(pathname);

    if (!qBankSpecificRoute && !osceSpecificRoute) {
      return null;
    }

    if (osceSpecificRoute) {
      return EProductType.OSCE;
    }

    return EProductType.QBANK;
  }

  if (app === EAppType.MRCP) {
    const mrcpSpecificRoute = isMRCPRoute(pathname);
    const pacesSpecificpath = isPacesRoute(pathname);

    if (!mrcpSpecificRoute && !pacesSpecificpath) {
      return null;
    }

    if (pacesSpecificpath) {
      return EProductType.PACES;
    }

    return EProductType.MRCP_PART1;
  }

  return null;
};

const Switcher = styled(Box)<SwitcherProps>(
  ({
    expanded,
    theme: { palette, spacing, transitions, typography, shape },
  }) => ({
    borderRadius: shape.borderRadius,
    marginBottom: spacing(5),
    backgroundColor: palette.contrast.main,
    color: palette.contrast.contrastText,
    ...typography.body1Medium,
    padding: expanded ? `${spacing(3)} ${spacing(4)}` : 0,
    height: spacing(12),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: transitions.create(['background-color', 'padding']),

    '.MuiSvgIcon-root': {
      marginLeft: 'auto',
      marginRight: expanded ? 0 : 'auto',
      transition: transitions.create(['margin-right']),
    },

    '&:hover': {
      backgroundColor: palette.contrast.dark,
    },
  })
);

export interface PlatformSwitcherProps {
  expanded: boolean;
  onItemClick: () => void;
  products: QuesmedAppData['products'];
}

export const getProductOptions = (
  products: QuesmedAppData['products'],
  availableProducts: EProductType[]
) =>
  (values(products) as QuesmedProductData[]).map(({ name, value, active }) => ({
    label: name,
    value,
    active,
    locked: !availableProducts?.includes(value),
  }));

const isProductAllowed = (
  newProduct: AppProductType,
  availableProducts: AppProductType[]
) => availableProducts.includes(newProduct);

const ProductSwitcher = ({
  expanded,
  products,
  onItemClick,
}: PlatformSwitcherProps): JSX.Element => {
  const [missedProduct, setMissedProduct] =
    useState<Nullable<AppProductType>>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { app, product, setProduct, setApp, availableProducts, availableApps } =
    usePlatform();
  const { isAuthenticated } = useAuth();
  const { canLeave } = useLeaveExerciseState();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [, isPopupHover, , setPopupRef] = useHover<HTMLDivElement>();
  const [switcherRef, isSwitcherHover] = useHover<HTMLDivElement>();
  const disableListeners = expanded || Boolean(anchorEl);
  const isOpen = Boolean(anchorEl);
  const prevIsSwitcherHover = usePrevious(isSwitcherHover);
  const prevIsPopupHover = usePrevious(isPopupHover);
  const noHover = !isPopupHover && !isSwitcherHover;
  const wasHover = prevIsPopupHover || prevIsSwitcherHover;

  const links = getProductOptions(products, availableProducts);

  const handleChangeProduct = useCallback(
    (newProduct: AppProductType) => {
      if (product === newProduct) return;

      if (product !== newProduct) {
        if (
          !isAuthenticated ||
          isProductAllowed(newProduct, availableProducts)
        ) {
          setProduct(newProduct as AppProductType);
          setApp(productsApp[newProduct as AppProductType]);
        } else {
          setMissedProduct(newProduct);
        }
      }
    },
    [availableProducts, isAuthenticated, product, setProduct, setApp]
  );

  const handleCloseModal = useCallback(() => {
    setMissedProduct(null);
  }, []);

  const handleActivateSubscription = useCallback(() => {
    handleCloseModal();
    if (missedProduct) {
      const parsedAppType = kebabCase(
        QuesmedAppNames[productsApp[missedProduct as AppProductType]]
      );

      navigate(`${paths.pricing.root}/${parsedAppType}`);
    }
  }, [handleCloseModal, navigate, missedProduct]);

  const handleTooltipOpenState = (isOpen: boolean) => () =>
    setShowTooltip(isOpen);

  const handleOpenPopper = (event: React.MouseEvent<HTMLSpanElement>) => {
    handleTooltipOpenState(false)();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopper = useCallback(() => {
    setAnchorEl(null);
    onItemClick();
  }, [onItemClick]);

  useEffect(() => {
    if (isOpen && noHover && wasHover) {
      setAnchorEl(null);
    }
  }, [isOpen, noHover, wasHover]);

  useEffect(() => {
    if (!pathname || isCommonAuthenticatedRoute(pathname)) {
      return;
    }

    if (
      !isNil(app) &&
      APP_SPECIFIC_PATHS[app].some(route => pathname.includes(route))
    ) {
      const pathExistsForProduct =
        product &&
        PRODUCT_SPECIFIC_PATHS[product].some(route => pathname.includes(route));

      if (pathExistsForProduct) {
        return;
      }

      const productToSetFromTheCurrentApp = setProductForPath(app, pathname);

      if (productToSetFromTheCurrentApp !== null) {
        handleChangeProduct(productToSetFromTheCurrentApp);
      }
    } else {
      const productsForIncommingPath = keys(PRODUCT_SPECIFIC_PATHS).filter(
        productType =>
          PRODUCT_SPECIFIC_PATHS[productType].some(route =>
            pathname.includes(route)
          )
      );

      const [ownedProduct, missedProducts] = partition(
        productsForIncommingPath,
        product => availableApps.includes(Number(product))
      );

      if (ownedProduct.length) {
        const [productForIncommingPath] = productsForIncommingPath;

        handleChangeProduct(productForIncommingPath);
      } else {
        setMissedProduct(missedProducts[0]);
      }
    }
  }, [app, availableApps, handleChangeProduct, pathname, product]);

  const handleClickProduct = useCallback(
    (newProduct?: AppProductType) => () => {
      handleClosePopper();

      if (!isNil(newProduct)) {
        if (
          !isAuthenticated ||
          isProductAllowed(newProduct, availableProducts)
        ) {
          if (canLeave) {
            navigate(paths.dashboard);
            setProduct(newProduct);
          } else {
            navigate(paths.dashboard, {
              state: { newProduct },
            });
          }
        } else {
          setMissedProduct(newProduct);
        }
      }
    },
    [
      handleClosePopper,
      isAuthenticated,
      availableProducts,
      canLeave,
      navigate,
      setProduct,
    ]
  );
  const productName = (
    products[product as AppProductType] as QuesmedProductData
  )?.name;

  return (
    <>
      <NavigationToolitp
        disableListeners={disableListeners}
        onTooltipStateChange={handleTooltipOpenState}
        open={showTooltip}
        title={expanded ? '' : 'Product switcher'}
      >
        <Switcher
          expanded={expanded ? 1 : 0}
          onClick={handleOpenPopper}
          ref={switcherRef}
        >
          <Fade in={expanded} unmountOnExit>
            <span>
              <Body bold>{productName}</Body>
            </span>
          </Fade>
          <ChevronUpAndDownIcon />
        </Switcher>
      </NavigationToolitp>
      <NavigationPopperMenu
        activeValue={product}
        anchorEl={anchorEl}
        links={{
          products: links,
          apppManagement: APPS_MANAGEMENT_LINK,
        }}
        onClick={handleClickProduct}
        onClose={handleClosePopper}
        open={isOpen}
        placement={expanded ? 'bottom' : 'right-start'}
        ref={setPopupRef}
        showIcon
      />
      {missedProduct ? (
        <MissedProductModal
          onClose={handleCloseModal}
          onSubmit={handleActivateSubscription}
          open={Boolean(missedProduct)}
          product={missedProduct}
        />
      ) : null}
    </>
  );
};

export default ProductSwitcher;
