import { EPlatformId } from '@quesmed/types-rn';
import { EAppType, EProductType } from '@quesmed/types-rn/models';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { AppProductType, Nullable, ProductSubscriptionsState } from 'types';
import { isStationsProduct, localStorageTyped, setSentryTag } from 'utils';

const { setItem, removeItem } =
  localStorageTyped<Nullable<EProductType>>('product');

const { setItem: setAppItem, removeItem: removeAppItem } =
  localStorageTyped<Nullable<EAppType>>('app');

export const getPlatform = (product: Nullable<EProductType>) =>
  isStationsProduct(product) ? EPlatformId.OSCE : EPlatformId.QBANK;

interface IPlatformContext {
  app: Nullable<EAppType>;
  product: Nullable<AppProductType>;
  availableProducts: AppProductType[];
  availableApps: EAppType[];
  hasProduct: boolean;
  productStates: Nullable<ProductSubscriptionsState>;

  setApp: (arg: Nullable<EAppType>) => void;
  setProduct: (arg: Nullable<AppProductType>) => void;
  setProductState: (arg: Nullable<ProductSubscriptionsState>) => void;
  clearState: () => void;
  setAvailableProducts: (arg: AppProductType[], isDemo?: boolean) => void;
  setAvailableApps: (arg: EAppType[], isDemo?: boolean) => void;
}

export const platformState = create<IPlatformContext>()((set, get) => ({
  app: null,
  product: null,
  hasProduct: false,
  availableProducts: [],
  availableApps: [],
  productStates: null,

  setApp: app => {
    if (app) {
      setAppItem(app);
    } else {
      removeAppItem();
    }
    set({ app });
  },
  setProduct: product => {
    if (product) {
      setItem(product);
      setSentryTag('product', product);
    } else {
      removeItem();
    }
    set({ product, hasProduct: product !== null });
  },
  setAvailableProducts: (availableProducts, isDemo) => {
    set({ ...(isDemo ? get().availableProducts : []), availableProducts });
  },
  setAvailableApps: (availableApps, isDemo) => {
    set({ ...(isDemo ? get().availableApps : []), availableApps });
  },
  setProductState: productStates => set({ productStates }),
  clearState: () => {
    set({
      ...get(),
      availableApps: [],
      availableProducts: [],
      app: null,
      product: null,
      hasProduct: false,
      productStates: null,
    });

    removeItem();
    removeAppItem();
  },
}));

export const usePlatform = () => platformState(state => state, shallow);
