import React, { SVGProps } from 'react';

const LightLayoutImage = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      fill="none"
      height="123"
      viewBox="0 0 228 123"
      width="228"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8084_7157)">
        <rect fill="white" height="122.515" rx="3" width="226.06" x="0.96875" />
        <rect
          fill="white"
          height="108.615"
          width="46.1511"
          x="0.57354"
          y="13.7298"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="8.17578"
          y="21.625"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="8.17578"
          y="53.0703"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="8.17578"
          y="32.1016"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="8.17578"
          y="63.5547"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="8.17578"
          y="42.5859"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="8.17578"
          y="74.0391"
        />
        <rect
          height="108.615"
          stroke="#DADCE0"
          strokeWidth="0.790419"
          width="46.1511"
          x="0.57354"
          y="13.7298"
        />
        <rect
          fill="white"
          height="14.5506"
          transform="matrix(1 0 0 -1 0.96875 14.5482)"
          width="226.85"
          x="-0.39521"
          y="0.39521"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="6.55246"
          x="215.234"
          y="3.92969"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="6.55246"
          x="204.75"
          y="3.92969"
        />
        <rect
          height="14.5506"
          stroke="#DADCE0"
          strokeWidth="0.790419"
          transform="matrix(1 0 0 -1 0.96875 14.5482)"
          width="226.85"
          x="-0.39521"
          y="0.39521"
        />
        <rect
          fill="#F1F2F3"
          height="91.0792"
          opacity="0.5"
          width="106.15"
          x="83.5312"
          y="21.625"
        />
      </g>
      <rect
        height="121.515"
        rx="2.5"
        stroke="#DADCE0"
        width="225.06"
        x="1.46875"
        y="0.5"
      />
      <defs>
        <clipPath id="clip0_8084_7157">
          <rect
            fill="white"
            height="122.515"
            rx="3"
            width="226.06"
            x="0.96875"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightLayoutImage;
