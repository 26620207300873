import { Box, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import { Body } from 'components/Typography';

const MAX_MOBILE_WIDTH = '340px';
const MAX_WIDTH = '430px';

export const Drawer = styled(MuiDrawer)(
  ({
    theme: {
      breakpoints,
      palette,
      mixins: { appBar },
    },
  }) => ({
    '.MuiDrawer-paper': {
      boxShadow: 'none',
      borderTop: `1px solid ${palette.stroke.main}`,
      borderLeft: `1px solid ${palette.stroke.main}`,
      height: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
      marginTop: appBar.mobile.minHeight,
      background: palette.background.paper,

      [breakpoints.up('md')]: {
        height: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
        marginTop: appBar.desktop.minHeight,
      },
    },
  })
);

const LabWrapper = styled(Box)(({ theme: { breakpoints } }) => ({
  width: MAX_MOBILE_WIDTH,

  [breakpoints.up('md')]: {
    width: MAX_WIDTH,
  },
}));

const TabContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  padding: spacing(0, 4),
}));

const Tab = styled(Box)(({ theme: { spacing, palette, typography } }) => ({
  padding: spacing(2, 4, 4, 4),
  ...typography.button,
  color: palette.text.secondary,
  cursor: 'pointer',

  '&.selected': {
    color: palette.primary.main,
    borderBottom: `1.5px solid ${palette.primary.main}`,
  },
}));

const LabValuesContainer = styled(Box)(({ theme: { spacing } }) => ({
  margin: spacing(2, 1, 2, 6),
  paddingRight: spacing(6),
  overflowX: 'hidden',
  overflowY: 'auto',
}));

const LabsHeader = styled(Body)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));

const Description = styled(Body)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));

const LabsTable = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(8),
}));

export {
  LabWrapper,
  Tab,
  LabValuesContainer,
  LabsHeader,
  Description,
  LabsTable,
  TabContainer,
};
