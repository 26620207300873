import React from 'react';

import { useAgoraAPI } from './AgoraAPI';
import { useAgoraSetState, useAgoraState } from './AgoraState';
import { MicrophoneIcon } from 'components/Icons';
import AudioVideoButton from './AudioVideoButton';

export interface SoundButtonProps {
  connected?: boolean;
}

const SoundButton = ({
  connected: sourceConnected,
}: SoundButtonProps): JSX.Element => {
  const { audioEnabled } = useAgoraState();
  const { isConnected } = useAgoraAPI();
  const { setAudioEnabled } = useAgoraSetState();
  const connected =
    sourceConnected !== undefined ? sourceConnected : isConnected();

  const handleOnClick = () => {
    if (connected) {
      setAudioEnabled(!audioEnabled);
    }
  };

  return (
    <AudioVideoButton
      active={connected && audioEnabled}
      disabled={!connected}
      onClick={handleOnClick}
    >
      <MicrophoneIcon muted={!connected || !audioEnabled} />
    </AudioVideoButton>
  );
};

export default SoundButton;
