import React, { forwardRef } from 'react';
import { mdiProgressUpload } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ProgressStartedIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <Icon path={mdiProgressUpload} ref={ref} {...props} />
);

ProgressStartedIcon.displayName = 'ProgressStartedIcon';

export default ProgressStartedIcon;
