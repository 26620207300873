import { Reducer } from 'react';

import { ActionTypes, ActiveSpeaker, AgoraAction, AgoraState } from './types';

export const agoraStateReducer: Reducer<AgoraState, AgoraAction> = (
  state,
  action
): AgoraState => {
  switch (action.type) {
    case ActionTypes.AudioEnabled:
      return { ...state, audioEnabled: action.payload };
    case ActionTypes.VideoEnabled:
      return { ...state, videoEnabled: action.payload };
    case ActionTypes.MutedUsers:
      return { ...state, mutedUsers: action.payload };
    case ActionTypes.SessionExpired:
      return { ...state, sessionExpired: action.payload };
    case ActionTypes.ChannelId:
      return { ...state, channelId: action.payload };
    case ActionTypes.UserId:
      return { ...state, userId: action.payload };
    case ActionTypes.InitVideo:
      return { ...state, initVideo: action.payload };
    case ActionTypes.InitAudio:
      return { ...state, initAudio: action.payload };
    case ActionTypes.InitSession:
      return { ...state, initSession: action.payload };
    case ActionTypes.WillExpired:
      return { ...state, willExpired: action.payload };
    case ActionTypes.Preview:
      return { ...state, preview: action.payload };
    case ActionTypes.Platform:
      return { ...state, platform: action.payload };
    default:
      return state;
  }
};

export const activeSpeakerReducer: Reducer<ActiveSpeaker, AgoraAction> = (
  state,
  action
): ActiveSpeaker => {
  switch (action.type) {
    case ActionTypes.ActiveSpeaker:
      return { ...state, activeSpeaker: action.payload };
    default:
      return state;
  }
};
