import React from 'react';
import { useMutation } from '@apollo/client';
import {
  IResetPasswordPrepData,
  IResetPasswordPrepVar,
  RESET_PASSWORD_PREP,
} from '@quesmed/types-rn/resolvers/mutation';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { FormField } from 'components/TextField';
import { Body, H4 } from 'components/Typography';
import { Button } from 'components/Button/index';
import { paths } from 'Router';
import { ArrowLeftIcon } from 'components/Icons';
import { useSnackbar } from 'components/Snackbar';
import {
  ContentContainer,
  ContentHolderContainer,
  ResetButton,
} from './Shared.styles';
import { isErrorButNotApolloOne } from 'utils';

interface ForgotPasswordFormInput {
  username: string;
}

const Title = styled(H4)(({ theme: { breakpoints, spacing } }) => ({
  marginTop: spacing(8),

  [breakpoints.up('md')]: {
    marginTop: spacing(10),
  },
}));

const Content = styled(Body)(({ theme: { breakpoints, spacing } }) => ({
  marginBottom: spacing(2),
  marginTop: spacing(4),

  [breakpoints.up('md')]: {
    marginBottom: spacing(4),
  },
}));

const schema = Joi.object<ForgotPasswordFormInput>({
  username: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Email Address is required',
      'string.email': 'Email must be a valid e-mail address',
    }),
});

const ForgotPassword = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [resetPasswordPrep, { loading }] = useMutation<
    IResetPasswordPrepData,
    IResetPasswordPrepVar
  >(RESET_PASSWORD_PREP);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<ForgotPasswordFormInput>({
    defaultValues: {
      username: '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const handleBackClick = () => {
    navigate(paths.login);
  };

  const handleReset = async ({ username }: ForgotPasswordFormInput) => {
    try {
      const res = await resetPasswordPrep({
        variables: {
          username,
        },
      });

      if (!res.errors?.length) {
        navigate(paths.checkEmail);
      }
    } catch (error) {
      if (isErrorButNotApolloOne(error)) {
        enqueueSnackbar(error.message);
      }
    }
  };

  return (
    <ContentHolderContainer>
      <ContentContainer>
        <Box>
          <Button
            color="secondary"
            onClick={handleBackClick}
            startIcon={<ArrowLeftIcon />}
            variant="outlined"
          >
            Back to Login
          </Button>
        </Box>
        <Title>Forgot your password?</Title>
        <Content>
          Please enter the email address of your account. We&apos;ll send you a
          reset link if your account exists.
        </Content>
        <form onSubmit={handleSubmit(handleReset)}>
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Email address"
            name="username"
            placeholder="Email address"
          />
          <ResetButton
            disabled={!isValid}
            fullWidth
            loading={loading}
            size="large"
            type="submit"
          >
            Reset my password
          </ResetButton>
        </form>
      </ContentContainer>
    </ContentHolderContainer>
  );
};

export default ForgotPassword;
