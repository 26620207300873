import React from 'react';
import { mdiBellOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const BellOutlineIcon = (props: IconProps) => (
  <Icon path={mdiBellOutline} {...props} />
);

export default BellOutlineIcon;
