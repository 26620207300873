import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';

import { Button } from 'components/Button';
import { useAuth } from 'Auth';
import { AppProductType, Nullable, productsApp, QuesmedAppNames } from 'types';
import { usePlatform } from 'context/PlatformState';
import { paths } from 'Router';

const { pricing, register } = paths;

export const SampleBox = (): Nullable<JSX.Element> => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { availableProducts } = usePlatform();
  const navigate = useNavigate();
  const { productType = '' } = useParams<{ productType: string }>();
  const isSample = pathname.includes('/sample');
  const hasProduct = Boolean(
    productType && availableProducts.includes(Number(productType))
  );

  if (!isSample || hasProduct) {
    return null;
  }

  const handleClick = () => {
    const app = productsApp[productType as unknown as AppProductType];

    const path = isAuthenticated
      ? `${pricing.root}/${kebabCase(QuesmedAppNames[app])}`
      : register;

    navigate(path);
  };

  return (
    <Button onClick={handleClick}>
      {isAuthenticated ? 'Upgrade now' : 'Register'}
    </Button>
  );
};
