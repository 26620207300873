import { EOsceRoles } from '@quesmed/types-rn/models';
import partition from 'lodash/partition';

import { Participant } from './types';

type SplitPredicate = (participants: Participant) => boolean;

export const splitParticipantsBy =
  (predicate: SplitPredicate) =>
  (participants: Participant[]): [Participant[], Participant[]] =>
    partition(participants, predicate);

export const splitByVideoToShow =
  (participants: Participant[]) =>
  ({ role }: Participant) => {
    const actor = participants.some(({ role }) => role === EOsceRoles.ACTOR);

    const hasRequiredRole =
      role === EOsceRoles.CANDIDATE ||
      role === EOsceRoles.ACTOR ||
      (role === EOsceRoles.EXAMINER && !actor);

    return hasRequiredRole;
  };

export const splitByCurrentUser =
  (currentUserId: number) =>
  ({ id }: Participant) =>
    Number(id) === currentUserId;
