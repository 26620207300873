import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Children } from 'types';

const Content = styled(Box)<{ selection: 1 | 0 }>(
  ({ selection, theme: { breakpoints, mixins, spacing, transitions } }) => ({
    boxSizing: 'border-box',
    width: '100%',
    margin: '0 auto',
    maxWidth: `calc(${mixins.container.maxWidth}px + 48px)`,
    padding: spacing(8, 6, selection ? 28 : 8),
    transition: transitions.create(['padding']),

    [breakpoints.up('xl')]: {
      maxWidth: `calc(${mixins.container.maxWidth}px + 80px)`,
      padding: spacing(8, 10, selection ? 24 : 8),
    },
  })
);

const Container = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar },
    },
  }) => ({
    height: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
    maxHeight: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
    boxSizing: 'border-box',
    overflow: 'auto',
    width: '100%',

    [breakpoints.up('md')]: {
      height: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
      maxHeight: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
    },
  })
);

interface PreLaunchLayoutPrps {
  children: Children;
  selection: 1 | 0;
}

const PreLaunchLayout = ({
  children,
  selection,
}: PreLaunchLayoutPrps): JSX.Element => (
  <Container>
    <Content selection={selection}>{children}</Content>
  </Container>
);

export default PreLaunchLayout;
