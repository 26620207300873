import { MutationHookOptions, useMutation } from '@apollo/client';
import {
  ILeaveMarksheetData,
  ILeaveMarksheetVar,
  LEAVE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

const useLeaveMarksheetMutation = (
  baseOptions?: MutationHookOptions<ILeaveMarksheetData, ILeaveMarksheetVar>
) => {
  const options = { ...baseOptions };

  return useMutation<ILeaveMarksheetData, ILeaveMarksheetVar>(LEAVE_MARKSHEET, {
    ...options,
  });
};

export default useLeaveMarksheetMutation;
