import React from 'react';
import { mdiBadgeAccountHorizontalOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CandidateRoleIcon = (props: IconProps) => (
  <Icon path={mdiBadgeAccountHorizontalOutline} {...props} />
);

export default CandidateRoleIcon;
