import { useCallback } from 'react';
import { IBuildTodoData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EEntitlementType } from '@quesmed/types-rn/models';

import { OnRequestComplete } from 'types';
import usePreBuildTodo from './usePreBuildTodo';
import useBuildTodo from './useBuildTodo';

interface BuildFlashcardsParams {
  search?: string;
  source?: string;
  entitlementId?: EEntitlementType;
  onBuildComplete?: OnRequestComplete<IBuildTodoData>;
}

const useBuildFlashcards = (params?: BuildFlashcardsParams) => {
  const {
    search = '',
    source = 'Cards',
    onBuildComplete,
    entitlementId,
  } = params || {};
  const {
    seen,
    unseen,
    buildRef,
    preBuildTodo,
    loading: preBuildLoading,
  } = usePreBuildTodo();
  const { loading: buildLoading, buildTodo } = useBuildTodo(onBuildComplete);

  const buildFlashcards = useCallback(
    async (unseen: number, seen: number) => {
      if (buildRef) {
        return buildTodo(buildRef, unseen, seen);
      }
    },
    [buildRef, buildTodo]
  );

  const preBuildFlashcards = useCallback(
    async (conceptIds: Set<number> = new Set()) =>
      preBuildTodo(conceptIds, search, source, entitlementId),
    [preBuildTodo, search, source, entitlementId]
  );

  return {
    preBuildLoading,
    preBuildFlashcards,
    buildLoading,
    buildFlashcards,
    seen,
    unseen,
  };
};

export default useBuildFlashcards;
