import React from 'react';
import MuiGlobalStyles from '@mui/material/GlobalStyles';

const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={theme => ({
      'html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td':
        {
          margin: 0,
          padding: 0,
          border: 0,
          outline: 0,
          fontSize: '100%',
          verticalAlign: 'baseline',
          background: 'transparent',
          boxSizing: 'border-box',
        },
      sup: {
        fontSize: 'smaller',
        verticalAlign: 'super',
      },
      'ol,ul': {
        listStyle: 'none',
      },
      'blockquote, q': {
        quotes: 'none',
      },
      ':focus': {
        outline: 0,
      },
      ins: {
        textDecoration: 'none',
      },
      del: {
        textDecoration: 'line-through',
      },
      'html,body': {
        maxWidth: '100vw',
        overflowX: 'hidden',
        minHeight: '100vh',
      },
      html: {
        minHeight: '-webkit-fill-available',
      },
      body: {
        fontStyle: 'normal',
        fontVariant: 'no-common-ligatures',
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        minHeight: '-webkit-fill-available',
      },

      // TODO discusse this settings
      '*': {
        scrollbarWidth: 'thin',
      },
      '::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.palette.stroke.main,
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.stroke.light,
      },
      '.ReactModal__Body--open': {
        overflowY: 'hidden',
      },
      '.MuiFormControl-marginNormal': {
        margin: '0 !important',
      },
    })}
  />
);

export default GlobalStyles;
