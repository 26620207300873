import React from 'react';
import { mdiCommentMultipleOutline } from '@mdi/js';
import { useTheme } from '@mui/system';

import Icon, { IconProps } from './Icon';

const CommentsIcon = (props: IconProps) => {
  const { palette } = useTheme();

  return (
    <Icon
      color={palette.icon.main}
      path={mdiCommentMultipleOutline}
      {...props}
    />
  );
};

export default CommentsIcon;
