import React from 'react';
import { mdiSwapHorizontal } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const SwapHorizontalIcon = (props: IconProps) => (
  <Icon path={mdiSwapHorizontal} {...props} />
);

export default SwapHorizontalIcon;
