import React from 'react';
import { mdiVolumeOff } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const VolumeOffIcon = (props: IconProps) => (
  <Icon path={mdiVolumeOff} {...props} />
);

export default VolumeOffIcon;
