import React, { forwardRef, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';

import { Children } from 'types';
import { ArrowCollapseLeftIcon } from 'components/Icons';
import { NavigationItem } from './NavigationItem';
import NavigationToolitp from './NavigationTooltip';

const SideBarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const SideBarContent = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(6, 2, 0),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowY: 'auto',
  margin: spacing(0, -2),
}));

const NavigationToolBar = styled(Toolbar)(({ theme: { breakpoints } }) => ({
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'flex',

    '&.hidden': {
      display: 'none',
    },
  },
}));

interface StyledBoxProps extends BoxProps {
  expanded: 0 | 1;
}

const ExpandIconBox = styled(Box)<StyledBoxProps>(
  ({ expanded, theme: { palette, spacing, transitions } }) => ({
    borderTop: `1px solid ${palette.stroke.main}`,
    boxSizing: 'border-box',
    padding: spacing(0, 2),
    margin: spacing(0, -2),
    marginTop: 'auto',
    display: 'flex',
    justifyContent: expanded ? 'flex-end' : 'center',

    '.MuiSvgIcon-root': {
      transform: expanded ? 'rotate(0)' : 'rotate(180deg)',
      transition: transitions.create(['transform'], {
        easing: transitions.easing.easeOut,
        delay: 150,
        duration: transitions.duration.standard,
      }),
    },
  })
);

interface MuiDrawerProps extends DrawerProps {
  expanded: 0 | 1;
  open: boolean;
}

const Drawer = styled(MuiDrawer)<MuiDrawerProps>(
  ({
    expanded,
    open,
    theme: { breakpoints, mixins, palette, transitions, spacing, zIndex },
  }) => ({
    [breakpoints.up('xl')]: {
      position: 'absolute',
      marginLeft: open
        ? 0
        : `-${mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth}px`,
    },

    '& .MuiDrawer-paper': {
      zIndex: zIndex.appBar - 1,
      padding: `0 ${spacing(2)}`,
      borderRight: `1px solid ${palette.stroke.main}`,
      backgroundColor: palette.background.paper,
      backgroundImage: 'none',
      boxSizing: 'border-box',
      overflow: 'hidden',
      width: `${
        mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth
      }px`,
      minWidth: `${
        mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth
      }px`,
      transition: `${transitions.create(
        ['transform', 'min-width', 'width', 'margin'],
        {
          easing: transitions.easing.easeOut,
          duration: transitions.duration.enteringScreen,
        }
      )} !important`,
    },
  })
);

export interface NavSideBarProps extends DrawerProps {
  children: Children;
  expanded: boolean;
  isOpen: boolean;
  onClose: () => void;
  onExpand: () => void;
}

const NavSideBar = forwardRef<HTMLDivElement, NavSideBarProps>(
  (
    { children, expanded, isOpen, onClose, onExpand, variant, ...props },
    ref
  ): JSX.Element => {
    const label = `${expanded ? 'Collapse' : 'Expand'} sidebar`;
    const [title, setTitle] = useState(label);
    const disableListeners = expanded || !title;

    const handleClick = () => {
      onExpand();
    };

    // workaround to prevent wrong tooltip
    // positioning during collapsing sidebar
    useEffect(() => {
      setTitle('');

      const timeout = setTimeout(() => {
        setTitle(label);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }, [expanded, label]);

    return (
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        anchor="left"
        expanded={expanded ? 1 : 0}
        onClose={onClose}
        open={isOpen}
        variant={variant}
        {...props}
      >
        <SideBarContainer ref={ref}>
          <NavigationToolBar
            className={clsx({ hidden: variant === 'temporary' })}
          />
          <SideBarContent>{children}</SideBarContent>
          <NavigationToolitp
            disableListeners={disableListeners}
            title={expanded ? '' : title}
          >
            <ExpandIconBox expanded={expanded ? 1 : 0}>
              <NavigationItem
                className="collapse-button"
                expanded={expanded}
                icon={ArrowCollapseLeftIcon}
                label={label}
                onClick={handleClick}
              />
            </ExpandIconBox>
          </NavigationToolitp>
        </SideBarContainer>
      </Drawer>
    );
  }
);

NavSideBar.displayName = 'NavSideBar';

export default NavSideBar;
