import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  BUILD_TODO,
  IBuildTodoData,
  IBuildTodoVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import { OnRequestComplete } from 'types';

const useBuildTodo = (onBuildComplete?: OnRequestComplete<IBuildTodoData>) => {
  const navigate = useNavigate();

  const [build, { loading }] = useMutation<IBuildTodoData, IBuildTodoVar>(
    BUILD_TODO,
    {
      onCompleted: data => {
        const { id } = data.restricted.buildTodo || {};
        if (id) {
          navigate(`${paths.flashcards.root}/quiz/${id}`, {
            state: { leave: true },
          });

          if (onBuildComplete) {
            onBuildComplete(data);
          }
        }
      },
    }
  );

  const buildTodo = useCallback(
    async (buildRef: number, unseen: number, seen: number) => {
      if (buildRef) {
        return build({
          variables: {
            buildTodo: {
              unseen,
              seen,
              buildRef,
            },
          },
        });
      }
    },
    [build]
  );

  return {
    loading,
    buildTodo,
  };
};

export default useBuildTodo;
