import { Dispatch as ReactDispatch, ReactNode } from 'react';

import { DashboardTilesSettings } from 'types';

export enum ActionTypes {
  BookExplanationVisible = 'APP_STATE/BOOK_EXPLANATION_VISIBLE',
  CheckoutWithSelectedPlan = 'APP_STATE/CHECKOUT_WITH_SELECTED_PLAN',
  FontSize = 'APP_STATE/FONT_SIZE',
  IsMenuOpen = 'APP_STATE/IS_MENU_OPEN',
  LogoutCallback = 'APP_STATE/LOGOUT_CALLBACK',
  CustomiseDashboard = 'APP_STATE/CUSTOMISE_DASHBOARD',
}

export interface CustomiseDashboardAction {
  type: ActionTypes.CustomiseDashboard;
  payload: DashboardTilesSettings;
}

export interface CheckoutWithSelectedPlanAction {
  type: ActionTypes.CheckoutWithSelectedPlan;
  payload: boolean;
}
export interface BookExplanationVisibleAction {
  type: ActionTypes.BookExplanationVisible;
  payload: boolean;
}

export interface FontSizeAction {
  type: ActionTypes.FontSize;
  payload: number;
}

export interface IsMenuOpenAction {
  type: ActionTypes.IsMenuOpen;
  payload: boolean;
}
export interface LogoutCallbackAction {
  type: ActionTypes.LogoutCallback;
  payload?: () => Promise<any>;
}

export type AppAction =
  | BookExplanationVisibleAction
  | CheckoutWithSelectedPlanAction
  | CustomiseDashboardAction
  | FontSizeAction
  | IsMenuOpenAction
  | LogoutCallbackAction;

export interface AppState {
  bookExplanationVisible: boolean;
  checkoutWithSelectedPlan: boolean;
  dashboardSettings: DashboardTilesSettings;
  fontSize: number;
  isMenuOpen: boolean;
  logoutCallback?: () => Promise<any>;
}

export type ActionCreator = (payload: any) => AppAction;

export type Dispatch = ReactDispatch<AppAction>;

export type AppDispatch = {
  setCheckoutWithSelectedPlan: (payload: boolean) => void;
  setDashboardSettings: (settings: DashboardTilesSettings) => void;
  setFontSize: (payload: number) => void;
  setIsMenuOpen: (payload: boolean) => void;
  setLogoutCollback: (payload?: () => Promise<any>) => void;
};

export interface AppDispatchContextProviderProps {
  children: ReactNode;
  value: Dispatch;
}

export interface AppStateContextProviderProps {
  children: ReactNode;
  value: AppState;
}

export interface AppStateProviderProps {
  children: ReactNode;
}
