import { useCallback, useEffect, useState } from 'react';

export const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  const handleWindowFocus = useCallback(() => {
    setIsWindowFocused(true);
  }, []);

  const handleWindowBlur = useCallback(() => {
    setIsWindowFocused(false);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [handleVisibilityChange, handleWindowBlur, handleWindowFocus]);

  return isTabVisible && isWindowFocused;
};
