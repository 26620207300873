export function extractHTMLContentFromElement(node: Node) {
  const content = [].map
    .call(node.childNodes, (element: Element) => {
      return element.nodeType === element.TEXT_NODE
        ? element.textContent
        : element.outerHTML;
    })
    .join('');

  return content;
}
