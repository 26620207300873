import React, { useEffect, useRef, useState } from 'react';

import { CheckIcon, CopyIcon } from 'components/Icons';
import { useSnackbar } from 'components/Snackbar';
import { clearTimeoutSafely, noop } from 'utils';
import { ButtonOnClickHandler } from 'types';
import { Button } from 'components/Button';

interface ClipboardCopyButtonProps {
  text: string;
  icon?: boolean;
  timeout?: number;
}

const copyToClipboard = async (text: string): Promise<void | boolean> => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  }
};

const ClipboardCopyButton = ({
  icon = true,
  text,
  timeout = 5000,
}: ClipboardCopyButtonProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCopied, setIsCopied] = useState(false);
  const Icon = isCopied ? CheckIcon : CopyIcon;
  const resetTimeoutRef = useRef<number | null>(null);

  const resetCopyState = () => {
    setIsCopied(false);
  };

  const handleButtonCopy: ButtonOnClickHandler = async () => {
    try {
      await copyToClipboard(text);

      if (icon) {
        setIsCopied(true);
        clearTimeoutSafely(resetTimeoutRef.current);
        resetTimeoutRef.current = window.setTimeout(resetCopyState, timeout);
      } else {
        enqueueSnackbar('Copied to Clipboard');
      }
    } catch {
      noop();
    }
  };

  useEffect(() => {
    () => {
      clearTimeoutSafely(resetTimeoutRef.current);
    };
  }, []);

  return icon ? (
    // <IconButton onClick={handleButtonCopy}>
    <Icon color="black" /> // </IconButton>
  ) : (
    <Button onClick={handleButtonCopy}> Copy Text </Button>
  );
};

export default ClipboardCopyButton;
