import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { clsx } from 'clsx';

import { Breadcrumbs, BreadcrumbsProps } from 'components/Breadcrumbs';
import { constSize } from 'utils';

const LearningMaterialsBreadcrumbsContainer = styled(MuiBreadcrumbs)(
  ({ theme: { palette, typography, spacing } }) => ({
    marginBottom: spacing(2),

    '&.MuiBreadcrumbs-root .MuiBreadcrumbs-ol': {
      '& .MuiBreadcrumbs-separator': {
        ...typography.body2Medium,
        margin: spacing(0, 1),
      },
    },

    '&.MuiBreadcrumbs-root.header .MuiBreadcrumbs-ol': {
      '& .MuiBreadcrumbs-separator': {
        ...typography.h5,
        margin: spacing(0, 2),
      },
    },

    overflow: 'hidden',

    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
    },

    '& .MuiBreadcrumbs-li:last-of-type': {
      overflow: 'hidden',
    },

    '&.header': {
      '& .MuiBreadcrumbs-li:last-of-type:not(:first-of-type)': {
        '& .MuiBox-root.header': {
          color: palette.primary.dark,
          '& .MuiSvgIcon-root': {
            color: palette.primary.dark,
          },
        },
      },
    },
  })
);

const LearningMaterialsBreadcrumbsItem = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: spacing(1),
    cursor: 'default',
    color: palette.text.primary,
    ...typography.body2Medium,

    '& .MuiSvgIcon-root': {
      color: palette.icon.main,
      ...constSize('16px'),
    },

    '&.header': {
      gap: spacing(2),
      ...typography.h5,
      '& .MuiSvgIcon-root': {
        ...constSize('24px'),
      },
    },

    '&.link': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  })
);

export interface LearningMaterialsBreadcrumbsProps
  extends Pick<BreadcrumbsProps, 'items'> {
  header?: boolean;
}

export const LearningMaterialsBreadcrumbs = ({
  items,
  header = false,
}: LearningMaterialsBreadcrumbsProps): JSX.Element => (
  <Breadcrumbs
    breadcrumbItemComponent={LearningMaterialsBreadcrumbsItem}
    className={clsx({ header })}
    component={LearningMaterialsBreadcrumbsContainer}
    ellipsis
    items={items}
  />
);
