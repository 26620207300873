import { IEntitlement } from '@quesmed/types-rn/models';

export enum SearchScopeIndex {
  Questions = 'questions',
  Flashcards = 'cards',
  Videos = 'videos',
  Quesbook = 'quesbook',
  Oscebook = 'oscebook',
  Pacebook = 'pacebook',
}

export const TEST_SEARCH_PREFIX = 'test_';
export const PROD_SEARCH_PREFIX = 'rebrand_';

export type SearchIndex =
  | `${typeof TEST_SEARCH_PREFIX}${SearchScopeIndex}`
  | `${typeof PROD_SEARCH_PREFIX}${SearchScopeIndex}`;

export enum Title {
  LearningMaterials = 'Learning Materials',
  PracticeMaterials = 'Practice Materials',
}

export interface TopicHit {
  typeId: number;
  topicId: number;
  conceptId: number;
  entitlement: IEntitlement;
}

export interface StationHit {
  osceStationId: number;
  osceTypeId: number;
}
