import { useLazyQuery, useQuery } from '@apollo/client';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { useCallback } from 'react';

const useMarksheet = (loading: boolean, marksheetId?: number) => {
  const {
    data,
    error: marksheetError,
    loading: marksheetLoading,
    refetch,
  } = useQuery<IMarksheetData, IMarksheetVar>(MARKSHEET, {
    skip: !marksheetId || loading,
    variables: {
      id: Number(marksheetId),
    },
  });

  const [lazyMarksheetQuery, { data: lazyData, loading: fetchLoading }] =
    useLazyQuery<IMarksheetData, IMarksheetVar>(MARKSHEET);

  const fetchMarksheet = (id: number) =>
    lazyMarksheetQuery({ variables: { id } });

  const refetchMarksheet = useCallback(() => {
    refetch?.();
  }, [refetch]);

  const { marksheet } = data?.restricted || {};
  const { marksheet: lazyMarksheet } = lazyData?.restricted || {};

  return {
    fetchLoading,
    fetchMarksheet,
    marksheet: marksheet || lazyMarksheet,
    marksheetLoading,
    marksheetError,
    refetchMarksheet,
  };
};

export default useMarksheet;
