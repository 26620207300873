import { styled } from '@mui/material/styles';

import { Body } from 'components/Typography';

export const Fallback = styled(Body)(({ theme: { palette } }) => ({
  textAlign: 'center',
  color: palette.text.secondary,
}));

export const SearchPhrase = styled(Body)(({ theme: { palette } }) => ({
  display: 'inline',
  color: palette.text.primary,
}));
