import React from 'react';
import { mdiRadioboxBlank } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const RadioBoxBlankIcon = (props: IconProps) => (
  <Icon path={mdiRadioboxBlank} {...props} />
);

export default RadioBoxBlankIcon;
