import * as Sentry from '@sentry/react';

export const addSentryBreadcrumb = ({
  category,
  message,
  level = 'info',
}: {
  category: string;
  message: string;
  level?: Sentry.SeverityLevel;
}) =>
  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
