import React, { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalProps } from 'components/Modal/Modal';
import { Body } from 'components/Typography';
import { paths } from 'Router';

const { dashboard } = paths;

const Content = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(6),
  flexDirection: 'column',
}));

const UnorderedList = styled('ul')(({ theme: { spacing } }) => ({
  paddingLeft: spacing(4),
  margin: spacing(4, 0),
}));

const OrderedList = styled('ol')(({ theme: { spacing } }) => ({
  paddingLeft: spacing(4),
  listStyle: 'decimal',
  marginBottom: spacing(8),

  span: {
    textTransform: 'capitalize',
  },
}));

const ListItem = styled('li')(
  ({ theme: { spacing, palette, typography } }) => ({
    marginBottom: spacing(2),
    color: palette.text.primary,
    position: 'relative',
    paddingLeft: spacing(3.5),
    ...typography.body1,

    '&::before': {
      content: "''",
      display: 'block',
      width: '2px',
      height: '2px',
      top: '11px',
      borderRadius: '50%',
      position: 'absolute',
      left: 0,
      background: palette.text.primary,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  })
);

const cameraPermissionsContent = (
  <>
    <Body>
      To get the most out of the exercise, we recommend that you allow camera
      access. With the webcam on, you’ll be able to:
    </Body>
    <UnorderedList>
      <ListItem>Get personal feedback on your performance</ListItem>
      <ListItem>Connect more deeply with other participants</ListItem>
      <ListItem>Improve your non-verbal communication skills</ListItem>
    </UnorderedList>
    <Body>
      Please note that camera access is not required to participate in the
      exercise, but it can enhance your learning experience.
    </Body>
  </>
);

const microphonePermissionsContent = (
  <Content>
    <Body>
      Microphone access required We’re sorry, but it looks like you don’t have
      the necessary permissions for our app to work properly.
    </Body>
    <Body>
      Without access to your camera and microphone, exercise: Stations will not
      work and you will not be able to use it.
    </Body>
    <Body>
      Don’t worry, we understand your privacy concerns and will only use your
      camera and microphone for the purpose of this exercise.
    </Body>
  </Content>
);

const instruction = (variant: PermissionsModalVariant) => (
  <Content>
    <Body>To grant access, follow these steps:</Body>

    <OrderedList>
      <li>Click the lock icon in the address bar.</li>
      <li>
        In the Permissions section, locate <span>’{variant}’</span> and select
        ’Allow’.
      </li>
      <li>Refresh the page or click the ‘Check permissions again’ button.</li>
    </OrderedList>
  </Content>
);

const confirmation = () => (
  <Content>
    <Body>
      It looks like the session is still going on. Before you go, are you sure
      you want to leave?
    </Body>
  </Content>
);

const displayContent = (variant: PermissionsModalVariant) => {
  switch (variant) {
    case 'camera':
      return cameraPermissionsContent;
    case 'microphone':
      return microphonePermissionsContent;
    default:
      return microphonePermissionsContent;
  }
};

const getContent = (
  instructionsOpen: boolean,
  variant: PermissionsModalVariant,
  confirmatinOpen: boolean
) => {
  if (confirmatinOpen) {
    return confirmation();
  }

  return instructionsOpen ? instruction(variant) : displayContent(variant);
};

const getTitle = (
  instructionsOpen: boolean,
  variant: PermissionsModalVariant,
  confirmatinOpen: boolean
) => {
  if (confirmatinOpen) {
    return 'Are you sure want to exit?';
  }

  return instructionsOpen ? 'Instruction' : `Allow ${variant} access`;
};

type PermissionsModalVariant = 'camera' | 'microphone';

type PermissionsModalProps = Pick<ModalProps, 'open'> & {
  loading?: boolean;
  permissionOptional?: boolean;
  variant: PermissionsModalVariant;
  onCheckAgain: () => void;
};

const PermissionsModal = ({
  loading,
  open,
  permissionOptional = false,
  variant,
  onCheckAgain,
}: PermissionsModalProps): JSX.Element => {
  const navigate = useNavigate();
  const [isInstructionsVisible, setIsInstructionsVisible] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDecline = () => {
    setShowConfirmation(false);

    if (!permissionOptional) {
      navigate(dashboard);
    }
  };

  const handleClose = () => {
    setShowConfirmation(true);
    setIsInstructionsVisible(false);
  };

  const showInstructions = () => {
    setIsInstructionsVisible(true);
  };

  const handleBack = () => {
    if (isInstructionsVisible) {
      setIsInstructionsVisible(false);

      return;
    }

    if (showConfirmation) {
      setShowConfirmation(false);
    }
  };

  const title = getTitle(isInstructionsVisible, variant, showConfirmation);

  const content = getContent(isInstructionsVisible, variant, showConfirmation);

  const showBackButton = isInstructionsVisible || showConfirmation;

  return (
    <Modal
      closeOnBackdrop={false}
      closeOnEscape={false}
      complementaryActionLabel="Instruction"
      danger={showConfirmation}
      disableContentScroll
      maxWidth="md"
      noPaddingY
      onBack={showBackButton ? handleBack : undefined}
      onClose={handleClose}
      onComplementaryAction={!showConfirmation ? showInstructions : undefined}
      onSubmit={showConfirmation ? handleDecline : onCheckAgain}
      open={open}
      showCloseButton={!showConfirmation}
      showControls={!isInstructionsVisible}
      sizeVariant="md"
      submitLabel={
        showConfirmation ? 'Leave session' : 'Check permissions again'
      }
      submitLoading={loading}
      title={title}
    >
      {content}
    </Modal>
  );
};

export default PermissionsModal;
