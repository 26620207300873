import React from 'react';
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CheckboxMarkedCircleOutlineIcon = (props: IconProps) => (
  <Icon path={mdiCheckboxMarkedCircleOutline} {...props} />
);

export default CheckboxMarkedCircleOutlineIcon;
