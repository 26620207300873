import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { CheckIcon, ChevronDownIcon, LockOutlineIcon } from 'components/Icons';

interface NavigationItemLabelProps {
  active?: boolean;
  locked?: boolean;
  label: string;
  withNestedItems?: boolean;
  withActiveState?: boolean;
}

const LabelBox = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',

  '& .MuiSvgIcon-root': {
    marginLeft: 'auto',
  },

  '& .check-icon': {
    display: 'none',
  },

  '&.active .check-icon': {
    display: 'block',
  },
});

const NavigationItemLabel = ({
  active,
  label,
  locked,
  withNestedItems,
  withActiveState,
}: NavigationItemLabelProps) => (
  <LabelBox className={clsx({ active })}>
    {label}
    {withNestedItems && !locked && !withActiveState ? (
      <ChevronDownIcon />
    ) : null}
    {locked ? <LockOutlineIcon /> : null}
    {withActiveState && !locked ? <CheckIcon className="check-icon" /> : null}
  </LabelBox>
);

export default NavigationItemLabel;
