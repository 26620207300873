import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import clsx from 'clsx';

import { Body } from 'components/Typography';

const Label = styled(Body)(({ theme: { breakpoints, spacing } }) => ({
  marginLeft: spacing(2),
  display: 'none',

  [breakpoints.up('md')]: {
    display: 'inline',
  },
}));

export interface IconButtonProps extends MuiIconButtonProps {
  icon?: JSX.Element;
  label?: string;
  white?: boolean;
}

const StyledIconButton = styled(MuiIconButton)(
  ({ theme: { palette, transitions } }) => ({
    color: palette.icon.main,
    transition: transitions.create(['color']),
    border: '1px solid transparent',

    '&:hover': {
      color: palette.icon.dark,
    },

    '&:disabled': {
      color: palette.icon.light,
    },

    '&.open': {
      background: palette.toggle.light,
      border: `1px solid ${palette.stroke.main}`,
      borderRadius: '4px',
    },

    '&.white': {
      color: palette.mockTest.contrastText,

      '&:hover': {
        color: alpha(palette.mockTest.contrastText as string, 0.7),
      },

      '&:disabled': {
        color: alpha(palette.mockTest.contrastText as string, 0.5),
      },

      '&.open': {
        background: alpha(palette.mockTest.contrastText as string, 0.1),
        border: `1px solid ${alpha(
          palette.mockTest.contrastText as string,
          0.1
        )}`,
        borderRadius: '4px',
      },
    },
  })
);

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { children, className, label, icon, white, color = 'secondary', ...props },
    ref
  ): JSX.Element => (
    <StyledIconButton
      className={clsx(className, { white })}
      color={color}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      ref={ref}
      {...props}
    >
      {icon || children}
      {label ? <Label component="span">{label}</Label> : null}
    </StyledIconButton>
  )
);

IconButton.displayName = 'IconButton';

export default IconButton;
