import React from 'react';
import { mdiAccountPlusOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const InviteToLobbyIcon = (props: IconProps) => (
  <Icon path={mdiAccountPlusOutline} {...props} />
);

export default InviteToLobbyIcon;
