import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface LabValuesState {
  open: boolean;
  visible: boolean;
  openLabValues: () => void;
  closeLabValues: () => void;
  showLabValues: () => void;
  hideLabValues: () => void;
}

const useLabValues = create<LabValuesState>()(set => ({
  open: false,
  visible: false,
  openLabValues: () => set({ open: true }),
  closeLabValues: () => set({ open: false }),
  showLabValues: () => set({ visible: true }),
  hideLabValues: () => set({ visible: false }),
}));

const useLabValuesState = () => useLabValues(state => state, shallow);

export default useLabValuesState;
