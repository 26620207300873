import { useApolloClient, useLazyQuery } from '@apollo/client';
import React from 'react';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  GET_USER_TOKEN,
  IGetUserTokenData,
  IGetUserTokenVar,
} from '@quesmed/types-rn/resolvers/query/admin';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalProps } from 'components/Modal';
import { Body } from 'components/Typography';
import { FormField } from 'components/TextField/FormField';
import { useAuth } from 'Auth';
import { EMAIL_REGEXP } from 'constants/general';
import { useCheckEmailAvailability } from 'hooks';
import { CircularProgress } from 'components/CircularProgress';
import { paths } from 'Router';
import { useSettingsModalState } from './useSettingsModalState';

const { appsManagement } = paths;

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  '& .MuiTypography-root': {
    marginBottom: spacing(8),
  },
  '& .MuiFormControl-root': {
    marginBottom: spacing(4),
  },
}));

export interface UserEmailInput {
  userEmail?: string;
}

const AccountInvestigationModal = ({
  onClose,
  open,
}: ModalProps): JSX.Element => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { setIsSettingsOpen } = useSettingsModalState();

  const { checkEmail, loading: checkEmailLoading } =
    useCheckEmailAvailability();
  const { setToken } = useAuth();
  const [getUserToken, { loading }] = useLazyQuery<
    IGetUserTokenData,
    IGetUserTokenVar
  >(GET_USER_TOKEN, {
    onCompleted: async ({ admin }) => {
      if (admin?.getUserToken) {
        setIsSettingsOpen(false);
        await client.clearStore();
        navigate(appsManagement);
        setToken(admin.getUserToken);
      }
    },
  });

  const schema = Joi.object<UserEmailInput>({
    userEmail: Joi.string()
      .pattern(EMAIL_REGEXP)
      .required()
      .external(async email => {
        const emailUnused = await checkEmail(email);
        if (emailUnused) {
          throw new Joi.ValidationError(
            'string.email',
            [
              {
                message: `User ${email} does not exists`,
                path: ['userEmail'],
                type: 'string.email',
                context: {
                  key: 'userEmail',
                  label: 'userEmail',
                  value: email,
                },
              },
            ],
            email
          );
        }
      })
      .messages({
        'any.required': 'User email address field is required',
        'string.empty': 'User email address field is required',
        'string.pattern.base': 'User email address must be a valid email',
      }),
  });

  const {
    control,
    formState: { isDirty, isValid },
    reset,
    handleSubmit,
  } = useForm<UserEmailInput>({
    resolver: joiResolver(schema),
    defaultValues: {
      userEmail: '',
    },
    mode: 'onTouched',
  });

  const handleClose = () => {
    reset();
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = async (data: UserEmailInput) => {
    if (!data.userEmail || !isValid || !isDirty) {
      return;
    }

    getUserToken({ variables: { username: data.userEmail } });
    handleClose();
  };

  const disableSubmit = !isValid || !isDirty;

  return (
    <Modal
      cancelLabel="Cancel"
      danger
      loading={loading}
      noPaddingY
      onBack={onClose}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={disableSubmit}
      submitLabel="Confirm Investigation"
      title="Perform account investigation"
    >
      <StyledBox>
        <Body>
          Enter the email address of the user whose account you want to access.
          Once you have entered their email address, you will be directed to
          their account where you can navigate and investigate any reported
          issues.
        </Body>
        <FormField
          InputProps={{
            endAdornment: checkEmailLoading ? (
              <CircularProgress color="primary" fullscreen={false} />
            ) : null,
          }}
          control={control}
          fullWidth
          label="User email address"
          name="userEmail"
          type="string"
        />
      </StyledBox>
    </Modal>
  );
};

export default AccountInvestigationModal;
