import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { EOsceMarksheetState, EOsceRoles } from '@quesmed/types-rn/models';

import { Participant, ParticipantCardVariant } from './types';
import ParticipantCard from './ParticipantCard';
import { InviteToLobbyIcon } from 'components/Icons';
import { InviteToGroupStudyModal } from 'components/InviteToGroupStudyModal';
import { Nullable, StationRole } from 'types';
import { useStartMarksheetMutation } from 'pages/Stations/hooks';
import {
  splitByCurrentUser,
  splitByVideoToShow,
  splitParticipantsBy,
} from './splitParticipants';
import { useSnackbar } from 'components/Snackbar';
import { useDemo } from 'Auth';
import locales from 'locales';

const MAX_PARTICIPANT_COUNT = 4;

const ParticipantContainer = styled(Box)(({ theme: { spacing, palette } }) => ({
  padding: spacing(7, 8),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${palette.stroke.main}`,
  borderRadius: spacing(1),
  minHeight: '120px',
  background: palette.background.paper,
}));

const ParticipantsListHeader = styled(Box)(
  ({ theme: { typography, spacing, palette } }) => ({
    ...typography.button,
    color: palette.text.disabled,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: spacing(4, 0),

    '& .MuiSvgIcon-root': {
      color: palette.icon.main,
    },
  })
);

const ParticipantEmpty = styled(Box)(
  ({ theme: { typography, spacing, palette } }) => ({
    ...typography.button,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(2),
    color: palette.text.disabled,
    cursor: 'pointer',

    '& .MuiSvgIcon-root': {
      color: palette.text.disabled,
    },
  })
);

const ParticipantsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  marginBottom: spacing(6),
}));

export interface ParticipantListProps {
  currentUserId: number;
  participants: Participant[];
  withAudio?: boolean;
  withVideo?: boolean;
  withHeader?: boolean;
  withStatus?: boolean;
  withoutRole?: boolean;
  withRoleHeader?: boolean;
  onChangeRole?: () => void;
  inviteLink?: string;
  onSwapRole?: (role: StationRole) => void;
  variant: ParticipantCardVariant;
  isCandidate?: boolean;
  isExaminer?: boolean;
  osceMarksheetId?: number;
  state?: EOsceMarksheetState;
}

export const ParticipantList = ({
  currentUserId,
  withVideo = false,
  withHeader = false,
  withRoleHeader,
  participants,
  withAudio,
  withStatus,
  variant,
  inviteLink,
  onSwapRole,
  onChangeRole,
  withoutRole,
  osceMarksheetId,
  state,
  isCandidate = false,
  isExaminer = false,
}: ParticipantListProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const isDemo = useDemo();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const showRoleButton = Boolean(onChangeRole && onSwapRole);
  const { startMarksheet } = useStartMarksheetMutation();

  const splitParticipants = splitParticipantsBy(
    withVideo
      ? splitByVideoToShow(participants)
      : splitByCurrentUser(currentUserId)
  );

  const [topParticipants, bottomParticipants] = useMemo(
    () => splitParticipants(participants),
    [splitParticipants, participants]
  );

  const handleRoleChange = (role: StationRole, id: number) => () => {
    if (id === currentUserId && onChangeRole) {
      onChangeRole();
    } else if (onSwapRole) {
      onSwapRole(role);
    }
  };

  const hadleOpenInviteModal = () => {
    if (isDemo) {
      enqueueSnackbar(locales.common.demo.groupStudy);
    } else {
      setOpenInviteModal(true);
    }
  };
  const hadleCloseInviteModal = () => setOpenInviteModal(false);

  const inviteBoxesCount = MAX_PARTICIPANT_COUNT - participants.length;

  const getLabelAndAction = (
    userId: number,
    currentUser: boolean,
    role?: Nullable<StationRole>
  ) => {
    let label = '';
    let onClick =
      showRoleButton && role ? handleRoleChange(role, userId) : null;
    if (
      isExaminer &&
      state === EOsceMarksheetState.PRESTART &&
      role === EOsceRoles.CANDIDATE
    ) {
      label = 'Allow Entry';
      onClick = () => {
        startMarksheet(Number(osceMarksheetId));
      };
    } else {
      label =
        showRoleButton && role
          ? currentUser
            ? 'Change role'
            : 'Swap role'
          : '';
    }

    return {
      label,
      onClick,
    };
  };

  return (
    <ParticipantsContainer>
      {withHeader ? (
        <ParticipantsListHeader>
          Participants ({participants.length})
        </ParticipantsListHeader>
      ) : null}
      {topParticipants.map(({ id, displayName, role }) => {
        const userId = Number(id);
        const currentUser = userId === currentUserId;
        const { label, onClick } = getLabelAndAction(userId, currentUser, role);

        const color = showRoleButton && role ? 'secondary' : 'primary';

        return (
          <ParticipantCard
            color={color}
            currentUser={currentUser}
            displayName={displayName}
            isCandidate={isCandidate}
            key={userId}
            label={label}
            onClick={onClick}
            participantId={userId}
            role={role}
            state={state}
            variant={variant}
            withAudio={withAudio}
            withRoleHeader={withRoleHeader}
            withStatus={withStatus}
            withVideo={withVideo}
            withoutRole={withoutRole}
          />
        );
      })}
      {bottomParticipants.map(({ id, displayName, role }) => {
        const userId = Number(id);
        const currentUser = userId === currentUserId;
        const { label, onClick } = getLabelAndAction(userId, currentUser, role);

        const color = showRoleButton && role ? 'secondary' : 'primary';

        return (
          <ParticipantCard
            color={color}
            currentUser={currentUser}
            displayName={displayName}
            isCandidate={isCandidate}
            key={userId}
            label={label}
            onClick={onClick}
            participantId={userId}
            role={role}
            state={state}
            variant={variant}
            withAudio={withAudio}
            withRoleHeader={withRoleHeader}
            withStatus={withStatus}
            withVideo={withVideo}
            withoutRole={withoutRole}
          />
        );
      })}
      {inviteLink && inviteBoxesCount
        ? Array.from({ length: inviteBoxesCount }).map((_, i) => (
            <ParticipantContainer key={i} onClick={hadleOpenInviteModal}>
              <ParticipantEmpty>
                <InviteToLobbyIcon /> Invite Friend
              </ParticipantEmpty>
            </ParticipantContainer>
          ))
        : null}
      {inviteLink ? (
        <InviteToGroupStudyModal
          inviteLink={inviteLink}
          onClose={hadleCloseInviteModal}
          open={openInviteModal}
        />
      ) : null}
    </ParticipantsContainer>
  );
};
