import React, {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  ReactNode,
  SetStateAction,
} from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import {
  IConcept,
  IMarksheet,
  IOsceMarksheet,
  IUniversity,
} from '@quesmed/types-rn/models';
import { OptionsObject, SnackbarKey } from 'notistack';

// eslint-disable-next-line import/no-cycle
import { AppProductType } from './app';

export type InputOnChangeHandler<T = HTMLInputElement | HTMLTextAreaElement> = (
  event: ChangeEvent<T>
) => void;

export type ButtonOnClickHandler = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

export interface AppIconProps extends SvgIconProps {
  children: ReactNode | ReactNode[];
  iconcolor?: string;
  mui?: boolean;
}

export type IconProps = Omit<AppIconProps, 'children' | 'mui'>;

export type Icon = (props: IconProps) => JSX.Element;

export type CompareRankNameResult = -1 | 0 | 1;

export interface ScoreNameItem {
  name: string;
  score: number;
}

export type StateSetter<T = unknown> = Dispatch<SetStateAction<T>>;

export type FocusHandler<T = HTMLInputElement | HTMLTextAreaElement> = (
  event: FocusEvent<T>
) => void;

export interface ValidatorOptions {
  [key: string]: any;
}

export type MapIds = Map<number, number>;

export enum Direction {
  HORIZONTAL,
  VERTICAL,
}

export enum ExerciseType {
  Flashcards = 'Flashcards',
  MockTests = 'Mock Tests',
  Questions = 'Questions',
  QuestionsTest = 'Questions (test)',
  Stations = 'Stations',
  Station = 'Station',
  QuestionsMLA = 'Questions (MLA)',
}

export enum ExerciseMode {
  Solo = 'Solo Study',
  Group = 'Group Study',
}

export const enum BuilderViewMode {
  Overview = 'Overview',
  Details = 'Details View',
}

export interface LocationState {
  leave?: boolean;
  newProduct?: AppProductType;
}
export interface Location {
  path: string;
  state?: LocationState;
}

export enum AccountType {
  STUDENT = 'student',
  DOCTOR = 'doctor',
}

export enum SplashScreenView {
  MOCK_TEST,
  OSCE,
}

export enum DEVICE_SIZE {
  MOBILE,
  TABLET,
  DESKTOP,
  UNKNOW,
}

export interface DashboardTileSetting {
  visible: boolean;
  title: string;
}

export type ClearStore = () => Promise<any> | void;

export interface DashboardTilesSettings {
  cardsViewedTile: DashboardTileSetting;
  dailyFeedTile: DashboardTileSetting;
  dailyProgressTile: DashboardTileSetting;
  flaggedQuestionsTile: DashboardTileSetting;
  pastQuizzesTile: DashboardTileSetting;
  performanceTile: DashboardTileSetting;
  questionAttemptedTile: DashboardTileSetting;
  studyScheduleTile: DashboardTileSetting;
  universityLeaderboardTile: DashboardTileSetting;
}

export type Children = ReactNode | ReactNode[];

export type SyncValidator<T, K> = (val: T, props: K) => Nullable<string>;

export type AsyncValidator<T, K> = (
  val: T,
  props: K
) => Promise<Nullable<string>>;

export type Validator<T, K> = SyncValidator<T, K> | AsyncValidator<T, K>;

export type ValidatorsStateInitial<T, K> =
  | Validator<T, K>[]
  | (() => Validator<T, K>[]);

export type InputChangeEvent = ChangeEvent<HTMLInputElement>;

export type ToggleEvent = React.MouseEvent<HTMLElement, MouseEvent>;

export type ToggleHandler<T> = (event: ToggleEvent, value: T) => void;

export interface ToggleOption<T> {
  icon?: JSX.Element;
  value: T;
  label: string;
}

export type ToggleOptions<T> = ToggleOption<T>[];

export const NO_VALUE_SET = Symbol();

export interface ValidatedValue<T, K> {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  message: Nullable<string>;
  setValidators: React.Dispatch<React.SetStateAction<Validator<T, K>[]>>;
  valueLastChecked: T | typeof NO_VALUE_SET;
  validationInProgress: boolean;
}
export interface FormFieldValue<T, K> extends ValidatedValue<T, K> {
  inputProps: {
    value: T;
    error: boolean;
    helperText: string;
    style: { minHeight: string };
    onBlur: () => void;
    onChange: (e: InputChangeEvent) => void;
  };
  isTouched: boolean;
  setisTouched: React.Dispatch<React.SetStateAction<boolean>>;
  isValid: boolean;
  isUpdated: boolean;
}

export type Elevation = (string | 'none')[];

export enum CheckboxState {
  UNCHECKED,
  INTERMEDIATE,
  CHECKED,
}

export interface ConstSize {
  width: string;
  maxWidth: string;
  minWidth: string;
  height: string;
  maxHeight: string;
  minHeight: string;
  flexShrink: number;
}

export type Nullable<T> = T | null;
export type Undefinable<T> = T | undefined;
export type Nilable<T> = Nullable<Undefinable<T>>;

export type OnRequestComplete<T> = (data: T) => void | Promise<void>;

export enum ExercisePresets {
  CustomSet = 'Custom set',
  MyPreset = 'My preset',
  PreDefinedPreset = 'Pre-defined presets',
  PrivatePreset = 'Private preset',
}

export enum Mode {
  SAMPLE,
  NORMAL,
  TEST,
}

export enum KeyboardKey {
  Space = 'Space',
  Enter = 'Enter',
  Escape = 'Escape',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
  H = 'h',
  Slash = '/',
  CtrlK = 'ctrl+k',
  MetaK = 'meta+k',
  ArrowLeft = 'left',
  ArrowRight = 'right',
}

export type TableData = { [key: string]: any } & { id: number; demo?: boolean };
export type CellValue = Exclude<ReactNode, 'null' | 'undefined'>;
export type CellAllignment = 'left' | 'right' | 'center';
export type CellType = 'chevron' | 'input' | 'bar' | 'label' | 'default';

export enum ComplementaryReadings {
  RelatedVideos = 'Related Videos',
  MyNotes = 'My Notes',
}

export interface AccountFormInput {
  displayName?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  university?: IUniversity;
  universityReadOnly?: string;
  classYear?: string;
  graduationYear?: string;
  referral?: string;
}

export interface PasswordFormInput {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export enum TimeMeasures {
  Minutes = 'minutes',
  Seconds = 'seconds',
}

export interface Tab {
  label: string;
  value: string;
}

export interface QuesbookBrief {
  content: string;
  title?: string;
}

export type QuestionsActivity = Array<{
  date: Date | number;
  result: IMarksheet[];
}>;

export type StationsActivity = Array<{
  date: Date | number;
  result: IOsceMarksheet[];
}>;

export type ActivityData = QuestionsActivity | StationsActivity;

export type BottomOffsetVariant = 'tiny' | 'small' | 'medium' | 'large';

export enum Utils {
  Calculator = 'Calculator',
  Notes = 'Notes',
}

export const LAB_VALUES = 'Lab Values';

export type Extends<T, U extends T> = U;

export type SnackBar = (
  message: string,
  options?: OptionsObject
) => SnackbarKey;

export type ErrorType = 'network' | 'graphql' | 'general';

export interface ErrorDetails {
  [key: string]: any; // as we can pass anything regarldess of the place of usage
}

export type WithDemo<T extends TableData> = T & { demo?: boolean };

export type StateInput<T> = T | (() => T);
export type StateCallback<T> = (
  state: T | ((state: T) => T),
  callback?: (state: T) => void
) => void;

export type UIColumn = {
  chevron?: ReactNode;
  checkbox?: ReactNode;
  label?: ReactNode;
  labelIcon?: ReactNode;
  topic?: ReactNode;
  concept?: ReactNode;
  concepts?: IConcept[];
  added?: ReactNode;
  radio?: ReactNode;
  actions?: ReactNode;
  notAnswered?: ReactNode;
  score?: ReactNode;
  performed?: ReactNode;
  notPerformed?: ReactNode;
  progress?: ReactNode;
  knowledgeIcon?: ReactNode;
  default?: ReactNode;
};

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
