import React from 'react';
import { mdiVideoOffOutline, mdiVideoOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

interface CameraIconPros extends IconProps {
  video: boolean;
}

const CameraIcon = ({ video, ...props }: CameraIconPros) => (
  <Icon path={video ? mdiVideoOffOutline : mdiVideoOutline} {...props} />
);

export default CameraIcon;
