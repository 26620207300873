import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { IStartOsceMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';

import { paths } from 'Router';
import StationsTimerModal from 'pages/Stations/StationsTimerModal';
import { StationIcon } from 'components/Icons';
import { Nullable, PathName } from 'types';
import { SearchIndex } from './type';
import SearchResult from './SearchResult';

interface StationsSearchResultProps {
  onSearchClose: () => void;
  hideSearchModal: () => void;
  showSearchModal: () => void;
  setCount: (value: number) => void;
  indexName: SearchIndex;
  title: string;
}

const StationsSearchResult = ({
  onSearchClose,
  indexName,
  hideSearchModal,
  showSearchModal,
  setCount,
  title,
}: StationsSearchResultProps): JSX.Element => {
  const [selectedStationId, setSelectedStationId] =
    useState<Nullable<number>>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const practice = title === PathName.Stations;

  const handleSelectStation =
    (stationId: number) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (practice) {
        setSelectedStationId(stationId);
      } else {
        onSearchClose();
      }
    };

  const handleOpenStationTimerModal = useCallback(() => {
    hideSearchModal();
    setOpen(true);
  }, [hideSearchModal]);

  const handleCloseStationTimerModal = () => {
    setSelectedStationId(null);
    showSearchModal();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    onSearchClose();
  };

  const handleStartStation = (data: IStartOsceMarksheetData) => {
    const { id } = data.restricted.startOsceMarksheet;

    navigate(`${paths.stations.root}/solo/study/${id}`);
    handleClose();
  };

  useEffect(() => {
    if (selectedStationId) {
      handleOpenStationTimerModal();
    }
  }, [selectedStationId, handleOpenStationTimerModal]);

  return (
    <>
      <SearchResult
        icon={StationIcon}
        indexName={indexName}
        onItemClick={onSearchClose}
        onPractice={handleSelectStation}
        setCount={setCount}
        title={title}
        withHints
      />
      {practice && selectedStationId && open ? (
        <StationsTimerModal
          onBack={handleCloseStationTimerModal}
          onClose={handleClose}
          onStartStation={handleStartStation}
          open={open}
          solo
          stationId={selectedStationId}
        />
      ) : null}
    </>
  );
};

export default StationsSearchResult;
