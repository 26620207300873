import React, { useCallback } from 'react';
import MuiSlider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { clsx } from 'clsx';

import { useMarkdownTextScale } from './useMarkdownTextScale';
import { RestoreIcon } from 'components/Icons';
import { constSize } from 'utils';
import { DEFAULT_TEXT_SCALE } from 'config/constants';

const SliderWrapper = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '240px',
  height: '48px',

  '& .MuiSvgIcon-root:first-of-type ': {
    ...constSize('20px'),
    color: palette.icon.main,
    marginRight: 0,
  },
}));

const SliderBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  padding: spacing(0, 3),
  gap: spacing(3),
}));

const Slider = styled(MuiSlider)(({ theme: { palette, elevation } }) => ({
  color: palette.icon.main,

  '& .MuiSlider-track': {
    border: 'none',
  },

  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: palette.icon.main,
  },

  '& .MuiSlider-thumb': {
    width: 16,
    height: 16,
    backgroundColor: palette.background.paper,
    border: `2px solid ${palette.icon.main}`,

    '&:before': {
      boxShadow: elevation[3],
    },
    '&:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: 'none',
    },
  },

  '&.updated': {
    color: palette.primary.main,

    '& .MuiSlider-thumb': {
      border: `2px solid ${palette.primary.main}`,
    },
  },
}));

const ResetScale = styled(Box)(({ theme: { palette, transitions } }) => ({
  ...constSize('44px'),
  boxSizing: 'border-box',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: palette.icon.main,
  transition: transitions.create(['color']),

  '& .MuiSvgIcon-root': {
    color: palette.icon.main,
    transition: transitions.create(['color']),
  },

  '&:hover': {
    '& .MuiSvgIcon-root': {
      color: palette.icon.dark,
    },
  },

  '&.disabled': {
    cursor: 'not-allowed',

    '& .MuiSvgIcon-root': {
      color: palette.icon.light,
    },
  },
}));

const Font = styled(Box)(({ theme: { typography, palette } }) => ({
  display: 'inline',
  color: palette.icon.main,
  ...typography.body1Medium,
  fontSize: typography.body2.fontSize,

  '&.big': {
    fontSize: typography.h5.fontSize,
  },
}));

export const TextSizeSlider = (): JSX.Element => {
  const { scale, setScale, resetScale } = useMarkdownTextScale();

  const handleChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      // we can safely cast as our slider always return number
      setScale(newValue as number);
    },
    [setScale]
  );

  return (
    <SliderWrapper className="text-size-slider-wrapper">
      <SliderBox className="text-size-slider-box">
        <Font>A</Font>
        <Slider
          className={clsx({ updated: scale !== DEFAULT_TEXT_SCALE })}
          max={2}
          min={0.75}
          onChange={handleChange}
          step={0.05}
          value={scale}
        />
        <Font className="big">A</Font>
      </SliderBox>
      <Divider flexItem orientation="vertical" />
      <ResetScale
        className={clsx({ disabled: scale === DEFAULT_TEXT_SCALE })}
        onClick={resetScale}
        role="button"
      >
        <RestoreIcon />
      </ResetScale>
    </SliderWrapper>
  );
};
