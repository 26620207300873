import { create } from 'zustand';

import { Nullable } from 'types';

export enum StationModal {
  ErrorModal,
  RolesModal,
  MatchmakingStartModal,
  MatchmakingAcceptModal,
  MatchmakingRejectModal,
}

interface StationModalState {
  shownModal: Nullable<StationModal>;
  openModal: (shownModal: StationModal) => void;
  closeModal: () => void;
}

export const useStationModalState = create<StationModalState>()(set => ({
  shownModal: null,
  openModal: shownModal => set({ shownModal }),
  closeModal: () => set({ shownModal: null }),
}));
