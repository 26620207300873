import React, { forwardRef } from 'react';
import { mdiDeleteForever } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const DeleteIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon path={mdiDeleteForever} ref={ref} {...props} />
));

DeleteIcon.displayName = 'DeleteIcon';

export default DeleteIcon;
