import { useLocation } from 'react-router-dom';

function useURLQuery(): URLSearchParams;
function useURLQuery(param: string): string | null;
function useURLQuery(param?: any) {
  const query = new URLSearchParams(useLocation().search);

  if (param) {
    return query.get(param);
  }

  return query;
}

export default useURLQuery;
