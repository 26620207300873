import React from 'react';

import { IconButton, IconButtonProps } from 'components/IconButton';
import { RestartIcon } from 'components/Icons';

const SnackbarRetryButton = ({ onClick }: IconButtonProps): JSX.Element => (
  <IconButton onClick={onClick} size="small">
    <RestartIcon />
  </IconButton>
);

export default SnackbarRetryButton;
