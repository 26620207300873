import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useMediaQuery } from '@mui/material';

import { localStorageTyped, noop } from 'utils';
import { ThemeMode } from './types';
import { darkTheme, lightTheme } from './theme';
import GlobalStyles from './GlobalStyles';
import { Children } from 'types';

interface IThemeModeContext {
  themeMode: ThemeMode;
  setThemeMode: (theme: ThemeMode) => void;
}

const ThemeModeContext = createContext<IThemeModeContext>({
  themeMode: ThemeMode.Auto,
  setThemeMode: noop,
});

interface ThemeProviderProps {
  children: Children;
}

const { getItem, setItem } = localStorageTyped<ThemeMode>('themeMode');

export const ThemeProvider = ({
  children,
}: ThemeProviderProps): JSX.Element => {
  const defaultTheme = getItem();

  const [themeMode, setThemeMode] = useState<ThemeMode>(
    defaultTheme ? defaultTheme : ThemeMode.Auto
  );

  const isSystemThemeLight = useMediaQuery('(prefers-color-scheme: light)');
  const handleSetThemeMode = (themeMode: ThemeMode) => {
    setThemeMode(themeMode);
    setItem(themeMode);
  };

  const theme = useMemo(() => {
    if (themeMode === ThemeMode.Auto) {
      return isSystemThemeLight ? lightTheme : darkTheme;
    }

    return themeMode === ThemeMode.Light ? lightTheme : darkTheme;
  }, [themeMode, isSystemThemeLight]);

  return (
    <ThemeModeContext.Provider
      value={{ themeMode, setThemeMode: handleSetThemeMode }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MuiThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export const useThemeMode = () => useContext(ThemeModeContext);
