/* eslint-disable max-depth */
import { IHighlightNode } from '@quesmed/types-rn/models';
import markdownToTxt from 'markdown-to-txt';

import { randomKey } from 'utils/math/random';
import { getPlainText, getStrictPlainText } from './getPlainText';
import { isAlphanumeric } from './isAlphanumeric';

/**
 * It computes the start/xpos in markdown string
 *
 * 1. Make plain highlights and mark highlights length equals if not equals, by some computation
 * 2. Loop over normal/textual string and markdown string array by splitting them with new line
 * 3. Loop over and at each iteration, compare both element,
 *    a. Add the sum of length of markdown words at each loop
 *    b. if match comes, i.e selectedText starts from there, means we found out start and end position for that word
 *    c. Also add the position for whitespace that comes after it, if next word is present.
 *    c. If match comes, there will be three conditions for calculate proper start and end positions.
 */
export const computePositionsInMarkdown = (
  markdown: string,
  xPos: number,
  selectedText: string
): IHighlightNode[] | null => {
  try {
    // create a unique id for each highlight
    const highlightId = randomKey(4);
    let plainTextCharCount = 0;
    let markdownCharCount = 0;
    let newXPos = 0;
    let idx = 0;
    const selectedWords = selectedText.split(' ').filter(e => String(e).trim());
    let wordsCount = 0;
    const highlights: IHighlightNode[] = [];
    const plainText = markdownToTxt(markdown);

    if (!plainText) {
      return null;
    }

    const markdownWords = markdown.trim().split(' ');
    const plainTextWords = plainText.trim().split(' ');

    if (plainTextWords.length !== markdownWords.length) {
      for (let index = 0; index < markdownWords.length; index++) {
        const plainTextWithoutHTMLTagsFromMarkdown = getPlainText(
          markdownWords[index]
        );
        const plainTextWithoutHTMLTagsFromText = plainTextWords[index]
          ? getPlainText(plainTextWords[index])
          : '';
        if (
          index >= plainTextWords.length ||
          plainTextWithoutHTMLTagsFromMarkdown.length !==
            plainTextWithoutHTMLTagsFromText.length
        ) {
          plainTextWords.splice(index, 0, '');
        }
      }
    }

    if (plainTextWords.length !== markdownWords.length) {
      return null;
    }

    while (idx < plainTextWords.length) {
      if (selectedWords.length === wordsCount) {
        return highlights;
      }

      const textWord = plainTextWords[idx];
      const markdownWord = markdownWords[idx];

      if (!markdownWord.length) {
        idx++;
        continue;
      }

      plainTextCharCount += textWord.length + 1;
      markdownCharCount += markdownWord.length + 1;

      const plainFirstWord = getStrictPlainText(markdownWord);
      const plainSecondWord = getStrictPlainText(selectedWords[wordsCount]);
      const match =
        wordsCount > 0 ||
        (plainTextCharCount >= xPos + 1 &&
          plainFirstWord.includes(plainSecondWord));

      if (match) {
        const firstChar = markdownWord.slice(0, 1);
        const matchedWord = selectedWords[wordsCount];
        let extraLengthToAdd = matchedWord.length;

        if (matchedWord === textWord) {
          /** exact match */
          const start = newXPos;
          highlights.push({
            start,
            end: start + markdownWord.length,
            part: highlightId,
            text: matchedWord,
          });
        } else if (
          textWord === markdownWord &&
          matchedWord.length <= textWord.length
        ) {
          /** plain and markdown are equal but selectedWords length is smaller */
          const start =
            newXPos +
            (xPos > newXPos
              ? markdownWord.lastIndexOf(matchedWord)
              : markdownWord.indexOf(matchedWord));

          highlights.push({
            start,
            end: start + matchedWord.length,
            part: highlightId,
            text: matchedWord,
          });
        } else if (
          matchedWord.match(/[^a-zA-Z0-9./\\]/gi) &&
          isAlphanumeric(firstChar)
        ) {
          /** selectedWords text contain some part of html characters */
          const start = newXPos + markdownWord.indexOf(matchedWord.slice(0, 1));
          highlights.push({
            start,
            end: start + markdownWord.length,
            part: highlightId,
            text: matchedWord,
          });
        } else {
          if (!isAlphanumeric(matchedWord.slice(-1))) {
            extraLengthToAdd = markdownWord.length;
          }
          const strToCompare = isAlphanumeric(matchedWord)
            ? matchedWord
            : matchedWord.slice(0, 1);

          let start = markdownWord.indexOf(strToCompare);

          if (start === -1) {
            start = markdownWord.indexOf(
              strToCompare.slice(0, strToCompare.length - 2)
            );
          }

          if (start === -1) continue;

          start = start + newXPos;
          highlights.push({
            start,
            end: start + extraLengthToAdd,
            part: highlightId,
            text: matchedWord,
          });
        }

        wordsCount++;
      }

      newXPos = Number(markdownCharCount);

      if (wordsCount > 0 && wordsCount < selectedWords.length) {
        /** add positions for white spaces between words */
        highlights.push({
          start: newXPos - 1,
          end: newXPos,
          part: highlightId,
          text: '',
        });
      }
      idx++;
    }

    return highlights;
  } catch (err) {
    return null;
  }
};
