import React, {
  Component,
  ErrorInfo,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorMessage } from './ErrorMessage';
import { logError, setSentryTag } from 'utils';

interface ErrorBoundaryProps {
  children?: ReactNode | ReactNode[];
}

interface InnerErrorBoundaryProps extends ErrorBoundaryProps {
  hasError: boolean;
  setHasError: (value: boolean) => void;
}

interface InnerErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  stack?: string | null;
}

class ErrorBoundaryInner extends Component<
  InnerErrorBoundaryProps,
  InnerErrorBoundaryState
> {
  constructor(props: InnerErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    setSentryTag('screen_size', `${window.innerWidth}x${window.innerHeight}`);
    logError({ error, errorInfo });

    this.setState({ error, stack: errorInfo.componentStack });
    this.props.setHasError(true);
  }

  componentDidUpdate(prevProps: InnerErrorBoundaryProps) {
    const { hasError: prevHasError } = prevProps;
    const { hasError } = this.props;

    if (!hasError && prevHasError) {
      this.setState({ hasError: false });
    }
  }

  resetError() {
    this.props.setHasError(false);
  }

  render() {
    const { error, stack, hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorMessage
          error={error}
          resetError={this.resetError}
          stack={stack}
        />
      );
    }

    return children;
  }
}

export const ErrorBoundary = ({
  children,
}: ErrorBoundaryProps): JSX.Element => {
  const [hasError, setHasError] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setHasError(false);
  }, [pathname]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};
