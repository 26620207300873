import React from 'react';
import { mdiBookOpenVariant } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const KnowledgeLibraryIcon = (props: IconProps) => (
  <Icon path={mdiBookOpenVariant} {...props} />
);

export default KnowledgeLibraryIcon;
