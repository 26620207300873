import { EOsceStage } from '@quesmed/types-rn/models';
import { create } from 'zustand';

interface StationTimerState {
  setCompletedStages: (payload: EOsceStage[]) => void;
  setCurrentStage: (payload: EOsceStage) => void;
  setIsPaused: (payload: boolean) => void;
  setPauseAction: (payload: boolean) => void;
  setSelectedStage: (payload: EOsceStage) => void;
  setTimeIsUp: (payload: boolean) => void;
  completedStages: EOsceStage[];
  currentStage: EOsceStage;
  isPaused: boolean;
  pauseAction: boolean;
  selectedStage: EOsceStage;
  timeIsUp: boolean;
}

export const useStationTimerState = create<StationTimerState>()(set => ({
  completedStages: [],
  currentStage: EOsceStage.READING,
  isPaused: false,
  pauseAction: false,
  selectedStage: EOsceStage.READING,
  timeIsUp: false,
  setCompletedStages: completedStages =>
    set({
      completedStages,
    }),
  setCurrentStage: currentStage =>
    set({
      currentStage,
    }),
  setIsPaused: isPaused =>
    set({
      isPaused,
    }),
  setPauseAction: pauseAction =>
    set({
      pauseAction,
    }),
  setSelectedStage: selectedStage =>
    set({
      selectedStage,
    }),
  setTimeIsUp: timeIsUp =>
    set({
      timeIsUp,
    }),
}));
