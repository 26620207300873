import React, { ChangeEvent } from 'react';
import { BoxProps } from '@mui/material/Box';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import { convertEventTargetValueToNumber } from 'utils';
import { StyledCheckboxWrapper } from './Checkbox.styles';
import { Checkbox, CheckboxProps } from './Checkbox';

type CheckboxOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => void;

const handleChange =
  (onChange: CheckboxOnChangeHandler, numericValue?: boolean) =>
  (event: ChangeEvent<HTMLInputElement>) =>
    onChange(
      convertEventTargetValueToNumber(
        event,
        numericValue
      ) as ChangeEvent<HTMLInputElement>
    );

export type FormCheckboxProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> &
  CheckboxProps & {
    component?: typeof Checkbox;
    containerSx?: BoxProps['sx'];
    numericValue?: boolean;
  };

export function FormCheckbox<T extends FieldValues>({
  component: Component = Checkbox,
  containerSx,
  control,
  defaultValue,
  rules,
  name,
  shouldUnregister,
  numericValue,
  ...rest
}: FormCheckboxProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onBlur, onChange, ref, value },
        fieldState: { error },
      }) => (
        <StyledCheckboxWrapper
          className={error ? 'error' : ''}
          sx={containerSx}
        >
          <Component
            {...rest}
            checked={!!value}
            color={error ? 'error' : undefined}
            name={name}
            onBlur={onBlur}
            onChange={handleChange(onChange, numericValue)}
            ref={ref}
          />
          {error ? (
            <FormHelperText error={Boolean(error)}>
              {error?.message}
            </FormHelperText>
          ) : null}
        </StyledCheckboxWrapper>
      )}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
