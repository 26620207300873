import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface QuizState {
  quizStarted: boolean;
  setQuizStarted: (value: boolean) => void;
}
const inititalState = {
  quizStarted: false,
};

const useQuizStore = create<QuizState>()(set => ({
  ...inititalState,
  setQuizStarted: value => set({ quizStarted: value }),
}));

const useQuizState = () => useQuizStore(state => state, shallow);

export default useQuizState;
