import React, { forwardRef } from 'react';
import { mdiLoupe } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ZoomIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon path={mdiLoupe} ref={ref} {...props} />
));

ZoomIcon.displayName = 'ZoomIcon';

export default ZoomIcon;
