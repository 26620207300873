import React from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

import { FormField } from 'components/TextField';
import { Body, H1 } from 'components/Typography';
import { Logo } from 'components/Logo';
import { Button } from 'components/Button';
import { paths } from 'Router';
import { QuestionMarkCircleIcon } from 'components/Icons';
import { Link } from 'components/Link';
import { FormCheckbox } from 'components/Checkbox';
import { Tooltip } from 'components/Tooltip';
import { ContentContainer, ContentHolderContainer } from './Shared.styles';
import { LoginFormInput, useLogin } from './useLogin';

export const Title = styled(H1)(({ theme: { spacing, typography } }) => ({
  marginTop: spacing(4.5),
  marginBottom: spacing(4),
  ...typography.h4,
}));

const Row = styled(Box)({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  flexWrap: 'wrap',
});

export const ActionRow = styled(Row)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
  marginBottom: spacing(10),
}));

const CreateAnAccountContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginTop: spacing(6),
}));

const LabelBox = styled(Box)({
  display: 'flex',
});

const QuestionMarkBox = styled(Box)({
  display: 'flex',
  marginLeft: '10px',
});

const schema = Joi.object<LoginFormInput>({
  username: Joi.string().messages({
    'string.empty': '',
  }),
  password: Joi.string().messages({
    'string.empty': '',
  }),
  rememberMe: Joi.bool(),
});

const Login = (): JSX.Element => {
  const { login, loading } = useLogin();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<LoginFormInput>({
    defaultValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const handleLogin = async (data: LoginFormInput) => {
    login(data);
  };

  return (
    <ContentHolderContainer>
      <Helmet>
        <title>Quesmed | Log In</title>
        <meta
          content="Log in to your medical question bank here."
          name="description"
        />
        <link href="https://app.quesmed.com/login" rel="canonical" />
      </Helmet>
      <ContentContainer>
        <Logo showText={false} size={64} />
        <Title>Log in to Quesmed</Title>
        <form onSubmit={handleSubmit(handleLogin)}>
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Email address"
            name="username"
            placeholder="Email address"
          />
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Password"
            name="password"
            placeholder="Password"
            sx={({ spacing }) => ({ marginTop: spacing(6) })}
            type="password"
          />
          <ActionRow>
            <Row>
              <FormCheckbox
                control={control}
                controlSx={({ spacing }) => ({ marginRight: spacing(2.5) })}
                label={
                  <LabelBox>
                    <Body>Keep me logged in</Body>
                  </LabelBox>
                }
                name="rememberMe"
              />
              <Tooltip
                placement="top"
                sx={{ marginLeft: 0, cursor: 'pointer' }}
                title="Stay logged in for a week without re-entering your credentials"
              >
                <QuestionMarkBox>
                  <QuestionMarkCircleIcon />
                </QuestionMarkBox>
              </Tooltip>
            </Row>
            <Link to={paths.forgotPassword}>Forgot password?</Link>
          </ActionRow>
          <Button disabled={!isValid} fullWidth loading={loading} type="submit">
            Log in
          </Button>
        </form>
        <CreateAnAccountContainer>
          <Body mr={2}>Don&apos;t have an account yet?</Body>
          <Link to={paths.register}>Sign up</Link>
        </CreateAnAccountContainer>
      </ContentContainer>
    </ContentHolderContainer>
  );
};

export default Login;
