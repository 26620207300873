import {
  ApolloCache,
  DocumentNode,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import {
  IModifyMarksheetData,
  IModifyMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  ApolloUpdateOptions,
  ApolloUpdateResultRestricted,
} from '@quesmed/types-rn';

export function useModifyMarksheetMutation(
  query: DocumentNode,
  fragment: DocumentNode,
  baseOptions?: MutationHookOptions<IModifyMarksheetData, IModifyMarksheetVar>
) {
  const options = { ...baseOptions };

  return useMutation<IModifyMarksheetData, IModifyMarksheetVar>(query, {
    ...options,
    update: (
      cache: ApolloCache<any>,
      result: ApolloUpdateResultRestricted<IModifyMarksheetData>,
      options: ApolloUpdateOptions
    ) => {
      const { modifyMarksheet } = result?.data?.restricted || {};
      const { variables } = options || {};

      if (!variables || !modifyMarksheet) {
        return;
      }
      const { id, ...data } = modifyMarksheet || {};

      cache.writeFragment({
        id: cache.identify({ id, __typename: 'Marksheet' }),
        data,
        fragment,
      });
    },
  });
}

export default useModifyMarksheetMutation;
