import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LoginAnimation } from 'components/LottieAnimations';
import { ErrorBoundary } from 'components/Error';

const RootContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const AnimationContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'none',
  flex: 1,
  height: '100%',

  [breakpoints.up('xl')]: {
    display: 'block',
  },
}));

const LoginLayout = () => (
  <RootContainer>
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
    <AnimationContainer>
      <LoginAnimation />
    </AnimationContainer>
  </RootContainer>
);

export default LoginLayout;
