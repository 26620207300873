import { matchPath } from 'react-router-dom';

import { paths } from 'Router';

const {
  flashcards,
  questions,
  mockTests,
  stations,
  knowledgeLibrary,
  videoLibrary,
} = paths;

const PATH_SEGMENTS = ['builder', 'summary'];

const FULL_ROUTES_TO_MATCH = [
  `${flashcards.root}/${flashcards.quiz}`,
  `${mockTests.root}/${mockTests.test}`,
  `${questions.root}/${questions.soloQuiz}`,
  `${questions.root}/${questions.groupLobby}/${questions.groupQuiz}`,
  `${stations.root}/${stations.soloStudy}`,
  `${stations.root}/${stations.groupLobby}/${stations.groupStudy}`,
  `${knowledgeLibrary.root}/${knowledgeLibrary.chapter}`,
  `${videoLibrary.root}/${videoLibrary.video}`,
];
// TODO replaceAll is used to fix matchPath issue for optional params
// https://github.com/remix-run/react-router/discussions/9862
export const shouldShowTextSizeSlider = (pathname: string) =>
  FULL_ROUTES_TO_MATCH.some(
    route =>
      Boolean(matchPath(route.replaceAll('?', ''), pathname)) &&
      !PATH_SEGMENTS.some(segment => pathname.includes(segment))
  );
