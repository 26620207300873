import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';

enum AvatarSize {
  Small = 32,
  Medium = 40,
  Large = 48,
}

type Size = 'small' | 'medium' | 'large';

interface StyledAvatarProps extends MuiAvatarProps {
  size: AvatarSize;
}

const StyledAvatar = styled(MuiAvatar)<StyledAvatarProps>(
  ({ size, theme: { typography } }) => ({
    ...typography.button,
    width: `${size}px`,
    height: `${size}px`,
  })
);

const setSize = (size: Size): AvatarSize => {
  switch (size) {
    case 'large':
      return 48;
    case 'small':
      return 32;
    case 'medium':
    default:
      return 40;
  }
};
interface AvatarProps extends MuiAvatarProps {
  size?: Size;
  fallback?: ReactNode;
}

const Avatar = ({
  fallback,
  size = 'medium',
  src,
  variant = 'rounded',
  ...rest
}: AvatarProps): JSX.Element => (
  <StyledAvatar size={setSize(size)} src={src} variant={variant} {...rest}>
    {src ? undefined : fallback}
  </StyledAvatar>
);

export default Avatar;
