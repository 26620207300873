import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  IPreBuildMarksheetData,
  IPreBuildMarksheetVar,
  PRE_BUILD_MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { QUESTION_DIFFICULTY_LEVELS } from 'config/constants';
import { OnRequestComplete } from 'types';
import { addSentryBreadcrumb } from 'utils';

const usePrebuildMarksheetQuery = (
  onPrebuildComplete?: OnRequestComplete<IPreBuildMarksheetData>
) => {
  const [preBuild, { data, loading }] = useLazyQuery<
    IPreBuildMarksheetData,
    IPreBuildMarksheetVar
  >(PRE_BUILD_MARKSHEET, {
    onCompleted: data => {
      if (onPrebuildComplete && data) {
        onPrebuildComplete(data);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { preBuildMarksheet: preBuildMarksheetData } = data?.restricted || {};

  const preBuildMarksheet = useCallback(
    async (params: IPreBuildMarksheetVar) => {
      addSentryBreadcrumb({
        category: 'marksheet',
        message: 'Pre-Building Marksheet',
      });
      const {
        topicIds = [],
        conceptIds = [],
        search = '',
        source = '',
        marksheetId,
        difficulty = QUESTION_DIFFICULTY_LEVELS,
        entitlementId,
      } = params;

      const variables: IPreBuildMarksheetVar = {
        topicIds,
        conceptIds,
        search,
        source,
        difficulty,
        entitlementId,
      };

      if (marksheetId) {
        variables.marksheetId = marksheetId;
      }

      return preBuild({
        variables,
      });
    },

    [preBuild]
  );

  return {
    preBuildMarksheet,
    preBuildData: preBuildMarksheetData,
    preBuildLoading: loading,
  };
};

export default usePrebuildMarksheetQuery;
