import { setContext } from '@apollo/client/link/context';
import { IPayload } from '@quesmed/types-rn/models';
import { jwtDecode } from 'jwt-decode';

import { localStorageTyped } from 'utils';
import { isTokenVersionValid } from 'Auth';

const { getItem, removeItem } = localStorageTyped<string>('usertoken');
const { getItem: getApp } = localStorageTyped<string>('app');
const { getItem: getProduct } = localStorageTyped<string>('product');

export const authLink = setContext((_, { headers }) => {
  const token = getItem();
  const app = getApp();
  const product = getProduct();

  if (token) {
    const decodedToken = jwtDecode<IPayload>(token);
    const { ver } = decodedToken;

    if (!isTokenVersionValid(ver)) {
      removeItem();
      window.location.reload();
    }
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      UserApplication: `${app}`,
      UserProduct: `${product}`,
    },
  };
});
