import React from 'react';
import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import {
  CONVERSION_ID,
  isDev,
  MEASUREMENT_ID,
  SENTRY_DNS,
} from 'config/constants';
import App from 'app/App';

Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [SentryBrowser.browserTracingIntegration()],
  tracesSampleRate: isDev ? 1 : 0.25,
  environment: isDev ? 'development' : 'production',
});

if (MEASUREMENT_ID && !isDev) {
  ReactGA.initialize([
    {
      trackingId: MEASUREMENT_ID,
    },
    {
      trackingId: CONVERSION_ID,
    },
  ]);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
