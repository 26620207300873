import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Body, H6 } from 'components/Typography/Typography';

type VariantType = 'column' | 'row';

interface Props extends React.ComponentProps<typeof Box> {
  title: string;
  subTitle?: string;
  variant?: VariantType;
}

const Title = styled(H6)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SubTitle = styled(Body)(({ theme: { palette, spacing } }) => ({
  color: palette.text.secondary,
  marginTop: spacing(1),
}));

const StyledWrapper = styled(Box)<{ variant: VariantType }>(
  ({ theme: { breakpoints, spacing }, variant }) => ({
    margin: spacing(8, 0, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'initial',

    '&.mode-type': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: spacing(8),
    },

    [breakpoints.up('md')]: {
      flexDirection: variant === 'column' ? 'column' : 'row',
      justifyContent: variant === 'column' ? 'initial' : 'space-between',
    },
  })
);

const StyledChildren = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(6),
}));

const ModalSection = ({
  subTitle,
  title,
  children,
  variant = 'column',
  ...boxProps
}: Props): JSX.Element => (
  <StyledWrapper component="section" variant={variant} {...boxProps}>
    <Box>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Box>
    {children && <StyledChildren>{children}</StyledChildren>}
  </StyledWrapper>
);
export default ModalSection;
