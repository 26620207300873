import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { Modal } from 'components/Modal';
import { FormField } from 'components/TextField';

const StyledInputWrapper = styled(Box)(({ theme: { spacing } }) => ({
  '& .MuiFormControl-root': {
    marginTop: spacing(6),
  },
}));

interface SubscriptionCancelationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (comment: CancelationInput) => void;
  loading: boolean;
}

export interface CancelationInput {
  comment?: string;
}

const schema = Joi.object<CancelationInput>({
  comment: Joi.string().max(255).messages({
    'string.max': 'Comment can include up to 255 characters',
  }),
});

export const SubscriptionCancelationModal = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
}: SubscriptionCancelationModalProps) => {
  const { control, handleSubmit } = useForm<CancelationInput>({
    defaultValues: {
      comment: '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  return (
    <Modal
      loading={loading}
      maxWidth="md"
      noPaddingY
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      open={isOpen}
      showCloseButton
      sizeVariant="md"
      submitLabel="Confirm"
      title="Are you sure you want to cancel subscription?"
    >
      <Box>
        If you&apos;re done with exams or finishing medical school,
        congratulations!
      </Box>
      <Box>
        Otherwise, is there any particular reason why you&apos;re cancelling?
      </Box>
      <StyledInputWrapper>
        <FormField
          control={control}
          fullWidth
          label="Comment"
          multiline
          name="comment"
          rows={5}
          type="text"
        />
      </StyledInputWrapper>
    </Modal>
  );
};
