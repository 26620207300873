import React, { useState } from 'react';
import { clsx } from 'clsx';

import { LabIcon } from 'components/Icons';
import { LAB_VALUES } from 'types';
import { UtilPopup } from 'components/UtilPopup';
import LabsData from './LabsData';
import { chem, haem, miscellaneous } from './LabDataContent';
import { LabTabContent } from './types';
import {
  Description,
  Drawer,
  LabsHeader,
  LabsTable,
  LabValuesContainer,
  LabWrapper,
  Tab,
  TabContainer,
} from './labValues.styles';
import useLabValuesState from './useLabValuesState';

enum LabTab {
  Haem = 'HAEM',
  Chem = 'CHEM',
  Miscellaneous = 'MISCELLANEOUS',
}
const LabValues = (): JSX.Element => {
  const { open, closeLabValues } = useLabValuesState();
  const [currentTab, setCurrentTab] = useState<LabTab>(LabTab.Haem);

  const handleTabChange = (tab: LabTab) => () => {
    setCurrentTab(tab);
  };
  const tabs = Object.values(LabTab);
  const tabsContent: LabTabContent[] = [haem, chem, miscellaneous];
  const { title, description, tables } = tabsContent[tabs.indexOf(currentTab)];

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      onClose={closeLabValues}
      open={open}
      variant="persistent"
    >
      <UtilPopup icon={<LabIcon />} onClose={closeLabValues} title={LAB_VALUES}>
        <LabWrapper>
          <TabContainer>
            {tabs.map(tab => (
              <Tab
                className={clsx({ selected: currentTab === tab })}
                key={tab}
                onClick={handleTabChange(tab)}
              >
                {tab}
              </Tab>
            ))}
          </TabContainer>
          <LabValuesContainer>
            {title && <LabsHeader bold>{title}</LabsHeader>}
            {description && <Description>{description}</Description>}
            {tables.map(({ tableHead, tableData }) => (
              <LabsTable key={tableHead}>
                <LabsHeader bold>{tableHead}</LabsHeader>
                <LabsData data={tableData} />
              </LabsTable>
            ))}
          </LabValuesContainer>
        </LabWrapper>
      </UtilPopup>
    </Drawer>
  );
};

export default LabValues;
