import React from 'react';
import { useMutation } from '@apollo/client';
import {
  IResetPasswordData,
  IResetPasswordVar,
  RESET_PASSWORD,
} from '@quesmed/types-rn/resolvers/mutation';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { FormField } from 'components/TextField';
import { H4 } from 'components/Typography';
import { paths } from 'Router';
import { useSnackbar } from 'components/Snackbar';
import {
  ContentContainer,
  ContentHolderContainer,
  ResetButton,
} from './Shared.styles';
import { useURLQuery } from 'hooks';
import { isErrorButNotApolloOne } from 'utils';

interface ResetPasswordFormInput {
  password: string;
  confirmPassword: string;
}

const Title = styled(H4)(({ theme: { breakpoints, spacing } }) => ({
  marginTop: spacing(8),
  marginBottom: spacing(2),

  [breakpoints.up('md')]: {
    marginTop: spacing(10),
    marginBottom: spacing(4),
  },
}));

const schema = Joi.object<ResetPasswordFormInput>({
  password: Joi.string().required().min(6).messages({
    'string.empty': 'Password is required',
    'string.min': 'Password must be at least 6 characters long',
  }),
  confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({
    'string.empty': 'Confirm password is required',
    'any.only': 'Confirm password must match password',
  }),
});

const ResetPassword = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const token = useURLQuery('resetRef') || '';

  const [resetPassword, { loading }] = useMutation<
    IResetPasswordData,
    IResetPasswordVar
  >(RESET_PASSWORD);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<ResetPasswordFormInput>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const handleReset = async ({ password }: ResetPasswordFormInput) => {
    try {
      const res = await resetPassword({
        variables: {
          password,
          token,
        },
      });
      if (!res.errors?.length) {
        navigate(paths.passwordChanged);
      }
    } catch (error) {
      if (isErrorButNotApolloOne(error)) {
        enqueueSnackbar(error.message);
      }
    }
  };

  return (
    <ContentHolderContainer>
      <ContentContainer>
        <Title>Reset your password</Title>
        <form onSubmit={handleSubmit(handleReset)}>
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="New password"
            name="password"
            placeholder="New password"
            type="password"
          />
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Confirm new password"
            name="confirmPassword"
            placeholder="Confirm new password"
            type="password"
          />
          <ResetButton
            disabled={!isValid}
            fullWidth
            loading={loading}
            size="large"
            type="submit"
          >
            Reset my password
          </ResetButton>
        </form>
      </ContentContainer>
    </ContentHolderContainer>
  );
};

export default ResetPassword;
