import { IHighlightNode } from '@quesmed/types-rn/models';

/**
 * It handles merging, removal of highlights
 * i.e remove highlight conflicts
 *
 * 1. sort the array
 * 2. check two consecutive element every time in each iteration.
 * 3. Check currentHighlight element end and nextHighlight element start,
 *    a. if they collide ?
 *    b. delete nextHighlight element
 *    c. modify currentHighlight element to merge the positions
 *    d. again start with same element to compare with newer one, by continue;
 */
export const adjustHighlightData = (
  highlights: IHighlightNode[],
  text: string
) => {
  const newHighlights = highlights.sort((a, b) => a.start - b.start);
  let index = 0;

  while (index < newHighlights.length) {
    if (index + 1 >= newHighlights.length || newHighlights.length === 1) {
      return newHighlights;
    }

    const currentHighlight = newHighlights[index];
    const nextHighlight = newHighlights[index + 1];

    if (
      currentHighlight.end >= nextHighlight.start ||
      currentHighlight.end + 1 === nextHighlight.start
    ) {
      newHighlights.splice(index + 1, 1);
      const { start } = currentHighlight;
      const end = Math.max(currentHighlight.end, nextHighlight.end);
      const newText = text.slice(start, end + 1);
      newHighlights[index] = {
        ...newHighlights[index],
        start,
        end,
        text: newText,
        part: currentHighlight.part,
      };
      continue;
    }

    index++;
  }

  return newHighlights;
};
