import React from 'react';
import { mdiUnfoldMoreHorizontal } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ChevronUpAndDownIcon = (props: IconProps) => (
  <Icon path={mdiUnfoldMoreHorizontal} {...props} />
);

export default ChevronUpAndDownIcon;
