import React from 'react';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import { LabIcon, RankingsIcon, SearchIcon } from 'components/Icons';
import { ListItemButton, ListItemButtonProps } from 'components/List';
import { paths } from 'Router';
import {
  NotificationBadgeIcon,
  useNotificationsState,
} from 'components/Notifications';
import { useSearchState } from 'components/Search';
import {
  MarkdownTextSizeSlider,
  shouldShowTextSizeSlider,
} from 'components/Markdown';
import { useLabValuesState } from 'components/LabValues';

interface ProfileListItemProps
  extends Omit<ListItemButtonProps, 'expanded' | 'nested'> {
  label: string;
  icon: JSX.Element;
}

export const StyledDivider = styled(Divider)(({ theme: { spacing } }) => ({
  margin: spacing(2, 0),
}));

const EntriesBox = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'block',

  [breakpoints.up('md')]: {
    display: 'none',
  },

  '& .hide': {
    display: 'none',
  },

  '& .text-size-slider-wrapper': {
    minWidth: '208px',
  },

  '& .text-size-slider-box': {
    padding: spacing(0, 3, 0, 4),
  },
}));

export const ProfileListItem = ({
  icon,
  label,
  ...props
}: ProfileListItemProps): JSX.Element => (
  <ListItemButton expanded={1} nested={0} {...props}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText
      disableTypography
      primary={label}
      sx={{
        whiteSpace: 'nowrap',
      }}
    />
  </ListItemButton>
);

export const MobileProfileButtonEntries = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showTextSizeSlider = shouldShowTextSizeSlider(pathname);
  const { openSearch } = useSearchState();
  const { badge, openNotifications } = useNotificationsState();
  const { openLabValues, visible } = useLabValuesState();

  const handleLeaderboardClick = () => {
    navigate(paths.leaderboard);
  };

  return (
    <EntriesBox>
      {showTextSizeSlider ? (
        <>
          <MarkdownTextSizeSlider />
          <StyledDivider />
        </>
      ) : null}
      <ProfileListItem
        icon={<SearchIcon />}
        label="Search"
        onClick={openSearch}
      />
      {visible ? (
        <ProfileListItem
          icon={<LabIcon />}
          label="Lab Values"
          onClick={openLabValues}
        />
      ) : null}
      <ProfileListItem
        icon={<NotificationBadgeIcon invisible={!badge} />}
        label="Notifications"
        onClick={openNotifications}
      />
      <ProfileListItem
        // TODO: Just hiding for the time being, to not remove all the logic
        className="hide"
        icon={<RankingsIcon />}
        label="Leaderboard"
        onClick={handleLeaderboardClick}
      />
    </EntriesBox>
  );
};
