import React from 'react';

import {
  NotificationsEmptyInfo,
  NotificationsEmptyTitle,
  NotificationsEmptyWrapper,
} from './Notifications.styles';
import { NotificationsEmptyImage } from 'components/Illustrations';

const NotificationsEmpty = () => (
  <NotificationsEmptyWrapper>
    <NotificationsEmptyImage />
    <NotificationsEmptyTitle>No notifications yet</NotificationsEmptyTitle>
    <NotificationsEmptyInfo>
      We’ll let you know when someone mentions you or leaves a comment.
    </NotificationsEmptyInfo>
  </NotificationsEmptyWrapper>
);

export default NotificationsEmpty;
