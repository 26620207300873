import React from 'react';
import MuiInputAdornment, {
  InputAdornmentProps as MuiInputAdornmentProps,
} from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import { IconButton } from 'components/IconButton';
import { ButtonOnClickHandler, Icon } from 'types';

const CustomIconButton = styled(IconButton)(({ theme: { spacing } }) => ({
  padding: spacing(1),
}));

interface InputAdornmentProps
  extends Omit<MuiInputAdornmentProps, 'onClick' | 'position'> {
  label: string;
  onClick: ButtonOnClickHandler;
  icon: Icon;
  iconColor?: string;
  position?: 'start' | 'end';
}

const InputAdornment = ({
  icon: Icon,
  iconColor,
  label,
  onClick,
  position = 'end',
}: InputAdornmentProps): JSX.Element => (
  <MuiInputAdornment position={position}>
    <CustomIconButton aria-label={label} onClick={onClick}>
      <Icon htmlColor={iconColor} />
    </CustomIconButton>
  </MuiInputAdornment>
);

export default InputAdornment;
