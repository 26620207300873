import { ApolloClient } from '@apollo/client';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { Nullable } from 'types';

interface Client {
  client: Nullable<ApolloClient<object>>;
}

export const appClient = create<Client>()(() => ({
  client: null,
}));

export const useClient = () => appClient(state => state, shallow);
