import { ChangeEvent } from 'react';
import isNil from 'lodash/isNil';

export const convertEventTargetValueToNumber = (
  event: ChangeEvent<HTMLInputElement>,
  numericValue?: boolean
) =>
  numericValue && !isNil(event.target.value) && event.target.value !== ''
    ? {
        ...event,
        target: {
          ...event.target,
          value: Number(event.target.value),
        },
      }
    : event;
