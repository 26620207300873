import React, { memo, useEffect, useRef, useState } from 'react';

import { Popper } from 'components/Popper';
import { FormatSizeIcon } from 'components/Icons';
import { useHover, usePrevious } from 'hooks';
import { IconButton, IconButtonProps } from 'components/IconButton';
import { Tooltip } from 'components/Tooltip';
import { TextSizeSlider } from './TextSizeSlider';
import { Nullable } from 'types';

interface MarkdownTextSizeSliderProps
  extends Pick<IconButtonProps, 'white' | 'size' | 'label'> {
  showAsPopper?: boolean;
}

const MarkdownTextSizeSlider = ({
  showAsPopper = false,
  label,
  white,
  size,
}: MarkdownTextSizeSliderProps): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const [ref, value] = useHover<HTMLButtonElement>();
  const prevValue = usePrevious(value);
  const isOpen = Boolean(anchorEl) && showAsPopper;
  const timeoutRef = useRef(0);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowTooltip(false);
  };

  const handleShowTooltip = () => setShowTooltip(true);
  const handleCloseTooltip = () => setShowTooltip(false);

  const open = Boolean(anchorEl);
  const id = open ? 'adjust-font-size' : undefined;

  useEffect(() => {
    if (isOpen && prevValue && !value) {
      timeoutRef.current = window.setTimeout(() => {
        setAnchorEl(null);
      }, 500);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [value, isOpen, prevValue]);

  return (
    <>
      {showAsPopper ? (
        <Tooltip
          disableFocusListener={isOpen}
          disableHoverListener={isOpen}
          disableTouchListener={isOpen}
          onClose={handleCloseTooltip}
          onOpen={handleShowTooltip}
          open={showTooltip}
          placement="bottom"
          title="Change text size"
        >
          <IconButton
            label={label}
            onClick={handleOpen}
            ref={ref}
            size={size}
            white={white}
          >
            <FormatSizeIcon />
            <Popper
              anchorEl={anchorEl}
              disablePortal
              id={id}
              onClose={handleClose}
              open={isOpen}
              placement="bottom-end"
            >
              <TextSizeSlider />
            </Popper>
          </IconButton>
        </Tooltip>
      ) : (
        <TextSizeSlider />
      )}
    </>
  );
};

export default memo(MarkdownTextSizeSlider);
