import React from 'react';
import { styled } from '@mui/material/styles';

import { Overline } from 'components/Typography';

const StyledOverline = styled(Overline)(
  ({ theme: { palette, typography, spacing } }) => ({
    display: 'block',
    ...typography.button,
    color: palette.contrast.light,
    marginBottom: spacing(6),
  })
);

export interface SettingsSectionOverlineProps {
  text: string;
}

const SettingsSectionOverline = ({
  text,
}: SettingsSectionOverlineProps): JSX.Element => (
  <StyledOverline>{text}</StyledOverline>
);

export default SettingsSectionOverline;
