import React from 'react';
import { mdiCloseCircleOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CloseCircleOutlineIcon = (props: IconProps) => (
  <Icon path={mdiCloseCircleOutline} {...props} />
);

export default CloseCircleOutlineIcon;
