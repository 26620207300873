import React from 'react';
import { AppBarProps } from '@mui/material/AppBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { EAppType } from '@quesmed/types-rn/models';

import { IconButton } from 'components/IconButton';
import { AppsIcon, ExitIcon, MenuIcon, RankingsIcon } from 'components/Icons';
import { Logo } from 'components/Logo';
import { Search, SearchButton } from 'components/Search';
import { Notifications, NotificationsButton } from 'components/Notifications';
import { Tooltip } from 'components/Tooltip';
import { ProfileButton } from 'components/Profile';
import { paths } from 'Router';
import { usePlatform } from 'context/PlatformState';
import {
  MarkdownTextSizeSlider,
  shouldShowTextSizeSlider,
} from 'components/Markdown';
import {
  LabValues,
  LabValuesButton,
  useLabValuesState,
} from 'components/LabValues';
import useQuizState from 'hooks/useQuizState';
import { SampleBox } from 'components/Demo';
import { AppBar, LeaveButton, RightControls, Toolbar } from './LayoutStyles';
import { useExerciseState, useIsMobile } from 'hooks';
import { FeedbackModal, useFeedbackModal } from 'components/FeedbackModal';

interface TopBarProps extends AppBarProps {
  onClick: () => void;
  hasProduct?: boolean;
  isAppsOverview?: boolean;
  simplified?: boolean;
  withUser?: boolean;
}

const TopBar = ({
  onClick,
  hasProduct,
  isAppsOverview,
  withUser = true,
  simplified,
  ...props
}: TopBarProps): JSX.Element => {
  const { isMobile } = useIsMobile();
  const { app } = usePlatform();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { quizStarted } = useQuizState();
  const { leaveLabel, onLeave } = useExerciseState();
  const { open, closeFeedbackModal } = useFeedbackModal();

  const { visible } = useLabValuesState();

  const showTextSizeSlider = shouldShowTextSizeSlider(pathname);
  const paymentCompletePage = pathname === paths.paymentComplete;

  const handleLeaderboardClick = () => {
    navigate(paths.leaderboard);
  };

  const handleAppOverviewClick = () => {
    navigate(paths.appsManagement);
  };

  const handleLeave = () => {
    onLeave();
  };

  const showNavigationItems = !isAppsOverview && !simplified && !quizStarted;

  return (
    <AppBar position="fixed" {...props}>
      <Toolbar sx={({ spacing }) => ({ padding: spacing(0, 0) })}>
        {showNavigationItems ? (
          <>
            <Tooltip placement="bottom" title="Apps management">
              <IconButton
                aria-label="apps-management"
                edge="start"
                icon={<AppsIcon />}
                onClick={handleAppOverviewClick}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Menu">
              <IconButton
                aria-label="navigation"
                edge="start"
                icon={<MenuIcon />}
                onClick={onClick}
              />
            </Tooltip>
          </>
        ) : null}
        <Logo disableNavigation={paymentCompletePage} />
        <RightControls>
          {hasProduct && !simplified ? (
            <>
              {app !== EAppType.INTERVIEW && !quizStarted ? (
                <SearchButton />
              ) : null}
              <Tooltip placement="bottom" title="Leaderboard">
                <IconButton
                  aria-label="Leaderboard"
                  // TODO: Hiding for the time being, to not remove the logic and imports
                  className="hide"
                  icon={<RankingsIcon />}
                  onClick={handleLeaderboardClick}
                />
              </Tooltip>
              {!quizStarted ? <NotificationsButton /> : null}
              {visible ? <LabValuesButton /> : null}
              {showTextSizeSlider ? (
                <MarkdownTextSizeSlider showAsPopper />
              ) : null}
              {quizStarted && showTextSizeSlider && leaveLabel ? (
                <LeaveButton color="secondary" onClick={handleLeave} secondary>
                  <ExitIcon />
                  {isMobile ? null : leaveLabel}
                </LeaveButton>
              ) : null}
            </>
          ) : null}
          <SampleBox />
          {withUser && !quizStarted ? (
            <ProfileButton hasProduct={hasProduct} />
          ) : null}
        </RightControls>
      </Toolbar>
      {app !== EAppType.INTERVIEW ? <Search /> : null}
      {hasProduct && !simplified ? <Notifications /> : null}
      {visible ? <LabValues /> : null}
      {open ? <FeedbackModal onClose={closeFeedbackModal} open={open} /> : null}
    </AppBar>
  );
};

export default TopBar;
