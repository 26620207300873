import React from 'react';
import clsx from 'clsx';

import { IconButton } from 'components/IconButton';
import { Tooltip } from 'components/Tooltip';
import useNotificationsState from './useNotificationsState';
import NotificationBadgeIcon from './NotificationBadgeIcon';

const NotificationsButton = (): JSX.Element => {
  const { badge, open, openNotifications } = useNotificationsState();

  return (
    <Tooltip placement="bottom" title="Notifications">
      <IconButton
        aria-label="notification panel"
        className={clsx({ open })}
        onClick={openNotifications}
      >
        <NotificationBadgeIcon invisible={!badge} />
      </IconButton>
    </Tooltip>
  );
};

export default NotificationsButton;
