import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { Undefinable } from 'types';

const ExerciseStatusContainer = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      marginLeft: spacing(1),
    },

    '&.correct svg': {
      color: palette.success.dark,
    },
    '&.incorrect svg': {
      color: palette.error.main,
    },
    '&.moderately svg': {
      color: palette.warning.dark,
    },
    'span:first-letter': {
      textTransform: 'uppercase',
    },
  })
);

interface ExerciseStatusProps {
  exerciseMaxItemsNumber?: number;
  exerciseStatusSuffix?: string;
  exerciseTitle: 'Flashcard' | 'Question' | 'Stage';
  exerciseStatusLabel: Undefinable<string>;
  exerciseItemNumber?: number;
  exerciseStatusClassname?: string;
  exerciseIcon?: ReactNode;
}

const ExerciseStatus = ({
  exerciseMaxItemsNumber,
  exerciseTitle,
  exerciseStatusLabel,
  exerciseItemNumber,
  exerciseStatusClassname,
  exerciseIcon,
  exerciseStatusSuffix,
}: ExerciseStatusProps) => (
  <ExerciseStatusContainer
    className={exerciseStatusClassname ? exerciseStatusClassname : ''}
  >
    {exerciseTitle}
    {exerciseItemNumber && exerciseMaxItemsNumber
      ? ` ${exerciseItemNumber}/${exerciseMaxItemsNumber}`
      : null}
    {exerciseStatusLabel ? (
      <span>
        : {exerciseStatusLabel} {exerciseStatusSuffix}
      </span>
    ) : null}
    {exerciseIcon}
  </ExerciseStatusContainer>
);

export default ExerciseStatus;
