import React from 'react';
import { mdiChevronDown } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ChevronDownIcon = (props: IconProps) => (
  <Icon path={mdiChevronDown} {...props} />
);

export default ChevronDownIcon;
