import { selectionIsEmpty } from './selectionIsEmpty';

export const isBackwardsSelection = (selection: Selection) => {
  /**
   * This function will tell if selection is backwards or not
   */
  if (selectionIsEmpty(selection)) return false;

  const position = selection?.anchorNode?.compareDocumentPosition(
    selection?.focusNode as Node
  );

  return (
    (!position && selection.anchorOffset > selection.focusOffset) ||
    position === Node.DOCUMENT_POSITION_PRECEDING
  );
};
