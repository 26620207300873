import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { AppProductType, Nullable, SUBSCRIPTIONS } from 'types';
import { Body, H6 } from 'components/Typography';
import { ProductImage } from 'components/Illustrations';
import { formatDate } from 'utils';

const StyledHeader = styled(Box)(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    alignItems: 'left',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: spacing(4),
    },

    '& .MuiTypography-body2': {
      marginTop: spacing(1),
      color: palette.text.secondary,
    },
  })
);
interface SubscriptionHeaderProps {
  renewsDate: Nullable<number | Date>;
  subscription: AppProductType;
  isCanceled: boolean;
  canBeUpdated: boolean;
}

const SubscriptionHeader = ({
  renewsDate,
  subscription,
  isCanceled,
  canBeUpdated,
}: SubscriptionHeaderProps): JSX.Element => (
  <StyledHeader>
    <ProductImage product={subscription} variant="subscription" />
    <Box>
      <H6>{SUBSCRIPTIONS[subscription].name}</H6>
      <Body variant="body2">
        {isCanceled || !canBeUpdated ? 'Cancels' : 'Renews'} on:{' '}
        {renewsDate ? formatDate(renewsDate) : ''}
      </Body>
    </Box>
  </StyledHeader>
);

export default SubscriptionHeader;
