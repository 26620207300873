import React from 'react';
import { mdiAccountCircle } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ProfileIcon = (props: IconProps) => (
  <Icon path={mdiAccountCircle} {...props} />
);

export default ProfileIcon;
