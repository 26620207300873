import { useCallback, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { useSnackbar } from 'components/Snackbar';
import { offlineLink } from 'Apollo/link/offlineLink';

interface OnlineStatus {
  isOnline: boolean;
  setIsOnline: (isOnline: boolean) => void;
}

export const isOnlineState = create<OnlineStatus>()(set => ({
  isOnline: true,
  setIsOnline: isOnline => set({ isOnline }),
}));

const useIsOnlineState = () => isOnlineState(state => state, shallow);

export const useIsOnline = () => useIsOnlineState().isOnline;

export const useOnlineStatus = () => {
  const client = useApolloClient();
  const { isOnline, setIsOnline } = useIsOnlineState();
  const { enqueueSnackbar } = useSnackbar();
  const onlineStatusRef = useRef<boolean>(isOnline);

  const handleOfflineStatus = useCallback(() => {
    setIsOnline(false);
  }, [setIsOnline]);

  const handleOnlineStatus = useCallback(() => {
    setIsOnline(true);
  }, [setIsOnline]);

  useEffect(() => {
    const syncCache = async () => {
      await offlineLink.sync();
      await client.refetchQueries({
        include: 'active',
      });
    };
    if (onlineStatusRef.current !== isOnline) {
      enqueueSnackbar(`You are ${isOnline ? 'online' : 'offline'}`);

      if (!onlineStatusRef.current && isOnline) {
        syncCache();
      }

      onlineStatusRef.current = isOnline;
    }
  }, [enqueueSnackbar, isOnline, client]);

  useEffect(() => {
    window.addEventListener('offline', handleOfflineStatus);
    window.addEventListener('online', handleOnlineStatus);

    return () => {
      window.removeEventListener('offline', handleOfflineStatus);
      window.removeEventListener('online', handleOnlineStatus);
    };
  }, [handleOfflineStatus, handleOnlineStatus]);
};
