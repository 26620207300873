import React from 'react';
import { mdiLinkVariant } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const LinkIcon = (props: IconProps) => (
  <Icon path={mdiLinkVariant} {...props} />
);

export default LinkIcon;
