import React, { FC, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Switch } from '@mui/material';

import SettingsSectionDivider from './SettingsSectionDivider';
import SettingsSectionTitle from './SettingsSectionTitle';
import SettingsContentLoading from './SettingsContentLoading';
import { localStorageTyped } from 'utils';
import { TextSizeSlider } from 'components/Markdown/MarkdownTextSizeSlider/TextSizeSlider';
import SettingsSectionOverline from './SettingsSectionOverline';
import { Body } from 'components/Typography';
import { useDisableHighlighting } from 'hooks/useDisableHighlighting';
import { useUpdateUserSettings, useUserSettings } from 'hooks';

const TextSizeSettingBox = styled(Box)(({ theme: { spacing } }) => ({
  maxWidth: '432px',

  '& .text-size-slider-box': {
    padding: spacing(0, 3, 0, 0),
  },
}));

const SwitchContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(4),
}));

const StyledBody = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

const { getItem: getdisabledHighlighting } = localStorageTyped<boolean>(
  'disabledHighlighting'
);

const SettingsAccessibility: FC = () => {
  const { disabledColors } = useUserSettings();
  const { disabledHighlighting, setDisabled: setDisableHighlighting } =
    useDisableHighlighting();
  const { updateUserSettings } = useUpdateUserSettings();

  const handleChangeColor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateUserSettings({
        globalSettings: {
          disabledColors: event.target.checked,
        },
      });
    },
    [updateUserSettings]
  );

  const handleChangeHighlights = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDisableHighlighting(event.target.checked);
    },
    [setDisableHighlighting]
  );

  return (
    <SettingsContentLoading count={4} height={56} loading={false}>
      <SettingsSectionTitle title="User preferences" />
      <TextSizeSettingBox>
        <SettingsSectionOverline text="Text size" />
        <TextSizeSlider />
      </TextSizeSettingBox>
      <SettingsSectionDivider />
      <SettingsSectionOverline text="Hidden ongoing progress" />
      <SwitchContainer>
        <Box>
          <Body>Remove colour progress feedback while doing tests</Body>
          <StyledBody small>
            Changes to monochromatic colour scheme for the progress bars
          </StyledBody>
        </Box>
        <Switch checked={disabledColors} onChange={handleChangeColor} />
      </SwitchContainer>
      <SettingsSectionDivider />
      <SettingsSectionOverline text="Highlights" />
      <SwitchContainer>
        <Box>
          <Body>Disable highlighting text in questions</Body>
        </Box>
        <Switch
          checked={getdisabledHighlighting() || disabledHighlighting}
          onChange={handleChangeHighlights}
        />
      </SwitchContainer>
    </SettingsContentLoading>
  );
};

export default SettingsAccessibility;
