import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { EPlatformId } from '@quesmed/types-rn';

import { noop } from 'utils';
import {
  setAudioEnabledAction,
  setChannelIdAction,
  setInitAudioAction,
  setInitSessionAction,
  setInitVideoAction,
  setMutedUsersAction,
  setPlatformAction,
  setPreviewAction,
  setSessionExpiredAction,
  setUserIdAction,
  setVideoEnabledAction,
  setWillExpiredAction,
} from './AgoraActions';
import { agoraStateReducer } from './AgoraReducer';
import {
  AgoraDispatch,
  AgoraDispatchContextProviderProps,
  AgoraState,
  AgoraStateContextProviderProps,
  ChannelId,
} from './types';
import { Children } from 'types';

const initialAgoraState: AgoraState = {
  audioEnabled: true,
  mutedUsers: [],
  videoEnabled: false,
  channelId: undefined,
  sessionExpired: false,
  userId: undefined,
  initSession: false,
  initAudio: false,
  initVideo: false,
  willExpired: false,
  preview: false,
  platform: undefined,
};

const AgoraStateContext = createContext<AgoraState>(initialAgoraState);

const { Provider: StateContextProvider } = AgoraStateContext;

const AgoraStateProvider = ({
  children,
  value,
}: AgoraStateContextProviderProps): JSX.Element => (
  <StateContextProvider value={value}>{children}</StateContextProvider>
);

export const useAgoraState = (): AgoraState => useContext(AgoraStateContext);

const initialActionDispatcher: AgoraDispatch = {
  setAudioEnabled: noop,
  setVideoEnabled: noop,
  setMutedUsers: noop,
  setSessionExpired: noop,
  setChannelId: noop,
  setUserId: noop,
  setInitSession: noop,
  setInitAudio: noop,
  setInitVideo: noop,
  setWillExpired: noop,
  setPreview: noop,
  setPlatform: noop,
};

const AgoraDispatchContext = createContext<AgoraDispatch>(
  initialActionDispatcher
);

const { Provider: DispatchContextProvider } = AgoraDispatchContext;

const AgoraDispatchProvider = ({
  children,
  value: dispatch,
}: AgoraDispatchContextProviderProps): JSX.Element => {
  const setAudioEnabled = useCallback(
    (payload: boolean) => dispatch(setAudioEnabledAction(payload)),
    [dispatch]
  );

  const setVideoEnabled = useCallback(
    (payload: boolean) => dispatch(setVideoEnabledAction(payload)),
    [dispatch]
  );

  const setMutedUsers = useCallback(
    (payload: number[]) => dispatch(setMutedUsersAction(payload)),
    [dispatch]
  );

  const setSessionExpired = useCallback(
    (payload: boolean) => dispatch(setSessionExpiredAction(payload)),
    [dispatch]
  );

  const setChannelId = useCallback(
    (payload?: ChannelId) => dispatch(setChannelIdAction(payload)),
    [dispatch]
  );

  const setUserId = useCallback(
    (payload?: number) => dispatch(setUserIdAction(payload)),
    [dispatch]
  );

  const setInitSession = useCallback(
    (payload: boolean) => dispatch(setInitSessionAction(payload)),
    [dispatch]
  );

  const setInitAudio = useCallback(
    (payload: boolean) => dispatch(setInitAudioAction(payload)),
    [dispatch]
  );

  const setInitVideo = useCallback(
    (payload: boolean) => dispatch(setInitVideoAction(payload)),
    [dispatch]
  );

  const setWillExpired = useCallback(
    (payload: boolean) => dispatch(setWillExpiredAction(payload)),
    [dispatch]
  );

  const setPreview = useCallback(
    (payload: boolean) => dispatch(setPreviewAction(payload)),
    [dispatch]
  );

  const setPlatform = useCallback(
    (payload: EPlatformId) => dispatch(setPlatformAction(payload)),
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      setAudioEnabled,
      setVideoEnabled,
      setMutedUsers,
      setSessionExpired,
      setChannelId,
      setUserId,
      setInitSession,
      setInitAudio,
      setInitVideo,
      setWillExpired,
      setPreview,
      setPlatform,
    }),
    [
      setAudioEnabled,
      setVideoEnabled,
      setMutedUsers,
      setSessionExpired,
      setChannelId,
      setUserId,
      setInitSession,
      setInitAudio,
      setInitVideo,
      setWillExpired,
      setPreview,
      setPlatform,
    ]
  );

  return (
    <DispatchContextProvider value={actions}>
      {children}
    </DispatchContextProvider>
  );
};

export const useAgoraSetState = (): AgoraDispatch =>
  useContext(AgoraDispatchContext);

export const AgoraStateProviders = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  const [state, stateDispatch] = useReducer(
    agoraStateReducer,
    initialAgoraState
  );

  return (
    <AgoraDispatchProvider value={stateDispatch}>
      <AgoraStateProvider value={state}>{children}</AgoraStateProvider>
    </AgoraDispatchProvider>
  );
};
