import React, { forwardRef } from 'react';
import { mdiDeleteForeverOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const DeleteOutlineIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon path={mdiDeleteForeverOutline} ref={ref} {...props} />
));

DeleteOutlineIcon.displayName = 'DeleteOutlineIcon';

export default DeleteOutlineIcon;
