import React from 'react';
import { mdiThumbUpOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ThumbUpIcon = (props: IconProps) => (
  <Icon path={mdiThumbUpOutline} {...props} />
);

export default ThumbUpIcon;
