import { useMutation } from '@apollo/client';
import {
  CANCEL_SUBSCRIPTION,
  ICancelSubscriptionData,
  ICancelSubscriptionVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useSnackbar } from 'components/Snackbar';

export const useCancelSubscription = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [cancelSubscription, { loading }] = useMutation<
    ICancelSubscriptionData,
    ICancelSubscriptionVar
  >(CANCEL_SUBSCRIPTION, {
    onCompleted: data => {
      if (data?.restricted?.cancelSubscription) {
        const isCanceled = data.restricted.cancelSubscription.endedAt !== null;

        return enqueueSnackbar(
          isCanceled ? 'Subscription cancelled' : 'Subscription set to renew'
        );
      }
      enqueueSnackbar('Subscription cancellation or renew failed!');
    },
  });

  return { cancelSubscription, loading };
};
