import React from 'react';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { shouldForwardProp } from 'utils';

const TooltipToBeStyled = ({
  className,
  ...props
}: MuiTooltipProps): JSX.Element => (
  <MuiTooltip {...props} classes={{ tooltip: className }} />
);

interface TooltipProps extends MuiTooltipProps {
  textCenter?: boolean;
}

const StyledTooltip = styled(TooltipToBeStyled, {
  shouldForwardProp: prop => shouldForwardProp(['textCenter'], prop),
})<TooltipProps>(({ theme: { palette, spacing, typography }, textCenter }) => ({
  padding: spacing(2.5, 3),
  backgroundColor: palette.contrast.main,
  color: palette.contrast.contrastText,
  textAlign: textCenter ? 'center' : 'left',
  ...typography.button,
  maxWidth: '320px',
}));

const Tooltip = ({
  children,
  textCenter,
  placement = 'right',
  ...props
}: TooltipProps): JSX.Element => {
  return (
    <StyledTooltip
      disableInteractive
      placement={placement}
      textCenter={textCenter}
      {...props}
    >
      {children}
    </StyledTooltip>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
