import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface PermissionsState {
  audioAdmitted: boolean;
  videoAdmitted: boolean;

  setAudioAdmitted: (audioAdmitted: boolean) => void;
  setVideoAdmitted: (videoAdmitted: boolean) => void;
}

export const usePermissions = create<PermissionsState>()(set => ({
  audioAdmitted: true,
  videoAdmitted: true,
  setAudioAdmitted: audioAdmitted => set({ audioAdmitted }),
  setVideoAdmitted: videoAdmitted => set({ videoAdmitted }),
}));

const useAVPermissionsState = () => usePermissions(state => state, shallow);

export default useAVPermissionsState;
