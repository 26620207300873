import React from 'react';
import { mdiCursorDefaultClickOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CursorDefaultClickOutlineIcon = (props: IconProps) => (
  <Icon path={mdiCursorDefaultClickOutline} {...props} />
);

export default CursorDefaultClickOutlineIcon;
