import React from 'react';
import { mdiCardsOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const RemoveFlashcardsIcon = (props: IconProps) => (
  <Icon path={mdiCardsOutline} {...props} />
);

export default RemoveFlashcardsIcon;
