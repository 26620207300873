import { ConstSize } from 'types';

export const constSizeSC = (width: string, height: string = width): string => `
  width: ${width};
  max-width: ${width};
  min-width: ${width};
  height: ${height};
  max-height: ${height};
  min-height: ${height};
  flex-shrink: 0;
`;

export const constSize = (
  width: string,
  height: string = width
): ConstSize => ({
  width: width,
  maxWidth: width,
  minWidth: width,
  height: height,
  maxHeight: height,
  minHeight: height,
  flexShrink: 0,
});
