const messageAsKey = (
  topLevelUniqueMessage?: boolean,
  uniqueMessage?: boolean
) => {
  if (topLevelUniqueMessage && uniqueMessage !== false) {
    return true;
  }

  return Boolean(uniqueMessage);
};

export default messageAsKey;
