import React from 'react';

import { IconBadge } from 'components/IconBadge';
import { NotificationIcon } from 'components/Icons';

interface NotificationBadgedIconProps {
  invisible: boolean;
}

const NotificationBadgeIcon = ({
  invisible,
}: NotificationBadgedIconProps): JSX.Element => (
  <IconBadge invisible={invisible}>
    <NotificationIcon />
  </IconBadge>
);

export default NotificationBadgeIcon;
