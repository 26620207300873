import { from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { authLink } from './authLink';
import { errorLink } from './errorLink';
import { requestFilterLink } from './requestFilterLink';
import { httpLink } from './httpLink';
import { offlineLink } from './offlineLink';
import { responseLink } from './responseLink';
import { subscriptionLink } from './subscriptionLink';
import { SnackBar } from 'types';

export const splitLink = (snackbar: SnackBar) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    subscriptionLink,
    from([
      authLink,
      offlineLink,
      requestFilterLink(snackbar),
      errorLink(snackbar),
      responseLink.concat(httpLink),
    ])
  );
