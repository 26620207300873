import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { IUserSettings } from '@quesmed/types-rn/models';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Switch } from '@mui/material';
import { useLocation } from 'react-router-dom';

import SettingsSectionDivider from './SettingsSectionDivider';
import SettingsSectionTitle from './SettingsSectionTitle';
import { DataPickerField, FormField } from 'components/TextField';
import SettingsUpdate from './SettingsUpdate';
import {
  CalendarBlankIcon,
  ExpandVerticalIcon,
  RemoveFlashcardsIcon,
} from 'components/Icons';
import SettingsContentLoading from './SettingsContentLoading';
import { getDirtyValues, isAppManagementPage, parseDate } from 'utils';
import { ErrorBox } from 'components/Error';
import { Body } from 'components/Typography';
import { Nilable } from 'types';
import useUpdateUserSettings from 'hooks/useUpdateUserSettings';

const DAILY_FEED_MAX_MIN_VALUE = 0;
const DAILY_FEED_MAX_MAX_VALUE = 250;
const DAILY_FEED_RESET_MIN_VALUE = 0;
const DAILY_FEED_RESET_MAX_VALUE = 24;

const dailyFeedMaxMessage = `Max daily feed must be an integer between ${DAILY_FEED_MAX_MIN_VALUE} and ${DAILY_FEED_MAX_MAX_VALUE}`;
const dailyFeedResetTimeMessage = `Daily feed reset time must be an integer between ${DAILY_FEED_RESET_MIN_VALUE} and ${DAILY_FEED_RESET_MAX_VALUE}`;

type FormInput = Pick<
  IUserSettings['globalSettings'],
  'dailyFeedMax' | 'dailyFeedResetTime'
> &
  Pick<IUserSettings['productSettings'], 'examDate'>;

const schema = Joi.object<FormInput>({
  examDate: Joi.date(),
  dailyFeedMax: Joi.number().integer().min(0).max(250).messages({
    'number.base': dailyFeedMaxMessage,
    'number.integer': dailyFeedMaxMessage,
    'number.min': dailyFeedMaxMessage,
    'number.max': dailyFeedMaxMessage,
  }),
  dailyFeedResetTime: Joi.number().integer().min(0).max(24).messages({
    'number.base': dailyFeedResetTimeMessage,
    'number.integer': dailyFeedResetTimeMessage,
    'number.min': dailyFeedResetTimeMessage,
    'number.max': dailyFeedResetTimeMessage,
  }),
});

const SwitchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

interface SettingsGeneralProps {
  error?: ApolloError;
  loading?: boolean;
  setIsFormDirty?: (isDirty: boolean) => void;
  settings?: IUserSettings;
}

const SettingsGeneral = ({
  error,
  loading,
  setIsFormDirty,
  settings,
}: SettingsGeneralProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(
    settings?.globalSettings.expandedReading || false
  );
  const { globalSettings, productSettings } = settings || {};
  const {
    dailyFeedMax = DAILY_FEED_MAX_MIN_VALUE,
    dailyFeedResetTime = DAILY_FEED_RESET_MIN_VALUE,
  } = globalSettings || {};
  const { examDate } = productSettings || {};
  const { loading: updateUserSettingsLoading, updateUserSettings } =
    useUpdateUserSettings();
  const { pathname } = useLocation();

  const setDefaultDate = (examDate: Nilable<number | Date>) => {
    if (examDate instanceof Date) {
      return examDate;
    } else if (typeof examDate === 'number') {
      return new Date(parseDate(examDate));
    }
  };

  const {
    control,
    formState: { isDirty, isValid, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<FormInput>({
    defaultValues: {
      examDate: setDefaultDate(examDate),
      dailyFeedMax,
      dailyFeedResetTime,
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(isDirty);
    }
  }, [isDirty, setIsFormDirty]);

  const handleCancel = () => {
    reset();
  };

  const onSubmit = (data: FormInput) => {
    if (!isValid && !isDirty) {
      return;
    }

    const userData = getDirtyValues(data, dirtyFields);

    const updatedData = {
      productSettings: {
        examDate: userData.examDate
          ? dayjs(new Date(userData.examDate)).unix()
          : undefined,
      },
      globalSettings: userData,
    };

    updateUserSettings(updatedData, {
      successCallback: () => {
        reset(data);
      },
      successMsg: 'Updated successfully.',
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(prev => !prev);
    updateUserSettings({
      globalSettings: { expandedReading: event.target.checked },
    });
  };

  if (error) {
    return <ErrorBox description={error.message} />;
  }

  return (
    <>
      <SettingsContentLoading
        count={4}
        height={56}
        loading={loading || !settings}
      >
        <>
          {isAppManagementPage(pathname) ? null : (
            <>
              <SettingsSectionTitle
                icon={<CalendarBlankIcon />}
                title="Exam date countdown"
              />
              <DataPickerField
                control={control}
                inputProps={{
                  fullWidth: true,
                }}
                label="Exam date"
                name="examDate"
              />
              <SettingsSectionDivider />
            </>
          )}
          <SettingsSectionTitle
            icon={<RemoveFlashcardsIcon />}
            subtitle="Note: Changes take effect the following day."
            title="Daily flashcards"
          />
          <FormField
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            control={control}
            fullWidth
            helperText="Maximum number of daily flashcards to review in 24 hours"
            label="Daily cards limit"
            name="dailyFeedMax"
            type="number"
          />
          <FormField
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            control={control}
            fullWidth
            helperText="Hours to reset your Daily Feed Cards (0-24)"
            label="Daily feed reset time"
            name="dailyFeedResetTime"
            type="number"
          />
          <SettingsSectionDivider />
          <SettingsSectionTitle
            icon={<ExpandVerticalIcon />}
            title="Expand all reading sections by default"
          />
          <SwitchContainer>
            <Body>No</Body>
            <Switch checked={isExpanded} onChange={handleChange} />
            <Body>Yes</Body>
          </SwitchContainer>
        </>
      </SettingsContentLoading>
      {isDirty ? (
        <SettingsUpdate
          disabled={!isValid || updateUserSettingsLoading}
          onCancel={handleCancel}
          onUpdate={handleSubmit(onSubmit)}
        />
      ) : null}
    </>
  );
};

export default SettingsGeneral;
