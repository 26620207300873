import { FetchResult, useMutation } from '@apollo/client';
import {
  IUpdateUserData,
  IUpdateUserVar,
  IUserInput,
  UPDATE_USER,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useCallback } from 'react';

import { useSnackbar } from 'components/Snackbar';
import { useAuth } from 'Auth';

interface UpdateUserOptions {
  successMsg?: string;
  errorMsg?: string;
  successCallback?: () => void;
}

const useUpdateUser = () => {
  const { setToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserMutation, { loading }] = useMutation<
    IUpdateUserData,
    IUpdateUserVar
  >(UPDATE_USER);

  const updateUser = useCallback(
    async (
      data: IUserInput,
      options?: UpdateUserOptions
    ): Promise<
      FetchResult<IUpdateUserData, Record<string, any>, Record<string, any>>
    > => {
      const { successMsg, errorMsg, successCallback } = options || {};

      return updateUserMutation({
        variables: { data },
        onCompleted: data => {
          const {
            restricted: {
              updateUser: { jwt },
            },
          } = data;

          if (jwt) {
            setToken(jwt);

            if (successCallback) {
              successCallback();
            }

            if (successMsg) {
              enqueueSnackbar(successMsg);
            }
          }
        },
        onError: () => {
          if (errorMsg) {
            enqueueSnackbar(errorMsg);
          }
        },
      });
    },
    [updateUserMutation, setToken, enqueueSnackbar]
  );

  return { updateUser, loading };
};

export default useUpdateUser;
