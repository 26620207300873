import React from 'react';
import Lottie from 'lottie-react';
import { styled } from '@mui/material/styles';

import loginAnimation from './animations/login-animation.json';

const LottieBox = styled(Lottie)({
  display: 'flex',
  width: '90%',
  margin: '0 auto',
});

const LoginAnimation = () => (
  <LottieBox animationData={loginAnimation} loop={false} />
);

export default LoginAnimation;
