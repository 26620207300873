import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const RoundedButton = styled(Button)(
  ({ theme: { palette, spacing, transitions, typography } }) => ({
    height: spacing(10),
    borderRadius: '20px',
    border: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.default,
    width: '220px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    letterSpacing: 'initial',
    color: palette.text.disabled,
    textTransform: 'capitalize',
    ...typography.body1,
    transition: transitions.create(['border-color']),

    '& .MuiSvgIcon-root': {
      color: palette.icon.main,
      transition: transitions.create(['color']),

      '&:first-of-type': {
        marginRight: spacing(1.5),
      },
    },

    '&:hover': {
      border: `1px solid ${palette.stroke.dark}`,

      '& .MuiSvgIcon-root': {
        color: palette.icon.dark,
      },
    },
  })
);

export default RoundedButton;
