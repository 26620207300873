import React from 'react';

import { SearchIcon } from 'components/Icons';
import { IconButton } from 'components/IconButton';
import { Tooltip } from 'components/Tooltip';
import { RoundedButton } from 'components/Button';
import useSearchState from './useSearchState';
import { useIsMobile } from 'hooks';

const SearchButton = (): JSX.Element => {
  const { isMobile } = useIsMobile();
  const { openSearch } = useSearchState();

  return isMobile ? (
    <Tooltip placement="bottom" title="Search">
      <IconButton
        aria-label="search"
        icon={<SearchIcon />}
        onClick={openSearch}
      />
    </Tooltip>
  ) : (
    <RoundedButton
      color="secondary"
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onClick={openSearch}
      variant="outlined"
    >
      <SearchIcon /> Search
    </RoundedButton>
  );
};

export default SearchButton;
