import React from 'react';
import { useNavigate } from 'react-router-dom';

import { H4 } from 'components/Typography';
import { paths } from 'Router';
import {
  ContentContainer,
  ContentHolderContainer,
  ResetButton,
} from './Shared.styles';

const PasswordChanged = (): JSX.Element => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(paths.login);
  };

  return (
    <ContentHolderContainer>
      <ContentContainer>
        <H4>Password changed!</H4>
        <ResetButton
          fullWidth
          onClick={handleSubmit}
          size="large"
          variant="outlined"
        >
          Back to login
        </ResetButton>
      </ContentContainer>
    </ContentHolderContainer>
  );
};

export default PasswordChanged;
