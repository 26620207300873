import React, { MouseEvent, ReactNode, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import MuiDialogContent, {
  DialogContentProps,
} from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import Box, { BoxProps } from '@mui/material/Box';
import clsx from 'clsx';

import { ButtonOnClickHandler } from '../../types';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { ArrowLeftIcon, CloseIcon } from '../Icons';
import { shouldForwardProp } from 'utils';

type SizeVariant = 'sm' | 'md' | 'lg' | 'xl';
type HeightVariant = 'sm' | 'md' | 'lg';

const Dialog = styled(MuiDialog, {
  shouldForwardProp: prop => shouldForwardProp(['sizeVariant'], prop),
})<{ sizeVariant: SizeVariant }>(
  ({ theme: { mixins, breakpoints, spacing }, sizeVariant }) => ({
    '& .MuiPaper-root': {
      backgroundImage: 'none',
    },
    '&.hidden': {
      visibility: 'hidden',
    },

    '.MuiDialog-paper': {
      borderRadius: '7px',
      overflow: 'hidden',

      '&.MuiDialog-paperFullScreen': {
        height: `calc(100% - ${spacing(8)})`,
        alignSelf: 'flex-end',
        borderRadius: '7px 7px 0 0',
      },
    },

    [breakpoints.up('md')]: {
      '.MuiDialog-paper': {
        ...mixins.modal.width[sizeVariant],
      },
    },
  })
);

const DialogHeader = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  boxSizing: 'border-box',
  minHeight: spacing(16),
  padding: spacing(4),
  display: 'flex',
  alignItems: 'center',

  [breakpoints.up('md')]: {
    minHeight: spacing(24),
    padding: spacing(8),
  },
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.h5,
  padding: 0,
  marginRight: 'auto',
  flex: 1,
}));

const Actions = styled(DialogActions)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    borderTop: `1px solid ${palette.stroke.main}`,
    padding: spacing(4),
    gap: spacing(4),
    justifyContent: 'space-between',
    flexDirection: 'column-reverse',

    '& > .MuiButtonBase-root': {
      width: '100%',

      [breakpoints.up('md')]: {
        width: 'auto',
      },
    },

    '&.MuiDialogActions-root > :not(:first-of-type)': {
      marginLeft: spacing(4),
    },

    [breakpoints.up('md')]: {
      padding: spacing(8),
      borderTop: 'none',
      flexDirection: 'row',
    },

    '&>:not(style)~:not(style)': {
      marginLeft: 0,
    },
  })
);
const StyledButtonsContainer = styled(Box, {
  shouldForwardProp: prop => shouldForwardProp(['fullWidthSubmit'], prop),
})<{ fullWidthSubmit?: boolean }>(
  ({ theme: { breakpoints, spacing }, fullWidthSubmit }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: spacing(4),
    width: '100%',
    marginLeft: 'auto',

    [breakpoints.up('md')]: {
      width: fullWidthSubmit ? '100%' : 'auto',
      flexDirection: 'row',

      '& .MuiButtonBase-root': {
        width: '100%',
      },
    },
  })
);

const DialogContent = styled(MuiDialogContent)(
  ({ theme: { breakpoints, mixins, spacing } }) => ({
    padding: spacing(4),
    overflowX: 'hidden',

    '&.scrollable': {
      '&.sm': {
        ...mixins.modal.maxContentHeight.sm,
      },

      '&.md': {
        ...mixins.modal.maxContentHeight.md,
      },

      '&.lg': {
        ...mixins.modal.maxContentHeight.lg,
      },

      '&.fullScreen': {
        maxHeight: 'none',
      },
    },

    [breakpoints.up('md')]: {
      maxHeight: 'none',
      padding: spacing(8),
    },

    '&.no-padding': {
      padding: spacing(0),
    },

    '&.no-padding-x': {
      paddingLeft: spacing(0),
      paddingRight: spacing(0),
    },

    '&.no-padding-y': {
      paddingTop: spacing(0),
      paddingBottom: spacing(0),
    },

    '& > *:not(:last-child)': {
      paddingBottom: spacing(8),
    },
  })
);

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const Description = styled(DialogContentText)(
  ({ theme: { palette, typography } }) => ({
    color: palette.text.primary,
    ...typography.body1,
  })
);

type Event = React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent;

export interface ModalProps
  extends Omit<DialogProps, 'onClose' | 'onSubmit' | 'sx' | 'title'> {
  hidden?: boolean;
  actionsSx?: DialogActionsProps['sx'];
  cancelDisabled?: boolean;
  cancelLabel?: string;
  cancelLoading?: boolean;
  closeOnBackdrop?: boolean;
  closeOnEscape?: boolean;
  contentSx?: BoxProps['sx'];
  description?: string;
  dialogSx?: DialogProps['sx'];
  headerSx?: BoxProps['sx'];
  heightVariant?: HeightVariant;
  loading?: boolean;
  onBack?: ButtonOnClickHandler;
  onCancel?: ButtonOnClickHandler;
  onClose?: () => void;
  onSubmit?: ButtonOnClickHandler;
  open: boolean;
  showCloseButton?: boolean;
  showControls?: boolean;
  showHeader?: boolean;
  submitDisabled?: boolean;
  submitLabel?: ReactNode;
  onComplementaryAction?: ButtonOnClickHandler;
  complementaryActionDisabled?: boolean;
  complementaryActionLabel?: string;
  submitLoading?: boolean;
  complementaryLoading?: boolean;
  sizeVariant?: SizeVariant;
  title?: ReactNode;
  titleSx?: DialogTitleProps['sx'];
  wrapperSx?: DialogContentProps['sx'];
  danger?: boolean;
  fullWidthSubmit?: boolean;
  fullWidthComplementary?: boolean;
  disableContentScroll?: boolean;
  noPadding?: boolean;
  noPaddingX?: boolean;
  noPaddingY?: boolean;
}

export const Modal = ({
  actionsSx,
  cancelDisabled,
  cancelLabel,
  cancelLoading,
  closeOnBackdrop = true,
  closeOnEscape = true,
  children,
  contentSx,
  description,
  dialogSx,
  fullWidth = true,
  headerSx,
  heightVariant = 'sm',
  hidden,
  loading = false,
  maxWidth = 'md',
  onBack,
  onCancel,
  onClose,
  onComplementaryAction,
  onSubmit,
  open,
  noPadding,
  noPaddingX,
  noPaddingY,
  submitLoading,
  complementaryLoading,
  submitDisabled,
  submitLabel,
  complementaryActionDisabled,
  complementaryActionLabel,
  showCloseButton = false,
  showControls = true,
  showHeader = true,
  title,
  titleSx,
  wrapperSx,
  danger,
  fullWidthSubmit,
  disableContentScroll,
  fullWidthComplementary,
  sizeVariant = 'lg',
  ...rest
}: ModalProps): JSX.Element => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose: DialogProps['onClose'] = useCallback(
    (e: Event, reason: string) => {
      if (!closeOnBackdrop && reason === 'backdropClick') {
        (e as React.MouseEvent<HTMLDivElement, MouseEvent>).preventDefault();

        return;
      }
      if (!closeOnBackdrop && reason === 'escapeKeyDown') {
        (e as React.KeyboardEvent).preventDefault();

        return;
      }

      if (onClose) {
        onClose();
      }
    },
    [closeOnBackdrop, onClose]
  );

  return (
    <Dialog
      aria-describedby={description ? 'modal-description' : undefined}
      aria-labelledby={title ? 'modal-title' : undefined}
      className={clsx({ hidden })}
      disableEscapeKeyDown={!closeOnEscape}
      disableScrollLock
      fullScreen={isMobileScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      sizeVariant={sizeVariant}
      sx={dialogSx}
      {...rest}
    >
      {showHeader ? (
        <DialogHeader sx={headerSx}>
          {onBack ? (
            <IconButton
              edge="start"
              icon={<ArrowLeftIcon />}
              onClick={onBack}
            />
          ) : null}
          {title ? (
            <Title id="modal-title" sx={titleSx}>
              {title}
            </Title>
          ) : null}
          {showCloseButton ? (
            <IconButton edge="end" icon={<CloseIcon />} onClick={onClose} />
          ) : null}
        </DialogHeader>
      ) : null}
      <DialogContent
        className={clsx(heightVariant, {
          fullScreen: isMobileScreen,
          scrollable: !disableContentScroll,
          'no-padding': noPadding,
          'no-padding-x': noPaddingX,
          'no-padding-y': noPaddingY,
        })}
        sx={wrapperSx}
      >
        {description ? (
          <Description id="modal-description">{description}</Description>
        ) : null}
        <Content sx={contentSx}>{children}</Content>
      </DialogContent>
      {showControls && (
        <Actions sx={actionsSx}>
          {cancelLabel ? (
            <Button
              color="secondary"
              disabled={cancelDisabled}
              loading={loading || cancelLoading}
              onClick={onCancel || onClose}
              secondary
            >
              {cancelLabel}
            </Button>
          ) : null}
          <StyledButtonsContainer fullWidthSubmit={fullWidthSubmit}>
            {complementaryActionLabel && onComplementaryAction ? (
              <Button
                danger={danger}
                disabled={complementaryActionDisabled}
                fullWidth={fullWidthComplementary}
                loading={loading || complementaryLoading}
                onClick={onComplementaryAction}
                secondary
              >
                {complementaryActionLabel}
              </Button>
            ) : null}
            {submitLabel && onSubmit && !fullWidthComplementary ? (
              <Button
                danger={danger}
                disabled={submitDisabled}
                loading={loading || submitLoading}
                onClick={onSubmit}
              >
                {submitLabel}
              </Button>
            ) : null}
          </StyledButtonsContainer>
        </Actions>
      )}
    </Dialog>
  );
};
