import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { ENotificationType, INotification } from '@quesmed/types-rn/models';
import {
  IMarkNotificationAsReadData,
  IMarkNotificationAsReadVar,
  MARK_AS_READ,
  optimisticMarkAsRead,
  updateNotificatonsOnMarkAsRead,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import {
  NotificationContainer,
  NotificationMessageContainer,
  NotificationTime,
} from './Notification.styles';
import NotificationAvatar from './NotificationAvatar';
import NotificationMessage from './NotificationMessage';
import { getRelativeTime, parseDate } from 'utils';
import NotificationAdditionalContent from './NotificationAdditionalContent';
import { paths } from 'Router';
import { useDemo } from 'Auth';

interface NotificationProps {
  notification: INotification;
  markAllAsReadLoading: boolean;
}

export const Notification = ({
  notification,
  markAllAsReadLoading,
}: NotificationProps): JSX.Element => {
  const navigate = useNavigate();
  const {
    actionType,
    comment,
    createdAt,
    read,
    type: notificationType,
    id,
  } = notification;
  const isComment = notificationType === ENotificationType.COMMENT;
  const { question } = comment || {};
  const { id: questionId } = question || {};
  const isDemo = useDemo();
  const [markNotificationAsRead, { loading }] = useMutation<
    IMarkNotificationAsReadData,
    IMarkNotificationAsReadVar
  >(MARK_AS_READ);

  const markAsRead = useCallback(() => {
    if (loading || markAllAsReadLoading) {
      return;
    }
    if (!read) {
      markNotificationAsRead({
        variables: {
          id,
        },
        update: updateNotificatonsOnMarkAsRead,
        optimisticResponse: optimisticMarkAsRead(notification),
      });
    }

    if (isComment && questionId && !isDemo) {
      navigate(`${paths.questions.root}/question/${questionId}`);
    }
  }, [
    loading,
    markAllAsReadLoading,
    read,
    isComment,
    questionId,
    isDemo,
    markNotificationAsRead,
    id,
    notification,
    navigate,
  ]);

  return (
    <NotificationContainer
      className={clsx({ unread: !read, comment: isComment })}
      onClick={markAsRead}
    >
      <NotificationAvatar
        actionType={actionType}
        notificationType={notificationType}
      />
      <NotificationMessageContainer>
        <NotificationMessage notification={notification} />
        <NotificationAdditionalContent
          comment={comment?.comment}
          notificationType={notificationType}
        />
        {Boolean(createdAt) && (
          <NotificationTime small>
            {getRelativeTime(parseDate(createdAt))}
          </NotificationTime>
        )}
      </NotificationMessageContainer>
    </NotificationContainer>
  );
};
