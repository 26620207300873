import React from 'react';

import { useAgoraAPI } from './AgoraAPI';
import { useAgoraSetState, useAgoraState } from './AgoraState';
import { CameraIcon } from 'components/Icons';
import AudioVideoButton from './AudioVideoButton';

export interface CameraButtonProps {
  connected?: boolean;
}

const CameraButton = ({
  connected: sourceConnected,
}: CameraButtonProps): JSX.Element => {
  const { videoEnabled } = useAgoraState();
  const { setVideoEnabled } = useAgoraSetState();
  const { isConnected } = useAgoraAPI();
  const connected =
    sourceConnected !== undefined ? sourceConnected : isConnected();

  const handleOnClick = () => {
    if (connected) {
      setVideoEnabled(!videoEnabled);
    }
  };

  return (
    <AudioVideoButton
      active={connected && videoEnabled}
      disabled={!connected}
      onClick={handleOnClick}
    >
      <CameraIcon video={!connected || !videoEnabled} />
    </AudioVideoButton>
  );
};

export default CameraButton;
