import {
  Nullable,
  ProductType,
  SubscriptionsState,
  WithSubscriptionDates,
} from 'types';
import { keys } from 'utils/object/keys';
import { isSubscriptionActive } from './isSubscriptionActive';

const SUBSCRIPTION_KEY_POSTFIX = 'SubscriptionEndDate';

export const getSubscriptions = (
  token: Nullable<WithSubscriptionDates>
): SubscriptionsState => {
  if (token) {
    return keys(token)
      .filter((key): key is ProductType =>
        key.includes(SUBSCRIPTION_KEY_POSTFIX)
      )
      .reduce((acc, key) => {
        acc[key] = isSubscriptionActive(token[key]);

        return acc;
      }, {} as SubscriptionsState);
  }

  return {} as SubscriptionsState;
};
