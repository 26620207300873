import React from 'react';
import { mdiCogOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const SettingsIcon = (props: IconProps) => (
  <Icon path={mdiCogOutline} {...props} />
);

export default SettingsIcon;
