import React, { FC, PropsWithChildren } from 'react';
import { createBrowserHistory } from 'history';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

export const history = createBrowserHistory({ window });

interface NavigateOptions {
  replace?: boolean;
  state?: any;
}

export const navigate = (path: string, options?: NavigateOptions) => {
  const { replace, state } = options || {};

  history[replace ? 'replace' : 'push'](path, state);
};

export const Router: FC<PropsWithChildren> = ({
  children,
  ...props
}): JSX.Element => (
  // @ts-expect-error - https://github.com/remix-run/react-router/issues/9422#issuecomment-1302146568
  <HistoryRouter history={history} {...props}>
    {children}
  </HistoryRouter>
);
