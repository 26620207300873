import React, { MutableRefObject, Ref, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { Nullable, Utils } from 'types';
import { UtilPopupButton } from 'components/UtilButton';

interface UtilButtonGroupProps {
  utils: Utils[];
  anchorRef: Ref<HTMLElement>;
  quizProgressBar?: boolean;
}

const UtilsButtonContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    gap: spacing(1),
    marginLeft: spacing(-2),

    [breakpoints.up('md')]: {
      gap: 0,
      marginLeft: spacing(-3),
    },
  })
);

export const UtilButtonGroup = ({
  utils,
  anchorRef,
  quizProgressBar,
}: UtilButtonGroupProps): JSX.Element => {
  const [currentOpenedUtil, setCurrentOpenedUtil] =
    useState<Nullable<string>>(null);

  const handleOpen = (util: Utils) => () => {
    setCurrentOpenedUtil(util);
  };

  const handleClose = () => {
    setCurrentOpenedUtil(null);
  };

  const utilAnchorEl = (anchorRef as MutableRefObject<HTMLDivElement>)?.current;

  return (
    <UtilsButtonContainer>
      {utils?.map(util => (
        <UtilPopupButton
          anchorEl={utilAnchorEl}
          key={util}
          onClose={handleClose}
          onOpen={handleOpen(util)}
          quizProgressBar={quizProgressBar}
          util={util}
          utilOpen={currentOpenedUtil === util}
        />
      ))}
    </UtilsButtonContainer>
  );
};

export default UtilButtonGroup;
