import { create } from 'zustand';

interface GroupStudyGuardState {
  guardActive: boolean;
  setGuardActive: (value: boolean) => void;
}

const useGroupStudyGuardState = create<GroupStudyGuardState>()(set => ({
  guardActive: false,
  setGuardActive: guardActive => set({ guardActive }),
}));

export default useGroupStudyGuardState;
