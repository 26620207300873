import React from 'react';
import { ENotificationType } from '@quesmed/types-rn/models';

import {
  CommentPreview,
  NotificationActions,
  NotificationAdditionalContentWrapper,
} from './Notification.styles';
import { Button } from 'components/Button';
import { Undefinable } from 'types';

interface NotificationContentProps {
  notificationType: ENotificationType;
  comment: Undefinable<string>;
}

const getNotificationAdditionalContent = (
  type: ENotificationType,
  comment: Undefinable<string>
) => {
  switch (type) {
    case ENotificationType.COMMENT:
      return <CommentPreview small>{comment}</CommentPreview>;
    case ENotificationType.FRIEND_REQUEST:
      return (
        <NotificationActions>
          <Button secondary>Reject</Button>
          <Button>Accept</Button>
        </NotificationActions>
      );
    default:
      return null;
  }
};

const NotificationAdditionalContent = ({
  notificationType,
  comment,
}: NotificationContentProps): JSX.Element => {
  const content = getNotificationAdditionalContent(notificationType, comment);

  return (
    <>
      {content ? (
        <NotificationAdditionalContentWrapper>
          {content}
        </NotificationAdditionalContentWrapper>
      ) : null}
    </>
  );
};

export default NotificationAdditionalContent;
