import React, { forwardRef } from 'react';
import { mdiProgressClose } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ProgressIncorrectIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <Icon path={mdiProgressClose} ref={ref} {...props} />
);

ProgressIncorrectIcon.displayName = 'ProgressIncorrectIcon';

export default ProgressIncorrectIcon;
