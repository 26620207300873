import { useCallback } from 'react';
import { ApolloCache, useMutation } from '@apollo/client';
import {
  BUILD_MARKSHEET,
  IBuildMarksheetData,
  IBuildMarksheetInput,
  IBuildMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  ApolloUpdateOptions,
  ApolloUpdateResultRestricted,
} from '@quesmed/types-rn';
import {
  IMarksheetData,
  IMarksheetVar,
  MARKSHEET,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { OnRequestComplete } from 'types';
import { addSentryBreadcrumb, setSentryTag } from 'utils';

const updateCacheOnBuild = (
  cache: ApolloCache<any>,
  result: ApolloUpdateResultRestricted<IBuildMarksheetData>,
  options: ApolloUpdateOptions
) => {
  const { buildMarksheet } = result?.data?.restricted || {};
  const { variables } = options || {};

  if (!variables || !buildMarksheet) {
    return;
  }

  const { id, source } = buildMarksheet || {};

  if (id) {
    const prevData = cache.readQuery<IMarksheetData, IMarksheetVar>({
      variables: { id: Number(id) },
      query: MARKSHEET,
    });

    if (prevData) {
      const { marksheet } = prevData.restricted || {};

      cache.writeQuery({
        query: MARKSHEET,
        variables: { id },
        data: {
          ...prevData,
          restricted: {
            ...prevData.restricted,
            marksheet: {
              ...marksheet,
              ...buildMarksheet,
              // TODO Pass source created dynamicaly base on question category
              // and type of the quiz: solo and group
              source: source === 'Quiz' ? 'QBank Group Study' : source,
            },
          },
        },
      });
    }
  }
};

const useBuildMarksheetMutation = (
  onBuildComplete?: OnRequestComplete<IBuildMarksheetData>,
  marksheetId?: number
) => {
  const [build, { loading: buildLoading }] = useMutation<
    IBuildMarksheetData,
    IBuildMarksheetVar
  >(BUILD_MARKSHEET, {
    onCompleted: data => {
      if (onBuildComplete) {
        onBuildComplete(data);
        setSentryTag('marksheetId', data.restricted.buildMarksheet.id);
      }
    },
    update: updateCacheOnBuild,
  });

  const buildMarksheet = useCallback(
    async (params: IBuildMarksheetInput) => {
      addSentryBreadcrumb({
        category: 'marksheet',
        message: 'Building Marksheet',
      });
      const { secondsPerQuestion, ...rest } = params;
      const input: IBuildMarksheetInput = rest;

      if (marksheetId) {
        input.marksheetId = marksheetId;
      }

      if (secondsPerQuestion) {
        input.secondsPerQuestion = secondsPerQuestion;
      }

      return build({
        variables: {
          input,
        },
      });
    },
    [build, marksheetId]
  );

  return { buildLoading, buildMarksheet };
};

export default useBuildMarksheetMutation;
