import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormField, TextInputProps } from 'components/TextField';
import { CloseIcon, SearchIcon } from 'components/Icons';
import { IconButton, IconButtonProps } from 'components/IconButton';
import { useSnackbar } from 'components/Snackbar';
import { CircularProgress } from 'components/CircularProgress';
import { SearchKey, useSearchState } from './useSearchState';

interface SearchForm {
  searchString: string;
}

const EndAdornmentButton = ({
  loading,
  ...props
}: Omit<IconButtonProps, 'end' | 'icon' | 'children'> & {
  loading: boolean;
}): JSX.Element => (
  <IconButton
    edge="end"
    icon={loading ? <CircularProgress color="primary" /> : <CloseIcon />}
    {...props}
  />
);

export interface SearchFieldProps {
  autoFocus?: boolean;
  delayInMs?: number;
  resetSearch?: boolean;
  onResetSearch?: () => void;
  onSearchPerformed?: (value: boolean) => void;
  onSearch: (text: string) => Promise<void>;
  placeholder?: string;
  searchKey?: SearchKey;
}

type InputProps = Pick<TextInputProps, 'sx' | 'fullWidth' | 'withBackground'>;

export const SearchField = ({
  autoFocus = false,
  onResetSearch,
  onSearch,
  delayInMs,
  placeholder = 'search',
  searchKey = SearchKey.Global,
  ...props
}: SearchFieldProps & InputProps): JSX.Element => {
  const {
    initSearchState,
    setSearchPerformed,
    setSearchString,
    resetSearchState,
    getResetSearch,
    getSearchString,
  } = useSearchState();
  const searchString = getSearchString(searchKey);
  const resetSearch = getResetSearch(searchKey);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    formState,
    reset,
    watch,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<SearchForm>({
    defaultValues: {
      searchString: '',
    },
    mode: 'onTouched',
  });
  const { isDirty } = formState;

  const handleResetSearch = useCallback(() => {
    reset();

    if (onResetSearch) {
      onResetSearch();
    }
    setSearchString(searchKey, '');
    setSearchPerformed(searchKey, false);
  }, [onResetSearch, reset, setSearchPerformed, setSearchString, searchKey]);

  const search = useCallback(
    async (searchString: string) => {
      setLoading(true);
      try {
        await onSearch(searchString);
      } catch {
        enqueueSnackbar('Search failed');
      }

      setSearchPerformed(searchKey, true);

      setLoading(false);
    },
    [enqueueSnackbar, onSearch, setSearchPerformed, searchKey]
  );

  const submitSearch = useMemo(
    () =>
      handleSubmit((data: SearchForm) => {
        const { searchString } = data;

        const searchValue = searchString.trim();

        if (searchValue !== '') {
          search(searchValue);
        }
      }),
    [handleSubmit, search]
  );

  useEffect(() => {
    const localValue = getValues('searchString');

    if (!localValue || searchString !== localValue) {
      setValue('searchString', searchString);
    }
  }, [getValues, searchString, setValue]);

  useEffect(() => {
    initSearchState(searchKey);
  }, [initSearchState, searchKey]);

  useEffect(() => {
    if (!resetSearch) {
      return;
    }
    handleResetSearch();
  }, [handleResetSearch, resetSearch]);

  useEffect(() => {
    if (delayInMs) {
      let timeout = 0;
      const subscription = watch(data => {
        clearTimeout(timeout);

        const { searchString = '' } = data;

        timeout = window.setTimeout(() => {
          search(searchString);
        }, delayInMs);
      });

      return () => {
        subscription.unsubscribe();
        clearTimeout(timeout);
      };
    }
  }, [watch, onSearch, delayInMs, search]);

  useEffect(() => {
    return () => {
      resetSearchState(searchKey);
    };
  }, [resetSearchState, searchKey]);

  return (
    <form onSubmit={submitSearch} role="search">
      <FormField
        InputProps={{
          endAdornment: isDirty ? (
            <EndAdornmentButton loading={loading} onClick={handleResetSearch} />
          ) : null,
          readOnly: loading,
          startAdornment: <SearchIcon />,
        }}
        autoFocus={autoFocus}
        control={control}
        disabled={loading}
        name="searchString"
        placeholder={placeholder}
        {...props}
      />
    </form>
  );
};
