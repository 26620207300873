import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { EOsceMarksheetState } from '@quesmed/types-rn/models';

import { Nullable, StationRoleData } from 'types';

const ParticipantRole = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
    color: palette.text.secondary,
    ...typography.body1Medium,

    '& > .MuiSvgIcon-root': {
      color: palette.icon.main,
    },
  })
);

interface ParticipantRoleInfoProps {
  currentUser: boolean;
  roleData: Nullable<StationRoleData>;
  isCandidate?: boolean;
  state?: EOsceMarksheetState;
}

const ParticipantRoleInfo = ({
  currentUser,
  isCandidate,
  roleData,
  state,
}: ParticipantRoleInfoProps): JSX.Element => {
  const { name, icon } = roleData || {};

  if (state === EOsceMarksheetState.PRESTART) {
    return (
      <ParticipantRole>
        {isCandidate ? 'Preparing...' : 'Waiting...'}
      </ParticipantRole>
    );
  }

  return (
    <ParticipantRole>
      Role:&nbsp;
      {name && icon ? (
        <>
          {name} {icon}
        </>
      ) : (
        `Select${currentUser ? '' : 's'} a role...`
      )}
    </ParticipantRole>
  );
};

export default ParticipantRoleInfo;
