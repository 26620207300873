import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';

import { InviteFriendsModal } from 'components/InviteFriendsModal';
import { RoundedButton } from 'components/Button';
import { Popper } from 'components/Popper';
import {
  ChevronDownIcon,
  HelpCircleOutlineIcon,
  InviteFriendIcon,
  LogoutIcon,
  ProfileIcon,
  SettingsIcon,
} from 'components/Icons';
import { useHover, useIsMobile, usePrevious } from 'hooks';
import { logoutUser, useCurrentUser } from 'Auth';
import { useAppState } from 'context/AppState';
import {
  Settings,
  SettingsModal,
  useSettingsModalState,
} from 'components/Settings';
import { useNotificationsState } from 'components/Notifications';
import { IconBadge } from 'components/IconBadge';
import { DiscardChangesModal } from 'components/DiscardChangesModal';
import {
  MobileProfileButtonEntries,
  ProfileListItem,
  StyledDivider,
} from './MobileProfileButtonEntries';
import { Nullable } from 'types';
import { useFeedbackModal } from 'components/FeedbackModal';
import { Body } from 'components/Typography';

interface ProfileButtonProps {
  hasProduct?: boolean;
}

const StyledButton = styled(RoundedButton)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    backgroundColor: palette.background.paper,
    minWidth: spacing(18),
    width: 'unset',

    '& .display-name': {
      display: 'none',

      [breakpoints.up('lg')]: {
        color: palette.text.secondary,
        display: 'block',
      },
    },
  })
);

const ProfileButton = ({ hasProduct }: ProfileButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);
  const [isInviteFriendsOpen, setIsInviteFriendsOpen] = useState(false);
  const { openFeedbackModal } = useFeedbackModal();
  const [isSettingsFormDirty, setIsSettingsFormDirty] = useState(false);
  const {
    shownModal,
    openModal,
    closeModal,
    setIsSettingsOpen,
    isSettingsOpen,
  } = useSettingsModalState();
  const isOpen = Boolean(anchorEl);
  const { logoutCallback } = useAppState();
  const { isMobile } = useIsMobile();
  const { badge } = useNotificationsState();
  const { displayName = '' } = useCurrentUser();

  const [ref, value] = useHover<HTMLButtonElement>();
  const prevValue = usePrevious(value);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    if (logoutCallback) {
      await logoutCallback();
    }
    await logoutUser();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };

  const handleOpenDiscardModal = () => {
    openModal(SettingsModal.DiscardProfileButton);
  };

  const handleCloseSettings = () => {
    if (isSettingsFormDirty) {
      handleOpenDiscardModal();

      return;
    }
    setIsSettingsOpen(false);
  };

  const handleDiscardChanges = () => {
    setIsSettingsOpen(false);
    closeModal();
  };

  const handleOpenInviteFriendsModal = () => {
    setIsInviteFriendsOpen(true);
  };

  const handleCloseInviteFriendsModal = () => {
    setIsInviteFriendsOpen(false);
  };

  useEffect(() => {
    if (isOpen && prevValue && !value) {
      setAnchorEl(null);
    }
  }, [value, isOpen, prevValue]);

  return (
    <>
      <StyledButton
        aria-controls={isOpen ? 'account-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="account-menu"
        onClick={isOpen ? undefined : handleOpenMenu}
        ref={ref}
      >
        <IconBadge invisible={!isMobile || !badge}>
          <ProfileIcon />
        </IconBadge>
        <Body className="display-name">{displayName}</Body>
        <ChevronDownIcon />
        <Popper
          anchorEl={anchorEl}
          disablePortal
          onClick={handleCloseMenu}
          onClose={handleCloseMenu}
          open={isOpen}
          paperSx={({ spacing }) => ({
            marginTop: spacing(2),
            padding: spacing(0, 2),
            width: '224px',
          })}
          placement="bottom-end"
        >
          <List>
            {hasProduct ? <MobileProfileButtonEntries /> : null}
            <ProfileListItem
              icon={<SettingsIcon />}
              label="Settings"
              onClick={handleOpenSettings}
            />
            {hasProduct ? (
              <>
                <StyledDivider />
                <ProfileListItem
                  icon={<InviteFriendIcon />}
                  label="Invite a Friend"
                  onClick={handleOpenInviteFriendsModal}
                />
                <ProfileListItem
                  icon={<HelpCircleOutlineIcon />}
                  label="Help & Support"
                  onClick={openFeedbackModal}
                />
              </>
            ) : null}
            <StyledDivider />
            <ProfileListItem
              icon={<LogoutIcon />}
              label="Log out"
              onClick={handleLogout}
            />
          </List>
        </Popper>
      </StyledButton>
      {isSettingsOpen ? (
        <Settings
          isSettingsFormDirty={isSettingsFormDirty}
          onClose={handleCloseSettings}
          open={isSettingsOpen}
          setIsSettingsFormDirty={setIsSettingsFormDirty}
        />
      ) : null}
      <DiscardChangesModal
        onClose={closeModal}
        onSubmit={handleDiscardChanges}
        open={shownModal === SettingsModal.DiscardProfileButton}
      />
      {isInviteFriendsOpen ? (
        <InviteFriendsModal
          onClose={handleCloseInviteFriendsModal}
          open={isInviteFriendsOpen}
        />
      ) : null}
    </>
  );
};

export default ProfileButton;
