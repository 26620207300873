import React, { ChangeEvent } from 'react';

import { CellLabelProps, CoreCellLabelProps } from 'components/GridCell';
import { ProgressLineChartProps } from 'components/ProgressLineChart';
import { ProgressScoreBadgeProps } from 'components/ProgressScoreBadge';
import { CellAllignment, CellType, CellValue, TableData } from 'types';

export enum KeyField {
  Chevron = 'chevron',
  Checkbox = 'checkbox',
  Label = 'label',
  LabelIcon = 'labelIcon',
  Concept = 'concept',
  Topic = 'topic',
  Added = 'added',
  Radio = 'radio',
  Actions = 'actions',
  NotAnswered = 'notAnswered',
  Performed = 'performed',
  NotPerformed = 'notPerformed',
  Score = 'score',
  Progress = 'progress',
  MinifiedProgress = 'minifiedProgress',
  KnowledgeIcon = 'knowledgeIcon',
}

export interface SelectionColumn<T extends TableData> extends CellLabelProps {
  align: CellAllignment;
  formatter?: (value: T, i?: number) => CellValue;
  key: keyof T;
  type: CellType;
  scoreFormatter?: (value: T) => ProgressScoreBadgeProps;
  valueFormatter?: (value: T[keyof T]) => CellValue;
  progressFormatter?: (value: T, isPaces?: boolean) => ProgressLineChartProps;
  progressLabels?: CoreCellLabelProps[];
  minWidth?: number;
  nestedItemsKey?: keyof T;
}

export type SelectItemHandler<T = HTMLInputElement | HTMLTextAreaElement> = (
  event: ChangeEvent<T> | React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;

export type ClickHandler<T = HTMLElement> = (
  event: React.MouseEvent<T, MouseEvent>
) => void;

export enum Tabs {
  Builder = 'Builder',
  Presets = 'Presets',
}
