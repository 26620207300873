import React from 'react';
import { mdiAccountOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const AccountOutlineIcon = (props: IconProps) => (
  <Icon path={mdiAccountOutline} {...props} />
);

export default AccountOutlineIcon;
