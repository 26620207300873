import React, { forwardRef } from 'react';
import MuiPopper, { PopperProps as MuiPopperProps } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { PaperProps } from '@mui/material/Paper';

import { Children } from 'types';
import { DropdownPaper } from 'components/Dropdown';

export interface PopperProps extends MuiPopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  fadeTimeout?: number;
  onClose?: (event: Event | React.SyntheticEvent) => void;
  children: Children;
  width?: number;
  clickOutsideToClose?: boolean;
  paperSx?: PaperProps['sx'];
}

const Popper = forwardRef<HTMLDivElement, PopperProps>(
  (
    {
      anchorEl,
      children,
      open,
      onClose,
      paperSx,
      placement = 'right-start',
      fadeTimeout = 350,
      clickOutsideToClose,
      ...props
    },
    ref
  ): JSX.Element => {
    return (
      <MuiPopper
        anchorEl={anchorEl}
        open={open}
        placement={placement}
        ref={ref}
        sx={({ zIndex }) => ({ zIndex: zIndex.tooltip })}
        transition
        {...props}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={fadeTimeout}>
            <DropdownPaper sx={paperSx}>
              {onClose && clickOutsideToClose ? (
                <ClickAwayListener onClickAway={onClose}>
                  <div>{children}</div>
                </ClickAwayListener>
              ) : (
                children
              )}
            </DropdownPaper>
          </Fade>
        )}
      </MuiPopper>
    );
  }
);

Popper.displayName = 'Popper';

export default Popper;
