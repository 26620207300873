import React from 'react';
import { mdiHumanMaleBoard } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ExaminerRoleIcon = (props: IconProps) => (
  <Icon path={mdiHumanMaleBoard} {...props} />
);

export default ExaminerRoleIcon;
