import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ApolloClient, ApolloProvider as Provider } from '@apollo/client';

import { offlineLink, splitLink } from './link';
import { cache, persistor } from './cache';
import { CircularProgress } from 'components/CircularProgress';
import { useSnackbar } from 'components/Snackbar';
import { isDev } from '../config/constants';
import { appClient } from './appClient';

const useClientInit = () => {
  const [client, setClient] = useState<ApolloClient<object>>();
  const { enqueueSnackbar } = useSnackbar();

  const link = useMemo(() => splitLink(enqueueSnackbar), [enqueueSnackbar]);

  useEffect(() => {
    async function init() {
      await persistor.restore();

      const client = new ApolloClient({
        link,
        cache,
        connectToDevTools: isDev,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
          },
          mutate: {
            errorPolicy: 'all',
          },
        },
      });

      offlineLink.setup(client);

      client.onClearStore(async () => {
        await persistor.purge();
      });

      setClient(client);
      appClient.setState({ client });
    }

    init();
  }, [link]);

  return client;
};

interface ApolloProviderProps {
  children: ReactNode;
}

export const ApolloProvider = ({
  children,
}: ApolloProviderProps): JSX.Element => {
  const client = useClientInit();

  if (!client) {
    return <CircularProgress description="Initializing app..." />;
  }

  return <Provider client={client}>{children}</Provider>;
};
