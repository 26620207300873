import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { H6 } from 'components/Typography';
import { Button } from 'components/Button';
import { SearchKey, useSearchState } from 'components/SearchField';
import { Fallback, SearchPhrase } from './Search.styles';

type Variant = 'no-padding' | 'with-padding';

const TITLE = 'No results found';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(2),

  '&.with-padding': {
    padding: spacing(8),
  },
}));

const FallbackContainer = styled(Container)(({ theme: { spacing } }) => ({
  maxWidth: '480px',
  gap: spacing(6),
}));

type SearchFallbackProps = {
  withFallback?: boolean;
  title?: string;
  searchKey?: SearchKey;
  text?: string;
  variant?: Variant;
};

const SearchFallback = ({
  searchKey = SearchKey.Global,
  title = TITLE,
  text,
  variant = 'no-padding',
  withFallback,
}: SearchFallbackProps): JSX.Element => {
  const { setResetSearch, getSearchString } = useSearchState();
  const handleClearSearch = () => setResetSearch(searchKey, true);

  const searchString = getSearchString(searchKey);

  return (
    <Container className={variant}>
      <H6>{title ? title : 'No results found'}</H6>
      {withFallback || text ? (
        <FallbackContainer>
          <Fallback>
            {text ? (
              text
            ) : (
              <>
                Sorry, we couldn’t find any results for the phrase:&nbsp;
                <SearchPhrase component="span">{`'${searchString}'`}</SearchPhrase>
                . Please check your spelling or try different search keywords.
              </>
            )}
          </Fallback>
          {withFallback ? (
            <Button onClick={handleClearSearch} secondary>
              Clear Search
            </Button>
          ) : null}
        </FallbackContainer>
      ) : null}
    </Container>
  );
};

export default SearchFallback;
