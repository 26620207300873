import React, { ForwardedRef, forwardRef, MutableRefObject } from 'react';
import { Controller, FieldValues, RefCallBack } from 'react-hook-form';

import { Nullable } from 'types';
import { TextFieldProps, TextInputProps, TextInputRef } from './TextField';

export type FormFieldProps<T extends FieldValues> = TextFieldProps<T> &
  TextInputProps;

const assignRefs =
  (inputRef: ForwardedRef<HTMLInputElement>, ref: RefCallBack) =>
  (element: Nullable<HTMLInputElement>) => {
    ref(element);
    if (inputRef && element) {
      (inputRef as MutableRefObject<HTMLInputElement>).current = element;
    }
  };

function Field<T extends FieldValues>(
  {
    control,
    defaultValue,
    rules,
    name,
    helperText,
    shouldUnregister,
    type,
    allowENumber = false,
    ...rest
  }: FormFieldProps<T>,
  inputRef: ForwardedRef<HTMLInputElement>
): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onBlur, onChange, ref: refCallback, value },
        fieldState: { error },
      }) => (
        <TextInputRef
          InputLabelProps={{ shrink: value === 0 || Boolean(value) }}
          {...rest}
          allowENumber={allowENumber}
          error={Boolean(error)}
          helperText={error ? error.message : helperText}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={assignRefs(inputRef, refCallback)}
          type={type}
          value={value}
        />
      )}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}

export const FormField = forwardRef(Field) as <T extends FieldValues>(
  props: FormFieldProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof Field>;
