import { useEffect, useRef } from 'react';

// custom hook for getting previous value
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);
  // Need not pass value : useRef<T>(); will work with useEffect with no deps
  // One extra save for typescipt infer type without undefined

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
