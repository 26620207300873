import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import NavigationLink from './NavigationItem';
import { NavigationHeader } from './NavigationHeader';
import { NavigationSection } from './types';

interface NavigationProps {
  expanded: boolean;
  navigationEntries: NavigationSection[];
  onItemClick?: () => void;
}

const Navigation = ({
  expanded,
  navigationEntries,
  onItemClick,
}: NavigationProps): JSX.Element => (
  <List aria-label="main-navigation" component="nav">
    {navigationEntries.map(({ title, items }) => (
      <Box
        key={title}
        sx={({ spacing }) => ({
          '&:not(:last-of-type)': {
            marginBottom: spacing(5),
          },
        })}
      >
        <NavigationHeader
          expanded={expanded}
          sx={({ spacing }) => ({
            paddingLeft: '18px', // value to align text to the sub-items
            paddingBottom: spacing(3),
          })}
        >
          {title}
        </NavigationHeader>
        <List sx={{ padding: 0 }}>
          {items.map(({ icon, items: links, label, path = '' }) => (
            <li key={label + path} onClick={onItemClick}>
              <NavigationLink
                expanded={expanded}
                icon={icon}
                label={label}
                links={links ? { links } : undefined}
                path={path}
              />
            </li>
          ))}
        </List>
      </Box>
    ))}
  </List>
);

export default Navigation;
