import React from 'react';
import { mdiArrowExpandVertical } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ExpandVerticalIcon = (props: IconProps) => (
  <Icon path={mdiArrowExpandVertical} {...props} />
);

export default ExpandVerticalIcon;
