import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import { Button } from 'components/Button';

interface ContentProps extends BoxProps {
  expanded: 0 | 1;
  open: 0 | 1;
}

const ContentBox = styled(Box)<ContentProps>(
  ({ expanded, open, theme: { breakpoints, mixins, transitions } }) => ({
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    paddingTop: `${mixins.appBar.mobile.minHeight}px`,
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),

    [breakpoints.up('md')]: {
      paddingTop: `${mixins.appBar.desktop.minHeight}px`,
    },

    [breakpoints.up('xl')]: {
      width: open
        ? `calc(100% - ${
            mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth
          }px)`
        : '100%',
      marginLeft: open
        ? mixins.sidebar[expanded ? 'expanded' : 'collapsed'].minWidth
        : 0,
    },
  })
);

export const Content = ({ children, ...props }: ContentProps): JSX.Element => (
  <ContentBox {...props}>{children}</ContentBox>
);

export const AppBar = styled(MuiAppBar)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar },
      palette,
      spacing,
      transitions,
    },
  }) => ({
    minHeight: appBar.mobile.minHeight,
    padding: spacing(0, 4),
    boxSizing: 'border-box',

    '[aria-label=navigation]': {
      display: 'inline-flex',
    },

    '& .app-logo': {
      '& .logo-image': {
        width: '34px',
      },

      '& .logo-text': {
        width: '111px',
      },
    },

    '[aria-label=apps-management]': {
      display: 'none',
    },

    [breakpoints.up('md')]: {
      minHeight: appBar.desktop.minHeight,
      padding: spacing(0, 6),

      '& .app-logo': {
        '& .logo-image': {
          width: 'initial',
        },

        '& .logo-text': {
          width: 'initial',
        },
      },
    },

    [breakpoints.up('xl')]: {
      '[aria-label=navigation]': {
        display: 'none',
      },

      '[aria-label=apps-management]': {
        display: 'inline-flex',
      },

      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      flexShrink: 0,
    },

    '&.MuiPaper-root.MuiAppBar-root': {
      boxShadow: 'none',
      backgroundImage: 'none',
      color: palette.text.primary,
      borderBottom: `1px solid ${palette.stroke.main}`,
      backgroundColor: palette.background.paper,
    },

    '&.mock-test-mode': {
      '&.MuiPaper-root.MuiAppBar-root': {
        backgroundColor: palette.mockTest.main,
      },
    },
  })
);

export const Toolbar = styled(MuiToolbar)(
  ({ theme: { breakpoints, spacing } }) => ({
    padding: spacing(0),

    [breakpoints.up('sm')]: {
      padding: spacing(0),
    },
  })
);

export const RightControls = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    marginLeft: 'auto',
    display: 'flex',

    '& > *:not(:last-of-type)': {
      display: 'none',
    },

    '& > *:last-of-type': {
      marginLeft: spacing(0.5),
    },

    [breakpoints.up('md')]: {
      '& > *:not(:last-of-type)': {
        display: 'inline-flex',
        marginRight: spacing(1.5),
      },

      '& > *:last-of-type': {
        marginLeft: spacing(1.5),
      },

      '& > *:first-of-type': {
        marginRight: spacing(3),
      },
    },

    '& .hide': {
      display: 'none',
    },
  })
);

export const LeaveButton = styled(Button)(
  ({ theme: { palette, spacing } }) => ({
    borderRadius: '100px',
    height: spacing(12),
    flexGrow: 1,
    gap: spacing(2),

    '&.MuiLoadingButton-root.MuiButton-outlinedSecondary': {
      backgroundColor: palette.background.paper,

      '&:hover': {
        backgroundColor: palette.error.main,
        borderColor: palette.error.main,
        color: palette.error.contrastText,
      },
    },
  })
);
