import { Nullable, StationRole } from 'types';
import { ParticipantStatus } from './types';

const getParticipantStatus = (
  withAudio: boolean,
  connected: boolean,
  role?: Nullable<StationRole>
): ParticipantStatus => {
  if (!connected && withAudio) {
    return ParticipantStatus.NoPermissions;
  }

  if (role === null) {
    return ParticipantStatus.RoleRequired;
  }

  return ParticipantStatus.Active;
};

export default getParticipantStatus;
