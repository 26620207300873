import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

import { LogoImage, LogoText } from './components';
import { Link } from 'components/Link';
import { paths } from 'Router';
import { usePlatform } from 'context/PlatformState';
import { useAuth } from 'Auth';

const LogoBox = styled(Link)(({ theme: { palette } }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '&.disabled': {
    pointerEvents: 'none',
  },
  '&.mock-test-mode .logo-text': {
    path: { fill: palette.mockTest.contrastText },
  },
}));

const TextInLogo = styled(LogoText)(({ theme: { palette, spacing } }) => ({
  marginLeft: spacing(2),
  path: {
    fill: palette.text.primary,
  },
}));

interface LogoProps {
  disableNavigation?: boolean;
  showText?: boolean;
  size?: number;
  className?: string;
}

const DEFAULT_LOGO_SIZE = 38;

const Logo = ({
  disableNavigation,
  showText = true,
  size = DEFAULT_LOGO_SIZE,
  className,
}: LogoProps): JSX.Element => {
  const { product } = usePlatform();
  const { isAuthenticated } = useAuth();
  const authenticatedPath = product ? paths.dashboard : paths.appsManagement;
  const path = isAuthenticated ? authenticatedPath : paths.login;

  return (
    <LogoBox
      className={clsx('app-logo', className, { disabled: disableNavigation })}
      tabIndex={disableNavigation ? -1 : 1}
      to={path}
    >
      <title>QuesMed</title>
      <LogoImage className="logo-image" size={size} />
      {showText && <TextInLogo className="logo-text" />}
    </LogoBox>
  );
};

export default Logo;
