import React from 'react';
import {
  MaterialDesignContent,
  SnackbarProvider as Provider,
  SnackbarProviderProps,
} from 'notistack';
import { styled } from '@mui/material/styles';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme: { palette, spacing, typography } }) => ({
    '&.notistack-MuiContent-default': {
      backgroundColor: palette.contrast.main,
      color: palette.contrast.contrastText,
      ...typography.body2Medium,
      padding: spacing(3, 4),
    },

    '& .MuiIconButton-root': {
      padding: spacing(0, 1),
    },

    '& #notistack-snackbar': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiSvgIcon-root': {
      color: palette.contrast.contrastText,
    },
  })
);

const SnackbarProvider = ({
  children,
}: Pick<SnackbarProviderProps, 'children'>): JSX.Element => (
  <Provider
    Components={{
      default: StyledMaterialDesignContent,
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    autoHideDuration={3000}
    hideIconVariant
    maxSnack={3}
    preventDuplicate
  >
    {children}
  </Provider>
);

export default SnackbarProvider;
