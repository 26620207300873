import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, FieldValues } from 'react-hook-form';

import { TextFieldProps, TextInputProps } from './TextField';

type DataPickerFieldProps<T extends FieldValues> = TextFieldProps<T> &
  Omit<DatePickerProps<Date>, 'renderInput' | 'onChange' | 'value'> & {
    inputProps: TextInputProps;
  };

export function DataPickerField<T extends FieldValues>({
  control,
  name,
  label,
  inputProps,
  ...rest
}: DataPickerFieldProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...rest}
            format="dd/MM/yyyy"
            label={label}
            onChange={onChange}
            slotProps={{ textField: { ...inputProps } }}
            value={value || null}
          />
        </LocalizationProvider>
      )}
    />
  );
}
