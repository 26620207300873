import React, { MouseEvent } from 'react';
import { IOsceStation, IVideo } from '@quesmed/types-rn/models';
import { Index } from 'react-instantsearch';

import SearchInfiniteHints from './SearchInfiniteHints';
import { SearchIndex } from './type';
import SearchResultSection, {
  SearchResultSectionProps,
} from './SearchResultSection';

interface SearchResultProps extends SearchResultSectionProps {
  indexName: SearchIndex;
  withHints?: boolean;
  stations?: IOsceStation[];
  videos?: IVideo[];
  noLink?: boolean;
  onItemClick: () => void;
  onClick?: () => void;
  onPractice?: (id: number) => (e: MouseEvent<HTMLAnchorElement>) => void;
}

export const SearchResult = ({
  indexName,
  stations,
  withHints,
  videos,
  onClick,
  onItemClick,
  onPractice,
  title,
  noLink,
  ...props
}: SearchResultProps): JSX.Element => (
  <Index indexName={indexName}>
    <SearchResultSection title={title} {...props} onClick={onClick}>
      {withHints ? (
        <SearchInfiniteHints
          indexName={indexName}
          onClick={onItemClick}
          onPractice={onPractice}
        />
      ) : null}
    </SearchResultSection>
  </Index>
);

export default SearchResult;
