import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  IResetProgressData,
  IResetProgressVar,
  RESET_PROGRESS,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useLocation } from 'react-router-dom';

import { Button } from 'components/Button';
import SettingsContentLayout from './SettingsContentLayout';
import SettingsSectionTitle from './SettingsSectionTitle';
import SettingsSectionDivider from './SettingsSectionDivider';
import { DiscardChangesModal } from 'components/DiscardChangesModal';
import { SettingsModal, useSettingsModalState } from './useSettingsModalState';
import { isAppManagementPage, localStorageTyped } from 'utils';

const { removeItem: removedDisabledHighlighting } = localStorageTyped<boolean>(
  'disabledHighlighting'
);

const SettingsAdvanced = (): JSX.Element => {
  const { shownModal, openModal, closeModal } = useSettingsModalState();
  const client = useApolloClient();
  const { pathname } = useLocation();

  const handleOpenClearCacheModal = () => {
    openModal(SettingsModal.ConfirmationClearCache);
  };

  const handleOpenClearProgressModal = () => {
    openModal(SettingsModal.ConfirmationClearProgress);
  };

  const [resetProgress, { loading: resetProgressLoading }] = useMutation<
    IResetProgressData,
    IResetProgressVar
  >(RESET_PROGRESS, {
    variables: { questions: true, cards: true },
    onCompleted: async () => {
      await client.clearStore();
      closeModal();
      window.location.reload();
    },
    onError: () => {
      closeModal();
    },
  });

  const handleClearCache = async () => {
    removedDisabledHighlighting();
    await client.clearStore();
    closeModal();
    window.location.reload();
  };

  const handleClearProgress = () => {
    resetProgress();
  };

  return (
    <>
      <SettingsContentLayout>
        <SettingsSectionTitle
          subtitle="Delete cookies and other local data stored on your device to improve performance. Your app settings will be reset to default."
          title="Clear browser data"
        />
        <Button danger onClick={handleOpenClearCacheModal} variant="outlined">
          clear cache
        </Button>
        {isAppManagementPage(pathname) ? null : (
          <>
            <SettingsSectionDivider />
            <SettingsSectionTitle
              subtitle="Delete all your saved data in the cloud to start fresh. You will lose all progress and data associated with your account. This action cannot be undone."
              title="Reset your progress"
            />
            <Button
              danger
              onClick={handleOpenClearProgressModal}
              variant="outlined"
            >
              clear progress data
            </Button>
          </>
        )}
      </SettingsContentLayout>
      <DiscardChangesModal
        onClose={closeModal}
        onSubmit={handleClearCache}
        open={shownModal === SettingsModal.ConfirmationClearCache}
        submitLabel="Clear cache"
        text="If you clean this data, your settings for this domain will be reset to defaults. The app's window wll be reloaded."
        title="Clear cache"
      />
      <DiscardChangesModal
        loading={resetProgressLoading}
        onClose={closeModal}
        onSubmit={handleClearProgress}
        open={shownModal === SettingsModal.ConfirmationClearProgress}
        submitLabel="clear progress data"
        text="If you clean this data, you will lose progress in your apps. Your entire set of statistics will be reset. The app's window wll be reloaded."
        title="Clear progress data"
      />
    </>
  );
};

export default SettingsAdvanced;
