import React from 'react';
import { mdiChevronLeftBoxOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ArrowCollapseLeftIcon = (props: IconProps) => (
  <Icon path={mdiChevronLeftBoxOutline} {...props} />
);

export default ArrowCollapseLeftIcon;
