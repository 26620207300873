import { FieldPolicy, Reference } from '@apollo/client';
import { ESortOrder } from '@quesmed/types-rn';
import isNil from 'lodash/isNil';

interface MergeParams {
  idField?: string;
  sortField: string;
  order?: ESortOrder;
}

export const createMerge =
  (params?: MergeParams): FieldPolicy['merge'] =>
  (
    existing: Reference[] = [],
    incoming: Reference[] = [],
    { readField, mergeObjects, variables }
  ) => {
    const {
      idField = 'id',
      sortField,
      order: customOrder = ESortOrder.ASC,
    } = params || {};

    const merged: Reference[] = existing ? existing.slice(0) : [];
    const itemsIndexMap = new Map<number, number>();

    if (existing) {
      existing.forEach((item, index) => {
        const value = readField<number>(idField, item);

        if (value) {
          itemsIndexMap.set(value, index);
        }
      });
    }

    incoming.forEach(item => {
      const identifier = readField<number>(idField, item) || 0;

      if (itemsIndexMap.has(identifier)) {
        const fieldIndex = itemsIndexMap.get(identifier) as number;
        merged[fieldIndex] = mergeObjects(merged[fieldIndex], item);
      } else {
        itemsIndexMap.set(identifier, merged.length);
        merged.push(item);
      }
    });

    if (sortField) {
      const { order } = variables || {};
      const finalOrder = !isNil(order) ? order : customOrder;

      return [...merged].sort((a, b) => {
        const aField = readField<string | number | Date>(sortField, a);
        const bField = readField<string | number | Date>(sortField, b);

        if (aField === bField) {
          return 0;
        }

        if (isNil(aField)) {
          return 1;
        }

        if (isNil(bField)) {
          return -1;
        }

        if (finalOrder === ESortOrder.ASC) {
          return aField < bField ? -1 : 1;
        }

        return aField < bField ? -1 : 1;
      });
    }

    return merged;
  };

export const mergeConcepts = createMerge({
  idField: 'id',
  sortField: 'name',
  order: ESortOrder.DESC,
});
