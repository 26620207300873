import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import MuiTextInputField, {
  TextFieldProps as MuiTextInputProps,
} from '@mui/material/TextField';
import { MenuProps } from '@mui/material';
import { ControllerProps, FieldValues } from 'react-hook-form';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { ChevronDownIcon } from '../Icons';

export const StyledSelect = styled(MuiTextInputField)<MuiTextInputProps>(
  ({ theme: { palette, typography, spacing, transitions } }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      ...typography.body1,
      borderColor: palette.stroke.main,
      transition: transitions.create(['color']),

      '& fieldset': {
        borderColor: palette.stroke.light,
        transition: transitions.create(['border-color', 'background-color']),
      },

      '.Mui-disabled': {
        '.MuiSelect-icon': {
          fill: palette.icon.light,
        },
        path: {
          fill: palette.icon.light,
        },
      },

      fieldset: {
        borderColor: palette.primary.main,
      },

      '&.MuiOutlinedInput-root': {
        borderRadius: '6px',
      },

      '&.Mui-focused': {
        fieldset: {
          borderColor: palette.stroke.dark,
          borderWidth: '1px',
        },
      },

      '& div.MuiSelect-select': {
        padding: spacing(3, 12, 2, 4),
        ...typography.button,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.stroke.main,
        borderWidth: '1px',
      },

      '& .MuiSvgIcon-root': {
        path: {
          color: palette.icon.dark,
        },
        position: 'absolute',
        right: '16px',
        transition: transitions.create(['transform']),
      },
    },
  })
);

interface SelectOption {
  label: string;
  value: string;
}

type SelectOptions = SelectOption[];

interface CustomSelectProps {
  dropdownPosition?: 'top' | 'bottom';
  options: SelectOptions;
}

export type SelectProps = MuiTextInputProps & CustomSelectProps;

// (lines 87-88) - it defines the gap be between the dropdown and the select field
const DROPDOWN_BOTTOM_POSITION_Y = -5;
const DROPDOWN_TOP_POSITION_Y = 105;

const dropdownTopPosition: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  anchorPosition: {
    top: 200,
    left: 200,
  },
  transformOrigin: {
    vertical: DROPDOWN_TOP_POSITION_Y,
    horizontal: 'left',
  },
};

const menuDefaultProps: Partial<MenuProps> = {
  sx: ({ spacing, typography, elevation }) => ({
    '.MuiPaper-elevation': {
      padding: spacing(2),
      boxShadow: elevation[1],
    },
    '.MuiList-root': {
      padding: 0,
    },
    '.MuiMenuItem-root': {
      borderRadius: '7px',
      padding: spacing(2, 3),
      ...typography.body1,
    },
  }),
  transformOrigin: {
    vertical: DROPDOWN_BOTTOM_POSITION_Y,
    horizontal: 'center',
  },
};

export type SelectFieldProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
>;

export const SelectField = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      variant = 'outlined',
      size = 'medium',
      dropdownPosition = 'bottom',
      options,
      ...props
    },
    ref
  ): JSX.Element => {
    const menuProps =
      dropdownPosition === 'top'
        ? { ...menuDefaultProps, ...dropdownTopPosition }
        : menuDefaultProps;

    return (
      <StyledSelect
        SelectProps={{
          IconComponent: (props: SvgIconProps) => (
            <ChevronDownIcon {...props} />
          ),
          MenuProps: menuProps,
        }}
        inputRef={ref}
        select
        size={size}
        variant={variant}
        {...props}
      >
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    );
  }
);

SelectField.displayName = 'SelectField';
