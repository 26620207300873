import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  AGORA_TOKEN,
  IAgoraTokenData,
  IAgoraTokenVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  ClientConfig,
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';

import { useSnackbar } from 'components/Snackbar';
import { AGORA_APP_ID } from 'config/constants';
import {
  AgoraErrorCode,
  AgoraRTCError,
  createCameraVideoTrack,
  createClient,
  createMicrophoneAudioTrack,
} from './AgoraReact';
import { useAgoraSetState, useAgoraState } from './AgoraState';
import { AgoraAPI, AgoraConnectionState, AgoraMessages, Media } from './types';
import { Children } from 'types';
import { logError } from 'utils';

const initialAgoraAPIContext: AgoraAPI = {
  audioReady: false,
  audioTrack: {} as IMicrophoneAudioTrack,
  client: {} as IAgoraRTCClient,
  connect: () => Promise.resolve(),
  disconnect: () => Promise.resolve(),
  endSession: () => Promise.resolve(),
  isConnected: () => false,
  publishAudio: () => Promise.resolve(),
  publishVideo: () => Promise.resolve(),
  startAudio: () => Promise.resolve(),
  startVideo: () => Promise.resolve(),
  unpublishVideo: () => Promise.resolve(),
  videoReady: false,
  videoTrack: {} as ICameraVideoTrack,
};

const AgoraAPIContext = createContext<AgoraAPI>(initialAgoraAPIContext);

const { Provider: AgoraAPIContextProvider } = AgoraAPIContext;

const defaultConfig: ClientConfig = { mode: 'rtc', codec: 'vp8' };

const isPublished = (client: IAgoraRTCClient, mediaType: Media) =>
  client.localTracks.some(track => track.trackMediaType === mediaType);

const getClient = createClient(defaultConfig);
const useAudio = createMicrophoneAudioTrack();
const useVideo = createCameraVideoTrack();

export const AgoraAPIProvider = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  const { channelId, userId, initAudio, initVideo, mutedUsers, platform } =
    useAgoraState();
  const {
    setSessionExpired,
    setMutedUsers,
    setAudioEnabled,
    setVideoEnabled,
    setWillExpired,
  } = useAgoraSetState();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const { audioTrack, audioReady } = useAudio(initAudio);
  const { videoTrack, videoReady } = useVideo(initVideo);
  const client = getClient();

  const [getAgoraToken] = useMutation<IAgoraTokenData, IAgoraTokenVar>(
    AGORA_TOKEN,
    {
      onError: () => {
        enqueueSnackbar(AgoraMessages.TOKEN);
      },
    }
  );

  const handleError = useCallback(
    (error: unknown) => {
      const { code, message } = error as AgoraRTCError;

      if (
        code === AgoraErrorCode.CAN_NOT_GET_GATEWAY_SERVER &&
        message.includes('expired')
      ) {
        enqueueSnackbar(AgoraMessages.DID_EXPIRED);
        setSessionExpired(true);
      } else {
        enqueueSnackbar(AgoraMessages.GENERAL);
        logError(error);
      }
    },
    [enqueueSnackbar, setSessionExpired]
  );

  const isConnected = useCallback(
    () => client.connectionState === AgoraConnectionState.CONNECTED,
    [client.connectionState]
  );

  const startAudio = useCallback(async () => {
    if (audioTrack) {
      try {
        if (!audioTrack.enabled) {
          await audioTrack.setEnabled(true);
        }
        setAudioEnabled(true);
        setMutedUsers(mutedUsers.filter(id => id !== Number(userId)));
      } catch (error) {
        enqueueSnackbar(AgoraMessages.AUDIO_ERROR);
        logError(error);
      }
    }
  }, [
    audioTrack,
    enqueueSnackbar,
    mutedUsers,
    setAudioEnabled,
    setMutedUsers,
    userId,
  ]);

  const startVideo = useCallback(async () => {
    if (videoTrack) {
      try {
        if (!videoTrack.enabled) {
          await videoTrack.setEnabled(true);
        }
        setVideoEnabled(true);
      } catch (error) {
        enqueueSnackbar(AgoraMessages.VIDEO_ERROR);
        logError(error);
      }
    }
  }, [videoTrack, setVideoEnabled, enqueueSnackbar]);

  const joinChannel = useCallback(
    async (appId: string, channel: string, token: string, userId: number) => {
      try {
        if (client.connectionState !== AgoraConnectionState.CONNECTED) {
          await client.join(appId, channel, token, userId);
          client.enableAudioVolumeIndicator();

          enqueueSnackbar(AgoraMessages.JOINED);
        } else {
          await client.renewToken(token);
        }
        setSessionExpired(false);
        setWillExpired(false);
      } catch (error) {
        handleError(error);
      }
    },
    [client, enqueueSnackbar, handleError, setSessionExpired, setWillExpired]
  );

  const connect = useCallback(async () => {
    if (channelId && userId && platform !== undefined) {
      const response = await getAgoraToken({
        fetchPolicy: 'no-cache',
        variables: {
          channel: channelId,
          platformId: platform,
          uid: userId,
        },
      });
      const { agoraToken } = response.data?.restricted || {};
      if (agoraToken) {
        await joinChannel(AGORA_APP_ID, channelId, agoraToken, userId);
      }
    }
  }, [channelId, userId, getAgoraToken, platform, joinChannel]);

  const disconnect = useCallback(async () => {
    if (client.connectionState === AgoraConnectionState.DISCONNECTED) {
      return;
    }

    await client.leave();
  }, [client]);

  const publishAudio = useCallback(async () => {
    if (audioTrack && !isPublished(client, Media.AUDIO)) {
      try {
        if (audioTrack.enabled) {
          await client.publish(audioTrack);
        } else {
          await audioTrack.setEnabled(true);
          await client.publish(audioTrack);
          await audioTrack.setEnabled(false);
        }
      } catch (error) {
        await audioTrack.setEnabled(false);
        setAudioEnabled(false);
        setMutedUsers([...mutedUsers, Number(userId)]);
        enqueueSnackbar(AgoraMessages.AUDIO_PUBLISH_ERROR);
        logError(error);
      }
    }
  }, [
    audioTrack,
    client,
    mutedUsers,
    userId,
    enqueueSnackbar,
    setAudioEnabled,
    setMutedUsers,
  ]);

  const publishVideo = useCallback(
    async (offByDefault = false) => {
      if (videoTrack && !isPublished(client, Media.VIDEO)) {
        try {
          if (videoTrack.enabled) {
            if (offByDefault) {
              await videoTrack.setMuted(true);
              await client.publish(videoTrack);
              await videoTrack.setMuted(false);
              await videoTrack.setEnabled(false);
              setVideoEnabled(false);
            } else {
              await client.publish(videoTrack);
              setVideoEnabled(true);
            }
          } else {
            await videoTrack.setEnabled(true);
            await videoTrack.setMuted(true);
            await client.publish(videoTrack);
            await videoTrack.setMuted(false);
            await videoTrack.setEnabled(false);
            setVideoEnabled(false);
          }
        } catch (error) {
          await videoTrack.setEnabled(false);
          setVideoEnabled(false);
          enqueueSnackbar(AgoraMessages.VIDEO_PUBLISH_ERROR);
          logError(error);
        }
      }
    },
    [videoTrack, client, enqueueSnackbar, setVideoEnabled]
  );

  const unpublishVideo = useCallback(async () => {
    if (videoTrack) {
      try {
        if (!videoTrack?.enabled) {
          await videoTrack.setMuted(true);
          await videoTrack?.setEnabled(true);
        }
        if (!isPublished(client, Media.VIDEO)) {
          await client.unpublish(videoTrack);
        }
        await videoTrack.setEnabled(false);
        await videoTrack?.setEnabled(false);
        setVideoEnabled(false);
        setSessionExpired(false);
      } catch (error) {
        enqueueSnackbar(AgoraMessages.VIDEO_UNPUBLISH_ERROR);
        logError(error);
      }
    }
  }, [videoTrack, client, setVideoEnabled, setSessionExpired, enqueueSnackbar]);

  const endSession = useCallback(async () => {
    if (audioTrack) {
      audioTrack.close();
    }

    if (videoTrack) {
      videoTrack.close();
    }

    return client.leave().then(() => client.removeAllListeners());
  }, [client, audioTrack, videoTrack]);

  const api = useMemo(
    () => ({
      audioReady,
      audioTrack,
      client,
      connect,
      disconnect,
      endSession,
      isConnected,
      publishAudio,
      publishVideo,
      startAudio,
      startVideo,
      unpublishVideo,
      videoReady,
      videoTrack,
    }),
    [
      audioReady,
      audioTrack,
      client,
      connect,
      disconnect,
      endSession,
      isConnected,
      publishAudio,
      publishVideo,
      startAudio,
      startVideo,
      unpublishVideo,
      videoReady,
      videoTrack,
    ]
  );

  return (
    <AgoraAPIContextProvider value={api}>{children}</AgoraAPIContextProvider>
  );
};

export const useAgoraAPI = (): AgoraAPI => useContext(AgoraAPIContext);
