import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { IPreset } from '@quesmed/types-rn/models';

import { defaultPreset } from '../constants';
import { Tabs } from 'components/ExerciseBuilder/types';

interface UsePresetStoreState {
  isPresetEditOpen: boolean;
  setIsPresetEditOpen: (value: boolean) => void;
  preset: IPreset;
  tab: Tabs;
  setTab: (tab: Tabs) => void;
  setPreset: (preset: IPreset) => void;
  resetPreset: () => void;
}

const usePresetStore = create<UsePresetStoreState>()(set => ({
  isPresetEditOpen: false,
  setIsPresetEditOpen: (value: boolean) => set({ isPresetEditOpen: value }),
  preset: defaultPreset,
  setPreset: (preset: IPreset) => set({ preset }),
  tab: Tabs.Builder,
  setTab: (tab: Tabs) => set({ tab }),
  resetPreset: () => set({ preset: defaultPreset }),
}));

export const usePresetGlobalState = () =>
  usePresetStore(state => state, shallow);
