import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import {
  CHAPTER,
  IChapterData,
  IChapterVar,
} from '@quesmed/types-rn/resolvers/query/restricted';
import Skeleton from '@mui/material/Skeleton';
import { getEntitlementFromTopicType } from '@quesmed/types-rn';
import {
  EOsceType,
  EPaceType,
  EProductType,
  ETopicType,
} from '@quesmed/types-rn/models';

import parseQbankReading from 'components/LearningMaterials/utils/parseQbankReading';
import { Body } from 'components/Typography';
import { ArrowRightIcon } from 'components/Icons';
import { Link } from 'components/Link';
import { usePlatform } from 'context/PlatformState';

const WikiLink = styled('span')(({ theme: { palette } }) => ({
  fontWeight: 500,
  cursor: 'pointer',
  color: palette.primary.dark,
}));

const ExplanationHeader = styled(Body)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: spacing(2),
  textTransform: 'capitalize',
  color: palette.text.primary,
}));

const ExplanationBody = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

const ExplanationFooter = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: spacing(4),
}));

const ExplanationBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  maxWidth: '393px',
  maxHeight: '176px',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 100,
  fontSize: '14px',
  width: '80vw',
  backgroundColor: palette.background.paper,
  padding: spacing(4),
  color: palette.text.primary,
}));

const SkeletonContainer = styled(Box)(({ theme: { spacing } }) => ({
  pading: spacing(1),
}));

export interface ConceptNameProps {
  children: ReactNode | ReactNode[];
  topicId: string;
  typeId: ETopicType | EOsceType | EPaceType;
  conceptId: string;
  chapterId: string;
}

export const useData = (open: boolean, chapterId: string) => {
  const { data, loading } = useQuery<IChapterData, IChapterVar>(CHAPTER, {
    variables: {
      id: Number(chapterId),
    },
    skip: !open || !chapterId,
  });

  return {
    data,
    loading,
  };
};

export const ConceptName = ({
  children: conceptText,
  typeId,
  topicId,
  conceptId,
  chapterId,
}: ConceptNameProps): JSX.Element => {
  const { product } = usePlatform();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const { data, loading } = useData(open, chapterId);
  const { chapter } = data?.restricted || {};
  const { explanation } = chapter || {};

  const briefs = useMemo(
    () => (explanation ? parseQbankReading(explanation) : []),
    [explanation]
  );

  const entitlement = getEntitlementFromTopicType(
    typeId,
    product as EProductType
  );

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <WikiLink id={conceptId} onClick={handlePopoverOpen}>
        {conceptText}
      </WikiLink>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableRestoreFocus
        disableScrollLock
        onClose={handlePopoverClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transitionDuration={{ appear: 500, enter: 500, exit: 0 }}
      >
        <ExplanationBox>
          {loading ? (
            <SkeletonContainer>
              {[...Array(7).keys()].map(item => (
                <Fragment key={item}>
                  <Skeleton height={20} variant="text" width="100%" />
                </Fragment>
              ))}
            </SkeletonContainer>
          ) : (
            <>
              <ExplanationHeader variant="body1Medium">
                {conceptText}
              </ExplanationHeader>
              <ExplanationBody>
                {briefs[0]?.content?.split('\n')[0]}
              </ExplanationBody>
              <ExplanationFooter>
                <Link
                  to={`/knowledge-library/chapter/${entitlement?.id}/${topicId}/${conceptId}`}
                >
                  <ArrowRightIcon />
                  <Body>Read more</Body>
                </Link>
              </ExplanationFooter>
            </>
          )}
        </ExplanationBox>
      </Popover>
    </>
  );
};
