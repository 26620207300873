import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { Nullable } from 'types';

export enum SettingsModal {
  DiscardProfileButton,
  DiscardSettings,
  ConfirmationClearCache,
  ConfirmationClearProgress,
  FeedbackModal,
  DeleteAccountModal,
  ChangePasswordModal,
  AccountInvestigationModal,
}

export enum MenuLabel {
  Account = 'Account',
  General = 'General',
  Subscriptions = 'Subscriptions',
  Theme = 'Theme',
  Advanced = 'Advanced',
  Accessibility = 'User preferences',
  AdminPanel = 'Admin panel',
}

interface SettingsModalState {
  shownModal: Nullable<SettingsModal>;
  activeMenuItem: Nullable<MenuLabel>;
  isSettingsOpen: boolean;
  showMenu: boolean;
  closeModal: () => void;
  openModal: (shownModal: SettingsModal) => void;
  setActiveMenuItem: (activeMenuItem: Nullable<MenuLabel>) => void;
  setIsSettingsOpen: (isSettingsOpen: boolean) => void;
  setShowMenu: (showMenu: boolean) => void;
  resetSettingsState: () => void;
}

const useSettings = create<SettingsModalState>()(set => ({
  shownModal: null,
  isSettingsOpen: false,
  activeMenuItem: MenuLabel.Account,
  showMenu: true,
  openModal: shownModal => set({ shownModal }),
  closeModal: () => set({ shownModal: null }),
  setActiveMenuItem: activeMenuItem => set({ activeMenuItem }),
  setIsSettingsOpen: isSettingsOpen => set({ isSettingsOpen }),
  setShowMenu: showMenu => set({ showMenu }),
  resetSettingsState: () => {
    set({
      shownModal: null,
      isSettingsOpen: false,
      showMenu: true,
      activeMenuItem: MenuLabel.Account,
    });
  },
}));

export const useSettingsModalState = () => useSettings(state => state, shallow);
