import constants from 'config/constants';

const { MILISECONDS_IN_SECOND } = constants;

type DateParser = <T = unknown>(a: T) => number | T;

export const parseDate: DateParser = date => {
  if (typeof date === 'number') {
    return date * MILISECONDS_IN_SECOND;
  }

  return date;
};
