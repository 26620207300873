import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  ILoginUserData,
  ILoginUserVar,
  LOGIN_USER,
} from '@quesmed/types-rn/resolvers/mutation';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'components/Snackbar';
import { useAuth } from 'Auth';
import paths from 'Router/paths';
import { isErrorButNotApolloOne } from 'utils';

export interface LoginFormInput {
  username: string;
  password: string;
  rememberMe: boolean;
}

const DAY = 1;
const WEEK = 7;

export const useLogin = () => {
  const { setToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [loginUser, { loading }] = useMutation<ILoginUserData, ILoginUserVar>(
    LOGIN_USER
  );

  const login = useCallback(
    async ({ username, password, rememberMe }: LoginFormInput) => {
      try {
        const { data, errors } = await loginUser({
          variables: {
            username,
            password,
            days: rememberMe ? WEEK : DAY,
          },
        });

        if (data?.loginUser) {
          setToken(data?.loginUser);
          navigate(paths.onboarding.root);
        }

        if (errors) {
          enqueueSnackbar(errors[0].message);
        }
      } catch (error) {
        if (isErrorButNotApolloOne(error)) {
          enqueueSnackbar(error.message);
        }
      }
    },
    [enqueueSnackbar, loginUser, navigate, setToken]
  );

  return { login, loading };
};
