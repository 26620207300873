import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Children } from 'types';

const StyledBox = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  position: 'relative',
  padding: spacing(4, 4, 22),
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  [breakpoints.up('md')]: {
    padding: spacing(8, 8, 30),
  },

  '& .MuiAutocomplete-root, > .MuiFormControl-root': {
    display: 'block',
    maxWidth: '432px',
    marginBottom: spacing(6),
  },
}));

interface SettingsContentLayoutProps {
  children: Children;
}

const SettingsContentLayout = ({
  children,
}: SettingsContentLayoutProps): JSX.Element => (
  <StyledBox>{children}</StyledBox>
);

export default SettingsContentLayout;
