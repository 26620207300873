import isNil from 'lodash/isNil';

import { noop } from './noop';

export function clearTimeoutSafely(timeoutRef?: number | null) {
  try {
    if (!isNil(timeoutRef)) {
      clearTimeout(timeoutRef);
    }
  } catch {
    noop();
  }
}
