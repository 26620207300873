import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

const HOUR = 3600000;

dayjs.extend(duration);

export const formatDuration = (miliseconds: number, pattern?: string) =>
  dayjs
    .duration(miliseconds)
    .format(pattern ?? (miliseconds >= HOUR ? 'HH:mm:ss' : 'mm:ss'));
