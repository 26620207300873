import React, { useState } from 'react';

import SearchResult from './SearchResult';
import { FlashcardsIcon } from 'components/Icons';
import { ExerciseType } from 'types';
import { SearchIndex } from './type';
import FlashcardsPreBuildModal from 'pages/Flashacards/FlashcardsPreBuildModal';

export interface FlashcardsSearchResultProps {
  onSearchClose: () => void;
  searchString: string;
  hideSearchModal: () => void;
  showSearchModal: () => void;
  setCount: (value: number) => void;
  indexName: SearchIndex;
}

const FlashcardsSearchResult = ({
  onSearchClose,
  searchString,
  hideSearchModal,
  showSearchModal,
  setCount,
  indexName,
}: FlashcardsSearchResultProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpenFlashcardBuilderModal = () => {
    hideSearchModal();
    setOpen(true);
  };

  const handleCloseFlashcardBuilderModal = () => {
    showSearchModal();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    onSearchClose();
  };

  return (
    <>
      <SearchResult
        asLink
        icon={FlashcardsIcon}
        indexName={indexName}
        onClick={handleOpenFlashcardBuilderModal}
        onItemClick={onSearchClose}
        setCount={setCount}
        title={ExerciseType.Flashcards}
      />
      {open ? (
        <FlashcardsPreBuildModal
          onBack={handleCloseFlashcardBuilderModal}
          onClose={handleClose}
          onStartQuiz={handleClose}
          open={open}
          search={searchString}
          source={`search ${ExerciseType.Flashcards}`}
        />
      ) : null}
    </>
  );
};

export default FlashcardsSearchResult;
