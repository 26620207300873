import { IPayload } from '@quesmed/types-rn/models';

import isExpired from './isExpired';
import isTokenVersionValid from './isTokenVersionValid';

const isTokenValid = (token?: IPayload | null): boolean => {
  if (!token) {
    return false;
  }

  return !isExpired(token.exp) && isTokenVersionValid(token.ver);
};

export default isTokenValid;
