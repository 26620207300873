import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Fallback, SearchPhrase } from './Search.styles';

const SummaryBox = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    padding: spacing(4, 2),
    borderBottom: `1px solid ${palette.stroke.main}`,

    [breakpoints.up('md')]: {
      padding: spacing(4),
    },
  })
);

interface SearchSummaryProps {
  count: number;
  searchString: string;
}

const SearchSummary = ({
  count,
  searchString,
}: SearchSummaryProps): JSX.Element => (
  <SummaryBox>
    <Fallback bold>
      We found <SearchPhrase component="span">{count}</SearchPhrase> results for{' '}
      <SearchPhrase component="span">{`'${searchString}'`}</SearchPhrase>{' '}
      including relevant practice and learning materials:
    </Fallback>
  </SummaryBox>
);

export default SearchSummary;
