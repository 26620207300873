import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

import { Logo } from 'components/Logo';
import { Body } from 'components/Typography';
import { FormField } from 'components/TextField';
import { FormCheckbox } from 'components/Checkbox';
import { Link, OutsideLink } from 'components/Link';
import { Button } from 'components/Button';
import { paths } from 'Router';
import { isEmailBlacklisted } from 'utils';
import { useRegister } from './useRegister';
import { privacyPolicyLink, termsAndConditionsLink } from 'config/constants';
import { ActionRow, Title } from 'pages/Login/Login';

interface RegisterFormInput {
  username: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
  newsletter: boolean;
}

const RootContainer = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '100vw',
  minHeight: '100vh',
  padding: spacing(4),
  backgroundColor: palette.background.paper,
}));

const ContentContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',

  '& .MuiFormControl-root': {
    marginTop: spacing(6),
  },

  '& .MuiCheckbox-root': {
    padding: spacing(0, 2),
  },

  [breakpoints.up('md')]: {
    width: '432px',
  },
}));

const LabelBox = styled(Box)({
  display: 'flex',
});

const RequiredAsterisk = styled(Box)(({ theme: { palette } }) => ({
  color: palette.error.dark,
}));

const LoginContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginTop: spacing(6),
}));

const StyledBody = styled(Body)(({ theme: { spacing } }) => ({
  marginRight: spacing(2),
}));

const schema = Joi.object<RegisterFormInput>({
  username: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .custom((email, helpers) => {
      if (isEmailBlacklisted(email)) {
        return helpers.error('email.blacklisted');
      }

      return email;
    })
    .messages({
      'string.empty': 'Email Address is required',
      'string.email': 'Email must be a valid e-mail address',
      'email.blacklisted':
        'We are unable to send emails to this address, please input a different email address',
    }),
  password: Joi.string().required().min(6).messages({
    'string.empty': 'Password is required',
    'string.min': 'Password must be at least 6 characters long',
  }),
  confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({
    'string.empty': 'Confirm password is required',
    'any.only': 'Confirm password must match password',
  }),
  termsAndConditions: Joi.boolean(),
  newsletter: Joi.boolean(),
});

const Register = (): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<RegisterFormInput>({
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
      termsAndConditions: false,
      newsletter: false,
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });
  const { register, loading } = useRegister();

  const handleRegister: SubmitHandler<RegisterFormInput> = async ({
    username,
    password,
    newsletter,
  }) => {
    const userData = {
      username,
      password,
      newsletter,
      displayName: '',
      firstName: '',
      lastName: '',
      universityId: 100,
      classYear: 'Graduated',
      graduationYear: 2020,
      referral: '',
    };
    await register(userData);
  };

  const submitDisabled = loading || !isValid || !watch('termsAndConditions');

  return (
    <RootContainer>
      <Helmet>
        <title>Quesmed | Sign Up</title>
        <meta
          content="Sign up to excel at your next medical exam"
          name="description"
        />
        <link href="https://app.quesmed.com/register" rel="canonical" />
      </Helmet>
      <ContentContainer>
        <Logo showText={false} size={64} />
        <Title>Sign up to Quesmed</Title>
        <form onSubmit={handleSubmit(handleRegister)}>
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Email address"
            name="username"
            placeholder="Email address"
          />
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Password"
            name="password"
            placeholder="Password"
            type="password"
          />
          <FormField
            control={control}
            disabled={loading}
            fullWidth
            label="Confirm password"
            name="confirmPassword"
            placeholder="Confirm password"
            type="password"
          />
          <ActionRow>
            <FormCheckbox
              containerSx={({ spacing }) => ({ marginBottom: spacing(4) })}
              control={control}
              controlSx={{ alignItems: 'flex-start' }}
              label={
                <LabelBox>
                  <Body>
                    I agree to&nbsp;
                    <OutsideLink href={termsAndConditionsLink}>
                      Terms & Conditions
                    </OutsideLink>
                    &nbsp;and&nbsp;
                    <OutsideLink href={privacyPolicyLink}>
                      Privacy Policy.
                    </OutsideLink>
                    <RequiredAsterisk component="span">*</RequiredAsterisk>
                  </Body>
                </LabelBox>
              }
              name="termsAndConditions"
            />
            <FormCheckbox
              control={control}
              controlSx={{ alignItems: 'flex-start' }}
              label={
                <LabelBox>
                  <Body>Send me updates and promotions via email.</Body>
                </LabelBox>
              }
              name="newsletter"
            />
          </ActionRow>
          <Button disabled={submitDisabled} fullWidth type="submit">
            Sign Up
          </Button>
        </form>
        <LoginContainer>
          <StyledBody>Already have an account?</StyledBody>
          <Link to={paths.login}>Log in</Link>
        </LoginContainer>
      </ContentContainer>
    </RootContainer>
  );
};

export default Register;
