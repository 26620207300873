import { keys } from '../object/keys';

export function getDirtyValues<T extends {}>(
  formValues: T,
  dirtyFields: Partial<{ [K in keyof T]: boolean | undefined }>
) {
  const data: Partial<T> = {};

  const dirtyKeys = keys(dirtyFields);

  if (!dirtyKeys.length) {
    return data;
  }

  keys(dirtyFields).forEach(key => {
    data[key] = formValues[key];
  });

  return data;
}
