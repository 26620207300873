import React from 'react';
import {
  ENotificationActionType,
  INotification,
} from '@quesmed/types-rn/models';
import { styled } from '@mui/material';

import { Body } from 'components/Typography';

const Highlighted = styled('span')(({ theme: { palette } }) => ({
  color: palette.primary.dark,
}));

const NotificationContent = styled(Body)(({ theme: { spacing, palette } }) => ({
  marginBottom: spacing(1),
  color: palette.text.primary,

  '&:first-letter': {
    textTransform: 'uppercase',
  },
}));

interface CommentLinkProps {
  id?: number;
  name?: string;
  user: JSX.Element | string;
  message: string;
}

const CommentLink = ({
  id,
  name,
  user,
  message,
}: CommentLinkProps): JSX.Element | null => (
  <>
    {user} {message}
    {id && name ? (
      <>
        &nbsp;in&nbsp;
        <Highlighted>{name}</Highlighted>
      </>
    ) : null}
  </>
);

interface NotificationMessageProps {
  notification: INotification;
}

const USER_DEFAULT_NAME = 'A user';

const NotificationMessage = ({
  notification,
}: NotificationMessageProps): JSX.Element => {
  const { actionType, description, fromUser, comment } = notification;
  const { question } = comment || {};
  const { id, concept } = question || {};
  const { name } = concept || {};

  const user = fromUser ? (
    <Highlighted>{fromUser.displayName}</Highlighted>
  ) : (
    USER_DEFAULT_NAME
  );

  const createCommentLink = (message: string) => (
    <CommentLink id={id} message={message} name={name} user={user} />
  );

  const content = () => {
    switch (actionType) {
      case ENotificationActionType.REPLY:
        return createCommentLink('has replied to your comment');
      case ENotificationActionType.LIKE:
        return createCommentLink('has upvoted your comment');
      case ENotificationActionType.DISLIKE:
        return createCommentLink('has downvoted your comment');
      case ENotificationActionType.REQUESTED:
        return <>{user} has sent you a friend request</>;
      case ENotificationActionType.ACCEPTED:
        return <>{user} has accepted your friend request</>;
      case ENotificationActionType.FEATURE:
      case ENotificationActionType.INFORMATION:
        return description;
      default:
        return null;
    }
  };

  return <NotificationContent>{content()}</NotificationContent>;
};

export default NotificationMessage;
