import React from 'react';

import { ChartIcon, TableIcon } from 'components/Icons';
import { BuilderViewMode, Tab, ToggleOptions } from 'types';

export const JOIN_GROUP_STUDY_PARAM = 'join';

export const BUILDER_VIEWS: ToggleOptions<BuilderViewMode> = [
  {
    icon: <ChartIcon />,
    label: BuilderViewMode.Overview,
    value: BuilderViewMode.Overview,
  },
  {
    icon: <TableIcon />,
    label: BuilderViewMode.Details,
    value: BuilderViewMode.Details,
  },
];

export const LOBBY_PANELS: Tab[] = [
  { label: 'Participants', value: 'Participants' },
];

export const PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS = {
  activeToggleOption: LOBBY_PANELS[0].value,
  exerciseLabel: 'Participants',
  toggleLabel: 'Participants',
  toggleOptions: LOBBY_PANELS,
};

export const EMAIL_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
