import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { Nullable } from 'types';

export enum QuestionsModal {
  LeaveModal,
  ErrorModal,
}

interface QuestionsModalState {
  shownModal: Nullable<QuestionsModal>;
  openModal: (shownModal: QuestionsModal) => void;
  closeModal: () => void;
}

export const useModalState = create<QuestionsModalState>()(set => ({
  shownModal: null,
  openModal: shownModal => set({ shownModal }),
  closeModal: () => set({ shownModal: null }),
}));

export const useQuestionsModalState = () =>
  useModalState(state => state, shallow);
