import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthenticatedApp } from 'app/AuthenticatedApp';
import { localStorageTyped } from 'utils';
import { useJoinSearchParam, usePrevious } from 'hooks';
import { UnauthenticatedApp } from 'app/UnauthenticatedApp';
import { useAuth } from 'Auth';
import locales from 'locales';
import { CircularProgress } from 'components/CircularProgress';

const { common } = locales;

const AppContentController = (): JSX.Element => {
  const { isAuthenticated, isAuthPending } = useAuth();
  const { setItem } = localStorageTyped<string>('redirectLink');
  const agoraId = useJoinSearchParam();
  const { pathname, search } = useLocation();
  const prevAuth = usePrevious(isAuthenticated);
  const path = `${pathname}${search}`;

  if (
    agoraId &&
    !prevAuth &&
    !isAuthenticated &&
    (path.includes('group?join=') || path.includes('questions?join='))
  ) {
    setItem(path);
  }

  if (isAuthPending) {
    return <CircularProgress description={common.loading.app} />;
  }

  if (!isAuthenticated) {
    return <UnauthenticatedApp />;
  }

  return (
    <Suspense fallback={<CircularProgress description="Loading data..." />}>
      <AuthenticatedApp />
    </Suspense>
  );
};

export default AppContentController;
