import React, {
  ComponentType,
  forwardRef,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';

import {
  CheckboxMarkedCircleOutlineIcon,
  ChevronDownIcon,
  CloseCircleOutlineIcon,
  EditIcon,
  IconProps,
} from 'components/Icons';
import { Nullable } from 'types';
import { useMarkdownTextScale } from 'components/Markdown';

type ExpandableSectionSize = 'small' | 'medium' | 'base';

const StyledBox = styled(Box)<{ scale: number }>(
  ({
    scale,
    theme: { palette, spacing, transitions, typography, breakpoints },
  }) => ({
    display: 'block',
    border: `1px solid ${palette.stroke.main}`,
    borderRadius: '9px',
    overflow: 'hidden',

    '& .dropdown': {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: palette.background.paper,

      color: palette.text.primary,
      transition: transitions.create(['border-radius']),
      padding: spacing(4),
      minHeight: '56px',

      '&.clickable': {
        cursor: 'pointer',
      },

      '& .left-col': {
        display: 'flex',
        alignItems: 'center',
        fontSize: `calc(${typography.body2.fontSize} * ${scale})`,
        lineHeight: `calc(${typography.body2.lineHeight} * ${scale})`,

        '& .MuiSvgIcon-root': {
          color: palette.icon.main,
          marginRight: spacing(2.5),
        },
      },

      '& .right-col': {
        display: 'flex',
        alignItems: 'center',
        color: palette.icon.main,
        transition: transitions.create(['color']),

        '& .MuiSvgIcon-root': {
          transition: transitions.create(['transform']),
          transform: 'rotate(0)',
        },

        '&.expanded': {
          '& .MuiSvgIcon-root': {
            transform: 'rotate(180deg)',
          },
        },
      },

      '&:hover': {
        '& .right-col': {
          color: palette.icon.dark,
        },
      },
    },

    '&.base': {
      '& .dropdown': {
        minHeight: '56px',
        ...typography.body1Medium,

        [breakpoints.up('md')]: {
          padding: spacing(4),
        },
      },
    },

    '&.medium': {
      '& .dropdown': {
        minHeight: '56px',
        ...typography.body1Medium,
      },
    },

    '&.small': {
      '& .dropdown': {
        minHeight: '56px',
        ...typography.button,
      },
    },

    '& .content': {
      backgroundColor: palette.background.paper,
      borderTop: `1px solid ${palette.stroke.main}`,
      padding: spacing(3, 4),

      [breakpoints.up('md')]: {
        padding: spacing(6, 8),
      },
    },

    '&.without-padding': {
      '& .content': {
        padding: 0,
      },
    },

    '&.info': {
      '& .left-col': {
        '& .MuiSvgIcon-root': {
          color: palette.primary.main,
        },
      },
    },

    '&.error': {
      borderColor: palette.error.main,
      '& .content': {
        backgroundColor: palette.error.extraLight,
        borderColor: palette.error.main,
      },
      '& .dropdown': {
        backgroundColor: palette.error.extraLight,
      },
      '& .left-col': {
        '& .MuiSvgIcon-root': {
          color: palette.error.main,
        },
      },
    },

    '&.success': {
      borderColor: palette.success.dark,
      '& .content': {
        backgroundColor: palette.success.extraLight,
        borderColor: palette.success.dark,
      },
      '& .dropdown': {
        backgroundColor: palette.success.extraLight,
      },
      '& .left-col': {
        '& .MuiSvgIcon-root': {
          color: palette.success.dark,
        },
      },
    },

    '&.link .dropdown .right-col .MuiSvgIcon-root': {
      color: palette.primary.main,
      transform: 'rotate(-90deg)',
    },

    '&.disable-content-background': {
      '& .content': {
        backgroundColor: palette.background.paper,
      },
    },
  })
);

const getVariantIcon = (
  variant: ExpandableSectionVariants
): Nullable<ComponentType<IconProps>> => {
  switch (variant) {
    case 'success':
      return CheckboxMarkedCircleOutlineIcon;
    case 'error':
      return CloseCircleOutlineIcon;
    case 'info':
      return EditIcon;
    default:
      return null;
  }
};

export type ExpandableSectionVariants =
  | 'success'
  | 'error'
  | 'normal'
  | 'info'
  | 'link';

export interface ExpandableSectionProps extends Omit<BoxProps, 'title'> {
  title: string | NonNullable<ReactNode>;
  icon?: ComponentType<IconProps>;
  onToggle?: () => void;
  expanded?: boolean;
  children?: React.ReactNode;
  clickable?: boolean;
  clickableTitle?: boolean;
  titleAction?: () => void;
  disableContentBackground?: boolean;
  expandable?: boolean;
  withoutPadding?: boolean;
  hideArrow?: boolean;
  size?: ExpandableSectionSize;
  variant?: ExpandableSectionVariants;
  nested?: boolean;
}

export const ExpandableSection = forwardRef(
  (
    {
      clickable = true,
      clickableTitle = false,
      title,
      titleAction,
      icon: Icon,
      onToggle,
      expanded: sourceExpanded = false,
      children,
      size = 'base',
      variant = 'normal',
      disableContentBackground,
      expandable,
      nested,
      hideArrow,
      withoutPadding,
    }: ExpandableSectionProps,
    ref
  ): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(sourceExpanded);
    const VariantIcon = getVariantIcon(variant);
    const { scale } = useMarkdownTextScale();

    const toggleExpandedState = () => {
      if (expandable) return;

      if (onToggle) {
        onToggle();
      } else {
        setExpanded(previous => !previous);
      }
    };

    useEffect(() => {
      setExpanded(sourceExpanded);
    }, [sourceExpanded]);

    const handleTitleClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (titleAction) {
        titleAction();
      }
    };

    return (
      <StyledBox
        className={clsx('expandable-section', variant, size, {
          clickable,
          nested,
          'without-padding': withoutPadding,
          'disable-content-background': disableContentBackground,
        })}
        ref={ref}
        scale={scale}
      >
        <Box
          className={clsx('expandable-section__header', 'dropdown', {
            clickable,
          })}
          onClick={toggleExpandedState}
        >
          <Box className="left-col">
            {VariantIcon ? <VariantIcon /> : Icon && <Icon />}
            {clickableTitle ? (
              <Box onClick={handleTitleClick}>{title}</Box>
            ) : (
              <Box>{title}</Box>
            )}
          </Box>
          {!hideArrow && !expandable && (
            <Box className={clsx('right-col', { expanded })}>
              <ChevronDownIcon />
            </Box>
          )}
        </Box>
        {children ? (
          <Collapse collapsedSize={0} in={expanded} unmountOnExit>
            <Box className="expandable-section__content content">
              {children}
            </Box>
          </Collapse>
        ) : null}
      </StyledBox>
    );
  }
);

ExpandableSection.displayName = 'ExpandableSection';
