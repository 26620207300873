import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { clsx } from 'clsx';

import { Tooltip } from 'components/Tooltip';
import { Button } from 'components/Button';
import { CursorDefaultClickOutlineIcon } from 'components/Icons';
import { Body } from 'components/Typography';
import useUpgradeNow from './useUpgradeNow';

const DemoBoxContainer = styled(Box)(
  ({ theme: { spacing, palette, shape, transitions } }) => ({
    color: palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flexStart',
    borderRadius: shape.borderRadius,
    backgroundColor: palette.primary.extraLight,
    padding: spacing(4),
    gap: spacing(4),
    marginBottom: spacing(4),
    cursor: 'pointer',
    height: '56px',
    transition: transitions.create(['height']),

    '&.expanded': {
      height: '176px',
    },
  })
);

const Details = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(4),
}));

const DemoMessage = styled(Body)(({ theme: { palette } }) => ({
  width: '100%',
  textAlign: 'center',
  color: palette.text.primary,
}));

interface DemoBoxProps {
  expanded: boolean;
}

const DemoBox = ({ expanded }: DemoBoxProps): JSX.Element => {
  const upgradeNow = useUpgradeNow();

  return (
    <Tooltip title={expanded ? '' : 'Upgrade now'}>
      <DemoBoxContainer className={clsx({ expanded })} onClick={upgradeNow}>
        <CursorDefaultClickOutlineIcon />
        <Collapse in={expanded} unmountOnExit>
          <Details>
            <DemoMessage>Unlock access to all content and features</DemoMessage>
            <Button>Upgrade now</Button>
          </Details>
        </Collapse>
      </DemoBoxContainer>
    </Tooltip>
  );
};

export default DemoBox;
