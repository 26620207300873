import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiCircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

import { Body } from 'components/Typography';

const CircularProgressContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  '&.fullscreen': {
    width: '100%',
    height: '100vh',
  },
});

const StyledCircularProgress = styled(MuiCircularProgress)(
  ({ theme: { palette } }) => ({
    '&.MuiCircularProgress-root': {
      '&.MuiCircularProgress-colorPrimary': {
        color: palette.primary.main,
      },
      '&.MuiCircularProgress-colorSecondary': {
        color: palette.secondary.main,
      },
    },
  })
);

interface CircularProgressProps {
  color?: 'primary' | 'secondary';
  size?: number;
  thickness?: number;
  description?: string;
  fullscreen?: boolean;
}

const CircularProgress = ({
  color = 'primary',
  size = 20,
  thickness = 3.6,
  description,
  fullscreen = true,
}: CircularProgressProps): JSX.Element => (
  <CircularProgressContainer className={clsx({ fullscreen })}>
    <StyledCircularProgress color={color} size={size} thickness={thickness} />
    {description ? <Body>{description}</Body> : null}
  </CircularProgressContainer>
);

export default CircularProgress;
