import React from 'react';
import Typography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';

export type TypographyProps<C extends React.ElementType = React.ElementType> =
  MuiTypographyProps<C, { component?: C }>;

export const H1 = ({
  children,
  ...props
}: Omit<TypographyProps, 'component' | 'variant'>): JSX.Element => (
  <Typography component="h1" variant="h1" {...props}>
    {children}
  </Typography>
);

export const H2 = ({
  children,
  ...props
}: Omit<TypographyProps, 'component' | 'variant'>): JSX.Element => (
  <Typography component="h2" variant="h2" {...props}>
    {children}
  </Typography>
);

export const H3 = ({
  children,
  ...props
}: Omit<TypographyProps, 'component' | 'variant'>): JSX.Element => (
  <Typography component="h3" variant="h3" {...props}>
    {children}
  </Typography>
);

export const H4 = ({
  children,
  ...props
}: Omit<TypographyProps, 'component' | 'variant'>): JSX.Element => (
  <Typography component="h4" variant="h4" {...props}>
    {children}
  </Typography>
);

export const H5 = ({
  children,
  ...props
}: Omit<TypographyProps, 'component' | 'variant'>): JSX.Element => (
  <Typography component="h5" variant="h5" {...props}>
    {children}
  </Typography>
);

export const H6 = ({
  children,
  ...props
}: Omit<TypographyProps, 'component' | 'variant'>): JSX.Element => (
  <Typography component="h6" variant="h6" {...props}>
    {children}
  </Typography>
);

type BodyProps = Omit<TypographyProps, 'variant'> & {
  small?: boolean;
  bold?: boolean;
};

type BodyVariant = Extract<
  MuiTypographyProps['variant'],
  'body1' | 'body2' | 'body1Medium' | 'body2Medium'
>;

export const Body = ({
  bold = false,
  small = false,
  ...props
}: BodyProps): JSX.Element => {
  const variant = `body${small ? 2 : 1}${bold ? 'Medium' : ''}` as BodyVariant;

  return <Typography variant={variant} {...props} />;
};

export const Caption = (
  props: Omit<TypographyProps, 'variant'>
): JSX.Element => <Typography variant="caption" {...props} />;

export const Overline = (
  props: Omit<TypographyProps, 'variant'>
): JSX.Element => <Typography variant="overline" {...props} />;
