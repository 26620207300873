import Box, { BoxProps } from '@mui/material/Box';
import React, { ReactElement, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import ExerciseControlPanel, {
  ExerciseControlPanelProps,
} from './ExerciseControlPanel';
import { Nullable } from 'types';
import { useIsDesktop } from 'hooks';
import { ParticipantList } from 'components/ParticipantCard/ParticipantList';
import useCurrentUser from 'Auth/useCurrentUser';
import useStartOrJoin from 'pages/Questions/hooks/useStartOrJoin';
import { PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS } from 'constants/general';
import { useExpandedState } from './useExerciseLayoutState';

interface ExerciseContainerProps extends BoxProps {
  expanded: 'expanded' | 'collapsed';
  withpanel: 0 | 1;
}

const ExerciseContainer = styled(Box)<ExerciseContainerProps>(
  ({
    expanded,
    theme: {
      breakpoints,
      mixins: { controlPanel },
      transitions,
    },
    withpanel,
  }) => ({
    position: 'relative',
    width: '100%',
    transition: transitions.create(['transform', 'width', 'margin'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),

    [breakpoints.up('xl')]: {
      width: `calc(100% - ${
        withpanel
          ? controlPanel[expanded].minWidth
          : controlPanel.collapsed.minWidth
      }px)`,
    },

    [breakpoints.up('xxl')]: {
      width: `calc(100% - ${
        withpanel
          ? controlPanel[expanded].minWidth
          : controlPanel.collapsed.minWidth
      }px)`,
    },

    '&.withoutPanel': {
      [breakpoints.up('md')]: {
        width: '100%',
      },

      [breakpoints.up('xxl')]: {
        width: '100%',
      },
    },
    '&.withoutVerticalOffset': {
      marginTop: 0,
      marginBottom: 0,
    },

    '&.noOverflow': {
      overflow: 'hidden',
    },
  })
);

interface ExerciseLayoutProps
  extends Omit<
    ExerciseControlPanelProps,
    'children' | 'expanded' | 'onExpand'
  > {
  exerciseContent: ReactElement | ReactElement[];
  controlPanelContent?: Nullable<ReactElement | ReactElement[]>;
  collapsedPanel?: boolean;
  withoutPanel?: boolean;
  withoutVerticalOffset?: boolean;
  noOverflow?: boolean;
}

const ExerciseLayout = ({
  collapsedPanel = true,
  noOverflow = false,
  controlPanelContent,
  exerciseContent,
  floatingPanel = false,
  withoutPanel,
  withoutVerticalOffset,
  ...props
}: ExerciseLayoutProps): JSX.Element => {
  const { expanded, toggleExpanded, setExpanded } = useExpandedState();

  const { isDesktop } = useIsDesktop();

  useEffect(() => {
    setExpanded(!collapsedPanel && isDesktop);
  }, [collapsedPanel, isDesktop, setExpanded]);

  const { id, displayName } = useCurrentUser();
  const { activeUsers } = useStartOrJoin();
  const participants = activeUsers.length ? activeUsers : [{ id, displayName }];
  const handleNavigationExpand = () => {
    toggleExpanded();
  };

  const participantsOptions = controlPanelContent
    ? null
    : PARTICIPANTS_LOBBY_CONTROL_PANEL_OPTIONS;

  return (
    <ExerciseContainer
      className={clsx({ withoutPanel, withoutVerticalOffset, noOverflow })}
      expanded={expanded ? 'expanded' : 'collapsed'}
      withpanel={floatingPanel ? 0 : 1}
    >
      {exerciseContent}
      {withoutPanel ? null : (
        <ExerciseControlPanel
          expanded={expanded}
          floatingPanel={floatingPanel}
          onExpand={handleNavigationExpand}
          open
          {...participantsOptions}
          {...props}
        >
          {controlPanelContent ? (
            controlPanelContent
          ) : (
            <ParticipantList
              currentUserId={Number(id)}
              participants={participants}
              variant="panel"
            />
          )}
        </ExerciseControlPanel>
      )}
    </ExerciseContainer>
  );
};

export default ExerciseLayout;
