import React from 'react';
import { mdiRadioboxMarked } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const RadioBoxMarkedIcon = (props: IconProps) => (
  <Icon path={mdiRadioboxMarked} {...props} />
);

export default RadioBoxMarkedIcon;
