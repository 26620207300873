import { styled } from '@mui/material/styles';
import MuiCheckbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import { constSize } from 'utils';

export const StyledCheckboxWrapper = styled(Box)(({ theme: { palette } }) => ({
  '&.error': {
    '.MuiFormControlLabel-label': {
      color: palette.error.main,
    },
  },
}));

export const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  '& .MuiBox-root': {
    ...constSize('24px'),
  },
  '& .Mui-checked.MuiSvgIcon-root': {
    path: {
      fill: theme.palette.primary.main,
    },
  },
  '&.MuiCheckbox-colorError': {
    '.MuiSvgIcon-root': {
      path: {
        fill: theme.palette.error.main,
      },
    },
  },
}));
