import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  IPreBuildTodoData,
  IPreBuildTodoVar,
  PRE_BUILD_TODO,
} from '@quesmed/types-rn/resolvers/query/restricted';
import { EEntitlementType } from '@quesmed/types-rn/models';

const usePreBuildTodo = () => {
  const [preBuild, { data, loading }] = useLazyQuery<
    IPreBuildTodoData,
    IPreBuildTodoVar
  >(PRE_BUILD_TODO, {
    fetchPolicy: 'network-only',
  });

  const { seen, unseen, buildRef } = data?.restricted.preBuildTodo || {};

  const preBuildTodo = useCallback(
    async (
      conceptIds: Set<number> = new Set(),
      search = '',
      source = '',
      entitlementId?: EEntitlementType
    ) =>
      preBuild({
        variables: {
          topicIds: [],
          conceptIds: Array.from(conceptIds),
          search,
          source,
          entitlementId,
        },
      }),
    [preBuild]
  );

  return {
    loading,
    preBuildTodo,
    buildRef,
    seen,
    unseen,
  };
};

export default usePreBuildTodo;
