export const setProductPeriod = (duration: number) => {
  switch (duration) {
    case 1:
      return 'monthly';
    case 3:
      return '3 monthly';
    case 6:
      return '6 monthly';
    case 12:
    default:
      return 'year';
  }
};
