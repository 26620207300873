import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { isDev } from 'config/constants';
import { Button } from 'components/Button';

const ErrorBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  padding: '24px',
  boxSizing: 'border-box',
  overflow: 'auto',
  justifyContent: 'center',
  alignItems: 'center',

  '& > *:not(:last-child)': {
    marginBottom: ' 24px',
  },
});

const ErrorInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  color: theme.palette.text.primary,
}));

const ErrorStack = styled(Box)({
  whiteSpace: 'pre-wrap',
});

interface ErrorMessageProps {
  resetError?: () => void;
  error?: Error;
  stack?: string | null;
}

export const ErrorMessage = ({
  resetError,
  error,
  stack,
}: ErrorMessageProps): JSX.Element => (
  <ErrorBox>
    <Alert severity="error" title="Something went wrong">
      An unexpected error has happened, so Quesmed could not perform the action.
      Please try it again and if the problem reoccurs, please wait, probably we
      are fixing it already.
    </Alert>
    {isDev ? (
      <div>
        {error ? <ErrorInfoBox>{error.toString()}</ErrorInfoBox> : null}
        {stack ? (
          <ErrorInfoBox>
            <ErrorStack>{stack}</ErrorStack>
          </ErrorInfoBox>
        ) : null}
      </div>
    ) : null}
    <div>
      <Button onClick={resetError}>Try to reset error</Button>
    </div>
  </ErrorBox>
);
