import React from 'react';
import { useLocation } from 'react-router-dom';
import { EAppType, EProductType } from '@quesmed/types-rn/models';

import AlgoliaProvider from 'components/algolia/AlgoliaProvider';
import { SearchModal } from './SearchModal';
import { usePlatform } from 'context/PlatformContext';
import { isStationsProduct, isVideoLibraryPage } from 'utils';
import { SearchScopeIndex } from './type';

export interface SearchControllerProps {
  open: boolean;
  onClose: () => void;
}

const getAlgoliaConfig = (product: EProductType | null) => {
  switch (product) {
    case EProductType.OSCE:
    case EProductType.PLAB2:
      return SearchScopeIndex.Oscebook;
    case EProductType.PACES:
      return SearchScopeIndex.Pacebook;

    default:
      return SearchScopeIndex.Quesbook;
  }
};

const SearchController = (props: SearchControllerProps): JSX.Element => {
  const { product, app } = usePlatform();
  const { pathname } = useLocation();

  const showVideos = isVideoLibraryPage(pathname);
  const showStations = !showVideos && isStationsProduct(product);
  const showPaces = product === EProductType.PACES && showStations;
  const showQuestions = !showVideos && !showStations;
  const showFlashcards =
    (app === EAppType.AMS || app === EAppType.CM_UKMLA) &&
    !showVideos &&
    !showStations;

  const indexName = showVideos
    ? SearchScopeIndex.Videos
    : getAlgoliaConfig(product);

  return (
    <AlgoliaProvider indexName={indexName}>
      <SearchModal
        {...props}
        product={product || EProductType.QBANK}
        searchWithAlgolia
        showFlashcards={showFlashcards}
        showPaces={showPaces}
        showQuestions={showQuestions}
        showStations={showStations}
        showVideos={showVideos}
      />
    </AlgoliaProvider>
  );
};

export default SearchController;
