import React from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const StyledDivider = styled(Divider)(({ theme: { spacing } }) => ({
  margin: spacing(8, 0),
}));

const SettingsSectionDivider = (): JSX.Element => <StyledDivider />;

export default SettingsSectionDivider;
