import React, { forwardRef } from 'react';
import { mdiProgressQuestion } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ProgressQuestionIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <Icon path={mdiProgressQuestion} ref={ref} {...props} />
);

ProgressQuestionIcon.displayName = 'ProgressQuestionIcon';

export default ProgressQuestionIcon;
