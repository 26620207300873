import React from 'react';

import { ParticipantStatus } from './types';
import { AlertCircleOutlineIcon, CheckCircleIcon } from 'components/Icons';
import { CircularProgress } from 'components/CircularProgress';

const getParticipantStatusIndicator = (status: ParticipantStatus) => {
  switch (status) {
    case ParticipantStatus.NoPermissions:
      return <AlertCircleOutlineIcon className="no-permissions" />;
    case ParticipantStatus.RoleRequired:
      return <CircularProgress fullscreen={false} size={16} thickness={4.5} />;
    case ParticipantStatus.Active:
    default:
      return <CheckCircleIcon className="active" />;
  }
};

export default getParticipantStatusIndicator;
