import React from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Body } from 'components/Typography';

const defaultDiscardChangesText =
  'Any unsaved modifications will be lost. Do you want to discard your changes and continue without saving?';

interface DiscardChangesModalProps extends ModalProps {
  text?: string;
  showBackButton?: boolean;
}

const DiscardChangesModal = ({
  onClose,
  open,
  onSubmit,
  loading,
  showBackButton = true,
  submitLabel = 'Discard',
  text = defaultDiscardChangesText,
  title = 'Discard changes?',
  ...props
}: DiscardChangesModalProps): JSX.Element => {
  return (
    <Modal
      cancelLabel="Cancel"
      danger
      loading={loading}
      noPaddingY
      onBack={showBackButton ? onClose : undefined}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitLabel={submitLabel}
      title={title}
      {...props}
    >
      <Body>{text}</Body>
    </Modal>
  );
};

export default DiscardChangesModal;
