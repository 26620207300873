import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IPrestartOsceMarksheetData,
  IPrestartOsceMarksheetVar,
  PRESTART_OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'components/Snackbar';
import { paths } from 'Router';
import { OnRequestComplete } from 'types';

const { dashboard } = paths;

const usePrestartMarksheetMutation = (
  onPrestartComplete?: OnRequestComplete<IPrestartOsceMarksheetData>
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [prestart, { loading: prestartLoading }] = useMutation<
    IPrestartOsceMarksheetData,
    IPrestartOsceMarksheetVar
  >(PRESTART_OSCE_MARKSHEET, {
    onCompleted: data => {
      if (!data) {
        enqueueSnackbar('Prestarting study failed.');
        navigate(dashboard);
      }

      if (onPrestartComplete) {
        onPrestartComplete(data);
      }
    },
    onError: () => {
      navigate(dashboard);
    },
  });

  const prestartMarksheet = useCallback(
    (
      osceMarksheetId: number,
      stationTime: number,
      readingTime: number,
      feedbackTime: number,
      isTimed = true
    ) =>
      prestart({
        variables: {
          feedbackTime,
          readingTime,
          stationTime,
          osceMarksheetId,
          isTimed,
        },
      }),
    [prestart]
  );

  return { prestartLoading, prestartMarksheet };
};

export default usePrestartMarksheetMutation;
