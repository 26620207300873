import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardKey, Nullable } from 'types';
import SearchController from './SearchController';
import { useAuth } from 'Auth';
import useSearchState from './useSearchState';

const Search = (): Nullable<JSX.Element> => {
  const { open, openSearch, closeSearch } = useSearchState();
  const { isAuthenticated } = useAuth();

  useHotkeys(
    KeyboardKey.Slash,
    event => {
      event.preventDefault();
      openSearch();
    },
    {
      enabled: () => isAuthenticated,
    },
    [isAuthenticated]
  );

  useHotkeys(
    KeyboardKey.CtrlK,
    event => {
      event.preventDefault();
      openSearch();
    },
    {
      enabled: () => isAuthenticated,
    },
    [isAuthenticated]
  );

  useHotkeys(
    KeyboardKey.MetaK,
    event => {
      event.preventDefault();
      openSearch();
    },
    {
      enabled: () => isAuthenticated,
    },
    [isAuthenticated]
  );

  return open ? <SearchController onClose={closeSearch} open={open} /> : null;
};

export default Search;
