import Link, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export type LinkProps<C extends React.ElementType = React.ElementType> =
  MuiLinkProps<C, { component?: C }>;

const StyledLink = styled(Link)<LinkProps>(
  ({ theme: { palette, spacing, typography, transitions } }) => ({
    ...typography.body1Medium,
    color: palette.text.primary,
    textDecoration: 'underline',
    transition: transitions.create(['color']),
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacing(2),

    '&:hover': {
      color: palette.primary.dark,
    },
  })
);

export default StyledLink;
