import { ApolloLink } from '@apollo/client';

import { isOnlineState } from 'hooks';
import { SnackBar } from 'types';

const onlyOnlineActions = [
  'StartOrJoinMarksheet',
  'PreBuildMarksheet',
  'PreBuildTodo',
  'BuildMarksheet',
  'BuildMockTestMarksheet',
  'BuildOsceMarksheet',
  'BuildQuestionMarksheet',
  'BuildTodo',
  'StartOsceMatchmaking',
  'CreateOrJoinOsceMarksheet',
  'PrestartOsceMarksheet',
  'StartOsceMarksheet',
];

export const requestFilterLink = (snackbar: SnackBar) =>
  new ApolloLink((operation, forward) => {
    const { isOnline } = isOnlineState.getState();
    const { operationName } = operation || {};

    if (!isOnline && onlyOnlineActions.includes(operationName)) {
      snackbar('No internet connection.');

      return null;
    }

    return forward(operation);
  });
