import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface SearchState {
  open: boolean;
  openSearch: () => void;
  closeSearch: () => void;
}

const useSearch = create<SearchState>()(set => ({
  open: false,
  openSearch: () => set({ open: true }),
  closeSearch: () => set({ open: false }),
}));

const useSearchState = () => useSearch(state => state, shallow);

export default useSearchState;
