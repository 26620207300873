import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { paths } from 'Router';
import { Layout, LoginLayout } from 'components/Layout';
import { Register } from 'pages/Register';
import {
  CheckEmail,
  ForgotPassword,
  Login,
  PasswordChanged,
  ResetPassword,
} from 'pages/Login';
import { NotFound } from 'pages/NotFound';
import { CircularProgress } from 'components/CircularProgress';

const Sample = lazy(() => import('../pages/Sample/Sample'));
const SampleQuestionsQuery = lazy(
  () => import('../pages/Sample/SampleQuestionsQuery')
);
const SampleQuestionsSummary = lazy(
  () => import('../pages/Sample/SampleQuestionsSummary')
);
const SampleStationsQuery = lazy(
  () => import('../pages/Sample/SampleStationsQuery')
);
const SampleStationsSummary = lazy(
  () => import('../pages/Sample/SampleStationsSummary')
);
const SampleMockTestsQuery = lazy(
  () => import('../pages/Sample/SampleMockTestsQuery')
);

export const UnauthenticatedApp = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route element={<Login />} path={paths.root} />
        <Route element={<Login />} path={paths.login} />
        <Route element={<CheckEmail />} path={paths.checkEmail} />
        <Route element={<PasswordChanged />} path={paths.passwordChanged} />
        <Route element={<ForgotPassword />} path={paths.forgotPassword} />
        <Route element={<ResetPassword />} path={paths.resetPassword} />
      </Route>
      <Route element={<Register />} path={paths.register} />
      {/* SAMPLE */}
      <Route element={<Layout simplified withUser={false} />}>
        <Route
          element={
            <Suspense fallback={<CircularProgress />}>
              <Sample />
            </Suspense>
          }
          path={paths.sample.root}
        >
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleQuestionsQuery />
              </Suspense>
            }
            path={paths.sample.questions}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleQuestionsSummary />
              </Suspense>
            }
            path={paths.sample.questionsSummary}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleStationsQuery />
              </Suspense>
            }
            path={paths.sample.stations}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleStationsSummary />
              </Suspense>
            }
            path={paths.sample.stationsSummary}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleMockTestsQuery />
              </Suspense>
            }
            path={paths.sample.mockTests}
          />
          <Route
            element={
              <Suspense fallback={<CircularProgress />}>
                <SampleQuestionsSummary />
              </Suspense>
            }
            path={paths.sample.mockTestsSummary}
          />
        </Route>
      </Route>
      <Route element={<Navigate replace to={paths.login} />} path="*" />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};
