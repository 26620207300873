import React from 'react';
import { mdiContentCopy } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CopyIcon = (props: IconProps) => (
  <Icon path={mdiContentCopy} {...props} />
);

export default CopyIcon;
