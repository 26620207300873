import React from 'react';
import { styled } from '@mui/material/styles';
import { ToggleButton as ToggleOption } from '@mui/material';
import Box from '@mui/material/Box';

const StyledToggle = styled(ToggleOption)(
  ({ theme: { palette, spacing, transitions } }) => ({
    height: spacing(10),
    borderRadius: '6px',
    boxShadow: 'none',
    display: 'inline-flex',
    gap: spacing(2),

    transition: transitions.create([
      'border-color',
      'color',
      'background-color',
    ]),

    '&:hover': {
      boxShadow: 'none',
    },
    '&.MuiToggleButton-standard': {
      backgroundColor: palette.toggle.light,
      color: palette.toggle.contrastTextDark,
      border: `1px solid ${palette.toggle.light}`,
      borderRadius: '6px',
    },
    '&.MuiToggleButton-standard:hover': {
      backgroundColor: palette.secondary.light,
      color: palette.toggle.contrastTextDark,
      border: `1px solid ${palette.secondary.light}`,
      borderRadius: '6px',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: palette.toggle.dark,
      color: palette.toggle.contrastText,
      border: `1px solid ${palette.toggle.dark}`,
      borderRadius: '6px',
    },
    '&.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: palette.toggle.main,
      color: palette.toggle.contrastText,
      border: `1px solid ${palette.toggle.main}`,
      borderRadius: '6px',
    },
  })
);

interface ToggleButtonProps<T> {
  icon?: any;
  label: string;
  value: T;
  selected: boolean;
  onChange?: () => void;
}

function ToggleButton<T>({
  icon,
  label,
  value,
  selected,
  onChange,
}: ToggleButtonProps<T>): JSX.Element {
  const displayIcon = icon ? icon : null;

  return (
    <StyledToggle
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onChange={onChange}
      selected={selected}
      value={value}
    >
      <Box component="span">{displayIcon}</Box>
      <Box component="span">{label}</Box>
    </StyledToggle>
  );
}

export default ToggleButton;
