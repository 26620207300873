import React from 'react';
import { styled } from '@mui/material/styles';
import Divider, { DividerProps } from '@mui/material/Divider';
import Box from '@mui/material/Box';
import clsx from 'clsx';

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
}));

const StyledDivider = styled(Divider)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'flex',
    ...typography.button,
    color: palette.secondary.main,

    '&.small': {
      margin: spacing(4, 0),
    },

    '&.normal': {
      margin: spacing(6, 0),
    },

    '&.dashed': {
      '&::before, &::after': {
        borderTop: `1px dashed ${palette.icon.light}`,
      },
    },

    '&.solid': {
      '&::before, &::after': {
        borderTop: `1px solid ${palette.icon.light}`,
      },
    },

    '&.MuiDivider-textAlignLeft': {
      '&::before': {
        width: '2%',
      },
    },

    '&.MuiDivider-textAlignRight': {
      '&::after': {
        width: '2%',
      },
    },

    '& .MuiDivider-wrapper': {
      paddingLeft: spacing(2),
    },
  })
);

type SeparatorVariant = 'dashed' | 'solid';
type SeparatorGaps = 'small' | 'normal';

interface ISeparatorProps {
  textAlign?: DividerProps['textAlign'];
  title: string;
  icon?: React.ReactNode;
  gap?: SeparatorGaps;
  variant?: SeparatorVariant;
}

const Separator = ({
  textAlign = 'center',
  title,
  icon,
  gap = 'normal',
  variant = 'dashed',
}: ISeparatorProps): JSX.Element => (
  <StyledDivider className={clsx(gap, variant)} textAlign={textAlign}>
    <StyledBox>
      {icon} {title}
    </StyledBox>
  </StyledDivider>
);

export default Separator;
