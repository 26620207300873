import React from 'react';
import { styled } from '@mui/material';

import { Popper, PopperProps } from 'components/Popper';
import { Nullable } from 'types';

interface UtilPopperProps extends PopperProps {
  anchorEl: Nullable<HTMLElement>;
  open: boolean;
  children: Nullable<JSX.Element>;
}

const StyledPopper = styled(Popper)(({ theme: { breakpoints, spacing } }) => ({
  '&> .MuiPaper-root': {
    margin: spacing(4),
    marginBottom: spacing(9),

    [breakpoints.up('md')]: {
      marginBottom: spacing(4),
    },
  },
}));

const UtilPopper = ({
  anchorEl,
  open,
  children,
  onClose,
  clickOutsideToClose,
  ...popperProps
}: UtilPopperProps): JSX.Element => (
  <StyledPopper
    anchorEl={anchorEl}
    clickOutsideToClose={clickOutsideToClose}
    onClose={onClose}
    open={open}
    placement="top-start"
    {...popperProps}
  >
    {children}
  </StyledPopper>
);

export default UtilPopper;
