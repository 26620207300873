import React from 'react';
import { mdiBell } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const NotificationIcon = (props: IconProps) => (
  <Icon path={mdiBell} {...props} />
);

export default NotificationIcon;
