import React, { useState } from 'react';

import SearchResult from './SearchResult';
import { QuestionsIcon } from 'components/Icons';
import { ExerciseType } from 'types';
import QuestionsPreBuildModal from 'pages/Questions/QuestionsPreBuildModal';
import { SearchIndex } from './type';

export interface QuestionsSearchResultProps {
  onSearchClose: () => void;
  searchString: string;
  hideSearchModal: () => void;
  showSearchModal: () => void;
  setCount: (value: number) => void;
  indexName: SearchIndex;
}

const QuestionsSearchResult = ({
  onSearchClose,
  searchString,
  hideSearchModal,
  showSearchModal,
  indexName,
  setCount,
}: QuestionsSearchResultProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpenQuestionBuilderModal = () => {
    hideSearchModal();
    setOpen(true);
  };

  const handleCloseQuestionBuilderModal = () => {
    showSearchModal();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    onSearchClose();
  };

  return (
    <>
      <SearchResult
        asLink
        icon={QuestionsIcon}
        indexName={indexName}
        onClick={handleOpenQuestionBuilderModal}
        onItemClick={onSearchClose}
        setCount={setCount}
        title={ExerciseType.Questions}
      />
      {open ? (
        <QuestionsPreBuildModal
          onBack={handleCloseQuestionBuilderModal}
          onClose={handleClose}
          onStartQuiz={handleClose}
          open={open}
          search={searchString}
          source={`search ${ExerciseType.Questions}`}
        />
      ) : null}
    </>
  );
};

export default QuestionsSearchResult;
