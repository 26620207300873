import React from 'react';
import { mdiPaletteOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const PaletteOutlineIcon = (props: IconProps) => (
  <Icon path={mdiPaletteOutline} {...props} />
);

export default PaletteOutlineIcon;
