import React, { useMemo } from 'react';
import {
  Configure,
  InstantSearch,
  InstantSearchProps,
} from 'react-instantsearch';

import { searchClient } from './algolia';
import { Children } from 'types';
import {
  PROD_SEARCH_PREFIX,
  SearchScopeIndex,
  TEST_SEARCH_PREFIX,
} from 'components/Search/type';
import { usePlatform } from 'context/PlatformContext';
import { isDev } from 'config/constants';

export const config = {
  hitsPerPage: 40,
};
interface SerchConfig {
  hitsPerPage?: number;
  attributesToRetrieve?: string[];
  filters?: string;
}

const pattern = /productId:[0-9]{1,2}/;

export interface AlgoliaSearchProps
  extends Omit<InstantSearchProps, 'searchClient'> {
  indexName: SearchScopeIndex;
  config?: SerchConfig;
  children: Children;
}

const AlgoliaProvider = ({
  children,
  indexName,
  config = {},
  ...props
}: AlgoliaSearchProps): JSX.Element => {
  const { product } = usePlatform();
  const prefix = isDev ? TEST_SEARCH_PREFIX : PROD_SEARCH_PREFIX;
  const searchIndex = `${prefix}${indexName}`;

  config.filters = useMemo(() => {
    if (config.filters) {
      if (pattern.test(config.filters)) {
        return config.filters.replace(pattern, `productId:${product}`);
      }

      return `${config.filters} AND productId:${product}`;
    }

    return `productId:${product}`;
  }, [config.filters, product]);

  return (
    <InstantSearch
      indexName={searchIndex}
      searchClient={searchClient}
      {...props}
    >
      {/* @ts-expect-error for this specific components types are not recognized properly */}
      <Configure {...config} />
      {children}
    </InstantSearch>
  );
};

export default AlgoliaProvider;
