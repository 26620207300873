import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { constSize } from 'utils/styles/constSize';

export type IconComponent = (props: IconProps) => JSX.Element;

export type IconProps = Omit<SvgIconProps, 'color'> & { color?: string };

const SvgIcon = styled(MuiSvgIcon)<IconProps>(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...constSize(theme.spacing(6)),
  },
}));

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'inherit', path, ...props }, ref): JSX.Element => (
    <SvgIcon ref={ref} sx={{ color }} {...props}>
      <path d={path} />
    </SvgIcon>
  )
);

Icon.displayName = 'Icon';

export default Icon;
