import { useMemo } from 'react';
import { EAppType } from '@quesmed/types-rn/models';

import {
  ALLOWED_PRODUCTS,
  AppProductType,
  productsApp,
  ProductState,
  ProductSubscriptionsState,
} from 'types';
import { useAuth } from './useAuth';
import { entries, getSubscriptions } from 'utils';

type SubscriptionData = {
  products?: AppProductType[];
  apps?: EAppType[];
  productStates: ProductSubscriptionsState;
};

const useSubscription = (): SubscriptionData => {
  const { tokenDecoded } = useAuth();

  const subscriptions = useMemo(
    () => getSubscriptions(tokenDecoded),
    [tokenDecoded]
  );

  const data = useMemo(() => {
    const availableProducts: AppProductType[] = [];
    const availableApps: Set<EAppType> = new Set();
    const productStates = {} as ProductSubscriptionsState;

    entries(subscriptions).forEach(([subscriptionName, state]) => {
      const allowedProducts = ALLOWED_PRODUCTS[subscriptionName];

      allowedProducts?.forEach(product => {
        productStates[product] =
          productStates[product] === ProductState.Active ||
          state === ProductState.AboutToExpired
            ? ProductState.Active
            : state;
      });

      if (
        state === ProductState.Active ||
        state === ProductState.AboutToExpired
      ) {
        availableProducts.push(...allowedProducts);
        const [product] = allowedProducts;
        availableApps.add(productsApp[product as AppProductType]);
      }
    });

    return {
      products: availableProducts.length ? availableProducts : undefined,
      apps: availableApps.size ? [...availableApps] : undefined,
      productStates,
    };
  }, [subscriptions]);

  return data;
};

export default useSubscription;
