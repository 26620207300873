import React from 'react';
import { mdiCheckboxBlankOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CheckboxBlankIcon = (props: IconProps) => (
  <Icon path={mdiCheckboxBlankOutline} {...props} />
);

export default CheckboxBlankIcon;
