import React from 'react';
import { mdiLockOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const LockOutlineIcon = (props: IconProps) => (
  <Icon path={mdiLockOutline} {...props} />
);

export default LockOutlineIcon;
