import { STORAGE_KEYS } from 'config/constants';
import { sessionStorageTyped } from 'utils/sessionstorage';

export const notesStorage = () => {
  const {
    getItem,
    removeItem: removeNotes,
    setItem: setNotes,
  } = sessionStorageTyped<string>(STORAGE_KEYS.USER_NOTES);

  const getNotes = () => getItem() || '';

  return {
    getNotes,
    removeNotes,
    setNotes,
  };
};
