import { EProductType } from '@quesmed/types-rn/models';

import { Nullable } from 'types';

const STATION_PRODUCTS = [
  EProductType.OSCE,
  EProductType.PACES,
  EProductType.PLAB2,
];

export const isStationsProduct = (product?: Nullable<EProductType>) =>
  Boolean(product && STATION_PRODUCTS.includes(product));
