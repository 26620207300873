import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import { EProductType } from '@quesmed/types-rn/models';

import SettingsSectionOverline from './SettingsSectionOverline';
import {
  AppProductType,
  productsApp,
  QuesmedAppNames,
  UserSubscription,
} from 'types';
import { SubscriptionCard } from 'components/SubscriptionCard';
import { useSettingsModalState } from './useSettingsModalState';
import { paths } from 'Router';

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(8),
  '& > .MuiBox-root': {
    marginBottom: spacing(6),
  },
}));

export interface SubscriptionsGroupProps {
  subscriptions: UserSubscription[];
  title: string;
}

const SubscriptionsGroup = ({
  subscriptions,
  title,
}: SubscriptionsGroupProps): JSX.Element => {
  const { setIsSettingsOpen } = useSettingsModalState();
  const navigate = useNavigate();

  const handleChangePlan = (subscription: EProductType) => () => {
    const parsedAppType = kebabCase(
      QuesmedAppNames[productsApp[subscription as AppProductType]]
    );

    setIsSettingsOpen(false);

    navigate(`${paths.pricing.root}/${parsedAppType}`, {
      state: { product: subscription },
    });
  };

  return (
    <StyledBox>
      <SettingsSectionOverline text={title} />
      {subscriptions.map(
        ({
          subscriptionId,
          subscription,
          isCanceled,
          renewalDate,
          price,
          duration,
          canBeUpdated,
        }) => (
          <SubscriptionCard
            canBeUpdated={canBeUpdated}
            duration={duration}
            isCanceled={isCanceled}
            key={subscription}
            onChangePlan={handleChangePlan(subscription)}
            price={price}
            renewsDate={renewalDate}
            subscription={subscription}
            subscriptionId={subscriptionId}
          />
        )
      )}
    </StyledBox>
  );
};

export default SubscriptionsGroup;
