import React, { forwardRef } from 'react';
import { mdiProgressCheck } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ProgressCorrectIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <Icon path={mdiProgressCheck} ref={ref} {...props} />
);

ProgressCorrectIcon.displayName = 'ProgressCorrectIcon';

export default ProgressCorrectIcon;
