import { create } from 'zustand';

interface MatchmakingLoadingState {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const useMatchmakingLoadingState = create<MatchmakingLoadingState>()(set => ({
  loading: false,
  setLoading: loading => set({ loading }),
}));

export default useMatchmakingLoadingState;
