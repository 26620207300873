import React from 'react';
import { Box, styled } from '@mui/material';

import { Body } from 'components/Typography';
import { LabsDataType } from './types';

const Row = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  marginBottom: spacing(3),
  justifyContent: 'space-between',
}));

const Value = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.primary,
}));

const StyledRows = styled(Box)(({ theme: { palette, spacing } }) => ({
  color: palette.text.secondary,
  marginTop: spacing(4),
}));

interface LabsDataProps {
  data: LabsDataType;
}

const LabsData = ({ data }: LabsDataProps) => (
  <StyledRows>
    {data.map(([title, value]) => (
      <Row key={title + value}>
        <Body small>{title}</Body>
        <Value bold small>
          {value}
        </Value>
      </Row>
    ))}
  </StyledRows>
);

export default LabsData;
