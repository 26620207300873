import { FetchResult } from '@apollo/client';
import {
  EDifficultyType,
  EOsceRoles,
  EOsceType,
  EPaceMarkType,
  EStudyAction,
  IOsceStationMark,
  IPicture,
} from '@quesmed/types-rn/models';
import { ICreateOrJoinOsceMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { Dictionary } from 'lodash';

import { Nullable } from './general';

export enum Filter {
  ROLE = 'Role',
  TOPIC = 'Speciality',
  DIFFICULTY = 'Difficulty',
  STATUS = 'Status',
}

export enum StationCategory {
  HistoryTaking = 'History Taking',
  CommunicationSkills = 'Communication Skills',
  Examination = 'Examination',
  ABCDE = 'ABCDE',
  Procedures = 'Procedures',
  DataInterpretation = 'Data Interpretation',
}

export type StationType = Exclude<EOsceType, EOsceType.ALL>;

export type Difficulty = Exclude<
  EDifficultyType,
  EDifficultyType.ALL | EDifficultyType.QUICK_FIRE // TODO add QUICK_FIRE when ready
>;

export type StationDifficulties = {
  [key in Difficulty]: string;
};

export type StationRole = Exclude<EOsceRoles, EOsceRoles.ALL>;

export enum StationRoleName {
  Actor = 'Actor',
  Candidate = 'Candidate',
  Examiner = 'Examiner',
  Observer = 'Observer',
}

export type StationRoleData = {
  name: StationRoleName;
  icon: JSX.Element;
};

export type StationRoles = {
  [key in StationRole]: StationRoleData;
};

export enum Brief {
  CANDIDATE = 'Candidate Brief',
  ACTOR = 'Actor Brief',
  EXAMINER = 'Examiner Brief',
  MARKSCHEME = 'Markscheme',
  WALKTHROUGH = 'Walkthrough Guide',
  PATIENT = 'Patient Brief',
}

export interface BriefData {
  title: Brief;
  pictures?: IPicture[];
  marks: Nullable<Dictionary<IOsceStationMark[]>>;
  content: Nullable<string>;
}

export type CreateOrJoinMutation = (
  action: EStudyAction,
  callback?: (id?: string) => void
) => Promise<FetchResult<ICreateOrJoinOsceMarksheetData>>;

export const LABELS = {
  [EPaceMarkType.SATISFACTORY]: 'Satisfactory',
  [EPaceMarkType.BORDERLINE]: 'Borderline',
  [EPaceMarkType.UN_SATISFACTORY]: 'Unsatisfactory',
};

export interface SoloTimerData {
  now: number;
  timerValue: number;
  id: number;
  paused: boolean;
}
