import { DashboardTilesSettings } from 'types';
import {
  ActionCreator,
  ActionTypes,
  CheckoutWithSelectedPlanAction,
  CustomiseDashboardAction,
  FontSizeAction,
  IsMenuOpenAction,
  LogoutCallbackAction,
} from './types';

export const setDashboardSettingsAction: ActionCreator = (
  payload: DashboardTilesSettings
): CustomiseDashboardAction => ({
  payload,
  type: ActionTypes.CustomiseDashboard,
});

export const setCheckoutWithSelectedPlanAction: ActionCreator = (
  payload: boolean
): CheckoutWithSelectedPlanAction => ({
  payload,
  type: ActionTypes.CheckoutWithSelectedPlan,
});

export const setFontSizeAction: ActionCreator = (
  payload: number
): FontSizeAction => ({
  payload,
  type: ActionTypes.FontSize,
});

export const setIsMenuOpenAction: ActionCreator = (
  payload: boolean
): IsMenuOpenAction => ({
  payload,
  type: ActionTypes.IsMenuOpen,
});

export const setLogoutCallbackdAction: ActionCreator = (
  payload?: () => Promise<any>
): LogoutCallbackAction => ({
  payload,
  type: ActionTypes.LogoutCallback,
});
