import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface FeedbackModalState {
  open: boolean;
  openFeedbackModal: () => void;
  closeFeedbackModal: () => void;
}

export const feedbackModal = create<FeedbackModalState>()(set => ({
  open: false,
  openFeedbackModal: () => set({ open: true }),
  closeFeedbackModal: () => set({ open: false }),
}));

export const useFeedbackModal = () => feedbackModal(state => state, shallow);
