import { MutableRefObject, useEffect, useRef, useState } from 'react';

// https://usehooks.com/useHover/
const useHover = <T>(): [
  MutableRefObject<T>,
  boolean,
  T | null,
  React.Dispatch<React.SetStateAction<T | null>>
] => {
  const [value, setValue] = useState<boolean>(false);
  const [node, setNode] = useState<T | null>(null);
  const ref: any = useRef<T | null>(null);

  const handleMouseOver = (): void => {
    setValue(true);
  };
  const handleMouseOut = (): void => {
    setValue(false);
  };

  useEffect(
    () => {
      const refNode: any = ref.current;

      if (refNode) {
        refNode.addEventListener('mouseenter', handleMouseOver);
        refNode.addEventListener('mouseleave', handleMouseOut);

        return () => {
          refNode.removeEventListener('mouseenter', handleMouseOver);
          refNode.removeEventListener('mouseleave', handleMouseOut);
        };
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  );

  useEffect(() => {
    const refNode: any = node;

    if (refNode) {
      refNode.addEventListener('mouseenter', handleMouseOver);
      refNode.addEventListener('mouseleave', handleMouseOut);

      return () => {
        refNode.removeEventListener('mouseenter', handleMouseOver);
        refNode.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [node]);

  return [ref, value, node, setNode];
};

export default useHover;
