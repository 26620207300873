import { MAX_QUESTION_VALUE } from 'config/constants';

export const calcMax = (
  unseen: number,
  seenIncorrect: number,
  seenCorrect: number,
  isUnseen: boolean,
  isPreviouslyIncorrect: boolean,
  isPreviouslyCorrect: boolean
): number => {
  const sum = unseen + seenCorrect + seenIncorrect;
  let max = sum;

  if (!isUnseen) {
    max -= unseen;
  }
  if (!isPreviouslyCorrect) {
    max -= seenCorrect;
  }
  if (!isPreviouslyIncorrect) {
    max -= seenIncorrect;
  }

  return Math.min(max, MAX_QUESTION_VALUE);
};
