import { EPlatformId } from '@quesmed/types-rn';
import { Dispatch as ReactDispatch } from 'react';
import {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';

import { Children } from 'types';

export type ChannelId = string | undefined | null;

export interface ConnectOptions {
  withVideo?: boolean;
  withPublish?: boolean;
}

export interface UserVolume {
  level: number;
  uid: number;
}

export enum AgoraConnectionState {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  RECONNECTING = 'RECONNECTING',
  DISCONNECTING = 'DISCONNECTING',
}

export enum AgoraEvent {
  USER_PUBLISHED = 'user-published',
  USER_UNPUBLISHED = 'user-published',
  USER_LEFT = 'user-left',
  USER_JOINED = 'user-joined',
  USER_INFO = 'user-info-updated',
  TOKEN_WILL_EXPIRE = 'token-privilege-will-expire',
  TOKEN_DID_EXPIRE = 'token-privilege-did-expire',
  VOLUME_INDIATOR = 'volume-indicator',
}

export enum AgoraInfoMessage {
  MUTE = 'mute-audio',
  UNMUTE = 'unmute-audio',
}

export enum Media {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum AgoraMessages {
  JOINED = "You are live! You've joined the group session's channel.",
  WILL_EXPIRED = 'The chat will be suspended in 30 seconds.',
  DID_EXPIRED = 'The chat is suspended.',
  VIDEO_ERROR = 'Turnin on the video failed.',
  AUDIO_ERROR = 'Turning on the audio failed.',
  AUDIO_PUBLISH_ERROR = 'Streaming audio failed.',
  AUDIO_UNPUBLISH_ERROR = 'Turning off the audio faled.',
  VIDEO_PUBLISH_ERROR = 'Streaming video failed.',
  VIDEO_UNPUBLISH_ERROR = 'Turning off the video faled.',
  GENERAL = 'Connecting to the chat failed.',
  TOKEN = 'Turning on the chat failed.',
}

export enum ActionTypes {
  AudioEnabled = 'AGORA/AUDIO_ENABLED',
  VideoEnabled = 'AGORA/VIDEO_ENABLED',
  ActiveSpeaker = 'AGORA/ACTIVE_SPEAKER',
  MutedUsers = 'AGORA/MUTED_USERS',
  LocalVideoTrack = 'AGORA/LOCAL_VIDEO_TRACK',
  SessionExpired = 'AGORA/SESSION_EXPIRED',
  ChannelId = 'AGORA/CHANNEL_ID',
  UserId = 'AGORA/USER_ID',
  InitVideo = 'AGORA/INIT_VIDEO',
  InitAudio = 'AGORA/INIT_AUDIO',
  InitSession = 'AGORA/INIT_SESSION',
  WillExpired = 'AGORA/WILL_EXPIRED',
  Preview = 'AGORA/PREVIEW',
  Platform = 'AGORA/PLATFORM',
}

export interface PlatformAction {
  type: ActionTypes.Platform;
  payload: EPlatformId;
}
export interface PreviewAction {
  type: ActionTypes.Preview;
  payload: boolean;
}

export interface WillExpiredAction {
  type: ActionTypes.WillExpired;
  payload: boolean;
}

export interface InitSessionAction {
  type: ActionTypes.InitSession;
  payload: boolean;
}

export interface InitAudioAction {
  type: ActionTypes.InitAudio;
  payload: boolean;
}

export interface InitVideoAction {
  type: ActionTypes.InitVideo;
  payload: boolean;
}

export interface UserIdAction {
  type: ActionTypes.UserId;
  payload?: number;
}

export interface ChannelIdAction {
  type: ActionTypes.ChannelId;
  payload: ChannelId;
}

export interface SessionExpiredAction {
  type: ActionTypes.SessionExpired;
  payload: boolean;
}
export interface AudioEnabledAction {
  type: ActionTypes.AudioEnabled;
  payload: boolean;
}

export interface VideoEnabledAction {
  type: ActionTypes.VideoEnabled;
  payload: boolean;
}

export interface ActiveSpeakerAction {
  type: ActionTypes.ActiveSpeaker;
  payload?: UserVolume;
}

export interface MutedUsersAction {
  type: ActionTypes.MutedUsers;
  payload: number[];
}

export interface AgoraState {
  audioEnabled: boolean; // indicates if audio track is enabled or not
  mutedUsers: number[]; // contains ids of all muted users (users with audia track disabled)
  videoEnabled: boolean; // indicates if video track is enabled or not
  sessionExpired: boolean; // indicates if Agora token is expired and chat is suspended
  channelId?: ChannelId; // agoraId from the join link or osce mutation used during joining tothe channel
  userId?: number; // current user Id
  initVideo: boolean; // indicates if video track can be created
  initAudio: boolean; // indicates if audio track can be created
  initSession: boolean; // indicates that Agora session can initiated
  willExpired: boolean; // indicates that token is going to be expired
  preview: boolean; // indicates that video can be preview but not published
  platform?: EPlatformId;
}

export interface ActiveSpeaker {
  activeSpeaker?: UserVolume;
}

export interface AgoraAPI {
  audioReady: boolean;
  audioTrack: IMicrophoneAudioTrack | null;
  client: IAgoraRTCClient;
  connect: () => Promise<void>;
  disconnect: (hardDisconnect?: boolean) => Promise<void>;
  endSession: () => Promise<void>;
  isConnected: () => boolean;
  publishAudio: () => Promise<void>;
  publishVideo: (offByDefault?: boolean) => Promise<void>;
  startAudio: () => Promise<void>;
  startVideo: () => Promise<void>;
  unpublishVideo: () => Promise<void>;
  videoReady: boolean;
  videoTrack: ICameraVideoTrack | null;
}

export type AgoraAction =
  | ActiveSpeakerAction
  | AudioEnabledAction
  | ChannelIdAction
  | InitSessionAction
  | InitAudioAction
  | InitVideoAction
  | MutedUsersAction
  | PlatformAction
  | PreviewAction
  | SessionExpiredAction
  | UserIdAction
  | VideoEnabledAction
  | WillExpiredAction;

export type ActionCreator = (payload: any) => AgoraAction;

export type Dispatch = ReactDispatch<AgoraAction>;

export type AgoraDispatch = {
  setAudioEnabled: (payload: boolean) => void;
  setVideoEnabled: (payload: boolean) => void;
  setMutedUsers: (payload: number[]) => void;
  setSessionExpired: (payload: boolean) => void;
  setChannelId: (payload: ChannelId) => void;
  setUserId: (payload?: number) => void;
  setInitSession: (payload: boolean) => void;
  setInitAudio: (payload: boolean) => void;
  setInitVideo: (payload: boolean) => void;
  setWillExpired: (payload: boolean) => void;
  setPreview: (payload: boolean) => void;
  setPlatform: (payload: EPlatformId) => void;
};

export interface AgoraDispatchContextProviderProps {
  children: Children;
  value: Dispatch;
}

export interface AgoraStateContextProviderProps {
  value: AgoraState;
  children: Children;
}

export type ActiveSpeakerDispatch = {
  setActiveSpeaker: (payload?: UserVolume) => void;
};
export interface ActiveSpeakerContextProviderProps {
  value: ActiveSpeaker;
  children: Children;
}

export interface ActiveSpeakerDispatchProviderProps {
  value: Dispatch;
  children: Children;
}
