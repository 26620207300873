import React, { SVGProps } from 'react';

import { StyledSVG } from './ProductStyle';

export const ProductTwo = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <StyledSVG
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6438_3355)">
      <rect
        className="background"
        fill="#B5B8C1"
        height="64"
        rx="8"
        width="64"
      />
      <rect
        className="foreground-one"
        fill="#DADCE0"
        height="60.831"
        rx="8"
        transform="rotate(-44.6572 21 31.5068)"
        width="131.614"
        x="21"
        y="31.5068"
      />
    </g>
    <defs>
      <clipPath id="clip0_6438_3355">
        <rect fill="white" height="64" rx="8" width="64" />
      </clipPath>
    </defs>
  </StyledSVG>
);
