import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/system/Box';
import { Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Body, H6 } from 'components/Typography';

export const Drawer = styled(MuiDrawer)(
  ({
    theme: {
      breakpoints,
      palette,
      mixins: { appBar },
    },
  }) => ({
    '.MuiDrawer-paper': {
      boxShadow: 'none',
      borderTop: `1px solid ${palette.stroke.main}`,
      borderLeft: `1px solid ${palette.stroke.main}`,
      height: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
      marginTop: appBar.mobile.minHeight,

      [breakpoints.up('md')]: {
        height: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
        marginTop: appBar.desktop.minHeight,
      },
    },
  })
);

export const NotificationsContainer = styled(Box)(
  ({ theme: { breakpoints, palette } }) => ({
    width: '100dvw',
    height: '100dvh',
    maxHeight: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.background.paper,

    [breakpoints.up('sm')]: {
      width: '25dvw',
      minWidth: '400px',
      maxWidth: '400px',
    },
  })
);

export const HeaderDivider = styled(Divider)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    borderBottom: `1px solid ${palette.stroke.main}`,
    width: '100%',
    margin: spacing(4, 0, 6),

    [breakpoints.up('md')]: {
      margin: spacing(6, 0),
    },
  })
);

export const NotificationsHeader = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    padding: spacing(4, 4, 0, 4),
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.up('md')]: {
      padding: spacing(8, 8, 0, 8),
    },
  })
);

export const NotificationsHeading = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const NotificationsToggle = styled(Box)(
  ({ theme: { breakpoints, typography, spacing } }) => ({
    marginTop: spacing(4),

    [breakpoints.up('md')]: {
      marginTop: spacing(6),
    },

    '& .MuiLoadingButton-root': {
      width: '100%',
      height: '100%',
      ...typography.body1Medium,
      textTransform: 'none',
      letterSpacing: 'initial',
    },
  })
);

export const Date = styled(Box)(
  ({ theme: { breakpoints, spacing, typography, palette } }) => ({
    ...typography.button,
    color: palette.text.disabled,
    padding: spacing(0, 4, 1, 4),
    [breakpoints.up('md')]: {
      padding: spacing(0, 8, 1, 8),
    },
  })
);

export const NotificationGroup = styled(Box)(({ theme: { spacing } }) => ({
  paddingTop: spacing(6),

  '&:first-of-type': {
    paddingTop: spacing(0),
  },
}));

export const NotificationList = styled(Box)({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  aspectRatio: '1 / 1',
});

export const SkeletonContainer = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(0, 8),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(8),
}));

export const SkeletonItem = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: spacing(4),
  width: '100%',

  '& .MuiSkeleton-circular': {
    minWidth: '48px',
  },

  '& .MuiSkeleton-rectangular': {
    borderRadius: '4px',
    width: '100%',
  },
}));

export const SkeletonColumn = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  width: '100%',
}));

export const NotificationsEmptyWrapper = styled(Box)(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacing(16, 8),
  })
);

export const NotificationsEmptyTitle = styled(H6)(({ theme: { spacing } }) => ({
  margin: spacing(4, 0, 2),
}));

export const NotificationsEmptyInfo = styled(Body)(
  ({ theme: { palette } }) => ({
    textAlign: 'center',
    color: palette.text.secondary,
  })
);

export const Link = styled(RouterLink)({
  textDecoration: 'none',
});
