import React from 'react';
import MuiBreadcrumbs, {
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { Children, Icon as IconComponent } from 'types';

const Label = styled(Box)({
  '&.ellipsis': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

type ItemComponent = ({
  className,
  children,
  onClick,
}: {
  className: string;
  children?: Children;
  onClick?: () => void;
}) => JSX.Element | null;

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  items: {
    icon?: IconComponent;
    label: string;
    onClick?: () => void;
    component?: ItemComponent;
  }[];
  breadcrumbItemComponent: ItemComponent;
  component?: typeof MuiBreadcrumbs;
  ellipsis?: boolean;
}

export const Breadcrumbs = ({
  className,
  component: BreadcrumbComponent = MuiBreadcrumbs,
  items,
  breadcrumbItemComponent,
  separator = '>',
  ellipsis,
  ...props
}: BreadcrumbsProps): JSX.Element => (
  <BreadcrumbComponent className={className} separator={separator} {...props}>
    {items.map(
      ({
        icon: Icon,
        label,
        component: ItemComponent = breadcrumbItemComponent,
        onClick,
      }) => (
        <ItemComponent
          className={clsx(className, { link: onClick })}
          key={label}
          onClick={onClick}
        >
          {Icon ? <Icon /> : null}
          <Label className={clsx({ ellipsis })}>{label}</Label>
        </ItemComponent>
      )
    )}
  </BreadcrumbComponent>
);
