import React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Body, H4 } from 'components/Typography';
import { paths } from 'Router';
import {
  ContentContainer,
  ContentHolderContainer,
  ResetButton,
} from './Shared.styles';

const Title = styled(H4)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));

const CheckEmail = (): JSX.Element => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(paths.login);
  };

  return (
    <ContentHolderContainer>
      <ContentContainer>
        <Title>Check your email</Title>
        <Body>
          We&apos;ve sent you instructions. Please check your spam folder if the
          email does not appear immediately.
        </Body>
        <ResetButton
          fullWidth
          onClick={handleSubmit}
          size="large"
          variant="outlined"
        >
          Back to login
        </ResetButton>
      </ContentContainer>
    </ContentHolderContainer>
  );
};

export default CheckEmail;
