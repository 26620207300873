import { LabTabContent, LabValuesTable } from '../types';

const haematology: LabValuesTable = {
  tableHead: 'Haematology',
  tableData: [
    ['Haemoglobin', '(M) 130 - 170 g/L'],
    ['', '(F) 115 - 155 g/L'],
    ['White cell count', '3.0 - 10.0 x 10\u2079 /L'],
    ['Platelets', '150 - 400 x 10\u2079/L'],
    ['Mean cell Haemoglobin (MCH)	', '27 - 33 pg'],
    ['Mean cell haemoglobin concentration (MCHC)	', '320 - 350 g/L'],
    ['Mean cell volume (MCV)', '80 - 96 fL'],
    ['Neutrophils', '2.0 - 7.5 x 10\u2079 /L'],
    ['Lymphocytes', '1.5 - 4.0 x 10\u2079/L'],
    ['Monocytes', '0.2 - 1.0 x 10\u2079/L'],
    ['Eosinophils', '0 - 0.4 x 10\u2079/L'],
    ['Basophils', '0 - 0.1 x 10\u2079/L'],
    ['Reticulocytes', '25 - 100 x 10\u2079/L'],
    ['Packed cell volume (PCV)', ' (M) 0.40 - 0.54'],
    ['', '(F) 0.37 - 0.50'],
    ['Erythrocyte sedimentation rate (ESR)', '< 20 mm/hr'],
    ['D-dimers', '< 0.5 mg/L'],
  ],
};

const coagulation: LabValuesTable = {
  tableHead: 'Coagulation screen',
  tableData: [
    ['International normalised ratio (INR)', '1.0'],
    ['Activated partial thromboplastin time (APTT)', '22 - 41 seconds'],
    ['Prothrombin time (PT)', '10 - 12 seconds'],
    ['Thrombin time (TT)', '9 - 15 seconds'],
    ['Fibrinogen', '15 - 40 g/L'],
    ['Fibrinogen degradation products', '< 8 mg/mL'],
    ['Factor VIII:C', '50 - 150 U/dL'],
    ['Bleeding time', '< 10 minutes'],
  ],
};

const arterial: LabValuesTable = {
  tableHead: 'Arterial blood gas',
  tableData: [
    ['pH', '7.35 - 7.45'],
    ['PaO\u2082', '11 - 15 kPa'],
    ['PaCO\u2082	', '4.6 - 6.4 kPa'],
    ['Bicarbonate', '22 - 30 mmol/L'],
    ['Base excess	', '-2 to +2 mmol/L'],
  ],
};

export const haem: LabTabContent = {
  title: 'Normal Values',
  description:
    'The figures quoted within the themes are all standardised to the units\n' +
    'shown below. Note ranges vary between populations and age groups and it\n' +
    'is important to always check the reference ranges.',
  tables: [haematology, coagulation, arterial],
};
