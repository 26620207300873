import React, { forwardRef, KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import MuiTextInputField, {
  TextFieldProps as MuiTextInputProps,
} from '@mui/material/TextField';
import { ControllerProps, FieldValues } from 'react-hook-form';
import clsx from 'clsx';

export const StyledInput = styled(MuiTextInputField)<MuiTextInputProps>(
  ({ theme: { palette, spacing, typography, transitions } }) => ({
    '&.with-background': {
      backgroundColor: palette.background.paper,
    },

    '& .Mui-focused.MuiFormHelperText-root': {
      color: palette.primary.main,
    },
    '& .Mui-focused.MuiFormHelperText-root.Mui-error': {
      color: palette.error.main,
    },
    '& .MuiTypography-root': {
      color: palette.text.disabled,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '3.5px',
      ...typography.body1,
      borderColor: palette.stroke.main,
      transition: transitions.create(['color']),
      '&.Mui-focused': {
        color: palette.text.primary,
      },

      '& fieldset': {
        borderColor: palette.stroke.main,
        transition: transitions.create(['border-color', 'background-color']),
      },

      '& input::placeholder': {
        color: palette.text.disabled,
        opacity: 1,
      },

      '& .MuiAutocomplete-endAdornment': {
        marginRight: spacing(1),
      },

      '& .MuiSvgIcon-root': {
        color: palette.icon.main,
      },

      '& .MuiInputBase-inputAdornedStart': {
        marginLeft: spacing(2),
      },

      '&:hover': {
        borderColor: palette.secondary.dark,

        '& .MuiSvgIcon-root': {
          color: palette.icon.dark,
        },
      },

      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: palette.icon.light,
        },
      },

      '&:fieldset': {
        borderColor: palette.primary.main,
      },
    },
  })
);

export type TextInputProps = MuiTextInputProps & {
  withBackground?: boolean;
  allowENumber?: boolean;
  component?: typeof StyledInput;
};

export type TextFieldProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
>;

const blockENumber =
  (type: TextInputProps['type'], allowENumber?: boolean) =>
  (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      type === 'number' &&
      !allowENumber &&
      (e.key === 'e' || e.key === '-')
    ) {
      e.preventDefault();
    }
  };

export const TextField = ({
  component: Input = StyledInput,
  withBackground = false,
  allowENumber = false,
  variant = 'outlined',
  size = 'medium',
  type,
  ...props
}: TextInputProps): JSX.Element => {
  const withBackgroundClass = withBackground && variant === 'outlined';

  return (
    <Input
      className={clsx({
        'with-background': withBackgroundClass,
      })}
      onKeyPress={blockENumber(type, allowENumber)}
      size={size}
      type={type}
      variant={variant}
      {...props}
    />
  );
};

export const TextInputRef = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      component: Input = StyledInput,
      withBackground = false,
      variant = 'outlined',
      size = 'medium',
      allowENumber = false,
      type,
      ...props
    },
    ref
  ): JSX.Element => {
    const withBackgroundClass = withBackground && variant === 'outlined';

    return (
      <Input
        className={clsx({
          'with-background': withBackgroundClass,
        })}
        inputRef={ref}
        onKeyPress={blockENumber(type, allowENumber)}
        size={size}
        type={type}
        variant={variant}
        {...props}
      />
    );
  }
);

TextInputRef.displayName = 'TextInputRef';
