import React from 'react';
import { mdiBookshelf } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const BookshelfIcon = (props: IconProps) => (
  <Icon path={mdiBookshelf} {...props} />
);

export default BookshelfIcon;
