import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const ContentBox = styled(Box)(
  ({ theme: { breakpoints, spacing, mixins } }) => ({
    width: '100%',

    maxWidth: `calc(${mixins.content.maxWidth}px + 32px )`,
    margin: '0 auto',
    padding: spacing(0, 4),

    '&.full-size': {
      width: '100%',
      maxWidth: '100%',
    },

    [breakpoints.up('lg')]: {
      padding: spacing(0, 12),
      maxWidth: `calc(${mixins.content.maxWidth}px + 96px)`,

      '&.full-size': {
        width: '100%',
      },
    },
  })
);

interface ViewProps extends BoxProps {
  fullSize?: boolean;
}

const View = ({
  children,
  fullSize = false,
  ...props
}: ViewProps): JSX.Element => (
  <ContentBox className={clsx({ 'full-size': fullSize })} {...props}>
    {children}
  </ContentBox>
);

export default View;
