import React from 'react';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '&.row': {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

interface SkeletonListProps extends SkeletonProps {
  count?: number;
  spacing?: number;
  row?: boolean;
}

const SkeletonList = ({
  count = 7,
  spacing,
  variant = 'rectangular',
  row,
  ...props
}: SkeletonListProps): JSX.Element => (
  <Container className={clsx({ row })} sx={{ gap: spacing }}>
    {Array.from({ length: count }).map((_, i) => (
      <Skeleton key={i} variant={variant} {...props} />
    ))}
  </Container>
);

export default SkeletonList;
