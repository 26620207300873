import React, { SVGProps } from 'react';

import { StyledSVG } from './ProductStyle';

export const ProductBundle = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <StyledSVG
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect className="background" fill="#B5B8C1" height="64" rx="8" width="64" />
    <path
      className="foreground-one"
      d="M59.4667 0.788122C62.1489 2.07981 64 4.82374 64 8V56C64 58.6584 62.7034 61.0139 60.708 62.4686C46.0776 59.7495 35 46.9184 35 31.5C35 16.5207 45.4555 3.98349 59.4667 0.788122Z"
      fill="#DADCE0"
    />
    <path
      className="foreground-two"
      d="M4.71959 0.701334L29.3022 25.5799C32.4077 28.7227 32.3774 33.7879 29.2346 36.8934L3.32628 62.4935C1.31151 61.0408 0 58.6736 0 56V8C0 4.7507 1.93716 1.95385 4.71959 0.701334Z"
      fill="#DADCE0"
    />
  </StyledSVG>
);
