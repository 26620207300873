import { Nullable, StationRole } from 'types';

export interface Participant {
  id: number;
  displayName: string;
  role?: Nullable<StationRole>; // undefined when role is no needed, null when needed but not assigned yet
}

export enum ParticipantStatus {
  NoPermissions,
  RoleRequired,
  Active,
}

export type ParticipantCardVariant = 'lobby' | 'panel';
