import { IBuildConfigData } from '@quesmed/types-rn/resolvers/mutation/restricted';

import { QUESTION_DIFFICULTY_LEVELS } from 'config/constants';
import { QuestionAttempt, QuestionFormInput, QuestionsMode } from 'types';

export const parseBuilderConfig = (
  config: IBuildConfigData
): QuestionFormInput => {
  const {
    unseen = false,
    secondsPerQuestion = 70,
    seenCorrect = false,
    seenIncorrect = false,
    isTest = false,
    numberOfQuestions = 0,
    difficulty = QUESTION_DIFFICULTY_LEVELS,
  } = config;

  const questionsAttempt: QuestionAttempt[] = [];

  if (unseen) {
    questionsAttempt.push(QuestionAttempt.Unseen);
  }
  if (seenCorrect) {
    questionsAttempt.push(QuestionAttempt.SeenCorrect);
  }
  if (seenIncorrect) {
    questionsAttempt.push(QuestionAttempt.SeenIncorrect);
  }

  return {
    difficulty,
    numberOfQuestions,
    questionsAttempt,
    mode: isTest ? QuestionsMode.Test : QuestionsMode.Quiz,
    secondsPerQuestion,
  };
};
