import { EAppType, EProductType } from '@quesmed/types-rn/models';
import {
  ILogoutUserData,
  ILogoutUserVar,
  LOGOUT_USER,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { authState } from './useAuth';
import { platformState } from 'context/PlatformState';
import { navigate, paths } from 'Router';
import { Nullable, SoloTimerData } from 'types';
import { localStorageTyped } from 'utils';
import { appClient } from 'Apollo/appClient';

const { removeItem: removeStorageToken } =
  localStorageTyped<string>('usertoken');

const { removeItem: removeCurrentApp } =
  localStorageTyped<Nullable<EProductType>>('product');

const { removeItem: removeCurrentProduct } =
  localStorageTyped<Nullable<EAppType>>('app');

const { removeItem: removeExpirationCheckDate } = localStorageTyped<number>(
  'tokenRenewalCheckTime'
);

const { removeItem: removeSoloTimerValue } =
  localStorageTyped<SoloTimerData>('soloTimerValue');

interface LogoutParams {
  all?: boolean;
  local?: boolean;
}

export const logoutUser = async (params?: LogoutParams) => {
  const { all = false, local = false } = params || {};
  const { client } = appClient.getState();
  const { clearState } = platformState.getState();
  const { resetState } = authState.getState();

  try {
    if (client && !local) {
      await client?.mutate<ILogoutUserData, ILogoutUserVar>({
        mutation: LOGOUT_USER,
        variables: {
          all,
        },
      });
    }
  } finally {
    if (client) {
      await client.clearStore();
    }

    removeStorageToken();
    removeCurrentApp();
    removeExpirationCheckDate();
    removeCurrentProduct();
    removeSoloTimerValue();
    resetState();
    clearState();

    navigate(paths.login);
  }
};
