import React, { Fragment, MouseEvent } from 'react';
import { useInfiniteHits } from 'react-instantsearch';
import {
  ETopicType,
  IEntitlementType,
  IOsceType,
} from '@quesmed/types-rn/models';
import { getEntitlementFromTopicType } from '@quesmed/types-rn';

import { paths } from 'Router';
import { ExpandableSectioScrollableListList } from 'components/ExpandableSection';
import { Button } from 'components/Button';
import SearchResultItem from './SearchResultItem';
import { SearchIndex } from './type';
import SearchFallback from './SearchFallback';
import { CATEGORIES } from 'constants/marksheet';
import { BookshelfIcon, FolderOpenOutlineIcon } from 'components/Icons';
import { BreadcrumbsProps } from 'components/Breadcrumbs';
import { Nilable, Nullable } from 'types';
import { usePlatform } from 'context/PlatformState';

const { knowledgeLibrary, videoLibrary } = paths;

const getBreadcrumbs = (
  labels: [string, string?]
): BreadcrumbsProps['items'] => {
  const [firstLabel, secondLabel] = labels;

  const breadcrumbs = [
    {
      icon: BookshelfIcon,
      label: firstLabel,
    },
  ];

  if (secondLabel) {
    breadcrumbs.push({
      icon: FolderOpenOutlineIcon,
      label: secondLabel,
    });
  }

  return breadcrumbs;
};

interface SearchHit {
  title: string;
  topic: string;
  name: string;
  topicId: number;
  conceptId: number;
  videoId: number;
  concept: string;
  typeId: number;
  topicType: ETopicType;
  entitlement?: Nullable<IEntitlementType>;
  osceType?: IOsceType;
  osceStationId: number;
  objectID: string;
}

const getId = (id?: Nilable<string | number>): string => (id ? `_${id}` : '');

interface InfiniteHitsProps {
  onClick: () => void;
  indexName: SearchIndex;
  onPractice?: (id: number) => (e: MouseEvent<HTMLAnchorElement>) => void;
}

const InfiniteHits = ({
  onClick,
  indexName,
  onPractice,
}: InfiniteHitsProps): JSX.Element => {
  const { product } = usePlatform();
  const { items, isLastPage, showMore } = useInfiniteHits();
  const video = indexName.includes('videos');
  const isPaces = indexName.includes('pacebook');
  const isQuesbookSearch = indexName.includes('quesbook');

  return (
    <ExpandableSectioScrollableListList>
      {items.length ? (
        (items as unknown as SearchHit[]).map(hit => {
          const {
            title,
            topic,
            topicId,
            conceptId,
            videoId,
            concept,
            name,
            objectID,
            typeId,
            osceType,
            osceStationId,
          } = hit;

          const type = CATEGORIES[typeId];
          const label = video ? title : concept || name;

          const entitlement =
            isQuesbookSearch && product && typeId !== null
              ? getEntitlementFromTopicType(typeId, product) || hit.entitlement
              : null;

          const entitlementId = entitlement ? entitlement?.id : osceType?.id;
          const sectionId = isPaces || osceStationId ? entitlementId : topicId;

          const chapterId = osceStationId ? osceStationId : conceptId;

          const path = video
            ? `${videoLibrary.root}/video/${entitlementId}/${
                topicId || entitlementId
              }/${videoId}`
            : `${knowledgeLibrary.root}/chapter/${entitlementId}/${sectionId}/${chapterId}`;

          return label ? (
            <Fragment
              key={
                objectID ||
                `${label}${getId(entitlementId)}${getId(topicId)}${getId(
                  chapterId
                )}${getId(videoId)}`
              }
            >
              <SearchResultItem
                breadcrumbs={getBreadcrumbs([entitlement?.name || type, topic])}
                label={label}
                onClick={
                  onPractice && osceStationId
                    ? onPractice(osceStationId)
                    : onClick
                }
                path={path}
              />
            </Fragment>
          ) : null;
        })
      ) : (
        <SearchFallback variant="with-padding" />
      )}
      {!isLastPage ? (
        <Button onClick={showMore} sx={{ alignSelf: 'flex-end' }} tertiary>
          Show more
        </Button>
      ) : null}
    </ExpandableSectioScrollableListList>
  );
};

export default InfiniteHits;
