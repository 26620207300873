import { ApolloLink } from '@apollo/client';

import { localStorageTyped } from 'utils';

export const { getItem } = localStorageTyped<string>('usertoken');

export const responseLink = new ApolloLink((operation, forward) => {
  const { getItem, setItem } = localStorageTyped<string>('clientversion');

  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const currentVerion = getItem();
      const clientVersion = headers.get('clientversion');

      if (clientVersion !== currentVerion) {
        setItem(clientVersion);
        window.location.reload();
      }
    }

    return response;
  });
});
