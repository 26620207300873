import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { QuesmedAppData } from 'types';
import TopBar from './TopBar';
import NavSideBar, { NavSideBarProps } from './NavSideBar';
import Content from './Content';
import Navigation from './Navigation';
import { NavigationHeader } from './NavigationHeader';
import ProductSwitcher from './ProductSwitcher';
import { NavigationSection } from './types';
import { useIsDesktop, useIsInRange, usePrevious, useQuizState } from 'hooks';
import { usePlatform } from 'context/PlatformContext';
import { paths } from 'Router';
import { noop } from 'utils';
import { DemoBox } from 'components/Demo';
import { useDemo } from 'Auth';
import { ErrorBoundary } from 'components/Error';

interface LayoutProps {
  appData?: QuesmedAppData;
  navigationEntries?: NavigationSection[];
  simplified?: boolean;
  withUser?: boolean;
}

const useDashboardSpecificStyles = (isDashboard: boolean) => {
  return useIsInRange('xl', 'xxl') && isDashboard;
};

const Layout = ({
  appData,
  navigationEntries,
  simplified = false,
  withUser,
}: LayoutProps): JSX.Element => {
  const { isDesktop } = useIsDesktop();
  const { name, products } = appData || {};
  const prevIsDesktop = usePrevious(isDesktop);
  const [open, setOpen] = useState(isDesktop);
  const [expanded, setExpanded] = useState(true);
  const { hasProduct } = usePlatform();
  const { pathname } = useLocation();
  const isDemo = useDemo();
  const isAppsOverview = pathname === paths.appsManagement;
  const ignoreExpand = useDashboardSpecificStyles(pathname === paths.dashboard);
  const { quizStarted } = useQuizState();

  const handleNavigationExpand = () => {
    setExpanded(previousExpanded => !previousExpanded);
  };

  const handleNavigationToggle = () => {
    setOpen(previousOpen => !previousOpen);
  };

  const handleNavigationOpen = () => {
    setOpen(true);
  };

  const handleNavigationClose = () => {
    setOpen(false);
  };

  const handleMobileNavigationClose = () => {
    if (!isDesktop) {
      handleNavigationClose();
    }
  };

  useEffect(() => {
    if (ignoreExpand) {
      setExpanded(false);
    }
  }, [ignoreExpand]);

  useEffect(() => {
    if (isDesktop) {
      setOpen(!simplified);
    }
  }, [simplified, isDesktop]);

  useEffect(() => {
    if (simplified) {
      return;
    }
    if (isDesktop) {
      setOpen(true);
    } else if (prevIsDesktop && !isDesktop && open) {
      setOpen(false);
    }
  }, [isDesktop, open, simplified, prevIsDesktop]);

  // MUI drawer causes layout jumping due to adding styles to the body
  // to prevent that we have to pass disableScrollLock prop to the drawer.
  // This causes a HTML validation error as disableScrollLock is passed to
  // to HTML tag as an attribute. To avoid that error we have to pass this prop
  // only when drawer is in 'temporary' mode otherwise, regardless of the value,
  // disableScrollLock is passed to the HTML sd an attribute and causes an error.
  const navbarProps = useMemo(() => {
    const props: Partial<NavSideBarProps> = {};

    if (!isDesktop) {
      props.disableScrollLock = true;
    }

    return props;
  }, [isDesktop]);

  const showNavigation = !simplified && !isAppsOverview && !quizStarted;

  return (
    <>
      <TopBar
        hasProduct={hasProduct}
        isAppsOverview={isAppsOverview}
        onClick={isDesktop ? handleNavigationToggle : handleNavigationOpen}
        simplified={simplified}
        withUser={withUser}
      />
      {showNavigation ? (
        <ClickAwayListener
          onClickAway={expanded && ignoreExpand ? handleNavigationExpand : noop}
        >
          <Slide direction="right" in={!simplified}>
            <div>
              <NavSideBar
                expanded={expanded}
                isOpen={Boolean(open)}
                onClose={handleNavigationClose}
                onExpand={handleNavigationExpand}
                variant={isDesktop ? 'persistent' : 'temporary'}
                {...navbarProps}
              >
                {isDemo ? <DemoBox expanded={expanded} /> : null}

                <div>
                  <NavigationHeader
                    expanded={expanded}
                    sx={{
                      minHeight: '30px',
                      height: '30px',
                      paddingLeft: '10px',
                      paddingBottom: '12px',
                    }}
                  >
                    {name}
                  </NavigationHeader>
                </div>
                {products ? (
                  <ProductSwitcher
                    expanded={expanded}
                    onItemClick={handleMobileNavigationClose}
                    products={products}
                  />
                ) : null}
                {navigationEntries ? (
                  <Navigation
                    expanded={expanded}
                    navigationEntries={navigationEntries}
                    onItemClick={handleMobileNavigationClose}
                  />
                ) : null}
              </NavSideBar>
            </div>
          </Slide>
        </ClickAwayListener>
      ) : null}
      <Content
        component="main"
        expanded={expanded && !ignoreExpand ? 1 : 0}
        open={open && !quizStarted ? 1 : 0}
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Content>
    </>
  );
};

export default Layout;
