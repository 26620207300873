import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { Body } from 'components/Typography';
import { constSize } from 'utils';

export const NotificationContainer = styled(Box)(
  ({ theme: { breakpoints, spacing, palette, shape } }) => ({
    position: 'relative',
    boxSizing: 'border-box',
    padding: spacing(4, 4, 4, 8),
    display: 'flex',
    gap: spacing(4),
    borderBottom: `1px solid ${palette.stroke.main}`,
    [breakpoints.up('md')]: {
      padding: spacing(4, 8),
    },

    '&:active': {
      background: palette.primary.extraLight,
    },
    '&:hover': {
      background: palette.toggle.light,
    },
    '&.comment': {
      cursor: 'pointer',
    },
    '&.unread': {
      cursor: 'pointer',

      '& .MuiAvatar-root::before': {
        content: '""',
        position: 'absolute',
        left: spacing(-4),
        borderRadius: shape.borderRadius,
        background: palette.error.dark,
        ...constSize('6px'),
      },
    },
  })
);

export const NotificationMessageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const NotificationAdditionalContentWrapper = styled(Box)(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
  })
);

export const CommentPreview = styled(Body)(({ theme: { palette } }) => ({
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  color: palette.text.secondary,
}));

export const NotificationTime = styled(Body)(({ theme: { palette } }) => ({
  color: palette.text.disabled,
}));

export const NotificationActions = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(2),
  marginTop: spacing(2),
}));
