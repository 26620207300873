import React from 'react';
import { mdiGiftOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const InviteFriendIcon = (props: IconProps) => (
  <Icon path={mdiGiftOutline} {...props} />
);

export default InviteFriendIcon;
