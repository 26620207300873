import { Reducer } from 'react';

import { ActionTypes, AppAction, AppState } from './types';

export const appStateReducer: Reducer<AppState, AppAction> = (
  state,
  action
): AppState => {
  switch (action.type) {
    case ActionTypes.BookExplanationVisible:
      return { ...state, bookExplanationVisible: action.payload };
    case ActionTypes.CheckoutWithSelectedPlan:
      return { ...state, checkoutWithSelectedPlan: action.payload };
    case ActionTypes.FontSize:
      return { ...state, fontSize: action.payload };
    case ActionTypes.IsMenuOpen:
      return { ...state, isMenuOpen: action.payload };
    case ActionTypes.LogoutCallback:
      return { ...state, logoutCallback: action.payload };
    case ActionTypes.CustomiseDashboard:
      return { ...state, dashboardSettings: action.payload };
    default:
      return state;
  }
};
