import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Body, H6 } from 'components/Typography';

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  margin: spacing(0, 0, 6),
}));

const StyledBody = styled(Body)(({ theme: { spacing, palette } }) => ({
  marginTop: spacing(2),
  color: palette.text.secondary,
}));

const StyledHeader = styled(Box)(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  gap: spacing(2),
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    color: palette.icon.main,
  },
}));

export interface SettingsSectionTitleProps {
  className?: string;
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
}

const SettingsSectionTitle = ({
  className,
  icon,
  title,
  subtitle = '',
}: SettingsSectionTitleProps): JSX.Element => (
  <StyledBox className={className}>
    <StyledHeader>
      {icon}
      <H6>{title}</H6>
    </StyledHeader>
    <StyledBody>{subtitle}</StyledBody>
  </StyledBox>
);

export default SettingsSectionTitle;
