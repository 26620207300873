import { IHighlightNode } from '@quesmed/types-rn/models';
import markdownToTxt from 'markdown-to-txt';

import { MAX_UNIQUE_WORD_LENGTH } from 'config/constants';
import { computePositionsInMarkdown } from './computePositionsInMarkdown';
import { isAlphanumeric } from './isAlphanumeric';

export const getPositions = (
  wholeMarkdown: string,
  paragraphMarkdown: string,
  selectedMarkdown: string,
  selectedText: string,
  element: Node,
  range: Range
): IHighlightNode[] | null => {
  try {
    const plainParagraphText = markdownToTxt(paragraphMarkdown).replace(
      /[^a-zA-Z0-9]+/g,
      ''
    );

    let previousAllNodeLength = 0;
    let matchedMarkdown = '';
    const lines = wholeMarkdown.split('\n');
    const isTableSelection = selectedMarkdown.includes('|');

    for (const line of lines) {
      const plain = markdownToTxt(line).replace(/[^a-zA-Z0-9]+/g, '');
      const matchCondition = isTableSelection
        ? plain.includes(plainParagraphText)
        : plain === plainParagraphText;

      if (matchCondition) {
        matchedMarkdown = line.trim();
        // If there's a tab (nested list), add 1 to starting index
        if (/\t(?=-)/g.test(line)) {
          previousAllNodeLength += 1;
        }
        break;
      } else {
        previousAllNodeLength += 1 + line.length;
      }
    }

    if (
      selectedText.length >= MAX_UNIQUE_WORD_LENGTH &&
      isAlphanumeric(selectedMarkdown.charAt(0)) &&
      isAlphanumeric(selectedMarkdown.slice(-1))
    ) {
      /** selectedText greater than length of MAX_UNIQUE_WORD_LENGTH, than we can easily fetch the location from index */
      const idx = paragraphMarkdown.indexOf(selectedMarkdown);
      const start = previousAllNodeLength + idx;

      return [
        {
          start,
          end: start + selectedMarkdown.length - 1, // as computation always lead to extra length of 1
          text: selectedMarkdown,
        },
      ];
    }

    /** From that element, fetch the highlighted text's start and end position */
    const priorRange = range.cloneRange();
    priorRange.selectNodeContents(element);
    priorRange.setEnd(range.startContainer, range.startOffset);
    const startOffset = priorRange.toString().length;

    let positions = computePositionsInMarkdown(
      matchedMarkdown,
      startOffset,
      selectedText
    );

    /** Tackling the table selection */
    if (isTableSelection) {
      const selectedTableData = selectedMarkdown.split('|');
      const selectedTableItems = selectedTableData.filter(
        selectedData => selectedData !== ''
      );

      positions = selectedTableItems
        .map(item =>
          computePositionsInMarkdown(matchedMarkdown, startOffset, item)
        )
        .flatMap(item => item) as IHighlightNode[];
    }

    if (positions) {
      return positions.map(x => ({
        ...x,
        start: x.start + previousAllNodeLength,
        end: x.end + previousAllNodeLength - 1, // as computation always lead to extra length of 1
      }));
    }

    return null;
  } catch (err) {
    return null;
  }
};
