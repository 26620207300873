import React, { ComponentPropsWithoutRef } from 'react';

import StyledLink from './StyledLink';

const OutsideLink = ({
  children,
  ...props
}: ComponentPropsWithoutRef<'a'>): JSX.Element => (
  <StyledLink rel="noreferrer" target="_blank" {...props}>
    {children}
  </StyledLink>
);

export default OutsideLink;
