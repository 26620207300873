import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Button } from 'components/Button';

export const ContentHolderContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    backgroundColor: palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    minHeight: '100vh',
    padding: spacing(4),
    paddingTop: spacing(8),

    [breakpoints.up('md')]: {
      alignItems: 'center',
    },
  })
);

export const ContentContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [breakpoints.up('md')]: {
      width: '432px',
      justifyContent: 'center',
    },

    '& .MuiFormControl-root': {
      marginTop: spacing(6),
    },
  })
);

export const ResetButton = styled(Button)(
  ({ theme: { breakpoints, spacing } }) => ({
    marginTop: spacing(8),

    [breakpoints.up('md')]: {
      marginTop: spacing(10),
    },
  })
);
