import React from 'react';
import clsx from 'clsx';

import { IconButton, IconButtonProps } from 'components/IconButton';
import { Tooltip } from 'components/Tooltip';
import LabIcon from 'components/Icons/LabIcon';
import useLabValuesState from './useLabValuesState';

interface LabValuesButton {
  label?: string;
  size?: IconButtonProps['size'];
  white?: boolean;
}

const LabValuesButton = ({
  label,
  size,
  white,
}: LabValuesButton): JSX.Element => {
  const { open, openLabValues, closeLabValues } = useLabValuesState();

  return (
    <Tooltip placement="bottom" title="Lab Values">
      <IconButton
        aria-label="lab values"
        className={clsx({ open })}
        icon={<LabIcon />}
        label={label}
        onClick={open ? closeLabValues : openLabValues}
        size={size}
        white={white}
      />
    </Tooltip>
  );
};

export default LabValuesButton;
