import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import { SelectField, SelectFieldProps, SelectProps } from './SelectField';

type FormFieldProps<T extends FieldValues> = SelectFieldProps<T> & SelectProps;

export function FormSelect<T extends FieldValues>({
  control,
  defaultValue,
  rules,
  name,
  shouldUnregister,
  ...rest
}: FormFieldProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onBlur, onChange, ref, value },
        fieldState: { error },
      }) => (
        <SelectField
          {...rest}
          error={Boolean(error)}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          value={value}
        />
      )}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
