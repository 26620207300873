import React from 'react';
import { mdiFlaskOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const LabIcon = (props: IconProps) => (
  <Icon path={mdiFlaskOutline} {...props} />
);

export default LabIcon;
