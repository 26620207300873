import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';

import { usePlatform } from 'context/PlatformState';
import { paths } from 'Router';
import { QuesmedAppNames } from 'types';

const { pricing } = paths;

const useUpgradeNow = () => {
  const { app } = usePlatform();
  const navigate = useNavigate();

  const upgradeNow = useCallback(() => {
    if (app) {
      navigate(`${pricing.root}/${kebabCase(QuesmedAppNames[app])}`);
    }
  }, [app, navigate]);

  return upgradeNow;
};

export default useUpgradeNow;
