import React, { useEffect, useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Joi from 'joi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { IStartOsceMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EProductType } from '@quesmed/types-rn/models';

import { Modal, ModalProps } from 'components/Modal';
import { FormField, TextField } from 'components/TextField';
import { formatSeconds } from 'utils';
import { usePrestartMarksheetMutation, useStartSoloStudy } from './hooks';
import { Nullable, OnRequestComplete } from 'types';
import { usePlatform } from 'context/PlatformState';
import { LockOutlineIcon } from 'components/Icons';

interface StationsTimerModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'onBack'> {
  solo: boolean;
  stationId?: Nullable<number>;
  marksheetId?: Nullable<number>;
  onStartStation?: OnRequestComplete<IStartOsceMarksheetData>;
}

const ErrorMessage = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    color: palette.error.main,
    marginTop: spacing(4),
    ...typography.body2Medium,
  })
);

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(2),
  gap: spacing(6),
}));

export interface StationsTimerFormInput {
  readingTime: number;
  stationTime: number;
  feedbackTime: number;
}

const MAXIMUM_READING_TIME = 180; // seconds
const MAXIMUM_STATION_TIME = 20 * 60; // seconds
const DEFAULT_READING_TIME = 30; // seconds
const DEFAULT_FEEDBACK_TIME = 5; // minutes
const DEFAULT_STATION_TIME = 10; // minutes
const MINIMUM_FIELD_VALUE = 1;
const MAXIMUM_STATIONS_TIME_ERROR =
  'Oops! It looks like the total time has been exceeded. Please reduce the time to 20 minutes.';

const sumStationTimerValues = ({
  feedbackTime,
  stationTime,
  readingTime,
}: StationsTimerFormInput) =>
  Number(readingTime) + Number(stationTime) * 60 + Number(feedbackTime) * 60;

const renderValidationMessages = (
  fieldName: string,
  maximumTime: number,
  unit: 'seconds' | 'minutes'
) => ({
  'any.required': `${fieldName} should not be empty`,
  'number.base': `${fieldName} must be number`,
  'number.max': `${fieldName} should not be greater than ${maximumTime} ${unit}`,
});

const schema = Joi.object<StationsTimerFormInput>({
  readingTime: Joi.number()
    .min(MINIMUM_FIELD_VALUE)
    .max(MAXIMUM_READING_TIME)
    .required()
    .messages(
      renderValidationMessages('Reading time', MAXIMUM_READING_TIME, 'seconds')
    ),
  stationTime: Joi.number()
    .min(MINIMUM_FIELD_VALUE)
    .max(MAXIMUM_STATION_TIME)
    .required()
    .messages(
      renderValidationMessages('Station time', MAXIMUM_STATION_TIME, 'minutes')
    ),
  feedbackTime: Joi.number()
    .min(MINIMUM_FIELD_VALUE)
    .max(MAXIMUM_STATION_TIME)
    .required()
    .messages(
      renderValidationMessages('Feedback time', MAXIMUM_STATION_TIME, 'minutes')
    ),
});

const StationsTimerModal = ({
  open,
  onBack,
  solo,
  stationId,
  marksheetId,
  onClose,
  onStartStation,
}: StationsTimerModalProps): JSX.Element => {
  const { product } = usePlatform();
  const [globalError, setGlobalError] = useState<string>('');
  const { startSoloStudy, loading } = useStartSoloStudy(onStartStation);
  const { prestartMarksheet, prestartLoading } = usePrestartMarksheetMutation();

  const { control, formState, handleSubmit, watch } =
    useForm<StationsTimerFormInput>({
      defaultValues: {
        readingTime: DEFAULT_READING_TIME,
        stationTime: DEFAULT_STATION_TIME,
        feedbackTime: DEFAULT_FEEDBACK_TIME,
      },
      resolver: joiResolver(schema),
      mode: 'onBlur',
    });

  const values = watch();
  const summedStationTime = sumStationTimerValues(values);

  useEffect(() => {
    if (summedStationTime > MAXIMUM_STATION_TIME) {
      setGlobalError(MAXIMUM_STATIONS_TIME_ERROR);
    } else {
      setGlobalError('');
    }
  }, [summedStationTime]);

  const totalTimeValue = summedStationTime
    ? formatSeconds(summedStationTime)
    : '';

  const onSubmit: SubmitHandler<StationsTimerFormInput> = data => {
    const { feedbackTime, stationTime, readingTime } = data;

    if (solo && stationId) {
      startSoloStudy(
        stationId,
        stationTime,
        readingTime,
        feedbackTime,
        marksheetId
      );
    }

    if (!solo && marksheetId) {
      prestartMarksheet(
        Number(marksheetId),
        stationTime,
        readingTime,
        feedbackTime
      );
    }
  };

  const onCancel = () => {
    if (stationId) {
      startSoloStudy(stationId, 0, 0, 0, marksheetId, false);
    }
  };

  const additionalSoloProps =
    solo && product === EProductType.PACES
      ? {
          onCancel,
          cancelLabel: 'Start without timer',
          cancelLoading: loading || prestartLoading,
        }
      : null;

  return (
    <Modal
      loading={loading || prestartLoading}
      maxWidth="md"
      noPaddingY
      onBack={onBack}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={!formState.isValid || Boolean(globalError)}
      submitLabel="start timed exercise"
      title="Set a station timer"
      {...additionalSoloProps}
    >
      <StyledBox>
        <FormField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">seconds</InputAdornment>
            ),
          }}
          control={control}
          fullWidth
          label="Reading time"
          name="readingTime"
        />
        <FormField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
          control={control}
          fullWidth
          label="Station time"
          name="stationTime"
        />
        <FormField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
          control={control}
          fullWidth
          label="Feedback time"
          name="feedbackTime"
        />

        <Divider variant="middle" />
        <TextField
          InputProps={{
            endAdornment: <LockOutlineIcon />,
          }}
          disabled
          fullWidth
          label="Total time"
          value={totalTimeValue}
        />
        {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
      </StyledBox>
    </Modal>
  );
};

export default StationsTimerModal;
