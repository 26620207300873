import { styled } from '@mui/material/styles';

import { constSize } from 'utils';

export const StyledSVG = styled('svg')(({ theme: { spacing, palette } }) => ({
  '&.product': {
    ...constSize(spacing(12)),
  },

  '& .background': {
    fill: palette.products.inactive.primary,
  },

  '& .foreground-one, .foreground-two': {
    fill: palette.products.inactive.light,
  },

  '&.product-1': {
    '& .background': {
      fill: palette.products.CM_UKMLA.dark,
    },

    '& .foreground-one': {
      fill: palette.products.CM_UKMLA.primary,
    },
  },

  '&.product-2': {
    '& .background': {
      fill: palette.products.CM_UKMLA.light,
    },

    '& .foreground-one': {
      fill: palette.products.CM_UKMLA.primary,
    },
  },

  '&.product-3': {
    '& .background': {
      fill: palette.products.CM_UKMLA.dark,
    },

    '& .foreground-one': {
      fill: palette.products.CM_UKMLA.primary,
    },

    '& .foreground-two': {
      fill: palette.products.CM_UKMLA.light,
    },
  },

  '&.product-4': {
    '& .background': {
      fill: palette.products.AMS.primary,
    },

    '& .foreground-one': {
      fill: palette.products.AMS.pink,
    },
  },

  '&.product-5': {
    '& .background': {
      fill: palette.products.AMS.pink,
    },

    '& .foreground-one': {
      fill: palette.products.AMS.primary,
    },
  },

  '&.product-6': {
    '& .background': {
      fill: palette.products.AMS.primary,
    },

    '& .foreground-one': {
      fill: palette.products.AMS.light,
    },

    '& .foreground-two': {
      fill: palette.products.AMS.pink,
    },
  },

  '&.product-7': {
    '& .background': {
      fill: palette.products.MRCP.supplementaryLight,
    },

    '& .foreground-one': {
      fill: palette.products.MRCP.main,
    },
  },

  '&.product-8': {
    '& .background': {
      fill: palette.products.MRCP.light,
    },

    '& .foreground-one': {
      fill: palette.products.MRCP.supplementaryLight,
    },
  },

  '&.product-9': {
    '& .background': {
      fill: palette.products.MRCP.secondary,
    },

    '& .foreground-one': {
      fill: palette.products.MRCP.main,
    },
  },

  '&.product-10': {
    '& .background': {
      fill: palette.products.MSRA.primary,
    },

    '& .foreground-one': {
      fill: palette.products.MSRA.extraLight,
    },
  },

  '&.product-11': {
    '& .background': {
      fill: palette.products.INTERVIEW.primary,
    },

    '& .foreground-one': {
      fill: palette.products.INTERVIEW.secondary,
    },
  },

  '&.product-12': {
    '& .background': {
      fill: palette.products.INTERVIEW.light,
    },

    '& .foreground-one': {
      fill: palette.products.INTERVIEW.primary,
    },
  },

  '&.product-13': {
    '& .background': {
      fill: palette.products.INTERVIEW.primary,
    },

    '& .foreground-one': {
      fill: palette.products.INTERVIEW.extraLight,
    },
  },

  '&.product-14': {
    '& .background': {
      fill: palette.products.INTERVIEW.primary,
    },

    '& .foreground-one': {
      fill: palette.products.INTERVIEW.light,
    },

    '& .foreground-two': {
      fill: palette.products.INTERVIEW.extraLight,
    },
  },

  '&.product-34': {
    '& .background': {
      fill: palette.products.PLAB.extraLight,
    },

    '& .foreground-one': {
      fill: palette.products.PLAB.primary,
    },
  },

  '&.product-35': {
    '& .background': {
      fill: palette.products.PLAB.extraLight,
    },

    '& .foreground-one': {
      fill: palette.products.PLAB.light,
    },
  },

  '&.inactive': {
    '& .background': {
      fill: palette.products.inactive.primary,
    },

    '& .foreground-one, .foreground-two': {
      fill: palette.products.inactive.light,
    },
  },
}));
