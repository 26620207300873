import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { IMarksheet } from '@quesmed/types-rn/models';

import { paths } from 'Router';
import useLeaveMarksheetMutation from './useLeaveMarksheetMutation';
import { usePlatform } from 'context/PlatformContext';
import { useAppSetState } from 'context/AppState';
import useLeaveExerciseState from '../useLeaveExerciseState';
import { useAgoraAPI } from 'components/Agora';
import { GQL_ENDPOINT } from 'config/constants';
import { useAuth } from 'Auth';

const { dashboard } = paths;

const useLeaveMarksheet = (marksheetId?: number, marksheet?: IMarksheet) => {
  const { setLogoutCollback } = useAppSetState();
  const { token } = useAuth();
  const { leaveState, setCanLeave } = useLeaveExerciseState();
  const { endSession } = useAgoraAPI();
  const navigate = useNavigate();
  const { setProduct } = usePlatform();
  const { path, state = {} } = leaveState;
  const { newProduct } = state;
  const [leave, { loading }] = useLeaveMarksheetMutation();

  const leaveMarksheet = useCallback(
    async (redirect = true, customPath?: string) => {
      if (!marksheetId || loading) {
        return;
      }

      if (marksheetId && !loading) {
        if (endSession) {
          await endSession();
        }

        await leave({
          variables: {
            marksheetId: Number(marksheetId),
          },
          optimisticResponse: marksheet
            ? {
                restricted: {
                  leaveMarksheet: {
                    ...marksheet,
                  },
                },
              }
            : undefined,
        });

        setCanLeave(true);

        if (newProduct !== undefined) {
          setProduct(newProduct);
        }

        if (redirect) {
          const targetPath = customPath || (path ? path : dashboard);
          navigate(targetPath, { state: { leave: true } });
        }
      }
    },
    [
      marksheetId,
      loading,
      endSession,
      leave,
      marksheet,
      setCanLeave,
      newProduct,
      setProduct,
      path,
      navigate,
    ]
  );

  const forceLeaveMarksheet = useCallback(async () => {
    if (marksheetId) {
      if (endSession) {
        endSession();
      }

      return leave({
        variables: {
          marksheetId: Number(marksheetId),
        },
      });
    }
  }, [leave, marksheetId, endSession]);

  // This function has to be called when tab or browser are closed
  //  to move current user progress from the cache to the DB
  const leaveOnClose = useCallback(() => {
    if (marksheetId) {
      fetch(GQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({
          query: `mutation LeaveMarksheet($marksheetId: Int!) {
            restricted {
              leaveMarksheet(marksheetId: $marksheetId) {
                id
              }
            }
          }`,
          variables: {
            marksheetId: Number(marksheetId),
          },
        }),
        keepalive: true,
      });
    }

    return undefined;
  }, [marksheetId, token]);

  useEffect(() => {
    setLogoutCollback(forceLeaveMarksheet);

    return () => {
      setLogoutCollback(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceLeaveMarksheet]);

  return { leaveMarksheet, loading, forceLeaveMarksheet, leaveOnClose };
};

export default useLeaveMarksheet;
