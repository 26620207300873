import { useSearchParams as useRouterSearchParams } from 'react-router-dom';

type SearchParams = { [key: string]: string };

function useSearchParams(): SearchParams;
function useSearchParams(param: string): string | null;
function useSearchParams(param?: any) {
  const [searchParams] = useRouterSearchParams();

  if (param) {
    return searchParams.get(param);
  }

  return Object.fromEntries(searchParams.entries()) as SearchParams;
}

export default useSearchParams;
