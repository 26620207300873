import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  IRegisterUserData,
  IRegisterUserVar,
  REGISTER_USER,
  RegisterUserInput,
} from '@quesmed/types-rn/resolvers/mutation';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'components/Snackbar';
import { useAuth } from 'Auth';
import paths from 'Router/paths';
import { isErrorButNotApolloOne } from 'utils';

export const useRegister = () => {
  const { setToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [registerUser, { loading }] = useMutation<
    IRegisterUserData,
    IRegisterUserVar
  >(REGISTER_USER);

  const register = useCallback(
    async (user: RegisterUserInput) => {
      try {
        const response = await registerUser({ variables: { userData: user } });
        const { errors, data } = response;

        if (!errors?.length) {
          const { registerUser: token } = data || {};

          if (token) {
            setToken(token);
            navigate(paths.onboarding.root);
          }
        }
      } catch (error) {
        if (isErrorButNotApolloOne(error)) {
          enqueueSnackbar(error.message);
        }
      }
    },
    [enqueueSnackbar, navigate, registerUser, setToken]
  );

  return { register, loading };
};
