import React, { useState } from 'react';

import { PopperProps } from 'components/Popper';
import { Nullable, Utils } from 'types';
import { CalculatorIcon, NoteEditIcon } from 'components/Icons';
import { UtilNotes } from 'components/UtilNotes';
import { UtilCalculator } from 'components/UtilCalculator';
import { UtilPopper } from 'components/UtilPopper';
import { UtilButton } from './UtilButton';

export const getUtilNodes = (util: Utils, onClose: () => void) => {
  switch (util) {
    case Utils.Calculator:
      return [
        <CalculatorIcon key={0} />,
        <UtilCalculator key={1} onClose={onClose} />,
      ];
    case Utils.Notes:
      return [
        <NoteEditIcon key={0} />,
        <UtilNotes key={1} onClose={onClose} />,
      ];
    default:
      return [];
  }
};

export interface UtilPopupButtonProps {
  utilOpen?: boolean;
  anchorEl?: Nullable<HTMLElement>;
  onOpen?: () => void;
  onClose?: () => void;
  util: Utils;
  popperProps?: PopperProps;
  quizProgressBar?: boolean;
}

export const UtilPopupButton = ({
  anchorEl,
  utilOpen,
  onOpen,
  onClose,
  popperProps,
  util,
  quizProgressBar,
}: UtilPopupButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [defaultAnchorEl, setDefaultAnchorEl] =
    useState<Nullable<HTMLElement>>(null);

  const popupOpened = utilOpen ?? open;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setDefaultAnchorEl(event.currentTarget);
    }
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const [utilIcon, utilContent] = getUtilNodes(util, handleClose);

  return (
    <>
      <UtilButton
        icon={utilIcon}
        label={util}
        onClick={handleClick}
        pressed={popupOpened}
        quizProgressBar={quizProgressBar}
      />
      <UtilPopper
        anchorEl={anchorEl ?? defaultAnchorEl}
        open={popupOpened}
        {...popperProps}
      >
        {utilContent}
      </UtilPopper>
    </>
  );
};
