import { EPlatformId } from '@quesmed/types-rn';

import {
  ActionCreator,
  ActionTypes,
  ActiveSpeakerAction,
  AudioEnabledAction,
  ChannelId,
  ChannelIdAction,
  InitAudioAction,
  InitSessionAction,
  InitVideoAction,
  MutedUsersAction,
  PlatformAction,
  PreviewAction,
  SessionExpiredAction,
  UserIdAction,
  UserVolume,
  VideoEnabledAction,
  WillExpiredAction,
} from './types';

export const setAudioEnabledAction: ActionCreator = (
  payload: boolean
): AudioEnabledAction => ({
  payload,
  type: ActionTypes.AudioEnabled,
});

export const setVideoEnabledAction: ActionCreator = (
  payload: boolean
): VideoEnabledAction => ({
  payload,
  type: ActionTypes.VideoEnabled,
});

export const setMutedUsersAction: ActionCreator = (
  payload: number[]
): MutedUsersAction => ({
  payload,
  type: ActionTypes.MutedUsers,
});

export const setActiveSpeakerAction: ActionCreator = (
  payload?: UserVolume
): ActiveSpeakerAction => ({
  payload,
  type: ActionTypes.ActiveSpeaker,
});

export const setInitSessionAction: ActionCreator = (
  payload: boolean
): InitSessionAction => ({
  payload,
  type: ActionTypes.InitSession,
});

export const setInitAudioAction: ActionCreator = (
  payload: boolean
): InitAudioAction => ({
  payload,
  type: ActionTypes.InitAudio,
});

export const setInitVideoAction: ActionCreator = (
  payload: boolean
): InitVideoAction => ({
  payload,
  type: ActionTypes.InitVideo,
});

export const setSessionExpiredAction: ActionCreator = (
  payload: boolean
): SessionExpiredAction => ({
  payload,
  type: ActionTypes.SessionExpired,
});

export const setUserIdAction: ActionCreator = (
  payload?: number
): UserIdAction => ({
  payload,
  type: ActionTypes.UserId,
});

export const setChannelIdAction: ActionCreator = (
  payload: ChannelId
): ChannelIdAction => ({
  payload,
  type: ActionTypes.ChannelId,
});

export const setWillExpiredAction: ActionCreator = (
  payload: boolean
): WillExpiredAction => ({
  payload,
  type: ActionTypes.WillExpired,
});

export const setPreviewAction: ActionCreator = (
  payload: boolean
): PreviewAction => ({
  payload,
  type: ActionTypes.Preview,
});

export const setPlatformAction: ActionCreator = (
  payload: EPlatformId
): PlatformAction => ({
  payload,
  type: ActionTypes.Platform,
});
