import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  IUsernameAvailableData,
  IUsernameAvailableVar,
  USERNAME_AVAILABILITY,
} from '@quesmed/types-rn/resolvers/query';

export const useCheckEmailAvailability = () => {
  const [checkIsEmailAvailability, { data, loading }] = useLazyQuery<
    IUsernameAvailableData,
    IUsernameAvailableVar
  >(USERNAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
  });

  const checkEmail = useCallback(
    async (email: string) => {
      let emailUnused = true;

      try {
        const result = await checkIsEmailAvailability({
          variables: { username: email },
        });

        const { usernameAvailable } = result.data || {};

        return Boolean(usernameAvailable);
      } catch (error) {
        emailUnused = false;
      }

      return emailUnused;
    },
    [checkIsEmailAvailability]
  );

  const { usernameAvailable: emailUnused } = data || {};

  return { checkEmail, loading, emailUnused };
};

export default useCheckEmailAvailability;
