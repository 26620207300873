import React from 'react';
import { mdiArrowExpandRight } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ArrowExpandRightIcon = (props: IconProps) => (
  <Icon path={mdiArrowExpandRight} {...props} />
);

export default ArrowExpandRightIcon;
