import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { H6 } from 'components/Typography';
import { IconButton } from 'components/IconButton';

const MAX_HEIGHT = '493px';
const MAX_WIDTH = '430px';
const MAX_MOBILE_WIDTH = '340px';
const MAX_MOBILE_HEIGHT = '390px';

const PopupHeader = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(4, 6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ClosePopupButton = styled(IconButton)(({ theme: { spacing } }) => ({
  padding: spacing(0, 2), // removes default top & bottom icon button padding
}));

const Title = styled(H6)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(2),
  alignItems: 'center',
}));

const PopupWrapper = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  maxHeight: MAX_MOBILE_HEIGHT,
  maxWidth: MAX_MOBILE_WIDTH,
  paddingBottom: spacing(6),

  '&.disableBottomPadding': {
    paddingBottom: 0,
  },

  [breakpoints.up('md')]: {
    maxHeight: MAX_HEIGHT,
    maxWidth: MAX_WIDTH,
  },
}));

export { PopupHeader, Title, PopupWrapper, ClosePopupButton };
