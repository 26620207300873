import React, { forwardRef, ReactNode } from 'react';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import isNil from 'lodash/isNil';

import { CheckboxState } from 'types';
import {
  CheckboxBlankIcon,
  CheckboxMarkedIcon,
  CheckboxMinusIcon,
  InfoIcon,
} from 'components/Icons';
import { StyledCheckbox } from './Checkbox.styles';
import { constSize } from 'utils';
import Tooltip from 'components/Tooltip/Tooltip';

export interface CheckboxProps
  extends Omit<MuiCheckboxProps, 'sx'>,
    Pick<FormControlLabelProps, 'labelPlacement'> {
  checkboxSx?: MuiCheckboxProps['sx'];
  controlSx?: FormControlLabelProps['sx'];
  checkedState?: CheckboxState;
  label?: ReactNode;
  formGroupSx?: FormGroupProps['sx'];
  withInfo?: boolean;
  infoTip?: string;
}

const StyledFormGroup = styled(FormGroup)({
  '&.info': {
    flexDirection: 'row',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      ...constSize('18px'),
    },
  },
});

const StyledInfoIcon = styled(InfoIcon)({
  cursor: 'pointer',
});

// To prevent event propagation when checkbox's parent has its own event handlers
const onClick = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
  event.stopPropagation();
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      labelPlacement,
      controlSx,
      checkboxSx,
      checked,
      checkedState,
      formGroupSx,
      icon,
      withInfo,
      infoTip,
      ...props
    },
    ref
  ) => {
    const isChecked = isNil(checkedState)
      ? checked
      : checkedState === CheckboxState.CHECKED;

    return (
      <StyledFormGroup
        className={withInfo ? 'info' : undefined}
        sx={formGroupSx}
      >
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={isChecked}
              checkedIcon={<CheckboxMarkedIcon />}
              icon={
                icon ? (
                  icon
                ) : checkedState === CheckboxState.INTERMEDIATE ? (
                  <CheckboxMinusIcon />
                ) : (
                  <CheckboxBlankIcon />
                )
              }
              inputRef={ref}
              size="medium"
              sx={checkboxSx}
              {...props}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
          onClick={onClick}
          sx={controlSx}
        />

        {withInfo && infoTip ? (
          <Tooltip placement="top" textCenter title={infoTip}>
            <StyledInfoIcon />
          </Tooltip>
        ) : null}
      </StyledFormGroup>
    );
  }
);

Checkbox.displayName = 'Checkbox';
