import { EMockTestType } from '@quesmed/types-rn/models';

import { ToggleOptions } from 'types';

export const TEST_CATEGORIES_ORDER = [
  EMockTestType.UKMLA,
  EMockTestType.FINALS,
  EMockTestType.PSA,
  EMockTestType.MISCELLANEOUS,
  EMockTestType.UNIVERSITY_SPECIFIC,
  EMockTestType.ANATOMY_SPOTTER,
];

export const sortMockTestCategories = (
  categories: ToggleOptions<EMockTestType>
) =>
  categories.sort(
    (a, b) =>
      TEST_CATEGORIES_ORDER.indexOf(a.value) -
      TEST_CATEGORIES_ORDER.indexOf(b.value)
  );
