import { useCallback, useEffect } from 'react';
import { EPlatformId } from '@quesmed/types-rn';
import { EAppType } from '@quesmed/types-rn/models';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { useAuth, useSubscription } from 'Auth';
import { AppProductType, Nullable, productsApp } from 'types';
import { isStationsProduct, localStorageTyped } from 'utils';
import { paths } from 'Router';
import { platformState, usePlatform } from './PlatformState';

const { appsManagement, pricing, paymentComplete } = paths;

const omitPaths = [paymentComplete, pricing.root, '/sample'];

const { getItem } = localStorageTyped<Nullable<AppProductType>>('product');

const { getItem: getAppItem, setItem: setAppItem } =
  localStorageTyped<Nullable<EAppType>>('app');

const { getItem: getDemoItem } = localStorageTyped<boolean>('demo');

export const getPlatform = (product: Nullable<AppProductType>) =>
  isStationsProduct(product) ? EPlatformId.OSCE : EPlatformId.QBANK;

const PlatformController = (): null => {
  const data = useSubscription();
  const { products, apps, productStates } = data || {};
  const {
    app,
    product,
    setAvailableProducts,
    setAvailableApps,
    setProductState,
    setProduct,
    setApp,
  } = usePlatform();
  const { pathname } = useLocation();
  const client = useApolloClient();

  const clearCache = useCallback(async () => {
    await client.clearStore();

    if (product !== null) {
      await client.refetchQueries({ include: 'active' });
    }
  }, [product, client]);

  useEffect(() => {
    clearCache();
  }, [clearCache]);

  const { isDemo, setIsDemo } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isDemo) {
      const storedProduct = getItem();
      const storedApp = getAppItem();

      const demoProduct = product || storedProduct;
      const demoApp = app || storedApp;
      if (demoApp && demoProduct) {
        setProduct(demoProduct);
        setApp(demoApp);
        setAvailableApps([demoApp], isDemo);
        setAvailableProducts([demoProduct], isDemo);
      }

      return;
    }
  }, [
    app,
    apps,
    isDemo,
    product,
    setApp,
    setAvailableApps,
    setAvailableProducts,
    setProduct,
  ]);

  useEffect(() => {
    if ((!products || !apps) && !isDemo) {
      const demo = getDemoItem();

      if (demo) {
        setIsDemo(demo);
      } else {
        platformState.setState({ product: null, app: null });
      }

      return;
    }

    if (apps && products) {
      setAvailableApps(apps);
      setAvailableProducts(products);

      const storedProduct = getItem();

      if (storedProduct !== null && products.includes(storedProduct)) {
        setProduct(storedProduct);
        setApp(productsApp[storedProduct as AppProductType]);
        setAppItem(productsApp[storedProduct as AppProductType]);
      } else {
        if (omitPaths.every(path => !pathname.includes(path)) && !isDemo) {
          navigate(appsManagement);
        }
      }
    }
  }, [
    apps,
    isDemo,
    navigate,
    pathname,
    products,
    setApp,
    setAvailableApps,
    setAvailableProducts,
    setIsDemo,
    setProduct,
  ]);

  useEffect(() => {
    if (productStates) {
      setProductState(productStates);
    }
  }, [productStates, setProductState]);

  return null;
};

export default PlatformController;

export { usePlatform };
