import { ApolloError, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import MenuItem from '@mui/material/MenuItem';
import { generateDisplayName } from '@quesmed/types-rn';
import { IAccessLevel, IUser } from '@quesmed/types-rn/models';
import {
  DELETE_USER,
  IDeleteUserData,
  IDeleteUserVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

// import { createFilterOptions } from '@mui/material/Autocomplete';
// import { IUniversity } from '@quesmed/types-rn/models';
import { LockOutlineIcon, SwapHorizontalIcon } from 'components/Icons';
import { InputAdornment } from 'components/InputAdornment';
import { Button } from 'components/Button';
import { FormField } from 'components/TextField';
import { AutocompleteField } from 'components/AutocompleteField';
import SettingsSectionTitle from './SettingsSectionTitle';
import { AccountFormInput } from 'types';
import { constants } from 'config';
import {
  backGraduationYears,
  forwardGraduationYears,
  getDirtyValues,
} from 'utils';
import SettingsUpdate from './SettingsUpdate';
import SettingsSectionDivider from './SettingsSectionDivider';
import SettingsSectionOverline from './SettingsSectionOverline';
import ChangePasswordModal from './ChangePasswordModal';
import SettingsContentLoading from './SettingsContentLoading';
import { ErrorBox } from 'components/Error';
import { DiscardChangesModal } from 'components/DiscardChangesModal';
import { SettingsModal, useSettingsModalState } from './useSettingsModalState';
import { logoutUser, useCurrentUser } from 'Auth';
import {
  // getUniversityOptionLabel,
  useUniversity,
  useUpdateUser,
} from 'hooks';

//TODO For now in this component are nested two solutions. One for <AutocompleteField name="university"/> (editable and object type) and <FormField name="universityReadOnly" /> (uneditable and string type). Commented parts of code are related to editable Autocomplete. Before the rebrand release, please set the final decision if the university field should be read-only or editable.
// If we go with the read-only solution: delete the commented parts of the code, refactor the parseAccountData function and I suggest changing the read-only field name to university
// If we go with an editable solution delete the <FormField name="universityReadOnly" /> and all related parts, uncomment commented parts of code. Also, make sure that updateUserMutation is accepting universityId field (at this moment it doesn't)
//regardless of chosen option delete the unused field from AccountFormInput

enum AccountTypes {
  Student = 'Student',
  Doctor = 'Doctor',
}

const classArray = constants.classArray.map(item => item.label);

const schema = Joi.object<AccountFormInput>({
  displayName: Joi.string().required().max(100).messages({
    'string.empty': 'Display name is required',
    'string.max': 'Display name can include 100 characters only',
  }),
  username: Joi.string().required(),
  firstName: Joi.string().required().messages({
    'string.empty': 'First name is required',
  }),
  lastName: Joi.string().required().messages({
    'string.empty': 'Last name is required',
  }),
  // this commented part is for editable university field solution (look at the comment under the imports)
  // university: Joi.required().messages({
  //   'string.empty': 'University is required',
  // }),
  classYear: Joi.string().required().max(50).messages({
    'string.empty': 'Class is required',
    'string.max': 'Class year can include up to 50 characters',
  }),
  graduationYear: Joi.string().required().messages({
    'string.empty': 'Year of graduation is required',
  }),
}).unknown(true);

type DirtyFieldsAccount = {
  [K in keyof AccountFormInput]: boolean | undefined;
};

type ParsedAccountFormInput = Omit<
  AccountFormInput,
  'university' | 'graduationYear'
> & {
  universityId?: number;
  graduationYear?: number;
};

const parseAccountData = (data: AccountFormInput) => {
  const { university, graduationYear, ...rest } = data;
  const result: ParsedAccountFormInput = { ...rest };
  if (university) {
    result.universityId = university.id;
  }
  if (data.graduationYear) {
    result.graduationYear = Number(data.graduationYear);
  }

  return result;
};

interface SettingsAccountProps {
  user?: IUser;
  error?: ApolloError;
  loading?: boolean;
  setIsFormDirty?: (isDirty: boolean) => void;
}

const SettingsAccount = ({
  user,
  error,
  loading: sourceLoading,
  setIsFormDirty,
}: SettingsAccountProps): JSX.Element => {
  const { accessLevel } = useCurrentUser();
  const [graduatingInArray, setGraduatingInArray] = useState<string[]>([]);
  const { shownModal, openModal, closeModal } = useSettingsModalState();
  const { loading: updateUserLoading, updateUser } = useUpdateUser();
  const [deleteAccount, { loading: loadingDeleteAccount }] = useMutation<
    IDeleteUserData,
    IDeleteUserVar
  >(DELETE_USER, {
    onCompleted: data => {
      if (data) {
        localStorage.clear();
        logoutUser();
      }
    },
  });

  const accountType: AccountTypes =
    accessLevel === IAccessLevel.TUTOR
      ? AccountTypes.Doctor
      : AccountTypes.Student;

  const {
    displayName = '',
    username = '',
    firstName = '',
    lastName = '',
    classYear = '',
    graduationYear = '',
    universityId,
  } = user || {};

  const {
    // this commented part is for editable university field solution (look at the comment under the imports)
    // universityList,
    userUniversity,
    loading: universityLoading,
  } = useUniversity(universityId);

  const {
    control,
    watch,
    setValue,
    formState: { isDirty, isValid, dirtyFields },
    getValues,
    handleSubmit,
    reset,
  } = useForm<AccountFormInput>({
    defaultValues: {
      displayName,
      username,
      firstName,
      lastName,
      classYear,
      graduationYear: String(graduationYear),
      // this commented part is for editable university field solution (look at the comment under the imports)
      // university: userUniversity,
      universityReadOnly: userUniversity?.name || '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
    values: {
      displayName,
      username,
      firstName,
      lastName,
      classYear,
      graduationYear: String(graduationYear),
      universityReadOnly: userUniversity?.name || '',
    },
  });

  useEffect(() => {
    if (classYear === 'Graduated') {
      setGraduatingInArray(backGraduationYears);
    } else {
      setGraduatingInArray(forwardGraduationYears);
    }
  }, [classYear]);

  useEffect(() => {
    const subscription = watch(data => {
      if (data.classYear === 'Graduated') {
        setGraduatingInArray(backGraduationYears);
      } else {
        setGraduatingInArray(forwardGraduationYears);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!universityLoading) {
      reset();
      setValue('universityReadOnly', userUniversity?.name, {
        shouldDirty: false,
      });
    }
  }, [reset, setValue, universityLoading, userUniversity?.name]);

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(isDirty);
    }
  }, [isDirty, setIsFormDirty]);

  const handleGenerateDisplayName = () => {
    const firstName = getValues('firstName');
    const lastName = getValues('lastName');
    setValue(
      'displayName',
      generateDisplayName(firstName ? firstName : '', lastName ? lastName : ''),
      { shouldDirty: true }
    );
  };

  const onSubmit = (data: AccountFormInput) => {
    if (!isValid && !isDirty) {
      return;
    }
    const updatedData = getDirtyValues(data, dirtyFields as DirtyFieldsAccount);
    const parsedData = parseAccountData(updatedData);

    updateUser(parsedData, {
      successCallback: () => {
        reset(data);
      },
      successMsg: 'Updated successfully.',
    });
  };

  const handleCancel = () => {
    reset();
  };

  const openDeleteAccountConfirmation = () => {
    openModal(SettingsModal.DeleteAccountModal);
  };

  const handleDeleteAccount = () => {
    deleteAccount();
  };

  const handleLogout = () => {
    logoutUser({ all: true });
  };

  const openPasswordModal = () => {
    openModal(SettingsModal.ChangePasswordModal);
  };

  // this commented part is for editable university field solution (look at the comment under the imports)
  // const isOptionEqualToValue = (option: IUniversity, value: IUniversity) => {
  //   return option.id === value.id;
  // };

  if (error) {
    return <ErrorBox description={error.message} />;
  }

  const loading = universityLoading || sourceLoading;

  return (
    <>
      <SettingsContentLoading count={10} height={56} loading={loading || !user}>
        <>
          <SettingsSectionTitle
            subtitle={`Account type: ${accountType}`}
            title="Personal info"
          />
          <SettingsSectionOverline text="Profile" />

          <FormField
            InputProps={{
              endAdornment: (
                <InputAdornment
                  icon={SwapHorizontalIcon}
                  label="Generate display name"
                  onClick={handleGenerateDisplayName}
                />
              ),
            }}
            control={control}
            fullWidth
            helperText="The name is visible to other users during question comment discussion and group study."
            label="Display name"
            name="displayName"
            type="string"
          />
          <FormField
            InputProps={{
              endAdornment: <LockOutlineIcon />,
            }}
            control={control}
            disabled
            fullWidth
            label="Email address"
            name="username"
            type="string"
          />
          <FormField
            control={control}
            fullWidth
            label="First name"
            name="firstName"
            type="string"
          />
          <FormField
            control={control}
            fullWidth
            label="Last name"
            name="lastName"
            type="string"
          />
          {accountType === AccountTypes.Student ? (
            <>
              <SettingsSectionOverline text="Education" />
              <FormField
                InputProps={{
                  endAdornment: <LockOutlineIcon />,
                }}
                control={control}
                disabled
                fullWidth
                label="University"
                name="universityReadOnly"
                type="string"
              />
              {/* for now, university field is read-only, it might change in the future, so this part is left with all related logic for possible future usage (look at the comment under the imports)*/}
              {/* <AutocompleteField
                control={control}
                defaultValue={userUniversity}
                disableClearable
                filterOptions={createFilterOptions({ limit: 50 })}
                getOptionLabel={getUniversityOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                label="University"
                name="university"
                options={universityList}
                renderOption={(optionProps, option) => (
                  <MenuItem {...optionProps}>{option.name}</MenuItem>
                )}
              /> */}
              <AutocompleteField
                control={control}
                defaultValue={classYear}
                disableClearable
                label="Class"
                name="classYear"
                options={classArray}
                renderOption={(optionProps, option) => (
                  <MenuItem {...optionProps}>{option}</MenuItem>
                )}
              />
              <AutocompleteField
                control={control}
                defaultValue={String(graduationYear)}
                disableClearable
                label="Year of graduation"
                name="graduationYear"
                options={graduatingInArray}
                renderOption={(optionProps, option) => (
                  <MenuItem {...optionProps}>{option}</MenuItem>
                )}
              />
            </>
          ) : null}
          <SettingsSectionDivider />
          <SettingsSectionTitle
            subtitle="Log out from all devices"
            title="Log Out"
          />
          <Button onClick={handleLogout} secondary>
            Log Out
          </Button>
          <SettingsSectionDivider />
          <SettingsSectionTitle
            subtitle="Update your password"
            title="Security"
          />
          <Button onClick={openPasswordModal} secondary>
            Change password
          </Button>
          <SettingsSectionDivider />
          <SettingsSectionTitle
            subtitle="Please be aware that this action cannot be undone and that all personal information, saved settings, and progress will be permanently removed from our servers."
            title="Account removal"
          />
          <Button danger onClick={openDeleteAccountConfirmation}>
            Delete my account
          </Button>
        </>
      </SettingsContentLoading>
      {isDirty ? (
        <SettingsUpdate
          disabled={!isValid || updateUserLoading}
          onCancel={handleCancel}
          onUpdate={handleSubmit(onSubmit)}
        />
      ) : null}
      <ChangePasswordModal
        onClose={closeModal}
        open={shownModal === SettingsModal.ChangePasswordModal}
      />
      <DiscardChangesModal
        loading={loadingDeleteAccount}
        onClose={closeModal}
        onSubmit={handleDeleteAccount}
        open={shownModal === SettingsModal.DeleteAccountModal}
        text="Please make sure you definitely want to delete your account, there will be no way to go back. You lose all progress and access to apps."
        title="Delete account forever?"
      />
    </>
  );
};

export default SettingsAccount;
