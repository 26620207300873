const getYears = (reverse = false, length = 10) => {
  const year = new Date().getFullYear();

  return Array.from({ length }, (_, i) => `${year + (reverse ? -i : i)}`);
};

const forwardGraduationYears = getYears();
const backGraduationYears = getYears(true, 15).reverse();

export { getYears, forwardGraduationYears, backGraduationYears };
