import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { localStorageTyped } from 'utils';

const { getItem, setItem } = localStorageTyped<boolean>('disabledHighlighting');

export const DEFAULT_DISABLED_HIGHLIGHTING = false;

interface DisableHighlighting {
  disabledHighlighting: boolean;
  setDisabled: (value: boolean) => void;
}

const useDisableHighlightingStore = create<DisableHighlighting>()(set => ({
  disabledHighlighting: getItem() || DEFAULT_DISABLED_HIGHLIGHTING,
  setDisabled: (disabledHighlighting: boolean) => {
    set({ disabledHighlighting });
    setItem(disabledHighlighting);
  },
}));

export const useDisableHighlighting = () =>
  useDisableHighlightingStore(state => state, shallow);
