import { useLayoutEffect, useMemo, useState } from 'react';

export type Dimensions = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;

export type ElementRef<E extends Element = Element> = (element: E) => void;
export type ResizeObserverResult<E extends Element = Element> = [
  ElementRef<E>,
  Dimensions,
  E | null
];

const defaultState: Dimensions = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

const useResizeObserver = <
  E extends Element = Element
>(): ResizeObserverResult<E> => {
  const [element, ref] = useState<E | null>(null);
  const [rect, setRect] = useState<Dimensions>(defaultState);

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return [ref, rect, element];
};

export default useResizeObserver;
