import React from 'react';
import { mdiFolderOpenOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FolderOpenOutlineIcon = (props: IconProps) => (
  <Icon path={mdiFolderOpenOutline} {...props} />
);

export default FolderOpenOutlineIcon;
