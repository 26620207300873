import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { Body, H6 } from 'components/Typography';
import { paths } from 'Router';

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  textAlign: 'center',
  width: '532px',
  margin: '0 auto',
  '& .MuiTypography-root:nth-child(1)': {
    margin: spacing(4, 0, 2, 0),
  },
  '& .MuiTypography-root:nth-child(2)': {
    marginBottom: spacing(6),
    color: palette.text.secondary,
  },
  '& .MuiButtonBase-root': {
    margin: '0 auto',
  },
}));

interface NoSubscriptionsScreenPops {
  closeSettingsModal: () => void;
}

const NoSubscriptionsScreen = ({
  closeSettingsModal,
}: NoSubscriptionsScreenPops): JSX.Element => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    //TODO navigate to "Apps management" instead after it is ready
    navigate(paths.appsManagement);
    closeSettingsModal();
  };

  return (
    <StyledBox>
      <H6>No subscribed products yet</H6>
      <Body>
        It looks like you haven&apos;t subscribed to any products yet. Hit that
        &apos;Subscribe Now&apos; button and start exploring all the great
        options available.
      </Body>
      <Button onClick={handleSubscribe} variant="outlined">
        Subscribe Now
      </Button>
    </StyledBox>
  );
};

export default NoSubscriptionsScreen;
