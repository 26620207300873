import { EAppType, EProductType, IProduct } from '@quesmed/types-rn/models';

import { CURRENCY } from 'config/constants';
import { entries } from '../object';
import { setProductPeriod } from './setProductPeriod';
import {
  AppProductType,
  ExtendedProduct,
  GroupedProducts,
  productsApp,
} from 'types';

const MRCP_ORDER = [
  EProductType.MRCP_PART1,
  EProductType.MRCP_PART2,
  EProductType.PACES,
];

const CENTS_IN_POUNDS = 100;

const groupByDuration = (products: ExtendedProduct[]) =>
  products.reduce((acc, product) => {
    const { duration } = product;

    if (acc[duration]) {
      acc[duration].push(product);
    } else {
      acc[duration] = [product];
    }

    return acc;
  }, {} as GroupedProducts);

const prepareProduct = (product: IProduct): ExtendedProduct => {
  const { description, duration, price, typeId } = product;

  return {
    ...product,
    app: productsApp[typeId as AppProductType],
    bundle: product.name.toLowerCase().includes('bundle'),
    features: description,
    formattedPrice: `${CURRENCY}${price / CENTS_IN_POUNDS}`,
    formattedDuration: setProductPeriod(duration),
  };
};

const prepareProducts = (products: IProduct[]) =>
  products.map(product => prepareProduct(product));

export const parseProducts = (products: IProduct[]) =>
  entries(groupByDuration(prepareProducts(products))).reduce(
    (acc, [key, grouped]) => {
      const [{ app }] = grouped;
      if (app === EAppType.MRCP) {
        grouped.sort(
          (s1, s2) =>
            MRCP_ORDER.indexOf(s1.typeId) - MRCP_ORDER.indexOf(s2.typeId)
        );
      } else {
        const index = grouped.findIndex(({ bundle }) => bundle);

        if (index >= 0) {
          grouped.splice(
            Math.floor(grouped.length / 2),
            0,
            grouped.splice(index, 1)[0]
          );
        }
      }
      acc[key] = grouped;

      return acc;
    },
    {} as GroupedProducts
  );

type MRCPPRoductsMap = { [key in EProductType]: ExtendedProduct[] };

export const parseMRCPProducts = (products: IProduct[]) =>
  products.reduce((acc, product) => {
    const { typeId } = product;

    const preparedProduct = prepareProduct(product);

    if (acc[typeId]) {
      acc[typeId].push(preparedProduct);
    } else {
      acc[typeId] = [preparedProduct];
    }

    return acc;
  }, {} as MRCPPRoductsMap);
