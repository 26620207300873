import React, { useCallback, useEffect, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import { SnackbarKey, useSnackbar as useNotistackSnackbar } from 'notistack';

import { randomKey } from 'utils';
import SnackBarButtons from './SnackbarButtons';
import SnackbarRetryButton from './SnackbarRetryButton';
import SnackbarCloseButton from './SnackbarCloseButton';
import messageAsKey from './messageAsKey';
import { SnackBar } from 'types';

interface SnackBarHookProps {
  unique?: boolean;
  retryError?: ApolloError | string;
  onClose?: () => void;
  onRetry?: () => void;
}

const useSnackbar = (params?: SnackBarHookProps) => {
  const {
    retryError,
    onRetry: sourceOnRetry,
    unique: sourceUnique,
  } = params || {};

  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const keyRef = useRef<SnackbarKey>('');

  const handleRetry = useCallback(() => {
    closeSnackbar(keyRef.current);

    if (sourceOnRetry) {
      sourceOnRetry();
    } else {
      window.location.reload();
    }
  }, [closeSnackbar, sourceOnRetry]);

  const enqueueSnackbarWrapper: SnackBar = useCallback(
    (snackbarMessage, options) => {
      const { unique, retry, onRetry, ...snackbarOptions } = options || {};
      const { key: sourceKey, autoHideDuration } = snackbarOptions || {};

      const key = messageAsKey(sourceUnique, unique)
        ? snackbarMessage
        : sourceKey || randomKey();

      const action = (
        <SnackBarButtons>
          {retry ? <SnackbarRetryButton onClick={onRetry} /> : null}
          <SnackbarCloseButton snackbarKey={key} />
        </SnackBarButtons>
      );

      enqueueSnackbar(snackbarMessage, {
        ...snackbarOptions,
        key,
        action,
        autoHideDuration: retry ? 5000 : autoHideDuration,
      });

      return key;
    },
    [enqueueSnackbar, sourceUnique]
  );

  useEffect(() => {
    if (retryError) {
      const message =
        retryError instanceof Error ? retryError.message : retryError;
      keyRef.current = enqueueSnackbarWrapper(message, {
        retry: true,
        onRetry: handleRetry,
      });
    }
  }, [enqueueSnackbarWrapper, retryError, handleRetry]);

  return { enqueueSnackbar: enqueueSnackbarWrapper, closeSnackbar };
};

export default useSnackbar;
