import React, { MouseEventHandler } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Button } from 'components/Button';

const StyledBox = styled(Box)(
  ({ theme: { palette, spacing, zIndex, breakpoints } }) => ({
    position: 'sticky',
    bottom: '0',
    right: '0',
    padding: spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(4),
    borderTop: `1px solid ${palette.stroke.main}`,
    backgroundColor: palette.background.paper,
    zIndex: zIndex.modal,

    [breakpoints.up('md')]: {
      padding: spacing(8),
    },
  })
);

interface SettingsUpdateProps {
  disabled?: boolean;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onUpdate: MouseEventHandler<HTMLButtonElement>;
}

const SettingsUpdate = ({
  disabled = false,
  onCancel,
  onUpdate,
}: SettingsUpdateProps): JSX.Element => (
  <StyledBox>
    <Button onClick={onCancel} secondary>
      cancel
    </Button>
    <Button disabled={disabled} onClick={onUpdate}>
      update
    </Button>
  </StyledBox>
);

export default SettingsUpdate;
