import React, { SVGProps } from 'react';

const AutoLayoutImage = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      fill="none"
      height="123"
      viewBox="0 0 227 123"
      width="227"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8122_7218)">
        <rect fill="white" height="122.515" rx="3" width="226.06" />
        <rect
          fill="white"
          height="108.615"
          width="46.1511"
          x="-0.39521"
          y="13.7298"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="7.20703"
          y="21.625"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="7.20703"
          y="53.0703"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="7.20703"
          y="32.1016"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="7.20703"
          y="63.5547"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="7.20703"
          y="42.5859"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="30.7966"
          x="7.20703"
          y="74.0391"
        />
        <rect
          height="108.615"
          stroke="#DADCE0"
          strokeWidth="0.790419"
          width="46.1511"
          x="-0.39521"
          y="13.7298"
        />
        <rect
          fill="white"
          height="14.5506"
          transform="matrix(1 0 0 -1 0 14.5482)"
          width="226.85"
          x="-0.39521"
          y="0.39521"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="6.55246"
          x="214.266"
          y="3.92969"
        />
        <rect
          fill="#F1F2F3"
          height="6.55246"
          width="6.55246"
          x="203.781"
          y="3.92969"
        />
        <rect
          height="14.5506"
          stroke="#DADCE0"
          strokeWidth="0.790419"
          transform="matrix(1 0 0 -1 0 14.5482)"
          width="226.85"
          x="-0.39521"
          y="0.39521"
        />
        <rect
          fill="#F1F2F3"
          height="91.0792"
          opacity="0.5"
          width="106.15"
          x="82.5625"
          y="21.625"
        />
      </g>
      <rect
        height="121.515"
        rx="2.5"
        stroke="#DADCE0"
        width="225.06"
        x="0.5"
        y="0.5"
      />
      <g clipPath="url(#clip1_8122_7218)">
        <mask fill="white" id="path-14-inside-1_8122_7218">
          <path d="M113 0H223C224.657 0 226 1.34315 226 3V120C226 121.657 224.657 123 223 123H113V0Z" />
        </mask>
        <path
          d="M113 0H223C224.657 0 226 1.34315 226 3V120C226 121.657 224.657 123 223 123H113V0Z"
          fill="#3E414A"
        />
        <rect
          fill="#101012"
          height="14.4154"
          transform="matrix(1 0 0 -1 0 14.4131)"
          width="226.715"
          x="-0.327623"
          y="0.327623"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="6.55246"
          x="214.266"
          y="3.92969"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="6.55246"
          x="203.781"
          y="3.92969"
        />
        <rect
          height="14.4154"
          stroke="#2E3137"
          strokeWidth="0.655246"
          transform="matrix(1 0 0 -1 0 14.4131)"
          width="226.715"
          x="-0.327623"
          y="0.327623"
        />
        <rect
          fill="#2E3137"
          height="91.0792"
          opacity="0.5"
          width="106.15"
          x="82.5625"
          y="21.625"
        />
      </g>
      <path
        d="M113 -1H223C225.209 -1 227 0.790861 227 3H225C225 1.89543 224.105 1 223 1H113V-1ZM227 120C227 122.209 225.209 124 223 124H113V122H223C224.105 122 225 121.105 225 120H227ZM113 123V0V123ZM223 -1C225.209 -1 227 0.790861 227 3V120C227 122.209 225.209 124 223 124V122C224.105 122 225 121.105 225 120V3C225 1.89543 224.105 1 223 1V-1Z"
        fill="#DADCE0"
        mask="url(#path-14-inside-1_8122_7218)"
      />
      <defs>
        <clipPath id="clip0_8122_7218">
          <rect fill="white" height="122.515" rx="3" width="226.06" />
        </clipPath>
        <clipPath id="clip1_8122_7218">
          <path
            d="M113 0H223C224.657 0 226 1.34315 226 3V120C226 121.657 224.657 123 223 123H113V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AutoLayoutImage;
