import React, { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { EAppType, ISubscription, IUser } from '@quesmed/types-rn/models';

import SettingsContentLoading from './SettingsContentLoading';
import { ErrorBox } from 'components/Error';
import { SettingsModal, useSettingsModalState } from './useSettingsModalState';
import { Button } from 'components/Button';
import { H6 } from 'components/Typography';
import { FeedbackModal } from 'components/FeedbackModal';
import NoSubscriptionsScreen from './NoSubscriptionsScreen';
import SubscriptionsGroup from './SubscriptionsGroup';
import { entries } from 'utils';
import { HelpCircleOutlineIcon } from 'components/Icons';
import {
  AppProductType,
  QuesmedAppNames,
  SortedSubscriptions,
  SUBSCRIPTIONS,
} from 'types';
import { useCustomerPortal } from 'hooks';

const CENTS_IN_POUNDS = 100;

const parseSubscriptions = (
  data?: ISubscription[]
): [SortedSubscriptions, boolean] => {
  const userSubscriptions: SortedSubscriptions = {
    [EAppType.AMS]: [],
    [EAppType.CM_UKMLA]: [],
    [EAppType.MRCP]: [],
    [EAppType.INTERVIEW]: [],
    [EAppType.MSRA]: [],
    [EAppType.PLAB]: [],
  };

  let count = 0;

  if (!data) {
    return [userSubscriptions, false];
  }

  const subscriptions = data.reduce(
    (acc, { id, product, periodEndAt, endedAt }) => {
      if (product) {
        const { typeId, duration, price, subscription } = product;
        const { app } = SUBSCRIPTIONS[typeId as AppProductType];
        const sub = {
          app,
          duration,
          price: price / CENTS_IN_POUNDS,
          renewalDate: periodEndAt,
          subscription: typeId as AppProductType,
          subscriptionId: id,
          isCanceled: endedAt !== null,
          canBeUpdated: subscription,
        };

        acc[app].push(sub);
        count += 1;
      }

      return acc;
    },
    userSubscriptions
  );

  return [subscriptions, count > 0];
};

const StyledHeader = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: spacing(6),
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: spacing(2),

  [breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const ButtonsBox = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  flexWrap: 'wrap',

  [breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

interface SettingsSubscriptionsProps {
  closeSettingsModal: () => void;
  error?: ApolloError;
  loading?: boolean;
  user?: IUser;
}

const SettingsSubscriptions = ({
  closeSettingsModal,
  error,
  loading,
  user,
}: SettingsSubscriptionsProps): JSX.Element => {
  const { shownModal, openModal, closeModal } = useSettingsModalState();
  const { customerPortal } = useCustomerPortal();

  const handleOpenFeedbackModal = () => {
    openModal(SettingsModal.FeedbackModal);
  };

  const handleCloseFeedbackModal = () => {
    closeModal();
  };

  const { subscriptions: subscriptionsData } = user || {};

  const [subscriptions, anySubscription] = useMemo(
    () => parseSubscriptions(subscriptionsData),
    [subscriptionsData]
  );

  const handleUpdateBilling = () => {
    customerPortal();
  };

  if (error) {
    return <ErrorBox description={error.message} />;
  }

  return (
    <>
      <SettingsContentLoading count={2} height={184} loading={Boolean(loading)}>
        {!anySubscription ? (
          <NoSubscriptionsScreen closeSettingsModal={closeSettingsModal} />
        ) : (
          <>
            <StyledHeader>
              <H6>Subscriptions</H6>
              <ButtonsBox>
                <Button
                  color="secondary"
                  onClick={handleUpdateBilling}
                  secondary
                  size="small"
                >
                  Update Billing Information
                </Button>
                <Button
                  color="secondary"
                  onClick={handleOpenFeedbackModal}
                  secondary
                  size="small"
                >
                  Help & Support
                  <HelpCircleOutlineIcon />
                </Button>
              </ButtonsBox>
            </StyledHeader>
            {entries(subscriptions).map(([app, appSubscriptions]) =>
              appSubscriptions.length ? (
                <SubscriptionsGroup
                  key={app}
                  subscriptions={appSubscriptions}
                  title={QuesmedAppNames[app]}
                />
              ) : null
            )}
          </>
        )}
      </SettingsContentLoading>
      <FeedbackModal
        isInvokedOnSubscriptions
        onClose={handleCloseFeedbackModal}
        open={shownModal === SettingsModal.FeedbackModal}
      />
    </>
  );
};

export default SettingsSubscriptions;
