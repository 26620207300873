import React from 'react';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';

import { Children } from 'types';

const AdditionalContent = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),

    [breakpoints.down('md')]: {
      '& .content-header': {
        padding: spacing(4),
        marginBottom: 0,
      },

      '& .content:not(.content .content)': {
        padding: 0,
      },
    },
  })
);

const QuestionAdditionalContent = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  return <AdditionalContent>{children}</AdditionalContent>;
};

export default QuestionAdditionalContent;
