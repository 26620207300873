import React from 'react';
import { mdiTelevisionPlay } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const VideoTrainingsIcon = (props: IconProps) => (
  <Icon path={mdiTelevisionPlay} {...props} />
);

export default VideoTrainingsIcon;
