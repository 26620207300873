import React from 'react';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import {
  IUserData,
  REFERRALS,
} from '@quesmed/types-rn/resolvers/query/restricted';

import { Modal, ModalProps } from 'components/Modal/Modal';
import { H6 } from 'components/Typography';
import { InviteFriendsImage } from 'components/Illustrations';
import { ClipboardCopyButton } from 'components/ClipboardCopyButton';

type InviteFriendsModalProps = Pick<ModalProps, 'open' | 'onClose'>;

const Image = styled(InviteFriendsImage)({
  margin: '0 auto',
});

const SubHeading = styled(H6)(({ theme: { spacing } }) => ({
  textAlign: 'center',
  paddingTop: spacing(4),
}));

const SectionHeading = styled(Box)(
  ({ theme: { spacing, palette, typography } }) => ({
    margin: spacing(8, 0, 4, 0),
    color: palette.text.disabled,
    ...typography.button,
  })
);

const StepsList = styled('ol')(({ theme: { spacing } }) => ({
  listStyle: 'decimal',
  paddingLeft: spacing(4),
}));

const HowItWorksStep = styled('li')(({ theme: { spacing, palette } }) => ({
  marginBottom: spacing(2),
  color: palette.text.primary,
}));

const CopyToClipboardContentContainer = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: spacing(5),
    border: `1px solid ${palette.stroke.main}`,
    color: palette.text.disabled,
    borderRadius: '4px',
    padding: spacing(4, 3),
  })
);

const FooterContainer = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(8, 0),
  width: '100%',
  '& .MuiButtonBase-root': {
    width: '100%',
  },
}));

const COPY_TO_CLIPBOARD_TEXT =
  'Hi there! I’ve been using Quesmed and it’s been a game changer for' +
  ' learning. If you’re interested in trying it out, just enter my email in the' +
  ' ’Referral email’ field during registration. and we’ll both get an extra one' +
  ' week of access. Win-win situation!';

const InviteFriendsModal = ({
  open,
  onClose,
}: InviteFriendsModalProps): JSX.Element => {
  const { data } = useQuery<IUserData>(REFERRALS);
  const { username } = data?.restricted?.user || {};

  return (
    <Modal
      disableContentScroll
      maxWidth="md"
      noPaddingY
      onClose={onClose}
      open={open}
      showCloseButton
      showControls={false}
      sizeVariant="md"
      title="Invite a friend. Earn rewards!"
    >
      <Image />
      <SubHeading>
        Invite your friends to join Quesmed and get an extra 1 week of access
        for every new user you refer.
      </SubHeading>
      <SectionHeading>How it works</SectionHeading>
      <StepsList>
        <HowItWorksStep>
          Simply forward your email to your friends.
        </HowItWorksStep>
        <HowItWorksStep>
          Let them know that all they have to do is put your email in the
          ’Referral email’ field when they sign up.
        </HowItWorksStep>
        <HowItWorksStep>
          Once your friend subscribes to one of our products, we’ll reward you
          by adding an extra 1 week to your existing subscriptions.
        </HowItWorksStep>
        <HowItWorksStep>
          There’s no limit to how many friends you can invite, so the more you
          refer, the more you earn!
        </HowItWorksStep>
      </StepsList>
      <SectionHeading>Copy to encourage your friends</SectionHeading>

      <CopyToClipboardContentContainer>
        {COPY_TO_CLIPBOARD_TEXT}
        <div>Here’s my e-mail: {username}</div>
      </CopyToClipboardContentContainer>
      <FooterContainer>
        <ClipboardCopyButton
          icon={false}
          text={`${COPY_TO_CLIPBOARD_TEXT} \n\nHere's my e-mail: ${username}`}
        />
      </FooterContainer>
    </Modal>
  );
};

export default InviteFriendsModal;
