import React from 'react';
import { styled } from '@mui/material/styles';
import MuiBadge, { BadgeProps } from '@mui/material/Badge';

const Badge = styled(MuiBadge)(({ theme: { palette } }) => ({
  '.MuiBadge-dot': {
    height: '10px',
    width: '10px',
    minWidth: '6px',
    borderRadius: '20px',
    border: `2px solid ${palette.background.paper}`,
    right: '4px',
    top: '5px',
  },
}));

const IconBadge = ({
  color = 'error',
  children,
  invisible,
  variant = 'dot',
  ...rest
}: BadgeProps): JSX.Element => (
  <Badge color={color} invisible={invisible} variant={variant} {...rest}>
    {children}
  </Badge>
);

export default IconBadge;
