import React, { SVGProps } from 'react';

import { StyledSVG } from './ProductStyle';

export const ProductOne = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <StyledSVG
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6438_3315)">
      <rect
        className="foreground-one"
        fill="#DADCE0"
        height="64"
        rx="8"
        width="64"
      />
      <circle
        className="background"
        cx="53.5"
        cy="10.5"
        fill="#B5B8C1"
        r="31.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_6438_3315">
        <rect fill="white" height="64" rx="8" width="64" />
      </clipPath>
    </defs>
  </StyledSVG>
);
