import React from 'react';

import SettingsContentLayout from './SettingsContentLayout';
import SettingsSectionTitle from './SettingsSectionTitle';
import { Button } from 'components/Button';
import { SettingsModal, useSettingsModalState } from './useSettingsModalState';
import AccountInvestigationModal from './AccountInvestigationModal';

const SettingsAdminPanel = (): JSX.Element => {
  const { shownModal, openModal, closeModal } = useSettingsModalState();

  const handleOpenAccountInvestigationModal = () => {
    openModal(SettingsModal.AccountInvestigationModal);
  };

  return (
    <>
      <SettingsContentLayout>
        <SettingsSectionTitle
          subtitle="As an administrator, you have the power to investigate and resolve issues by logging into users' accounts. This allows you to replicate their experience, identify errors and provide immediate help to resolve issues."
          title="User account investigation"
        />
        <Button
          danger
          onClick={handleOpenAccountInvestigationModal}
          variant="outlined"
        >
          Perform Account Investigation
        </Button>
      </SettingsContentLayout>
      <AccountInvestigationModal
        onClose={closeModal}
        open={shownModal === SettingsModal.AccountInvestigationModal}
      />
    </>
  );
};

export default SettingsAdminPanel;
