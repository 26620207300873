import React from 'react';
import List, { ListProps } from '@mui/material/List';
import { styled } from '@mui/material/styles';

import { Children } from 'types';

const ITEM_HEIGHT = 24;
const ITEM_GAP = 16;
const VISIBLE_ITEM_COUNT = 6;

const StyledList = styled(List)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',

  margin: spacing(0, -8),
  padding: spacing(0, 8),
}));

interface ExpandableSectioScrollableListListProps extends ListProps {
  children: Children;
  itemHeight?: number;
  visibleItemsCount?: number;
  itemsGap?: number;
}

const ExpandableSectioScrollableListList = ({
  children,
  itemHeight = ITEM_HEIGHT,
  visibleItemsCount = VISIBLE_ITEM_COUNT,
  itemsGap = ITEM_GAP,
  ...props
}: ExpandableSectioScrollableListListProps): JSX.Element => {
  const maxHeight = `${
    itemHeight * visibleItemsCount + itemsGap * (visibleItemsCount - 1)
  }px`;

  return (
    <StyledList sx={{ gap: `${itemsGap}px`, maxHeight }} {...props}>
      {children}
    </StyledList>
  );
};

export default ExpandableSectioScrollableListList;
