import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  IStartOsceMarksheetData,
  IStartOsceMarksheetVar,
  START_OSCE_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'components/Snackbar';
import { paths } from 'Router';
import { OnRequestComplete } from 'types';

const { dashboard, stations } = paths;

const useStartMarksheetMutation = (
  onStartComplete?: OnRequestComplete<IStartOsceMarksheetData>
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [start, { loading: startLoading }] = useMutation<
    IStartOsceMarksheetData,
    IStartOsceMarksheetVar
  >(START_OSCE_MARKSHEET, {
    onError: () => {
      navigate(dashboard);
    },
    onCompleted: data => {
      if (!data) {
        enqueueSnackbar('Starting study failed.');
        navigate(dashboard);

        return;
      }

      if (onStartComplete) {
        onStartComplete(data);

        return;
      }

      const { id, solo } = data.restricted.startOsceMarksheet;

      if (solo) {
        navigate(`${stations.root}/solo/study/${id}`, {
          state: { leave: true },
        });
      }
    },
  });

  const startMarksheet = useCallback(
    (osceMarksheetId: number) => start({ variables: { osceMarksheetId } }),
    [start]
  );

  return { startLoading, startMarksheet };
};

export default useStartMarksheetMutation;
