import React from 'react';

interface LogoImageProps {
  className: string;
}

const LogoImage = (props: LogoImageProps): JSX.Element => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 129 24"
    width="129"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.5126 0.823178V15.9629C32.5126 18.6409 30.8539 19.9125 28.9092 19.9125C26.9644 19.9125 25.2433 18.6409 25.2433 15.9629V0.823178H21.4211V15.9942C21.4211 21.1866 24.7649 23.4486 28.9092 23.4486C33.0535 23.4486 36.306 21.1866 36.306 15.9942V0.823178H32.5126Z"
      fill="#101012"
    />
    <path
      d="M44.6555 19.7851V13.4773H51.6003V10.1623H44.6555V4.1694H52.7182V0.823178H40.9246V23.1337H53.0355V19.7851H44.6555V19.7851Z"
      fill="#101012"
    />
    <path
      d="M65.1141 10.7678L63.8088 10.0346C62.2126 9.17399 60.5876 8.28214 60.5876 6.33739C60.5876 4.74601 61.6405 3.75801 63.1718 3.75801C64.8593 3.75801 65.7223 4.93592 65.8786 6.97442H69.5758C69.5133 2.95992 67.0589 0.503135 63.203 0.503135C59.5659 0.503135 56.7317 2.63779 56.7317 6.5922C56.7317 9.90717 58.8015 11.501 60.9073 12.614L62.4674 13.5058C64.4747 14.6525 65.8161 15.7679 65.8161 17.5852C65.8161 19.4026 64.5733 20.1983 63.1045 20.1983C61.2246 20.1983 60.1092 19.0203 59.9818 16.4386H56.2534C56.3159 20.9627 58.7702 23.4483 63.1357 23.4483C66.6406 23.4483 69.7008 21.7295 69.7008 17.4266C69.7008 13.1236 66.6743 11.5923 65.1093 10.7654L65.1141 10.7678Z"
      fill="#101012"
    />
    <path
      d="M88.1304 0.823178L83.5102 15.7682H83.2842L78.6014 0.823178H73.8825V23.1337H77.3874V8.37622H77.6422L81.4019 20.5808H85.3539L89.0824 8.37622H89.3372V23.1313H92.8445V0.823178H88.1256H88.1304Z"
      fill="#101012"
    />
    <path
      d="M101.165 19.7851V13.4773H108.113V10.1623H101.165V4.1694H109.228V0.823178H97.4345V23.1337H109.545V19.7851H101.165V19.7851Z"
      fill="#101012"
    />
    <path
      d="M119.073 0.823178H113.241V23.1337H119.073C124.109 23.1337 128.09 20.4895 128.09 11.9773C128.09 2.38331 122.993 0.825582 119.073 0.825582V0.823178ZM119.039 19.689H117.063V4.26796H119.039C121.844 4.26796 124.169 5.73193 124.169 11.9148C124.169 18.0976 121.972 19.689 119.039 19.689Z"
      fill="#101012"
    />
    <path
      d="M18.2754 19.6512C15.5951 19.6127 15.3451 20.0767 14.6191 20.6873L14.5446 20.6152C14.5446 20.6152 14.547 20.6128 14.547 20.608C14.5734 20.5815 14.5999 20.5551 14.6215 20.5286C14.6407 20.5118 14.6552 20.495 14.6672 20.4805C16.3355 18.5478 17.3379 15.5934 17.3379 11.6582C17.3379 6.34563 15.523 3.14845 12.7176 1.53544C11.5349 0.847923 10.1743 0.501761 8.6983 0.501761C6.42662 0.501761 4.43859 1.30947 2.94818 2.89845C1.14766 4.81195 0.0635033 7.53556 0.0635033 11.6582C0.0635033 18.9541 3.44097 22.8436 8.18867 23.132H20.0663V19.6752L18.2706 19.6488L18.2754 19.6512ZM10.9556 19.2257C10.5349 19.5238 10.0733 19.7185 9.57572 19.8243C9.29446 19.882 9.00359 19.9132 8.7031 19.9132C5.99632 19.9132 3.95541 17.555 3.95541 11.978C3.95541 6.40092 5.99632 4.0427 8.7031 4.0427C11.4099 4.0427 13.422 6.40333 13.422 11.978C13.422 15.8122 12.4748 18.1247 10.9988 19.1969C10.982 19.2089 10.97 19.2209 10.9556 19.2257V19.2257Z"
      fill="#101012"
    />
  </svg>
);

export default LogoImage;
