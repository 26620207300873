import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { H5 } from 'components/Typography';
import { Button } from 'components/Button';
import useUpgradeNow from './useUpgradeNow';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  height: '100%',
  minHeight: '200px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: spacing(8),
}));

interface DemoGuardProps {
  contentName?: string;
}

const DemoGuard = ({ contentName }: DemoGuardProps): JSX.Element => {
  const upgradeNow = useUpgradeNow();

  return (
    <Container>
      <H5>{`${contentName || 'Content'} not available in demo`}</H5>
      <Button onClick={upgradeNow}>Upgrade now</Button>
    </Container>
  );
};

export default DemoGuard;
