import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { noop } from 'utils';

interface ExerciseState {
  leaveLabel: string;
  setLeaveLabel: (leaveLabel: string) => void;
  onLeave: () => void;
  setOnLeave: (fn: () => void) => void;
}

const inititalState = {
  leaveLabel: '',
  onLeave: () => noop,
};

const exerciseState = create<ExerciseState>()(set => ({
  ...inititalState,
  setLeaveLabel: leaveLabel => set({ leaveLabel }),
  setOnLeave: fn => set({ onLeave: fn }),
}));

const useExerciseState = () =>
  exerciseState(
    useShallow(({ leaveLabel, onLeave, setLeaveLabel, setOnLeave }) => ({
      leaveLabel,
      onLeave,
      setLeaveLabel,
      setOnLeave,
    }))
  );

export default useExerciseState;
