import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { CameraButton, SoundButton } from 'components/Agora';
import { Button } from 'components/Button';
import { ExitIcon } from 'components/Icons';

const LeaveButton = styled(Button)(({ theme: { palette, spacing } }) => ({
  borderRadius: '100px',
  height: spacing(12),
  flexGrow: 1,
  gap: spacing(2),

  '&.MuiLoadingButton-root.MuiButton-outlinedSecondary': {
    backgroundColor: palette.background.paper,

    '&:hover': {
      backgroundColor: palette.error.main,
      borderColor: palette.error.main,
      color: palette.error.contrastText,
    },
  },
}));

const ControlsContainer = styled(Box)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.background.default,
  padding: spacing(4, 6),
}));

const ControlsBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(2),
}));

export interface ExerciseControlsProps {
  onLeave?: () => void;
  withCamera?: boolean;
  withSound?: boolean;
  timer?: ReactElement;
  leaveLabel?: string;
}

const ExerciseControls = ({
  withCamera = false,
  onLeave,
  timer,
  leaveLabel = 'Leave Session',
  withSound = false,
}: ExerciseControlsProps): JSX.Element => (
  <ControlsContainer>
    {timer}
    <ControlsBox>
      {withSound ? <SoundButton /> : null}
      {withCamera ? <CameraButton /> : null}
      {onLeave ? (
        <LeaveButton color="secondary" onClick={onLeave} secondary>
          <ExitIcon />
          {leaveLabel}
        </LeaveButton>
      ) : null}
    </ControlsBox>
  </ControlsContainer>
);

export default ExerciseControls;
