import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { noop } from 'utils';
import { setActiveSpeakerAction } from './AgoraActions';
import { activeSpeakerReducer } from './AgoraReducer';
import {
  ActiveSpeaker,
  ActiveSpeakerContextProviderProps,
  ActiveSpeakerDispatch,
  ActiveSpeakerDispatchProviderProps,
  UserVolume,
} from './types';
import { Children } from 'types';

// Active Speaker uses its own contexts due to often active speaker changes.
// Active Speaker uses useReducer to keep its state and dispatch in separate
// contexts to avoid rerender places where only dispatch is used.
// Using reducer causes that all Agora related states are built in the same way.

const initialActiveSpeaker: ActiveSpeaker = {
  activeSpeaker: undefined,
};

const ActiveSpeakerContext = createContext<ActiveSpeaker>(initialActiveSpeaker);

const { Provider: StateContextProvider } = ActiveSpeakerContext;

const ActiveSpeakerProvider = ({
  children,
  value,
}: ActiveSpeakerContextProviderProps): JSX.Element => (
  <StateContextProvider value={value}>{children}</StateContextProvider>
);

export const useActiveSpeaker = (): ActiveSpeaker =>
  useContext(ActiveSpeakerContext);

const initialActoveSpeakerDispatcher: ActiveSpeakerDispatch = {
  setActiveSpeaker: noop,
};

const ActiveSpeakerDispatchContext = createContext<ActiveSpeakerDispatch>(
  initialActoveSpeakerDispatcher
);

const { Provider: DispatchContextProvider } = ActiveSpeakerDispatchContext;

const ActiveSpeakerDispatchProvider = ({
  children,
  value: dispatch,
}: ActiveSpeakerDispatchProviderProps): JSX.Element => {
  const setActiveSpeaker = useCallback(
    (payload?: UserVolume) => dispatch(setActiveSpeakerAction(payload)),
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      setActiveSpeaker,
    }),
    [setActiveSpeaker]
  );

  return (
    <DispatchContextProvider value={actions}>
      {children}
    </DispatchContextProvider>
  );
};

export const useSetActiveSpeaker = (): ActiveSpeakerDispatch =>
  useContext(ActiveSpeakerDispatchContext);

export const ActiveSpeakerProviders = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  const [activeSpeaker, activeSpeakerDispatch] = useReducer(
    activeSpeakerReducer,
    initialActiveSpeaker
  );

  return (
    <ActiveSpeakerDispatchProvider value={activeSpeakerDispatch}>
      <ActiveSpeakerProvider value={activeSpeaker}>
        {children}
      </ActiveSpeakerProvider>
    </ActiveSpeakerDispatchProvider>
  );
};
