import React, { forwardRef, ReactElement } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { UtilButtonGroup } from 'components/UtilButtonGroup';
import { UtilButton } from 'components/UtilButton';
import { ButtonProps } from 'components/Button';
import { Utils } from 'types';

interface BottomBarProps {
  quizprogressbar: 1 | 0;
}

const BottomBar = styled(Box)<BottomBarProps>(
  ({
    quizprogressbar,
    theme: { palette, breakpoints, transitions, mixins },
  }) => ({
    display: 'flex',
    backgroundColor: palette.background.paper,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${palette.stroke.main}`,
    color: palette.text.primary,
    width: '100%',
    flexDirection: 'column',
    height: quizprogressbar
      ? `${mixins.bottombar.height}px`
      : `${mixins.bottombar.maxHeight}px`,

    '&.no-cta': {
      height: `${mixins.progressbar.minHeight}px`,
    },

    [breakpoints.up('md')]: {
      height: `${mixins.bottombar.minHeight}px`,
      '&.no-cta': {
        height: `${mixins.bottombar.minHeight}px`,
      },
      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      flexShrink: 0,
    },
  })
);

const Container = styled(Box)(({ theme: { breakpoints } }) => ({
  alignItems: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',

  [breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ComplementaryActions = styled(Box)(
  ({ theme: { breakpoints, spacing, mixins } }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: spacing(0, 4),
    height: `${mixins.progressbar.minHeight}px`,

    [breakpoints.up('md')]: {
      padding: spacing(0, 6),
      height: `${mixins.bottombar.minHeight}px`,
    },
  })
);

const HelperButtons = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  gap: spacing(1),

  [breakpoints.up('md')]: {
    gap: 0,
  },
}));

const ControlsContainer = styled(Box)(
  ({ theme: { breakpoints, palette, spacing, mixins } }) => ({
    borderTop: `1px solid ${palette.stroke.main}`,
    padding: spacing(0, 4),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '80px',

    [breakpoints.up('md')]: {
      height: `${mixins.bottombar.minHeight}px`,
      border: 0,
      width: 'auto',
      padding: spacing(0, 6, 0, 0),
    },

    '& .MuiButtonBase-root': {
      whiteSpace: 'nowrap',
    },
  })
);

export interface HelperControl
  extends Pick<ButtonProps, 'pressed' | 'onClick'> {
  icon: ButtonProps['startIcon'];
  active?: boolean;
  label: string;
  tip?: string;
}

interface ExerciseBottomBarProps {
  activeTab?: string;
  primaryControl?: ReactElement;
  helperControls?: HelperControl[];
  utils?: Utils[];
  quizProgressBar?: boolean;
}

export const ExerciseBottomBar = forwardRef<
  HTMLDivElement,
  ExerciseBottomBarProps
>(
  (
    { activeTab, helperControls, quizProgressBar, primaryControl, utils },
    ref
  ): JSX.Element => {
    const hideButton = activeTab && !activeTab.includes('Markscheme');

    return (
      <BottomBar
        className={clsx({ 'no-cta': hideButton })}
        quizprogressbar={quizProgressBar ? 1 : 0}
        ref={ref}
      >
        <Container>
          {utils || helperControls ? (
            <ComplementaryActions className={clsx({ 'no-cta': hideButton })}>
              {utils ? <UtilButtonGroup anchorRef={ref} utils={utils} /> : null}
              {helperControls ? (
                <HelperButtons>
                  {helperControls.map(controlProps => (
                    <UtilButton key={controlProps.label} {...controlProps} />
                  ))}
                </HelperButtons>
              ) : null}
            </ComplementaryActions>
          ) : null}
          {!hideButton && primaryControl && (
            <ControlsContainer>{primaryControl}</ControlsContainer>
          )}
        </Container>
      </BottomBar>
    );
  }
);

ExerciseBottomBar.displayName = 'ExerciseBottomBar';
