import { IAccessLevel, IPayload } from '@quesmed/types-rn/models';
import { useMemo } from 'react';

import { useAuth } from './useAuth';

export type CurrentUser = Omit<IPayload, 'exp' | 'ver'> & {
  email: string;
};

export const useCurrentUser = (): CurrentUser => {
  const { tokenDecoded } = useAuth();

  return useMemo(() => {
    const {
      id = 1,
      displayName = '',
      firstName = '',
      lastName = '',
      username = '',
      accessLevel = IAccessLevel.SUBSCRIBER,
      tocAccepted = false,
      qbankSubscriptionEndDate = null,
      osceSubscriptionEndDate = null,
      bundleSubscriptionEndDate = null,
      anatomySubscriptionEndDate = null,
      medicalSciencesSubscriptionEndDate = null,
      anatomyBundleSubscriptionEndDate = null,
      mrcpPart1SubscriptionEndDate = null,
      mrcpPart2SubscriptionEndDate = null,
      pacesSubscriptionEndDate = null,
      msraSubscriptionEndDate = null,
      anaestheticsInterviewSubscriptionEndDate = null,
      cstInterviewSubscriptionEndDate = null,
      imtInterviewSubscriptionEndDate = null,
      radiologyInterviewSubscriptionEndDate = null,
      plab1SubscriptionEndDate = null,
      plab2SubscriptionEndDate = null,
    } = tokenDecoded ? tokenDecoded : {};

    return {
      id,
      displayName,
      firstName,
      lastName,
      email: username,
      username: displayName || firstName || '',
      accessLevel,
      tocAccepted,
      qbankSubscriptionEndDate,
      osceSubscriptionEndDate,
      bundleSubscriptionEndDate,
      anatomySubscriptionEndDate,
      medicalSciencesSubscriptionEndDate,
      anatomyBundleSubscriptionEndDate,
      mrcpPart1SubscriptionEndDate,
      mrcpPart2SubscriptionEndDate,
      pacesSubscriptionEndDate,
      msraSubscriptionEndDate,
      anaestheticsInterviewSubscriptionEndDate,
      cstInterviewSubscriptionEndDate,
      imtInterviewSubscriptionEndDate,
      radiologyInterviewSubscriptionEndDate,
      plab1SubscriptionEndDate,
      plab2SubscriptionEndDate,
    };
  }, [tokenDecoded]);
};

export default useCurrentUser;
