import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Joi from 'joi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EEntitlementType } from '@quesmed/types-rn/models';

import { FormCheckboxGroup } from 'components/Checkbox';
import { Modal, ModalProps } from 'components/Modal/Modal';
import ModalSection from 'components/Modal/ModalSection';
import { FormField } from 'components/TextField';
import { PreBuildData } from 'types';
import { useBuildFlashcards } from './hooks';
import { SelectionLabel } from 'components/SelectionLabel';
import { SelectionCheckboxContainer } from '../Questions/QuestionsPreBuildModal';

enum FlashcardsType {
  Unseen = 'Unseen',
  Seen = 'Seen',
}
interface FlashCardForm {
  flashcardsCount: number;
  flashcardsType: FlashcardsType[];
}

interface FlashCardsPreBuildModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'onBack'> {
  selection?: PreBuildData;
  search?: string;
  source?: string;
  entitlementId?: EEntitlementType;
  onStartQuiz?: () => void;
}

const MINIMUM_FLASHACRDS_VALUE = 1;
const DEFAULT_FLASHCARDS_VALUE = 20;
const MAXIMUM_FLASHCARDS_VALUE = 300;

const calcBuildFlashcardValues = (
  seen: number,
  unseen: number,
  flashcardsCount?: number,
  flashcardsType?: (FlashcardsType | undefined)[]
) => {
  let max = 0;
  let finalSeen = 0;
  let finalUnseen = 0;
  let quota = Number(flashcardsCount);

  if (flashcardsType && flashcardsType.includes(FlashcardsType.Unseen)) {
    max = unseen;
    finalUnseen = Math.min(quota, unseen);
    quota = quota - finalUnseen;
  }

  if (flashcardsType && flashcardsType.includes(FlashcardsType.Seen)) {
    max = max + seen;
    finalSeen = Math.min(quota, seen);
    quota = quota - finalSeen;
  }

  if (max >= MAXIMUM_FLASHCARDS_VALUE) {
    max = MAXIMUM_FLASHCARDS_VALUE;
  }

  return { seen: finalSeen, unseen: finalUnseen, max };
};

interface CardsCount {
  seen: number;
  unseen: number;
}

const initCardsCount = {
  seen: 0,
  unseen: 0,
};

const defaultValues = {
  flashcardsCount: DEFAULT_FLASHCARDS_VALUE,
  flashcardsType: [FlashcardsType.Unseen, FlashcardsType.Seen],
};

const FlashCardsPreBuildModal = ({
  open,
  onBack,
  onClose,
  selection,
  search,
  source,
  onStartQuiz,
  entitlementId,
}: FlashCardsPreBuildModalProps): JSX.Element => {
  const {
    preBuildLoading,
    preBuildFlashcards,
    buildLoading,
    buildFlashcards,
    seen: totalSeen = 0,
    unseen: totalUnseen = 0,
  } = useBuildFlashcards({
    search,
    source,
    onBuildComplete: onStartQuiz,
    entitlementId,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentMaxValue, setCurrentMaValue] = useState<number>(0);
  const [cardsCount, setCardsCount] = useState<CardsCount>(initCardsCount);
  const schema = useMemo(
    () =>
      Joi.object<FlashCardForm>({
        flashcardsCount: Joi.number()
          .min(MINIMUM_FLASHACRDS_VALUE)
          .max(currentMaxValue)
          .required()
          .messages({
            'any.required': 'Enter the number of flashcards',
            'number.base': 'Flashcards amount must be a number',
            'number.min': `Select at least ${MINIMUM_FLASHACRDS_VALUE} flashcard`,
            'number.max': `Select maximum ${currentMaxValue} flashcards`,
          }),
        flashcardsType: Joi.array().items(Joi.string()).min(1).messages({
          'array.min': 'Select at least one flashcard types',
        }),
      }),
    [currentMaxValue]
  );

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm<FlashCardForm>({
    defaultValues,
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const setFlashcardsCount = useCallback(
    (count: number) => {
      setValue('flashcardsCount', count);
    },
    [setValue]
  );

  useEffect(() => {
    if (selection || (search && source)) {
      const conceptIds = selection || new Set();
      preBuildFlashcards(conceptIds);
    }
  }, [preBuildFlashcards, search, selection, source]);

  useEffect(() => {
    const { max } = calcBuildFlashcardValues(
      totalSeen,
      totalUnseen,
      getValues('flashcardsCount'),
      getValues('flashcardsType')
    );

    setCurrentMaValue(max);

    const subscription = watch(data => {
      const { flashcardsCount, flashcardsType } = data;

      const { max, seen, unseen } = calcBuildFlashcardValues(
        totalSeen,
        totalUnseen,
        flashcardsCount,
        flashcardsType
      );
      setCardsCount({ unseen, seen });
      setCurrentMaValue(max);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [totalSeen, totalUnseen, watch, getValues]);

  useEffect(() => {
    if (!isInitialized) {
      if (currentMaxValue) {
        setFlashcardsCount(
          currentMaxValue > DEFAULT_FLASHCARDS_VALUE
            ? DEFAULT_FLASHCARDS_VALUE
            : currentMaxValue
        );
        setIsInitialized(true);
        trigger();
      }
    } else {
      setFlashcardsCount(
        Math.min(Number(getValues('flashcardsCount')), currentMaxValue)
      );
    }
  }, [getValues, isInitialized, currentMaxValue, setFlashcardsCount, trigger]);

  const onSubmit: SubmitHandler<FlashCardForm> = () => {
    const { seen, unseen } = cardsCount;
    buildFlashcards(unseen, seen);
  };

  const FLASHCARDS_VARIANTS = [
    {
      id: 1,
      label: (
        <SelectionLabel
          label="Not answered flashcards"
          selectedCount={cardsCount.unseen}
          totalCount={totalUnseen}
        />
      ),

      value: FlashcardsType.Unseen,
    },
    {
      id: 2,
      label: (
        <SelectionLabel
          label="Previously answered flashcards"
          selectedCount={cardsCount.seen}
          totalCount={totalSeen}
        />
      ),
      value: FlashcardsType.Seen,
    },
  ];

  return (
    <Modal
      loading={buildLoading}
      maxWidth="md"
      noPaddingY
      onBack={onBack}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={!formState.isValid || preBuildLoading}
      submitLabel="start exercise"
      title="Set up the exercise"
    >
      <Box>
        <ModalSection sx={{ marginTop: 0 }} title="Number of Flashcards">
          {preBuildLoading && !isInitialized ? (
            <Skeleton height={80} variant="rectangular" />
          ) : (
            <FormField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    maximum: {currentMaxValue}
                  </InputAdornment>
                ),
              }}
              control={control}
              fullWidth
              helperText="Total number of flashcards to be included in the exercise."
              label="Flashcards"
              name="flashcardsCount"
              type="number"
            />
          )}
        </ModalSection>

        <ModalSection
          subTitle="You can choose more than one option."
          sx={{ marginBottom: 0 }}
          title="Flashcards type"
        >
          {preBuildLoading && !isInitialized ? (
            <Skeleton height={80} variant="rectangular" />
          ) : (
            <SelectionCheckboxContainer>
              <FormCheckboxGroup
                control={control}
                controlSx={{
                  '& .MuiTypography-root': { width: '100%' },
                }}
                name="flashcardsType"
                options={FLASHCARDS_VARIANTS}
              />
            </SelectionCheckboxContainer>
          )}
        </ModalSection>
      </Box>
    </Modal>
  );
};

export default FlashCardsPreBuildModal;
