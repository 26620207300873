import { EDifficultyType, EEntitlementType } from '@quesmed/types-rn/models';
import { IPreBuildMarksheetData } from '@quesmed/types-rn/resolvers/query/restricted';
import { useCallback } from 'react';

import { OnRequestComplete } from 'types';
import usePrebuildMarksheetQuery from './usePrebuildMarksheetQuery';

interface PreBuildQuestionsParams {
  search?: string;
  source?: string;
  marksheetId?: number;
  onPrebuildComplete?: OnRequestComplete<IPreBuildMarksheetData>;
}

const usePreBuildQuestions = (params?: PreBuildQuestionsParams) => {
  const {
    search = '',
    source = 'Questions',
    marksheetId,
    onPrebuildComplete,
  } = params || {};

  const { preBuildLoading, preBuildData, preBuildMarksheet } =
    usePrebuildMarksheetQuery(onPrebuildComplete);

  const preBuildQuestions = useCallback(
    async (
      conceptIds: Set<number> = new Set(),
      difficulty: EDifficultyType[],
      entitlementId?: EEntitlementType
    ) =>
      preBuildMarksheet({
        topicIds: [],
        conceptIds: Array.from(conceptIds),
        search,
        source,
        difficulty,
        marksheetId,
        entitlementId,
      }),
    [preBuildMarksheet, search, source, marksheetId]
  );

  return {
    preBuildData,
    preBuildLoading,
    preBuildQuestions,
  };
};

export default usePreBuildQuestions;
