import React, { ReactNode } from 'react';
import {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButtonGroup as ToggleOptionsGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ToggleEvent } from 'types';

interface ToggleButtonGroupProps<T> {
  onChange?: (event: ToggleEvent, value: T) => void;
  ariaLabel: string;
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  sx?: MuiToggleButtonGroupProps['sx'];
}

const StyledToggleGroup = styled(ToggleOptionsGroup)(
  ({ theme: { palette, spacing } }) => ({
    height: spacing(10),
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid ${palette.stroke.main}`,
    overflow: 'hidden',

    '&:hover': {
      boxShadow: 'none',
    },

    '& .MuiToggleButton-root.MuiToggleButton-standard': {
      backgroundColor: palette.toggle.light,
      color: palette.text.disabled,
      gap: 0,
      flexBasis: '100%',
      height: '38px',
      border: 'none',
      borderRadius: 0,

      '&:not(:first-of-type)': {
        marginLeft: '-1px',
        borderLeft: `1px solid ${palette.stroke.main}`,
      },

      '&.Mui-selected': {
        color: palette.text.primary,
        backgroundColor: palette.background.paper,
        border: 'none',
        marginLeft: '-1px',
        borderLeft: `1px solid ${palette.stroke.main}`,
        borderRadius: 0,

        '&:hover': {
          border: 'none',
          marginLeft: '-1px',
          borderLeft: `1px solid ${palette.stroke.main}`,
          borderRadius: 0,
          color: palette.text.primary,
          backgroundColor: palette.background.paper,
        },
      },

      '&:hover': {
        border: 'none',
        marginLeft: '-1px',
        borderLeft: `1px solid ${palette.stroke.main}`,
        borderRadius: 0,
        color: palette.toggle.contrastTextDark,
        backgroundColor: palette.toggle.light,
      },
    },

    '&.read-only': {
      cursor: 'default',
      pointerEvents: 'none',

      '& .MuiToggleButton-root.MuiToggleButton-standard': {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  })
);

function ToggleButtonGroup<T>({
  children,
  className,
  fullWidth = true,
  onChange,
  ariaLabel,
  sx,
}: ToggleButtonGroupProps<T>): JSX.Element {
  return (
    <StyledToggleGroup
      aria-label={ariaLabel}
      className={className}
      exclusive
      fullWidth={fullWidth}
      onChange={onChange}
      sx={sx}
    >
      {children}
    </StyledToggleGroup>
  );
}

export default ToggleButtonGroup;
