import React from 'react';
import { mdiMicrophoneOff, mdiMicrophoneOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

interface MicrophoneIconPros extends IconProps {
  muted: boolean;
}

const MicrophoneIcon = ({ muted, ...props }: MicrophoneIconPros) => (
  <Icon path={muted ? mdiMicrophoneOff : mdiMicrophoneOutline} {...props} />
);

export default MicrophoneIcon;
