import React, { useEffect, useRef, useState } from 'react';

import { Modal } from 'components/Modal';
import { CheckIcon, LinkIcon } from 'components/Icons';
import { APP_URL } from 'config/constants';
import { TextField } from 'components/TextField';
import { clearTimeoutSafely, copyToClipboard } from 'utils';

export interface InviteToGroupStudyModalProps {
  open: boolean;
  onClose: () => void;
  inviteLink: string;
}

const InviteToGroupStudyModal = ({
  open,
  onClose,
  inviteLink,
}: InviteToGroupStudyModalProps): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const feedbackTimeoutRef = useRef<number>(0);

  const link = `${APP_URL}/${inviteLink}`;

  const handleCopyLink = async () => {
    setCopied(true);
    copyToClipboard(link);
    feedbackTimeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 4000);
  };

  const label = copied ? (
    <>
      Link copied
      <CheckIcon />
    </>
  ) : (
    <>
      Copy link <LinkIcon />
    </>
  );

  useEffect(() => {
    return () => {
      clearTimeoutSafely(feedbackTimeoutRef.current);
    };
  }, []);

  return (
    <Modal
      fullWidthSubmit
      maxWidth="sm"
      noPaddingY
      onClose={onClose}
      onSubmit={handleCopyLink}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitLabel={label}
      title="Invite friend to a team"
    >
      <TextField
        InputProps={{ readOnly: true }}
        helperText="Copy and share the link with your friends."
        value={link}
      />
    </Modal>
  );
};

export default InviteToGroupStudyModal;
