import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  BUILD_OSCE_MARKSHEET,
  IBuildOsceMarksheetData,
  IBuildOsceMarksheetVar,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'components/Snackbar';
import { paths } from 'Router';
import { OnRequestComplete } from 'types';

const { dashboard } = paths;

const useBuildMarksheetMutation = (
  onBuildComplete?: OnRequestComplete<IBuildOsceMarksheetData>
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [build, { loading: buildLoading }] = useMutation<
    IBuildOsceMarksheetData,
    IBuildOsceMarksheetVar
  >(BUILD_OSCE_MARKSHEET, {
    onCompleted: data => {
      if (!data) {
        enqueueSnackbar('Building study failed.');
        navigate(dashboard);
      }

      if (onBuildComplete) {
        onBuildComplete(data);
      }
    },
    onError: () => {
      navigate(dashboard);
    },
  });

  const buildMarksheet = useCallback(
    (osceStationId: number, osceMarksheetId?: number) =>
      build({ variables: { osceStationId, osceMarksheetId } }),
    [build]
  );

  return { buildLoading, buildMarksheet };
};

export default useBuildMarksheetMutation;
