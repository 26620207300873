import { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { isDev } from 'config/constants';
import { Nullable } from 'types';
import { localStorageTyped, logError } from 'utils';
import { useNewVersionState } from 'hooks';
import { useSnackbar } from 'components/Snackbar';

const { setItem, getItem, removeItem } = localStorageTyped('isNewVersion');

const NewVersionController = (): Nullable<JSX.Element> => {
  const { setIsNewVersion } = useNewVersionState();
  const { enqueueSnackbar } = useSnackbar({ unique: true });
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisterError(error) {
      logError(error);

      if (isDev) {
        console.error('SW registration error', error);
      }
    },
  });

  useEffect(() => {
    if (getItem()) {
      setIsNewVersion(false);
      enqueueSnackbar('App has been updated to the newest version');
      removeItem();
    }
  }, [enqueueSnackbar, setIsNewVersion]);

  useEffect(() => {
    if (needRefresh) {
      setIsNewVersion(needRefresh);
      setItem(true);
      updateServiceWorker();
    }
  }, [setIsNewVersion, needRefresh, updateServiceWorker]);

  return null;
};

export default NewVersionController;
