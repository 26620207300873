import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import StyledLink from './StyledLink';

const Link = ({ ...props }: RouterLinkProps) => (
  <StyledLink component={RouterLink} {...props} />
);

export default Link;
