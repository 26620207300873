import React from 'react';
import { mdiCalendarBlank } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CalendarBlankIcon = (props: IconProps) => (
  <Icon path={mdiCalendarBlank} {...props} />
);

export default CalendarBlankIcon;
