import { IBuildMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';
import { IPreBuildMarksheetData } from '@quesmed/types-rn/resolvers/query/restricted';

import { OnRequestComplete } from 'types';
import useBuildMarksheetMutation from './useBuildMarksheetMutation';
import usePreBuildQuestions from './usePreBuildQuestions';

interface BuildQuestionsParams {
  search?: string;
  source?: string;
  marksheetId?: number;
  onPrebuildComplete?: OnRequestComplete<IPreBuildMarksheetData>;
  onBuildComplete?: OnRequestComplete<IBuildMarksheetData>;
}

const useBuildQuestions = (params: BuildQuestionsParams) => {
  const { onBuildComplete, marksheetId, ...rest } = params || {};

  const { preBuildLoading, preBuildData, preBuildQuestions } =
    usePreBuildQuestions({ ...rest, marksheetId });

  const { buildLoading, buildMarksheet: buildQuestions } =
    useBuildMarksheetMutation(onBuildComplete, marksheetId);

  return {
    buildLoading,
    buildQuestions,
    preBuildData,
    preBuildLoading,
    preBuildQuestions,
  };
};

export default useBuildQuestions;
