import React, { SVGProps } from 'react';

import { StyledSVG } from './ProductStyle';

export const ProductThree = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <StyledSVG
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6438_3339)">
      <rect
        height="47.3243"
        rx="8.94273"
        transform="rotate(30 -57.3379 -24)"
        width="112.032"
        x="-57.3379"
        y="-24"
      />
      <g clipPath="url(#clip1_6438_3339)">
        <rect
          className="background"
          fill="#B5B8C1"
          height="64"
          rx="10.6667"
          width="64"
        />
        <circle
          className="foreground-one"
          cx="10"
          cy="10"
          fill="#DADCE0"
          r="32"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6438_3339">
        <rect fill="white" height="64" rx="8" width="64" />
      </clipPath>
      <clipPath id="clip1_6438_3339">
        <rect fill="white" height="64" rx="10.6667" width="64" />
      </clipPath>
    </defs>
  </StyledSVG>
);
