export const isPage = (source: string | string[]) => (path: string) =>
  Array.isArray(source)
    ? source.some(text => path.includes(text))
    : path.includes(source);

export const isQuestionQuizPage = isPage('questions');
export const isFlashcardsPage = isPage('flashcards');
export const isStationsPage = isPage('stations');
export const isMockTestPage = isPage('mock-tests');
export const isVideoLibraryPage = isPage('video-library');
export const isPracticeMaterialsPage = isPage('practice-materials');
export const isAppManagementPage = isPage('apps-management');

export const isCommonAuthenticatedRoute = isPage([
  'dashboard',
  'leaderboard',
  'apps-management',
  'pricing',
  'not-found',
  'payment-complete',
  'knowledge-library',
  'practice-materials',
]);

// CM_UKMLA
export const isOsceRoute = isStationsPage;
export const isQbankRoute = (path: string) =>
  isFlashcardsPage(path) ||
  isQuestionQuizPage(path) ||
  isMockTestPage(path) ||
  isPracticeMaterialsPage(path);

// MRCP
export const isPacesRoute = (path: string) =>
  isStationsPage(path) ||
  isVideoLibraryPage(path) ||
  isPracticeMaterialsPage(path);
export const isMRCPRoute = isQuestionQuizPage;
