import React from 'react';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { IUserInput } from '@quesmed/types-rn/resolvers/mutation/restricted';

import { PasswordFormInput } from 'types';
import { Modal, ModalProps } from 'components/Modal';
import { FormField } from 'components/TextField/FormField';
import SettingsSectionOverline from './SettingsSectionOverline';
import useUpdateUser from 'hooks/useUpdateUser';

const StyledModal = styled(Modal)(({ theme: { spacing, breakpoints } }) => ({
  '& .MuiPaper-root': {
    [breakpoints.up('md')]: {
      maxWidth: '496px',
    },
  },
  '& .MuiFormControl-root': {
    maxWidth: '432px',
    marginBottom: spacing(6),
  },
}));

const schema = Joi.object<PasswordFormInput>({
  currentPassword: Joi.string().required().min(6).messages({
    'any.required': 'Current Password field is required',
    'string.empty': 'Current Password field is required',
    'string.min': 'Current Password must be at least 6 characters long',
  }),
  newPassword: Joi.string().required().min(6).messages({
    'any.required': 'New password field is required',
    'string.empty': 'New password field is required',
    'string.min': 'New password must be at least 6 characters long',
  }),
  confirmPassword: Joi.string()
    .required()
    .valid(Joi.ref('newPassword'))
    .messages({
      'any.required': 'Confirm password field is required',
      'string.empty': 'Confirm password field is required',
      'any.only': 'Confirm Password must match with New Password',
    }),
});

const ChangePasswordModal = ({ onClose, open }: ModalProps): JSX.Element => {
  const { loading: updateUserLoading, updateUser } = useUpdateUser();
  const {
    control,
    formState: { isDirty, isValid },
    reset,
    handleSubmit,
  } = useForm<PasswordFormInput>({
    resolver: joiResolver(schema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onTouched',
  });

  const handleClose = () => {
    reset();
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = (data: PasswordFormInput) => {
    if (!isValid && !isDirty) {
      return;
    }

    const userData: IUserInput = {
      oldPassword: data.currentPassword,
      password: data.confirmPassword,
    };

    updateUser(userData, {
      successCallback: reset,
      successMsg: 'Password updated successfully.',
    });
  };

  const disableSubmit = !isValid || !isDirty || updateUserLoading;

  return (
    <StyledModal
      cancelLabel="Cancel"
      closeOnBackdrop
      closeOnEscape
      noPaddingY
      onBack={handleClose}
      onCancel={handleClose}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      open={open}
      showCloseButton
      submitDisabled={disableSubmit}
      submitLabel="Change password"
      title="Change password"
    >
      <SettingsSectionOverline text="Password" />
      <FormField
        control={control}
        fullWidth
        label="Current password"
        name="currentPassword"
        type="password"
      />
      <FormField
        control={control}
        fullWidth
        label="New password"
        name="newPassword"
        type="password"
      />
      <FormField
        control={control}
        fullWidth
        label="Confirm password"
        name="confirmPassword"
        type="password"
      />
    </StyledModal>
  );
};

export default ChangePasswordModal;
