import React from 'react';
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

import { Tooltip } from 'components/Tooltip';

interface NavigationTooltipProps
  extends Omit<MuiTooltipProps, 'onOpen' | 'onClose'> {
  disableListeners: boolean;
  onTooltipStateChange?: (isOpen: boolean) => () => void;
}

const NavigationToolitp = ({
  children,
  disableListeners,
  onTooltipStateChange,
  ...props
}: NavigationTooltipProps): JSX.Element => (
  <Tooltip
    disableFocusListener={disableListeners}
    disableHoverListener={disableListeners}
    disableTouchListener={disableListeners}
    onClose={onTooltipStateChange ? onTooltipStateChange(false) : undefined}
    onOpen={onTooltipStateChange ? onTooltipStateChange(true) : undefined}
    {...props}
  >
    {children}
  </Tooltip>
);

export default NavigationToolitp;
