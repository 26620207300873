import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  IStartOrJoinMarksheetData,
  IStartOrJoinMarksheetVar,
  START_OR_JOIN_MARKSHEET,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EStudyAction } from '@quesmed/types-rn/models';

import { ERRORS } from 'config/constants';
import { paths } from 'Router';
import useMarksheet from './useMarksheet';
import {
  QuestionsModal,
  useQuestionsModalState,
} from './useQuestionsModalState';

const { questions, dashboard } = paths;

const { groupLobby } = questions;

const useStartOrJoin = (sessionIdURL = '') => {
  const navigate = useNavigate();
  const [isNewMarksheet, setIsNewMarksheet] = useState(false);
  const [currenttMarksheetId, setCurrentMarksheetId] = useState<number>();
  const { marksheet, fetchMarksheet } = useMarksheet(false);
  const { openModal } = useQuestionsModalState();
  const [start, { data, loading: startLoading, error }] = useMutation<
    IStartOrJoinMarksheetData,
    IStartOrJoinMarksheetVar
  >(START_OR_JOIN_MARKSHEET, {
    onCompleted: data => {
      const { id } = data.restricted.startOrJoinMarksheet;
      if (id) {
        fetchMarksheet(Number(id));
      }
    },
  });

  const startOrJoin = useCallback(
    (action: EStudyAction) =>
      start({ variables: { action, sessionId: sessionIdURL } }),
    [sessionIdURL, start]
  );

  const { id: marksheetId } = data?.restricted?.startOrJoinMarksheet || {};

  const { activeUsers = [], sessionId, agoraId = '' } = marksheet || {};

  const inviteLink = sessionId
    ? `questions/${groupLobby}?join=${sessionId}`
    : '';

  useEffect(() => {
    if (error) {
      if (error.message === ERRORS.GROUP_STUDY_ALREADY_STARTED) {
        openModal(QuestionsModal.ErrorModal);

        return;
      }

      if (error.message === ERRORS.MARKSHEET_COMPLETED) {
        navigate(dashboard);
      }
    }
  }, [error, navigate, openModal]);

  useEffect(() => {
    if (startLoading) {
      return;
    }

    if (marksheetId) {
      setIsNewMarksheet(
        Boolean(
          currenttMarksheetId !== undefined &&
            currenttMarksheetId !== Number(marksheetId)
        )
      );
      setCurrentMarksheetId(Number(marksheetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marksheetId, startLoading]);

  return {
    activeUsers,
    sessionId,
    agoraId,
    isNewMarksheet,
    startLoading,
    marksheetId: currenttMarksheetId,
    startOrJoin,
    inviteLink,
  };
};

export default useStartOrJoin;
