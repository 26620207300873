import React from 'react';
import { mdiCheckboxIntermediate } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CheckboxMarkedIcon = (props: IconProps) => (
  <Icon path={mdiCheckboxIntermediate} {...props} />
);

export default CheckboxMarkedIcon;
