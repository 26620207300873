import React, { MouseEvent, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { ArrowRightIcon } from 'components/Icons';
import { BreadcrumbsProps } from 'components/Breadcrumbs';
import { LearningMaterialsBreadcrumbs } from 'components/LearningMaterials/components/LearningMaterialsBreadcrumbs';
import { Link } from 'components/Link';

const LabelBox = styled(Box)({
  textDecoration: 'underline',
});

const SearchBreadcrumbsContainer = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    marginLeft: spacing(8),

    '& .MuiBreadcrumbs-li': {
      '.MuiBox-root': {
        color: palette.text.secondary,
      },

      '.MuiSvgIcon-root': {
        color: palette.icon.main,
        marginRight: 0,
      },
    },
  })
);

const StyledItem = styled(Link)<ItemProps>(
  ({ theme: { palette, transitions, typography, spacing } }) => ({
    padding: 0,
    backgroundColor: palette.background.paper,
    ...typography.body1Medium,
    transition: transitions.create(['color']),
    color: palette.text.primary,
    display: 'inline-flex',
    gap: spacing(2),

    '&:hover': {
      backgroundColor: palette.background.paper,
      color: palette.primary.dark,
    },

    '& .MuiSvgIcon-root': {
      color: palette.primary.main,
      marginRight: spacing(2),
    },

    '&.column': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  })
);

const StyledIcon = styled(ArrowRightIcon)({
  alignSelf: 'baseline',
});

type ItemProps<C extends React.ElementType = React.ElementType> =
  ListItemButtonProps<C, { component?: C }> & {
    children?: ListItemButtonProps['children'];
  };

type SearchItemProps = ItemProps & {
  label: ReactNode;
  breadcrumbs?: BreadcrumbsProps['items'];
  path: string;
  isNewTab?: boolean;
  hitAsLink?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

const SearchResultItem = ({
  breadcrumbs,
  onClick,
  label,
  path,
  isNewTab,
  ...props
}: SearchItemProps): JSX.Element => (
  <StyledItem
    className={clsx({ column: breadcrumbs })}
    onClick={onClick}
    target={isNewTab ? '_blank' : '_self'}
    to={path}
    {...props}
  >
    <Box sx={{ display: 'inline-flex' }}>
      <StyledIcon />
      <LabelBox>{label}</LabelBox>
    </Box>
    {breadcrumbs ? (
      <SearchBreadcrumbsContainer>
        <LearningMaterialsBreadcrumbs items={breadcrumbs} />
      </SearchBreadcrumbsContainer>
    ) : null}
  </StyledItem>
);

export default SearchResultItem;
