import React from 'react';
import { mdiFormatSize } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const FormatSizeIcon = (props: IconProps) => (
  <Icon path={mdiFormatSize} {...props} />
);

export default FormatSizeIcon;
