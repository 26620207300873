import React from 'react';
import { mdiCalculatorVariantOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const CalculatorIcon = (props: IconProps) => (
  <Icon path={mdiCalculatorVariantOutline} {...props} />
);

export default CalculatorIcon;
