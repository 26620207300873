import { useQuery } from '@apollo/client';
import {
  IUserData,
  IUserVar,
  USER,
} from '@quesmed/types-rn/resolvers/query/restricted';

export const useGetUser = (skip = false) => {
  const {
    data,
    loading,
    error,
    refetch: getUser,
  } = useQuery<IUserData, IUserVar>(USER, {
    variables: { active: true },
    skip,
  });

  const { user } = data?.restricted || {};

  return { loading, user, getUser, error };
};
