import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface NotificationsState {
  open: boolean;
  badge: boolean;
  openNotifications: () => void;
  closeNotifications: () => void;
  showBadge: () => void;
  hideBadge: () => void;
}

const useNotifications = create<NotificationsState>()(set => ({
  open: false,
  badge: false,
  openNotifications: () => set({ open: true }),
  closeNotifications: () => set({ open: false }),
  showBadge: () => set({ badge: true }),
  hideBadge: () => set({ badge: false }),
}));

const useNotificationsState = () => useNotifications(state => state, shallow);

export default useNotificationsState;
