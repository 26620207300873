import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { EAppType } from '@quesmed/types-rn/models';

import { Body } from 'components/Typography';
import { usePlatform } from 'context/PlatformContext';
import {
  AppProductType,
  ButtonOnClickHandler,
  QuesmedProductName,
} from 'types';
import { paths } from 'Router';
import { ChevronRightIcon } from 'components/Icons';
import { constSize } from 'utils';
import { ProductImage } from 'components/Illustrations';
import { useAuth } from 'Auth';

export const CARD_HEIGHT = 652;
export const CARD_WIDTH = 432;

const ProductContainer = styled(Box)(
  ({ theme: { spacing, palette, transitions } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(4),
    padding: spacing(4, 2),
    transitions: transitions.create(['background-color']),
    borderRadius: '8px',

    '& .MuiSvgIcon-root': {
      marginLeft: 'auto',
      color: palette.primary.main,
    },

    '&:not(.inactive):hover': {
      backgroundColor: palette.toggle.light,
      cursor: 'pointer',
    },
  })
);

const TryOutLink = styled('button')(
  ({ theme: { palette, transitions, typography } }) => ({
    display: 'inline-flex',
    whiteSpace: 'normal',
    ...typography.body2Medium,
    color: palette.primary.main,
    background: 'none',
    border: 0,
    outline: 0,
    padding: 0,
    margin: 0,
    transition: transitions.create(['color']),
    cursor: 'pointer',

    '&:hover': {
      color: palette.primary.dark,
      textDecoration: 'underline',
    },
  })
);

const Image = styled(Box)({
  ...constSize('48px'),

  '& .product-image': {
    width: 'inherit',
    height: 'inherit',
  },
});

const ProductItemHelperText = styled(Body)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.secondary,
    marginTop: spacing(1),
  })
);

interface ProductHelperTextProps {
  active: boolean;
  expired: boolean;
  locked: boolean;
  onClick: ButtonOnClickHandler;
  helperText?: string;
}

const ProductHelperText = ({
  active,
  expired,
  locked,
  onClick,
  helperText,
}: ProductHelperTextProps): JSX.Element => {
  if (active && !helperText) {
    return (
      <ProductItemHelperText small>
        {locked ? (
          <>
            Click here to purchase
            {!expired ? (
              <>
                &nbsp;or&nbsp;
                <TryOutLink onClick={onClick}>try out for free</TryOutLink>
              </>
            ) : null}
          </>
        ) : (
          'Click here to enter'
        )}
      </ProductItemHelperText>
    );
  }

  if (active && helperText) {
    return <ProductItemHelperText small>{helperText}</ProductItemHelperText>;
  }

  return <ProductItemHelperText small>Available soon!</ProductItemHelperText>;
};

interface ProductItemProps {
  active: boolean;
  app: EAppType;
  expired: boolean;
  helperText?: string;
  label: QuesmedProductName;
  locked: boolean;
  onClick?: () => void;
  onTryOut?: () => void;
  product: AppProductType;
}

const ProductItem = ({
  product,
  app,
  expired,
  active,
  locked,
  helperText,
  label,
  onClick,
  onTryOut,
}: ProductItemProps): JSX.Element => {
  const { setApp, setProduct } = usePlatform();
  const navigate = useNavigate();
  const { setIsDemo } = useAuth();

  const setAppAndProduct = async (product: AppProductType) => {
    setApp(app);
    setProduct(product);
    navigate(paths.root);
  };

  const handleTryOut: ButtonOnClickHandler = event => {
    event.stopPropagation();
    setAppAndProduct(product);
    setIsDemo(true);

    if (onTryOut) {
      onTryOut();
    }
  };

  const handleProductClick =
    (product: AppProductType, available: boolean, active: boolean) => () => {
      if (!active) {
        return;
      }

      if (available) {
        setAppAndProduct(product);

        return;
      }

      if (onClick) {
        onClick();

        return;
      }

      setAppAndProduct(product);
      setIsDemo(true);
    };

  return (
    <ProductContainer
      className={clsx({ inactive: !active })}
      key={label}
      onClick={handleProductClick(product, !locked, active)}
    >
      {ProductImage ? (
        <Image>
          <ProductImage
            className={clsx({ inactive: locked || !active })}
            product={product}
          />
        </Image>
      ) : null}
      <Box>
        <Body>{label}</Body>
        <ProductHelperText
          active={active}
          expired={expired}
          helperText={helperText}
          locked={locked}
          onClick={handleTryOut}
        />
      </Box>
      {active ? <ChevronRightIcon /> : null}
    </ProductContainer>
  );
};

export default ProductItem;
