import React from 'react';
import { mdiChevronRight } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ChevronRightIcon = (props: IconProps) => (
  <Icon path={mdiChevronRight} {...props} />
);

export default ChevronRightIcon;
