import { round } from '../math/round';

export const distributeQuestions = (
  requestedNumber: number,
  totalUnseen: number,
  totalSeenIncorrect: number,
  totalSeenCorrect: number,
  isUnseen: boolean,
  isSeenIncorrect: boolean,
  isSeenCorrect: boolean
) => {
  let unseen = 0;
  let seenIncorrect = 0;
  let seenCorrect = 0;
  let quota = round(Number(requestedNumber));

  if (isUnseen) {
    unseen = Math.min(quota, totalUnseen);
    quota = quota - unseen;
  }
  if (isSeenIncorrect) {
    seenIncorrect = Math.min(quota, totalSeenIncorrect);
    quota = quota - seenIncorrect;
  }
  if (isSeenCorrect) {
    seenCorrect = Math.min(quota, totalSeenCorrect);
  }

  return { unseen, seenCorrect, seenIncorrect };
};
