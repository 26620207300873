import React from 'react';
import { mdiArrowLeft } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ArrowLeftIcon = (props: IconProps) => (
  <Icon path={mdiArrowLeft} {...props} />
);

export default ArrowLeftIcon;
