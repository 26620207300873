import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';

import { useSnackbar } from 'components/Snackbar';
import { H1 } from 'components/Typography';
import { paths } from 'Router';
import { Button } from 'components/Button';
import { usePlatform } from 'context/PlatformContext';

const ErrorBox = styled(Box)(
  ({
    theme: {
      breakpoints,
      mixins: { appBar },
      spacing,
    },
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: `calc(100dvh - ${appBar.mobile.minHeight}px)`,
    padding: spacing(6),
    boxSizing: 'border-box',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(6),

    [breakpoints.up('md')]: {
      minHeight: `calc(100dvh - ${appBar.desktop.minHeight}px)`,
    },
  })
);

const InfoBox = styled('p')(({ theme: { palette } }) => ({
  textAlign: 'center',
  color: palette.text.primary,
  width: '100%',
}));

const ResourceName = styled('strong')(({ theme: { spacing, typography } }) => ({
  ...typography.h5,
  margin: spacing(0, 2),
}));

interface RouterState {
  requestedPath: string;
  message: string;
}

enum NotFoundFallbackItem {
  QuestionQuiz,
  Flashcards,
  GroupQuestionQuiz,
  MockTest,
  SoloStation,
  GroupStation,
  Root,
}

type NotFoundFallback = {
  [key in NotFoundFallbackItem]: {
    name: string;
    path: string;
    state?: { [key: string]: any };
  };
};

const fallbacks: NotFoundFallback = {
  [NotFoundFallbackItem.QuestionQuiz]: {
    name: 'Question Quiz',
    path: paths.questions.root,
  },
  [NotFoundFallbackItem.Flashcards]: {
    name: 'Flascards',
    path: paths.flashcards.root,
  },
  [NotFoundFallbackItem.GroupQuestionQuiz]: {
    name: 'Question Group Quiz',
    path: paths.questions.root,
  },
  [NotFoundFallbackItem.MockTest]: {
    name: 'Mock Test',
    path: paths.mockTests.root,
  },
  [NotFoundFallbackItem.SoloStation]: {
    name: 'Solo Station',
    path: paths.stations.root,
  },
  [NotFoundFallbackItem.GroupStation]: {
    name: 'Group Station',
    path: paths.stations.root,
  },
  [NotFoundFallbackItem.Root]: {
    name: 'Resource',
    path: paths.root,
  },
};

const getResourceName = (path?: string) => {
  if (path) {
    if (path.includes('questions/quiz/solo')) {
      return fallbacks[NotFoundFallbackItem.QuestionQuiz];
    }
    if (path.includes('flashcards/quiz')) {
      return fallbacks[NotFoundFallbackItem.Flashcards];
    }
    if (path.includes('questions/quiz/group')) {
      return fallbacks[NotFoundFallbackItem.GroupQuestionQuiz];
    }
    if (path.includes('mock-tests')) {
      return fallbacks[NotFoundFallbackItem.MockTest];
    }
    if (path.includes('stations/study/solo')) {
      return fallbacks[NotFoundFallbackItem.SoloStation];
    }
    if (path.includes('stations/study/group')) {
      return fallbacks[NotFoundFallbackItem.GroupStation];
    }
  }

  return fallbacks[NotFoundFallbackItem.Root];
};

const NotFound = (): JSX.Element => {
  const { state } = useLocation<RouterState>();
  const navigate = useNavigate();
  const { product } = usePlatform();
  const { enqueueSnackbar } = useSnackbar();
  const { message, requestedPath } = state || {};

  const { name, path, state: fallbackState } = getResourceName(requestedPath);

  const handleClick = () => {
    if (!product) {
      navigate(paths.appsManagement);
    } else {
      navigate(path, { state: fallbackState, replace: true });
    }
  };

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message);
    }
  }, [enqueueSnackbar, message]);

  const defaultFallbackLabel = product
    ? 'Go to Dashboard'
    : 'Go to app management';

  return (
    <ErrorBox>
      <H1>Not Found</H1>
      <InfoBox>
        We were not able to find the
        <ResourceName>{name}</ResourceName>
        you requested.
      </InfoBox>
      <Button onClick={handleClick}>
        {name === fallbacks[NotFoundFallbackItem.Root].name
          ? defaultFallbackLabel
          : 'Try to start new one'}
      </Button>
    </ErrorBox>
  );
};

export default NotFound;
