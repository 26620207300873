import { LabTabContent, LabValuesTable } from '../types';

const firstTable: LabValuesTable = {
  tableHead: 'Miscellaneous',
  tableData: [
    ['FVC', '> 80%'],
    ['FEV1', '> 80%'],
    ['Amylase', '< 220 U/L'],
    ['Prostate specific antigen (PSA)', '< 4 µg/L'],
    ['C reactive protein', '< 5 mg/L'],
    ['Carboxyhaemoglobin', '< 1.5%'],
    ['CD4 count	', '430 - 1690 μ/L'],
    ['Urate', '(M) 0.2 - 0.42 mmol/L'],
    ['', '(F) 0.14 - 0.34 mmol/L'],
    ['Total protein	', '60 - 80 g/L'],
    ['IgG	', '5.5 - 16.5 g/L'],
    ['IgA	', '0.8 - 4 g/L'],
    ['IgM	', '0.5 - 3 g/L'],
    ['Complement C3', '0.75 - 1.65 g/L'],
    ['Complement C4', '0.14 - 0.54 g/L'],
    ['Urinary Sodium (24hr)', '75 - 300 mmol/24hrs'],
    ['Urinary potassium	', '40 - 100 mmol/24hrs'],
    ['Plasma osmolality', '275 - 299 mosm/kg'],
  ],
};

const tumour: LabValuesTable = {
  tableHead: 'Tumour markers',
  tableData: [
    ['Lactate dehydrogenase	', '70 - 250 IU/L'],
    ['Alpha-fetoprotein	', '< 10 U/mL'],
    ['CA125', '< 35 U/mL'],
    ['CA 15-3	', '< 25 U/mL'],
    ['CA 19-9	', '< 34 U/mL'],
    ['Carcinoembryonic antigen (CEA)', '< 5 μg/L '],
  ],
};

const cerebrospinal: LabValuesTable = {
  tableHead: 'Cerebrospinal fluid',
  tableData: [
    ['Opening pressure	', '50 - 180 mmH\u2082O'],
    ['Total protein	', '0.15 - 0.45 g/L'],
    ['Glucose	', '3.2 - 4.4 mmol/L'],
    ['Cell count	', '< 5/μL'],
    ['Lymphocyte count	', '60 - 70%'],
    ['Lactate', '< 2 mmol/L'],
  ],
};

const peritoneal: LabValuesTable = {
  tableHead: 'Peritoneal fluid',
  tableData: [
    ['RBC', 'none'],
    ['WBC', '< 250/mm\u00B3'],
    ['Albumin', '< 40 g/L'],
    ['Glucose', '3.5 - 5.5 mmol/L'],
  ],
};

export const miscellaneous: LabTabContent = {
  tables: [firstTable, tumour, cerebrospinal, peritoneal],
};
