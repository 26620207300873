import { GraphQLErrors } from '@apollo/client/errors';
import { ERRORS as QUESMED_ERRORS } from '@quesmed/types-rn';

import { ERRORS, isDev } from 'config/constants';
import { history, navigate, paths } from 'Router';
import { ErrorDetails, SnackBar } from 'types';
import { logDevError, logError } from 'utils';
import { logoutUser } from 'Auth/logoutUser';
import { authState } from 'Auth/useAuth';
import locales from 'locales';

const { notFound, appsManagement, questions } = paths;

const AUTH_ERRORS = [
  QUESMED_ERRORS.TOKEN_INVALID,
  QUESMED_ERRORS.TOKEN_EXPIRED,
  QUESMED_ERRORS.TOKEN_MISSING,
  QUESMED_ERRORS.SESSION_MISSING,
  ERRORS.TOKEN_NOT_PROVIDED,
];

export const handleGraphQLErrors = (
  graphQLErrors: GraphQLErrors,
  snackbar: SnackBar,
  details?: ErrorDetails
) => {
  graphQLErrors.every(error => {
    logDevError('graphql', error, { ...details });

    const { message } = error;

    if (message.includes(ERRORS.BUILD_MARKSHEET)) {
      navigate(`${questions.root}`, { state: { isMarksheetOutdated: true } });

      return;
    }

    if (AUTH_ERRORS.some(authError => message.includes(authError))) {
      const { location } = history;
      const { pathname } = location;

      if (!pathname.includes(paths.paymentComplete)) {
        snackbar(ERRORS.SESSION_EXPIRED);
      }

      logoutUser({ local: true });

      return false;
    }

    if (message.includes(QUESMED_ERRORS.SUBSCRIPTION_EXPIRED)) {
      snackbar(QUESMED_ERRORS.SUBSCRIPTION_EXPIRED);

      navigate(appsManagement);

      return false;
    }

    if (!isDev) {
      logError(error);
    }

    if (message.includes('User not found')) {
      return false;
    }

    if (message.includes('not found')) {
      const { location } = history;
      const { pathname, search } = location;

      navigate(notFound, {
        state: {
          requestedPath: `${pathname}${search}`,
          message,
        },
      });

      return false;
    }

    if (error.message.includes(ERRORS.SERVER_ERROR)) {
      snackbar(ERRORS.GENERAL_ERROR);

      return true;
    }

    if (error.message.includes(QUESMED_ERRORS.PERMISSION_INVALID)) {
      const { isDemo } = authState.getState();

      if (isDemo && !isDev) {
        snackbar(locales.common.demo.resource, { unique: true });

        navigate(appsManagement, { state: { leave: true } });
      }
    }

    snackbar(message, { unique: true });

    return true;
  });
};
