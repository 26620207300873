import React, { ReactNode } from 'react';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';

import { BottomOffsetVariant, Children, DEVICE_SIZE, Nullable } from 'types';
import { NavigationHeader } from './NavigationHeader';
import { IconButton } from 'components/IconButton';
import {
  ArrowExpandRightIcon,
  CloseIcon,
  TuneVariantIcon,
} from 'components/Icons';
import {
  ExerciseControls,
  ExerciseControlsProps,
} from 'components/ExerciseControls';
import { useDeviceSize, useResizeObserver } from 'hooks';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { ToggleButton } from 'components/ToggleButton';

const controlPanelTopPadding = 88;
const controlPanelTopHeaderHeight = 70;

const ExpandButton = styled(IconButton)(
  ({ theme: { breakpoints, palette, spacing, transitions } }) => ({
    position: 'fixed',
    backgroundColor: palette.primary.dark,
    color: palette.background.paper,
    right: spacing(4),
    bottom: spacing(6),
    boxSizing: 'border-box',
    transition: transitions.create(['transform', 'width', 'border-color']),

    '&.tiny-offset': {
      bottom: spacing(6),
    },

    '&.small-offset': {
      bottom: spacing(22),
    },

    '&.medium-offset': {
      bottom: spacing(26),
    },

    '&.large-offset': {
      bottom: spacing(38),
    },

    '&.expanded': {
      backgroundColor: 'transparent',
      bottom: 'auto',
      color: palette.icon.main,
      top: spacing(18),
    },

    [breakpoints.up('md')]: {
      bottom: spacing(6),
      top: 'auto',

      '&.expanded': {
        bottom: 'auto',
        top: spacing(18),
      },
    },

    [breakpoints.up('xl')]: {
      backgroundColor: palette.background.paper,
      color: 'inherit',
      right: spacing(5),
      bottom: 'auto',
      top: spacing(32),
      border: `1px solid ${palette.background.paper}`,
      borderColor: palette.stroke.main,

      '&.expanded': {
        borderColor: palette.background.paper,
        right: spacing(6),
        top: spacing(20),
      },

      '&.tiny-offset, &.small-offset, &.medium-offset, &.large-offset': {
        bottom: 'initial',
      },
    },

    '&:hover': {
      backgroundColor: palette.background.paper,
    },

    '.MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },

    '&.expanded .MuiSvgIcon-root': {
      transform: 'rotate(0)',
    },
  })
);
const ControlsWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  marginTop: spacing(3),

  '&.is-other-content > div:nth-last-of-type(1)': {
    marginBottom: spacing(4),
  },
}));

const ContentWrapper = styled(Box)<{ height: number }>(
  ({ height, theme: { spacing } }) => ({
    height: `calc(100dvh - ${
      controlPanelTopPadding + controlPanelTopHeaderHeight + height
    }px)`,
    overflow: 'auto',

    '&.no-control-panel-title': {
      marginTop: spacing(6),
    },
  })
);

const Drawer = styled(MuiDrawer)(
  ({
    theme: {
      breakpoints,
      mixins: { controlPanel },
      palette,
      transitions,
      spacing,
      zIndex,
    },
  }) => ({
    position: 'absolute',
    marginRight: `-${controlPanel.collapsed.minWidth}px`,

    '&.expanded': {
      marginRight: `-${controlPanel.expanded.minWidth}px`,
    },

    '& .MuiDrawer-paper.MuiPaper-root.MuiPaper-elevation': {
      zIndex: zIndex.appBar - 1,
      maxHeight: '100vh',
      padding: spacing(22, 0, 0),
      borderLeft: `1px solid ${palette.stroke.main}`,
      backgroundColor: palette.background.paper,
      backgroundImage: 'none',
      boxShadow: 'none',
      boxSizing: 'border-box',
      overflow: 'hidden',
      width: 0,
      maxWidth: `${controlPanel.collapsed.minWidth}px`,
      transition: `${transitions.create(
        ['transform', 'width', 'margin', 'max-width', 'margin-right'],
        {
          easing: transitions.easing.easeOut,
          duration: transitions.duration.enteringScreen,
        }
      )} !important`,
      [breakpoints.up('xl')]: {
        width: `${controlPanel.collapsed.minWidth}px`,
      },
    },

    '&.expanded .MuiDrawer-paper.MuiPaper-root.MuiPaper-elevation': {
      padding: spacing(22, 6, 0),
      maxWidth: `${controlPanel.expanded.minWidth}px`,
      width: '100%',

      [breakpoints.up('xl')]: {
        width: `${controlPanel.expanded.minWidth}px`,
      },
    },
  })
);

const DrawerHeader = styled(Box)({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
});

interface ToggleOption {
  value: string;
  label: string;
}

export interface ExerciseControlPanelProps extends ExerciseControlsProps {
  activeToggleOption?: string;
  children?: Children;
  controlPanelTitle?: string;
  exerciseItemsCount?: number;
  quitModalMessage?: Nullable<string>;
  expanded: boolean;
  floatingPanel?: boolean;
  onExpand?: () => void;
  onToggle?: () => void;
  toggleLabel?: string;
  toggleOptions?: ToggleOption[];
  bottomOffsetVariant?: BottomOffsetVariant;
  controlPanelScoreProgress?: ReactNode;
}

const ExerciseControlPanel = ({
  activeToggleOption,
  bottomOffsetVariant = 'tiny',
  children,
  controlPanelTitle,
  exerciseItemsCount,
  expanded,
  floatingPanel,
  onExpand,
  onLeave,
  onToggle,
  timer,
  toggleLabel,
  toggleOptions,
  controlPanelScoreProgress,
  withCamera,
  withSound,
  ...props
}: ExerciseControlPanelProps & DrawerProps): JSX.Element => {
  const [controlsRef, { height: controlsHeight }] =
    useResizeObserver<HTMLDivElement>();
  const deviceSize = useDeviceSize();
  const isOtherContent = toggleOptions && onToggle && toggleLabel;

  const handleClickAway = () => {
    if (floatingPanel && expanded && onExpand) {
      onExpand();
    }
  };

  const MobileIcon = expanded ? <CloseIcon /> : <TuneVariantIcon />;

  const Icon =
    deviceSize !== DEVICE_SIZE.DESKTOP ? MobileIcon : <ArrowExpandRightIcon />;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        anchor="right"
        className={clsx({ expanded })}
        hideBackdrop
        {...props}
        variant="persistent"
      >
        <DrawerHeader>
          {controlPanelTitle ? (
            <NavigationHeader expanded={expanded} sx={{ marginBottom: 6 }}>
              {controlPanelTitle}
            </NavigationHeader>
          ) : null}
          <ExpandButton
            className={clsx(
              {
                expanded,
              },
              `${bottomOffsetVariant}-offset`
            )}
            edge={expanded ? 'end' : false}
            icon={Icon}
            onClick={onExpand}
          />
        </DrawerHeader>
        <Fade in={expanded}>
          <Box>
            {controlPanelScoreProgress ? controlPanelScoreProgress : null}
            <ControlsWrapper
              className={clsx({ 'is-other-content': isOtherContent })}
              ref={controlsRef}
            >
              {timer || onLeave || withCamera || withSound ? (
                <ExerciseControls
                  onLeave={onLeave}
                  timer={timer}
                  withCamera={withCamera}
                  withSound={withSound}
                />
              ) : null}
              {isOtherContent ? <Divider /> : null}
              {toggleOptions && toggleLabel ? (
                <ToggleButtonGroup
                  ariaLabel={toggleLabel}
                  className={clsx({ 'read-only': !onToggle })}
                  onChange={onToggle}
                >
                  {toggleOptions.map(({ label, value }) => (
                    <ToggleButton
                      key={value}
                      label={label}
                      selected={activeToggleOption === value}
                      value={value}
                    />
                  ))}
                </ToggleButtonGroup>
              ) : null}
            </ControlsWrapper>
            <ContentWrapper
              className={clsx({ 'no-control-panel-title': !controlPanelTitle })}
              height={controlsHeight}
            >
              {children}
            </ContentWrapper>
          </Box>
        </Fade>
      </Drawer>
    </ClickAwayListener>
  );
};

export default ExerciseControlPanel;
