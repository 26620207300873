import React from 'react';

import { Children } from 'types';
import SettingsContentLayout from './SettingsContentLayout';
import SkeletonList from 'components/Skeleton/SkeletonList';

interface SettingsContentProps {
  count: number;
  height: number;
  spacing?: number;
  loading: boolean;
  children: Children;
}

const SettingsContentLoading = ({
  children,
  count,
  height,
  loading,
  spacing = 6,
}: SettingsContentProps): JSX.Element => (
  <SettingsContentLayout>
    {loading ? (
      <SkeletonList count={count} height={height} spacing={spacing} />
    ) : (
      children
    )}
  </SettingsContentLayout>
);

export default SettingsContentLoading;
