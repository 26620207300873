export const getRootElement = (element: Node): Node => {
  /**
   * It returns the root/parent node, i.e paragraph or main node for that selected text
   * It checks recursively and find the parent element
   */
  const nodeNames = [
    'P',
    'LI',
    'TR',
    'TD',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'BLOCKQUOTE',
  ];

  if (nodeNames.includes(element.nodeName)) {
    return element as Node;
  }
  if (element?.parentElement?.nodeName !== 'P') {
    return getRootElement(element?.parentElement as Node);
  }
  if (element?.parentElement?.parentElement?.nodeName === 'BLOCKQUOTE') {
    return element?.parentElement?.parentElement as Node;
  }

  return element?.parentElement;
};
