import { Nullable, QuesbookBrief } from 'types';
import { trimOnSpace } from 'utils';

const HEADER_REGEXP = /^(?=#+ )/m;

const parseQbankReading = (
  explanation: Nullable<string>,
  fallbackHeaderLength = 30,
  showFallbackTitle = false,
  fallbackTitle?: string
): QuesbookBrief[] => {
  let sections: string[] = [];
  let level = 0;
  const anyHeader = explanation?.match(HEADER_REGEXP);
  // This U+00a0 characters appears in some markdowns
  // and causes that headers arent detect properly
  // eslint-disable-next-line no-irregular-whitespace
  const text = explanation?.replace(/ +?/g, ' ');

  if (anyHeader) {
    do {
      level += 1;
      const pattern = new RegExp(`^(?=#{1,${level}} )`, 'm');

      const matchLevel = text && text.match(pattern);

      sections = matchLevel ? text.split(pattern) : [];
    } while (sections.length < 1 && level < 6);

    if (sections.length) {
      return sections.map(part => {
        // if explanation contains headers but doesn't start
        // from a header we have to check if header exists
        const isHeader = part.match(HEADER_REGEXP);

        if (isHeader) {
          const [title] = part.split(/[\r\n]/);

          return {
            title: title.replace(/^#{1,7} /, ''),
            content: part.replace(title, '').trim(),
          };
        }

        const brief: QuesbookBrief = {
          content: part,
        };

        // If the first section doesn't have header the fallbackHeader
        // or trimmed section's content are used instead
        if (showFallbackTitle) {
          brief.title =
            fallbackTitle || trimOnSpace(part, fallbackHeaderLength);
        }

        return brief;
      });
    }
  }

  // if there is no header only content is returned
  return text ? [{ content: text }] : [];
};

export default parseQbankReading;
