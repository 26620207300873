import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { noop } from 'utils';
import {
  setCheckoutWithSelectedPlanAction,
  setDashboardSettingsAction,
  setFontSizeAction,
  setIsMenuOpenAction,
  setLogoutCallbackdAction,
} from './AppActions';
import { appStateReducer } from './AppReducer';
import {
  AppDispatch,
  AppDispatchContextProviderProps,
  AppState,
  AppStateContextProviderProps,
  AppStateProviderProps,
} from './types';
import { DashboardTilesSettings } from 'types';

const initialDashboardSettings: DashboardTilesSettings = {
  dailyFeedTile: { visible: true, title: 'Daily Feed' },
  questionAttemptedTile: { visible: true, title: 'Questions Attempted' },
  cardsViewedTile: { visible: true, title: 'Ques Cards Viewed' },
  dailyProgressTile: { visible: true, title: 'Daily Progress' },
  studyScheduleTile: { visible: true, title: 'Study Schedule' },
  performanceTile: { visible: true, title: 'Performance' },
  pastQuizzesTile: { visible: true, title: 'Past Quizzes' },
  flaggedQuestionsTile: { visible: true, title: 'Flagged Questions' },
  universityLeaderboardTile: { visible: true, title: 'University Leaderboard' },
};

const initialAppState: AppState = {
  bookExplanationVisible: false,
  checkoutWithSelectedPlan: false,
  dashboardSettings: initialDashboardSettings,
  fontSize: 1,
  isMenuOpen: false,
  logoutCallback: undefined,
};

const AppStateContext = createContext<AppState>(initialAppState);

const { Provider: StateContextProvider } = AppStateContext;

const AppStateProvider = ({
  children,
  value,
}: AppStateContextProviderProps): JSX.Element => (
  <StateContextProvider value={value}>{children}</StateContextProvider>
);

export const useAppState = (): AppState => useContext(AppStateContext);

const initialActionDispatcher: AppDispatch = {
  setCheckoutWithSelectedPlan: noop,
  setDashboardSettings: noop,
  setFontSize: noop,
  setIsMenuOpen: noop,
  setLogoutCollback: noop,
};

const AppDispatchContext = createContext<AppDispatch>(initialActionDispatcher);

const { Provider: DispatchContextProvider } = AppDispatchContext;

const AppDispatchProvider = ({
  children,
  value: dispatch,
}: AppDispatchContextProviderProps): JSX.Element => {
  const setDashboardSettings = useCallback(
    (payload: DashboardTilesSettings) =>
      dispatch(setDashboardSettingsAction(payload)),
    [dispatch]
  );

  const setIsMenuOpen = useCallback(
    (payload: boolean) => dispatch(setIsMenuOpenAction(payload)),
    [dispatch]
  );

  const setLogoutCollback = useCallback(
    (payload?: () => Promise<any>) =>
      dispatch(setLogoutCallbackdAction(payload)),
    [dispatch]
  );

  const setFontSize = useCallback(
    (payload: number) => dispatch(setFontSizeAction(payload)),
    [dispatch]
  );

  const setCheckoutWithSelectedPlan = useCallback(
    (payload: boolean) => dispatch(setCheckoutWithSelectedPlanAction(payload)),
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      setCheckoutWithSelectedPlan,
      setDashboardSettings,
      setFontSize,
      setIsMenuOpen,
      setLogoutCollback,
    }),
    [
      setCheckoutWithSelectedPlan,
      setDashboardSettings,
      setFontSize,
      setIsMenuOpen,
      setLogoutCollback,
    ]
  );

  return (
    <DispatchContextProvider value={actions}>
      {children}
    </DispatchContextProvider>
  );
};

export const useAppSetState = (): AppDispatch => useContext(AppDispatchContext);

export const AppStateProviders = ({
  children,
}: AppStateProviderProps): JSX.Element => {
  const [state, stateDispatch] = useReducer(appStateReducer, initialAppState);

  return (
    <AppDispatchProvider value={stateDispatch}>
      <AppStateProvider value={state}>{children}</AppStateProvider>
    </AppDispatchProvider>
  );
};
