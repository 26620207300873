import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { CloseIcon } from 'components/Icons';
import {
  ClosePopupButton,
  PopupHeader,
  PopupWrapper,
  Title,
} from './UtilPopup.styles';
import { Children } from 'types';

interface UtilPopupProps {
  onClose: () => void;
  children: Children;
  icon?: ReactNode;
  title?: string;
  hideHeader?: boolean;
  disableBottomPadding?: boolean;
}

export const UtilPopup = ({
  onClose,
  title,
  icon,
  children,
  hideHeader,
  disableBottomPadding,
}: UtilPopupProps) => (
  <PopupWrapper className={clsx({ disableBottomPadding })}>
    {hideHeader ? null : (
      <PopupHeader>
        <Title>
          {icon} {title}
        </Title>
        <ClosePopupButton edge="end" icon={<CloseIcon />} onClick={onClose} />
      </PopupHeader>
    )}
    <Box>{children}</Box>
  </PopupWrapper>
);

export default UtilPopup;
