import { ProductState } from 'types';
import { parseDate } from 'utils/dataTime';
import { round } from 'utils/math';

export const SUBSCRIPTION_EXPIRY_MARGIN = 43200; // 12 hours
export const TOKEN_RENEW_MARGIN = 60; // seconds

export const isSubscriptionActive = (productExpirationDate: number | null) => {
  if (!productExpirationDate || productExpirationDate === null) {
    return ProductState.Inactive;
  }
  const currentDate = new Date().getTime();

  const expirationTime = new Date(parseDate(productExpirationDate)).getTime();
  const timeDiff = round((expirationTime - currentDate) / 1000);

  if (expirationTime < currentDate) {
    return ProductState.Expired;
  }

  if (timeDiff < SUBSCRIPTION_EXPIRY_MARGIN) {
    if (timeDiff >= TOKEN_RENEW_MARGIN) {
      return ProductState.AboutToExpired;
    }
  }

  return timeDiff < TOKEN_RENEW_MARGIN
    ? ProductState.Expired
    : ProductState.Active;
};
