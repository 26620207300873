import { useMutation } from '@apollo/client';
import {
  CUSTOMER_PORTAL,
  ICustomerPortalData,
  ICustomerPortalVar,
} from '@quesmed/types-rn/resolvers/mutation';

const useCustomerPortal = () => {
  const [customerPortal] = useMutation<ICustomerPortalData, ICustomerPortalVar>(
    CUSTOMER_PORTAL,
    {
      onCompleted: ({ customerPortal }) => {
        if (customerPortal) {
          return (window.location.href = customerPortal);
        }
      },
    }
  );

  return { customerPortal };
};

export default useCustomerPortal;
