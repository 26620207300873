import React, { ReactNode } from 'react';
import styled from '@mui/system/styled';
import Box from '@mui/system/Box';

import { constSize } from 'utils/';
import { CheckIcon } from 'components/Icons';
import { Body } from 'components/Typography';

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(2),
}));

const Icon = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  ...constSize('24px'),

  '& .MuiSvgIcon-root': {
    color: palette.primary.main,
  },
}));

interface ProductFeatureListItemProps {
  children: ReactNode;
}
const ProductFeatureListItem = ({ children }: ProductFeatureListItemProps) => (
  <Container>
    <Icon>
      <CheckIcon />
    </Icon>
    <Body small variant="body2">
      {children}
    </Body>
  </Container>
);

export default ProductFeatureListItem;
