import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AgoraRTC, {
  CameraVideoTrackInitConfig,
  ClientConfig,
  IAgoraRTCClient,
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteVideoTrack,
  MicrophoneAudioTrackInitConfig,
  VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';

import useAVPermissionsState from './useAVPermissionsState';

export enum AgoraErrorCode {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  CAN_NOT_GET_GATEWAY_SERVER = 'CAN_NOT_GET_GATEWAY_SERVER',
}
export default AgoraRTC;
AgoraRTC.setLogLevel(3);

export interface AgoraRTCError {
  code: AgoraErrorCode;
  message: string;
  data?: any;
  name: string;
}

export const createClient = (config: ClientConfig) => {
  let client: IAgoraRTCClient;
  const createClosure = () => {
    if (!client) {
      client = AgoraRTC.createClient(config);
    }

    return client;
  };

  return () => createClosure();
};

export const createCameraVideoTrack = (config?: CameraVideoTrackInitConfig) => {
  let track: ICameraVideoTrack | null = null;

  const createClosure = async () => {
    track = await AgoraRTC.createCameraVideoTrack(config);

    return track;
  };

  const useCameraVideoTrack = (init = true) => {
    const [ready, setReady] = useState(false);
    const [agoraRTCError, setAgoraRTCError] = useState<null | AgoraRTCError>(
      null
    );
    const ref = useRef(track);

    const destroyVideo = useCallback(() => {
      track?.close();
      ref.current = null;
      track = null;
      setReady(false);
    }, []);

    useEffect(() => {
      if (!init) {
        return;
      }

      if (ref.current === null) {
        createClosure().then(
          track => {
            ref.current = track;
            setReady(true);
          },
          e => {
            setAgoraRTCError(e);
          }
        );
      } else {
        setReady(true);
      }
    }, [init]);

    useEffect(() => {
      return () => {
        track?.close();
        track = null;
        ref.current = null;
      };
    }, []);

    return {
      videoReady: ready,
      videoTrack: ref.current,
      error: agoraRTCError,
      destroyVideo,
    };
  };

  return useCameraVideoTrack;
};

export const createMicrophoneAudioTrack = (
  config?: MicrophoneAudioTrackInitConfig
) => {
  let track: IMicrophoneAudioTrack | null = null;
  const createClosure = async () => {
    track = await AgoraRTC.createMicrophoneAudioTrack(config);

    return track;
  };

  const useMicrophoneAudioTrack = (init = true) => {
    const [ready, setReady] = useState(false);
    const [agoraRTCError, setAgoraRTCError] = useState<null | AgoraRTCError>(
      null
    );
    const ref = useRef(track);

    const destroyAudio = useCallback(() => {
      track?.close();
      ref.current = null;
      track = null;
      setReady(false);
    }, []);

    useEffect(() => {
      if (!init) {
        return;
      }

      if (ref.current === null) {
        createClosure().then(
          track => {
            ref.current = track;
            setReady(true);
          },
          e => {
            setAgoraRTCError(e);
          }
        );
      } else {
        setReady(true);
      }
    }, [init]);

    useEffect(() => {
      return () => {
        track?.close();
        track = null;
        ref.current = null;
      };
    }, []);

    return {
      audioReady: ready,
      audioTrack: ref.current,
      error: agoraRTCError,
      destroyAudio,
    };
  };

  return useMicrophoneAudioTrack;
};

export const AgoraVideoPlayer = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    videoTrack: ILocalVideoTrack | IRemoteVideoTrack | ICameraVideoTrack;
  } & { config?: VideoPlayerConfig }
) => {
  const vidDiv: RefObject<HTMLDivElement> = useRef(null);
  const { videoTrack, config, ...other } = props;

  useEffect(() => {
    if (vidDiv.current !== null) {
      videoTrack.play(vidDiv.current, config);
    }

    return () => {
      videoTrack.stop();
    };
    // TODO check if this is needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack]);

  return <div {...other} ref={vidDiv} />;
};

export const useAudioPermissions = () => {
  const { audioAdmitted, setAudioAdmitted } = useAVPermissionsState();

  const checkAudioPermissions = useCallback(async () => {
    try {
      const result = await AgoraRTC.getMicrophones();

      if (result.length) {
        setAudioAdmitted(true);
      } else {
        setAudioAdmitted(false);
      }
    } catch (error: unknown) {
      const { code } = error as AgoraRTCError;

      if (code === AgoraErrorCode.PERMISSION_DENIED) {
        setAudioAdmitted(false);
      }
    }
  }, [setAudioAdmitted]);

  return { audioAdmitted, checkAudioPermissions };
};

export const useVideoPermissions = () => {
  const { videoAdmitted, setVideoAdmitted } = useAVPermissionsState();

  const checkVideoPermissions = useCallback(async () => {
    try {
      const result = await AgoraRTC.getCameras();
      if (result.length) {
        setVideoAdmitted(true);
      } else {
        setVideoAdmitted(false);
      }
    } catch (error: unknown) {
      const { code } = error as AgoraRTCError;
      if (code === AgoraErrorCode.PERMISSION_DENIED) {
        setVideoAdmitted(false);
      }
    }
  }, [setVideoAdmitted]);

  return { checkVideoPermissions, videoAdmitted };
};

export const useAVPermissions = () => {
  const { audioAdmitted, checkAudioPermissions } = useAudioPermissions();
  const { videoAdmitted, checkVideoPermissions } = useVideoPermissions();

  return {
    audioAdmitted,
    videoAdmitted,
    checkAudioPermissions,
    checkVideoPermissions,
  };
};
