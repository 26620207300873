import React, { SVGProps } from 'react';

const DarkLayoutImage = ({
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      fill="none"
      height="123"
      viewBox="0 0 227 123"
      width="227"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8084_7173)">
        <rect
          fill="#3E414A"
          height="122.515"
          rx="3"
          width="226.06"
          x="0.941406"
        />
        <rect
          fill="#101012"
          height="108.48"
          width="46.0159"
          x="0.613783"
          y="13.7974"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="30.7966"
          x="8.14844"
          y="21.625"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="30.7966"
          x="8.14844"
          y="53.0703"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="30.7966"
          x="8.14844"
          y="32.1016"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="30.7966"
          x="8.14844"
          y="63.5547"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="30.7966"
          x="8.14844"
          y="42.5859"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="30.7966"
          x="8.14844"
          y="74.0391"
        />
        <rect
          height="108.48"
          stroke="#2E3137"
          strokeWidth="0.655246"
          width="46.0159"
          x="0.613783"
          y="13.7974"
        />
        <rect
          fill="#101012"
          height="14.4154"
          transform="matrix(1 0 0 -1 0.941406 14.4131)"
          width="226.715"
          x="-0.327623"
          y="0.327623"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="6.55246"
          x="215.207"
          y="3.92969"
        />
        <rect
          fill="#2E3137"
          height="6.55246"
          opacity="0.6"
          width="6.55246"
          x="204.723"
          y="3.92969"
        />
        <rect
          height="14.4154"
          stroke="#2E3137"
          strokeWidth="0.655246"
          transform="matrix(1 0 0 -1 0.941406 14.4131)"
          width="226.715"
          x="-0.327623"
          y="0.327623"
        />
        <rect
          fill="#2E3137"
          height="91.0792"
          opacity="0.5"
          width="106.15"
          x="83.5039"
          y="21.625"
        />
      </g>
      <rect
        height="121.515"
        rx="2.5"
        stroke="#DADCE0"
        width="225.06"
        x="1.44141"
        y="0.5"
      />
      <defs>
        <clipPath id="clip0_8084_7173">
          <rect
            fill="white"
            height="122.515"
            rx="3"
            width="226.06"
            x="0.941406"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DarkLayoutImage;
