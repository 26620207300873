import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { EProductType } from '@quesmed/types-rn/models';
import { useSearchBox } from 'react-instantsearch';

import { Modal } from 'components/Modal';
import SearchResults, {
  FALLBACK_TEXT,
  FALLBACK_TITLE,
  SearchResultsProps,
} from './SearchResults';
import SearchFallback from './SearchFallback';
import { SearchField, SearchKey, useSearchState } from 'components/SearchField';
import Button from 'components/Button/Button';

const Container = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  padding: spacing(2, 4),
  overflow: 'hidden',

  [breakpoints.up('md')]: {
    padding: spacing(2),
  },
}));

const SearchAndCloseContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),

    '& > form': {
      flex: 1,
    },

    [breakpoints.up('md')]: {
      '& .MuiButtonBase-root': {
        display: 'none',
      },
    },
  })
);

export interface SearchModalProps
  extends Omit<
    SearchResultsProps,
    'onClose' | 'searchString' | 'hideSearchModal' | 'showSearchModal'
  > {
  searchWithAlgolia: boolean;
  open: boolean;
  product: EProductType;
  onClose: () => void;
}

export const SearchModal = ({
  showStations,
  showFlashcards,
  showPaces,
  open,
  onClose,
  showVideos,
  showQuestions,
}: SearchModalProps): JSX.Element => {
  const {
    getSearchPerformed,
    getSearchString,
    resetSearchState,
    setSearchString,
    setResetSearch,
  } = useSearchState();
  const searchString = getSearchString(SearchKey.Global);
  const searchPerformed = getSearchPerformed(SearchKey.Global);
  const [hidden, setHidden] = useState(false);
  const { refine } = useSearchBox();

  const handleSearch = async (searchString: string) => {
    setResetSearch(SearchKey.Global, false);
    setSearchString(SearchKey.Global, searchString);

    return refine(searchString);
  };

  const handleCloseModal = () => {
    resetSearchState(SearchKey.Global);
    onClose();
  };

  const handleHideModal = useCallback(() => setHidden(true), []);

  const handleShowModal = useCallback(() => setHidden(false), []);

  return (
    <Modal
      heightVariant="md"
      hidden={hidden}
      maxWidth="md"
      noPadding
      onClose={handleCloseModal}
      onSubmit={handleCloseModal}
      open={open}
      showCloseButton={false}
      showControls={false}
      showHeader={false}
      sizeVariant="md"
    >
      <Container>
        <SearchAndCloseContainer>
          <SearchField
            autoFocus
            fullWidth
            onSearch={handleSearch}
            placeholder="Search content..."
            searchKey={SearchKey.Global}
          />
          <Button color="secondary" onClick={onClose} variant="text">
            Close
          </Button>
        </SearchAndCloseContainer>
        {searchPerformed ? (
          <SearchResults
            hideSearchModal={handleHideModal}
            onClose={handleCloseModal}
            searchString={searchString}
            showFlashcards={showFlashcards}
            showPaces={showPaces}
            showQuestions={showQuestions}
            showSearchModal={handleShowModal}
            showStations={showStations}
            showVideos={showVideos}
          />
        ) : (
          <SearchFallback
            text={FALLBACK_TEXT}
            title={FALLBACK_TITLE}
            variant="with-padding"
          />
        )}
      </Container>
    </Modal>
  );
};
