import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IAccessLevel } from '@quesmed/types-rn/models';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ModalProps } from '@mui/material';
import clsx from 'clsx';
import { isNull } from 'lodash';

import {
  AccessibilityIcon,
  AccountOutlineIcon,
  CreditCardOutlineIcon,
  IconComponent,
  PaletteOutlineIcon,
  SettingsIcon,
  TuneVariantIcon,
  WrenchClockIcon,
} from 'components/Icons';
import { Modal } from 'components/Modal';
import { ListItemButton, ListItemButtonProps } from 'components/List';
import SettingsAccount from './SettingsAccount';
import SettingsGeneral from './SettingsGeneral';
import SettingsSubscriptions from './SettingsSubscriptions';
import SettingsTheme from './SettingsTheme';
import SettingsAdvanced from './SettingsAdvanced';
import SettingsAccessibility from './SettingsAccessibility';
import SettingsAdminPanel from './SettingsAdminPanel';
import { DiscardChangesModal } from 'components/DiscardChangesModal';
import {
  MenuLabel,
  SettingsModal,
  useSettingsModalState,
} from './useSettingsModalState';
import { useCurrentUser } from 'Auth';
import { usePlatform } from 'context/PlatformContext';
import { useGetUser, useIsMobile } from 'hooks';
import useRenewToken from 'Auth/useRenewToken';

const StyledModal = styled(Modal)(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    '& .MuiPaper-root': {
      maxWidth: '1112px',
      height: '816px',
      backgroundImage: 'none',
    },
    '& div.MuiDialogContent-root:nth-of-type(2)': {
      borderTop: `1px solid ${palette.stroke.main}`,
      maxHeight: 'none',
      overflowY: 'hidden',

      '& >.MuiBox-root': {
        height: '100%',

        '& >.MuiBox-root': {
          height: '100%',
        },
      },
    },
    '& .MuiDialogActions-root': {
      borderTop: `1px solid ${palette.stroke.main}`,
      padding: spacing(0),
      [breakpoints.up('md')]: {
        padding: spacing(4),
      },
    },
  })
);

const WrapperBox = styled(Box)(() => ({
  display: 'flex',
}));

const MenuBox = styled(Box)(
  ({ theme: { palette, spacing, breakpoints, transitions } }) => ({
    height: '100%',
    borderRight: `1px solid ${palette.stroke.main}`,
    width: '0',

    [breakpoints.up('md')]: {
      width: 'unset',
      minWidth: spacing(64),
    },

    '& .MuiList-root': {
      padding: spacing(4, 2),
      width: '100vw',
      transition: transitions.create(['transform']),

      '&.hidden-menu': {
        transform: 'translateX(-100vw)',
      },

      [breakpoints.up('md')]: {
        width: 'unset',
        minWidth: spacing(64),
      },
    },
  })
);

const ContentBox = styled(Box)(({ theme: { breakpoints, transitions } }) => ({
  width: '100vw',
  transition: transitions.create(['transform']),

  '&.hidden-content': {
    transform: 'translateX(+100vw)',
  },

  [breakpoints.up('md')]: {
    width: '100%',
  },
}));

interface MenuItemProps
  extends Omit<ListItemButtonProps, 'expanded' | 'nested'> {
  label: string;
  icon: IconComponent;
}

interface IMenuItem {
  label: MenuLabel;
  icon: IconComponent;
}

const menuItemsList: IMenuItem[] = [
  {
    label: MenuLabel.Account,
    icon: AccountOutlineIcon,
  },
  {
    label: MenuLabel.General,
    icon: SettingsIcon,
  },
  {
    label: MenuLabel.Subscriptions,
    icon: CreditCardOutlineIcon,
  },
  {
    label: MenuLabel.Theme,
    icon: PaletteOutlineIcon,
  },
  {
    label: MenuLabel.Advanced,
    icon: TuneVariantIcon,
  },
  {
    label: MenuLabel.Accessibility,
    icon: AccessibilityIcon,
  },
  {
    label: MenuLabel.AdminPanel,
    icon: WrenchClockIcon,
  },
];

const MenuItem = ({
  icon: Icon,
  label,
  ...props
}: MenuItemProps): JSX.Element => (
  <ListItemButton expanded={1} nested={0} {...props}>
    <ListItemIcon>{<Icon />}</ListItemIcon>
    <ListItemText
      disableTypography
      primary={label}
      sx={{
        whiteSpace: 'nowrap',
      }}
    />
  </ListItemButton>
);
interface SettingsModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  open: boolean;
  onClose: () => void;
  isSettingsFormDirty: boolean;
  setIsSettingsFormDirty: (isDirty: boolean) => void;
}

const Settings = ({
  onClose,
  open,
  isSettingsFormDirty,
  setIsSettingsFormDirty,
}: SettingsModalProps): JSX.Element => {
  const { accessLevel, firstName } = useCurrentUser();
  const { hasProduct } = usePlatform();
  const [chosenMenuItem, setChosenMenuItem] = useState<MenuLabel | null>(null);
  const {
    shownModal,
    openModal,
    closeModal,
    setActiveMenuItem,
    activeMenuItem,
    showMenu,
    setShowMenu,
    resetSettingsState,
  } = useSettingsModalState();
  const { isMobile } = useIsMobile();
  const hiddenMenu = !showMenu && isMobile;
  const hiddenContent = showMenu && isMobile;
  const { loading: renewTokenLoading, renewToken } = useRenewToken();

  const { user, loading, getUser, error } = useGetUser();

  const handleRefreshSubscriptions = useCallback(async () => {
    await renewToken();
    await getUser();
  }, [getUser, renewToken]);

  const handleOpenDiscardModal = () => {
    openModal(SettingsModal.DiscardSettings);
  };

  const handleCloseDiscardModal = () => {
    closeModal();
    setChosenMenuItem(null);
  };

  const changeActiveMenuItem = (label: MenuLabel): void => {
    if (isSettingsFormDirty) {
      setChosenMenuItem(label);
      handleOpenDiscardModal();
    } else {
      setActiveMenuItem(label);
    }
  };

  const handleDiscardChanges = () => {
    if (chosenMenuItem) {
      setActiveMenuItem(chosenMenuItem);
    }
    setIsSettingsFormDirty(false);
    handleCloseDiscardModal();
  };

  const handleChangeActiveMenuItem = (label: MenuLabel) => () => {
    changeActiveMenuItem(label);
    if (isMobile) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    return () => {
      resetSettingsState();
    };
  }, [resetSettingsState]);

  useEffect(() => {
    if (activeMenuItem === MenuLabel.Subscriptions) {
      handleRefreshSubscriptions();
    }
  }, [activeMenuItem, handleRefreshSubscriptions]);

  useEffect(() => {
    if (!firstName) {
      setActiveMenuItem(MenuLabel.Subscriptions);
    }
  }, [firstName, setActiveMenuItem]);

  const menuItems = menuItemsList.map(({ label, icon }) => {
    if (!hasProduct && label === MenuLabel.General) {
      return null;
    }
    if (
      label === MenuLabel.AdminPanel &&
      accessLevel !== IAccessLevel.ADMINISTRATOR
    ) {
      return null;
    }
    if (!firstName && label === MenuLabel.Account) {
      return null;
    }

    return (
      <MenuItem
        className={clsx({ active: label === activeMenuItem })}
        icon={icon}
        key={label}
        label={label}
        onClick={handleChangeActiveMenuItem(label)}
      />
    );
  });

  const hidden = !isNull(shownModal);

  const switchContent = (): ReactNode => {
    switch (activeMenuItem) {
      case MenuLabel.General:
        return (
          <SettingsGeneral
            error={error}
            loading={loading}
            setIsFormDirty={setIsSettingsFormDirty}
            settings={user?.settings}
          />
        );
      case MenuLabel.Subscriptions:
        return (
          <SettingsSubscriptions
            closeSettingsModal={onClose}
            error={error}
            loading={loading || renewTokenLoading}
            user={user}
          />
        );
      case MenuLabel.Theme:
        return <SettingsTheme />;
      case MenuLabel.Advanced:
        return <SettingsAdvanced />;
      case MenuLabel.Accessibility:
        return <SettingsAccessibility />;
      case MenuLabel.AdminPanel:
        return <SettingsAdminPanel />;
      default:
        return (
          <SettingsAccount
            error={error}
            loading={loading}
            setIsFormDirty={setIsSettingsFormDirty}
            user={user}
          />
        );
    }
  };

  const handleBack = () => {
    setShowMenu(true);
  };

  const setTitle = () => {
    if (!hiddenMenu) {
      return 'Settings';
    }

    return activeMenuItem;
  };

  return (
    <>
      <StyledModal
        className={clsx({ hidden })}
        disableContentScroll
        noPadding
        onBack={hiddenMenu ? handleBack : undefined}
        onClose={onClose}
        open={open}
        showCloseButton
        sizeVariant="xl"
        title={setTitle()}
      >
        <WrapperBox>
          <MenuBox>
            <List className={clsx({ ['hidden-menu']: hiddenMenu })}>
              {menuItems}
            </List>
          </MenuBox>
          <ContentBox className={clsx({ ['hidden-content']: hiddenContent })}>
            {switchContent()}
          </ContentBox>
        </WrapperBox>
      </StyledModal>
      <DiscardChangesModal
        onClose={handleCloseDiscardModal}
        onSubmit={handleDiscardChanges}
        open={shownModal === SettingsModal.DiscardSettings}
      />
    </>
  );
};

export default Settings;
