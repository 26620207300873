import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import clsx from 'clsx';

import { constSize } from 'utils';
import { Button, ButtonProps } from 'components/Button';
import { Tooltip } from 'components/Tooltip';

const StyledButton = styled(Button)(
  ({ theme: { palette, breakpoints, spacing, typography, transitions } }) => ({
    ...typography.body1Medium,
    textTransform: 'initial',
    letterSpacing: 'initial',
    ...constSize('40px'),
    padding: 0,
    color: palette.text.secondary,
    transition: transitions.create(['color']),

    [breakpoints.up('xxl')]: {
      ...constSize('initial', '40px'),
      padding: spacing(0, 3),
    },

    '& .MuiButton-startIcon': {
      transition: transitions.create(['color']),
      color: palette.icon.main,
      margin: 0,
    },

    '&:hover': {
      color: palette.text.primary,

      '& .MuiButton-startIcon': {
        color: palette.icon.dark,
      },
    },

    '&.active': {
      '& .MuiButton-startIcon': {
        color: palette.primary.main,
      },
    },
  })
);

const UtilTitle = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'none',
  [breakpoints.up('xxl')]: { display: 'block' },
}));

interface UtilButtonProps extends Omit<ButtonProps, 'rounded' | 'color'> {
  active?: boolean;
  label: string;
  tip?: string;
  icon: ButtonProps['startIcon'];
  quizProgressBar?: boolean;
}

export const UtilButton = ({
  active,
  label,
  icon,
  tip,
  quizProgressBar,
  ...props
}: UtilButtonProps): JSX.Element => (
  <Tooltip placement="top" textCenter title={tip}>
    <StyledButton
      className={clsx({ active })}
      color="secondary"
      rounded
      startIcon={icon}
      tertiary
      {...props}
    >
      {quizProgressBar ? null : <UtilTitle component="span">{label}</UtilTitle>}
    </StyledButton>
  </Tooltip>
);
