import {
  OnDataOptions,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { useCallback } from 'react';
import {
  IMarkAllNotificationsAsReadData,
  IMarkAllNotificationsAsReadVar,
  MARK_ALL_AS_READ,
  optimisticMarkAllAsRead,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import {
  INotificationsData,
  INotificationsVar,
  NOTIFICATIONS,
} from '@quesmed/types-rn/resolvers/query/restricted';
import {
  IOnNotificationAriseData,
  IOnNotificationAriseVar,
  NOTIFICATION_ARISE,
} from '@quesmed/types-rn/resolvers/subscription';

import useCurrentUser from 'Auth/useCurrentUser';

export const onNotificationArise = ({
  client,
  data: subscriptionData,
}: OnDataOptions<IOnNotificationAriseData>) => {
  const { onNotificationArise } = subscriptionData.data || {};
  if (onNotificationArise) {
    const prevData = client.readQuery<INotificationsData, INotificationsVar>({
      query: NOTIFICATIONS,
    });

    if (prevData) {
      client.writeQuery({
        query: NOTIFICATIONS,
        data: {
          ...prevData,
          restricted: {
            ...prevData.restricted,
            notifications: [
              ...onNotificationArise,
              ...prevData.restricted.notifications,
            ],
          },
        },
      });
    }
  }
};

const useNotifications = () => {
  const { id: userId } = useCurrentUser();

  const { data, loading } = useQuery<INotificationsData, INotificationsVar>(
    NOTIFICATIONS
  );
  const { notifications } = data?.restricted || {};

  const [marAllNotificationsAsRead, { loading: markAllAsReadLoading }] =
    useMutation<
      IMarkAllNotificationsAsReadData,
      IMarkAllNotificationsAsReadVar
    >(MARK_ALL_AS_READ);

  const markAllAsRead = useCallback(() => {
    marAllNotificationsAsRead({
      optimisticResponse: optimisticMarkAllAsRead(notifications || []),
    });
  }, [marAllNotificationsAsRead, notifications]);

  useSubscription<IOnNotificationAriseData, IOnNotificationAriseVar>(
    NOTIFICATION_ARISE,
    {
      variables: {
        userId: Number(userId),
      },
      skip: !userId,
      onData: onNotificationArise,
    }
  );

  return {
    notifications,
    loading,
    markAllAsRead,
    markAllAsReadLoading,
  };
};

export default useNotifications;
