import React from 'react';
import Box from '@mui/system/Box';
import { styled } from '@mui/material';
import { clsx } from 'clsx';

import { AgoraVideoPlayer } from './AgoraReact';
import { useAgoraAPI } from './AgoraAPI';
import { H5 } from 'components/Typography';

const VideoBox = styled(Box)(({ theme: { palette } }) => ({
  position: 'relative',
  width: '300px',
  margin: '0 auto',
  borderRadius: '8px',
  overflow: 'hidden',
  height: '300px',
  backgroundColor: palette.secondary.main,
}));

const StreamerBox = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StreamerName = styled(Box)(
  ({ theme: { palette, spacing, typography } }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: 'fit-content',
    borderRadius: '20px',
    backgroundColor: palette.background.paper,
    padding: spacing(0, 4),
    ...typography.body1Medium,
    color: palette.text.primary,
    margin: spacing(4),
  })
);

const StreamerAudio = styled(Box)(
  ({ theme: { palette, spacing, transitions } }) => ({
    display: 'inline-flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '56px',
    borderRadius: '20px',
    backgroundColor: palette.background.paper,
    margin: spacing(4),

    '& > .MuiSvgIcon-root': {
      color: palette.icon.light,
      transition: transitions.create(['color']),
    },

    '&.active': {
      '& > .MuiSvgIcon-root': {
        color: palette.primary.main,
      },
    },
  })
);

const CameraOff = styled(Box)(({ theme: { palette } }) => ({
  color: palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

interface AgoraVideoProps {
  active: boolean;
  audioIcon: JSX.Element;
  displayName: string;
  participantId?: number;
  currentUser: boolean;
}

const AgoraVideo = ({
  active,
  audioIcon,
  displayName,
  participantId,
  currentUser,
}: AgoraVideoProps): JSX.Element => {
  const { client, videoTrack: video } = useAgoraAPI();

  const videoTrack = currentUser
    ? video
    : client?.remoteUsers?.find(({ uid }) => uid === participantId)?.videoTrack;

  return (
    <VideoBox>
      {videoTrack ? (
        <AgoraVideoPlayer
          className="vid"
          style={{ height: '100%', width: '100%' }}
          videoTrack={videoTrack}
        />
      ) : (
        <CameraOff>
          <H5>Camera is off</H5>
        </CameraOff>
      )}
      <StreamerBox>
        <StreamerAudio className={clsx({ active })}>{audioIcon}</StreamerAudio>
        <StreamerName>{displayName}</StreamerName>
      </StreamerBox>
    </VideoBox>
  );
};

export default AgoraVideo;
