import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Children } from 'types';
import { Body, H1 } from 'components/Typography';

interface ErrorBoxProps {
  children?: Children[];
  description: string;
}

const ErrorContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  flexDirection: 'column',
});

const ErrorBox = ({ description, children }: ErrorBoxProps): JSX.Element => (
  <ErrorContainer>
    <H1>Oops!</H1>
    <Body component="p">{description}</Body>
    {children}
  </ErrorContainer>
);

export default ErrorBox;
