import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { clsx } from 'clsx';
import { EOsceMarksheetState } from '@quesmed/types-rn/models';

import { Extends, Nullable, StationRole } from 'types';
import { ParticipantCardVariant, ParticipantStatus } from './types';
import { Button, ButtonProps } from 'components/Button';
import {
  AgoraVideo,
  useActiveSpeaker,
  useAgoraAPI,
  useAgoraState,
} from 'components/Agora';
import getParticipantStatusIndicator from './getParticipantStatusIndicator';
import getParticipantStatus from './getParticipantStatus';
import { STATION_ROLES } from 'constants/stations';
import { VolumeIcon, VolumeOffIcon } from 'components/Icons';
import ParticipantRoleInfo from './ParticipantRoleInfo';
import { Separator } from 'components/Separator';

const ParticipantContainer = styled(Box)({
  overflow: 'hidden',
  borderRadius: '9px',
});

const ParticipantCardContainer = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    padding: spacing(7, 8),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '9px',
    border: `1px solid ${palette.stroke.main}`,
    color: palette.text.primary,
    background: palette.background.paper,
    boxSizing: 'border-box',

    '&.lobby': {
      minHeight: '120px',
      padding: spacing(7, 8),
    },

    '&.panel': {
      height: '88px',
      padding: spacing(4, 6),
    },
  })
);

const ParticipantData = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'flex-start',
  justifyContent: 'center',
  background: palette.background.paper,
  gap: spacing(2),
}));

const ParticipantHeading = styled(Box)(
  ({ theme: { palette, spacing, transitions } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),

    '& > .MuiSvgIcon-root': {
      transition: transitions.create(['color']),
    },

    '& > .no-permissions': {
      color: palette.error.main,
    },

    '& > .active': {
      color: palette.success.main,
    },

    '& > .audio': {
      color: palette.icon.light,

      '&.active': {
        color: palette.primary.main,
      },
    },
  })
);

const ParticipantName = styled(Box)(({ theme: { typography } }) => ({
  '&.lobby': {
    ...typography.h6,
  },

  '&.panel': {
    ...typography.body1Medium,
  },
}));

type Color = Extends<ButtonProps['color'], 'primary' | 'secondary'>;

interface ParticipantCardProps {
  displayName: string;
  label?: string;
  color?: Color;
  state?: EOsceMarksheetState;
  isCandidate?: boolean;
  role?: Nullable<StationRole>;
  onClick: Nullable<() => void>;
  withAudio?: boolean;
  withStatus?: boolean;
  currentUser?: boolean;
  participantId: number;
  variant: ParticipantCardVariant;
  withRoleHeader?: boolean;
  withVideo?: boolean;
  withoutRole?: boolean;
}

const ParticipantCard = ({
  color = 'secondary',
  currentUser = false,
  displayName,
  participantId,
  label,
  isCandidate,
  onClick,
  role,
  state,
  variant,
  withAudio = false,
  withStatus = false,
  withVideo = false,
  withRoleHeader = false,
  withoutRole,
}: ParticipantCardProps) => {
  const { client, isConnected, videoTrack: video } = useAgoraAPI();
  const { remoteUsers } = client;
  const { mutedUsers } = useAgoraState();
  const { activeSpeaker } = useActiveSpeaker();
  const videoTrack = currentUser
    ? video?.enabled
    : client?.remoteUsers?.find(({ uid }) => uid === participantId)?.videoTrack;

  const roleData = role ? STATION_ROLES[role] : null;

  const active = Boolean(
    participantId &&
      participantId === activeSpeaker?.uid &&
      activeSpeaker.level > 0
  );
  const connected = currentUser
    ? isConnected()
    : remoteUsers?.some(({ uid }) => uid === participantId);
  const muted = participantId ? mutedUsers.includes(participantId) : false;
  const AudioIcon = muted && !connected ? VolumeOffIcon : VolumeIcon;
  const status = getParticipantStatus(withAudio, connected, role);

  return (
    <ParticipantContainer>
      {withRoleHeader && roleData ? (
        <Separator
          gap="small"
          icon={roleData.icon}
          textAlign="left"
          title={roleData.name}
          variant="solid"
        />
      ) : null}
      {withVideo && videoTrack ? (
        <AgoraVideo
          active={active}
          audioIcon={<AudioIcon />}
          currentUser={currentUser}
          displayName={displayName}
          participantId={participantId}
        />
      ) : (
        <ParticipantCardContainer className={clsx(variant)}>
          <ParticipantData>
            <ParticipantHeading>
              {withStatus ? getParticipantStatusIndicator(status) : null}
              <ParticipantName className={clsx(variant)}>
                {displayName}
              </ParticipantName>
              {withAudio ? (
                <AudioIcon
                  className={clsx('audio', { active: active && connected })}
                />
              ) : null}
            </ParticipantHeading>
            {(roleData || status === ParticipantStatus.RoleRequired) &&
            !withoutRole ? (
              <ParticipantRoleInfo
                currentUser={currentUser}
                isCandidate={isCandidate}
                roleData={roleData}
                state={state}
              />
            ) : null}
          </ParticipantData>
          {label && onClick ? (
            <Button
              color={color}
              onClick={onClick}
              secondary={color === 'secondary'}
            >
              {label}
            </Button>
          ) : null}
        </ParticipantCardContainer>
      )}
    </ParticipantContainer>
  );
};

export default ParticipantCard;
