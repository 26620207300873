import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { localStorageTyped } from 'utils';
import { AuthState } from './types';

const { setItem: setDemo, removeItem: removeDemo } =
  localStorageTyped<boolean>('demo');

export const authState = create<AuthState>()((set, get) => ({
  isAuthenticated: false,
  isAuthPending: true,
  isDemo: false,
  token: null,
  tokenDecoded: null,

  resetState: () => {
    set({
      ...get(),
      isAuthenticated: false,
      token: null,
      isDemo: false,
      tokenDecoded: null,
    });
    removeDemo();
  },
  setIsAuthenticated: isAuthenticated => set({ isAuthenticated }),
  setIsAuthPending: isAuthPending => set({ isAuthPending }),
  setIsDemo: isDemo => {
    if (isDemo) {
      setDemo(true);
    } else {
      removeDemo();
    }
    set({ isDemo });
  },
  setTokenDecoded: tokenDecoded => set({ tokenDecoded }),
  setToken: token => set({ token }),
}));

export const useAuth = () => authState(state => state, shallow);
