import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

interface ExpandedState {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  toggleExpanded: () => void;
}

const useExpandedStore = create<ExpandedState>(set => ({
  expanded: false,
  setExpanded: value => set({ expanded: value }),
  toggleExpanded: () => set(state => ({ expanded: !state.expanded })),
}));

export const useExpandedState = () => useExpandedStore(state => state, shallow);
