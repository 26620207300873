import React from 'react';
import { mdiArrowRight } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const ArrowRightIcon = (props: IconProps) => (
  <Icon path={mdiArrowRight} {...props} />
);

export default ArrowRightIcon;
