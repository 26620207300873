interface ScoreNameItem {
  name?: string;
  rank?: number;
}
type CompareRankNameResult = -1 | 0 | 1;
type compareRankName = {
  (a: ScoreNameItem, b: ScoreNameItem): CompareRankNameResult;
  (
    a: [string, ScoreNameItem],
    b: [string, ScoreNameItem]
  ): CompareRankNameResult;
};

type Order = 'desc' | 'asc';

export const compareRankName =
  (order: Order = 'asc') =>
  (x: any, y: any) => {
    const a = Array.isArray(x) ? x[1] : x;
    const b = Array.isArray(y) ? y[1] : y;
    const useRank =
      a.rank !== undefined && b.rank !== undefined && a.rank !== b.rank;
    const useName = a.name !== undefined && b.name !== undefined;
    if (useRank) return order === 'asc' ? a.rank - b.rank : b.rank - a.rank;
    if (useName)
      return order === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);

    return 0;
  };

export const compareRankNameAsc = compareRankName('asc');

export const compareRankNameDesc = compareRankName('desc');
