import { ApolloError, useMutation } from '@apollo/client';
import {
  IRenewTokenData,
  IRenewTokenVar,
  RENEW_TOKEN,
} from '@quesmed/types-rn/resolvers/mutation/restricted';

import { useAuth } from './useAuth';

interface UseRenewTokenProps {
  onError?: (error?: ApolloError) => Promise<void> | void;
}

const useRenewToken = (params?: UseRenewTokenProps) => {
  const { onError } = params || {};

  const { setToken } = useAuth();
  const [renewToken, { loading }] = useMutation<
    IRenewTokenData,
    IRenewTokenVar
  >(RENEW_TOKEN, {
    onCompleted: data => {
      if (data) {
        const { renewToken } = data.restricted;

        if (renewToken) {
          setToken(renewToken);
        }
      }
    },
    onError: error => {
      onError?.(error);
    },
  });

  return { loading, renewToken };
};

export default useRenewToken;
