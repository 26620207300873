import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useQuizState } from 'hooks';

const quizPaths = [
  '/questions/solo/quiz/:marksheetId/:markId/:questionId/:questionNumber?',
  '/questions/group/quiz/:marksheetId/:markId/:questionId/:questionNumber',
  '/flashcards/quiz/:todoId/:markId/:cardId/:cardNumber?',
  '/mock-tests/test/:mockTestId/:marksheetId/:markId/:questionId',
  '/stations/solo/study/:osceMarksheetId',
  '/stations/group/study/:sessionId/:osceMarksheetId',
];

const QuizStatusController = () => {
  const { pathname } = useLocation();
  const { setQuizStarted } = useQuizState();

  useEffect(() => {
    const quizStarted = quizPaths.some(path => matchPath({ path }, pathname));
    setQuizStarted(quizStarted);
  }, [pathname, setQuizStarted]);

  return null;
};

export default QuizStatusController;
