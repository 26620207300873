import locales from 'locales';
import { Tab } from 'types';
import { LOBBY_PANELS } from './general';

export const QUESTIONS_TAB = [
  locales.questions.tabs.all,
  locales.questions.tabs.flagged,
] as const;

export const TABS: Tab[] = QUESTIONS_TAB.map(tab => ({
  label: tab,
  value: tab,
}));

export const QUESTION_PANEL: Tab[] = [
  { label: 'Questions', value: 'Questions' },
];

export const PANELS: Tab[] = [...QUESTION_PANEL, ...LOBBY_PANELS];

export const CATEGORIES: string[] = [
  'All',
  'Pre-clinical',
  'Clinical',
  'Anatomy',
  'Data interpretation',
  'PSA',
  'OSCE',
  'Mock tests',
  'SJT',
  'Interview Anaesthetics',
  'Interview CST',
  'Interview IMT',
  'Interview Radiology',
];
