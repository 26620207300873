import { ServerError, ServerParseError } from '@apollo/client';

import { ERRORS, isDev } from 'config/constants';
import { isOnlineState } from 'hooks/useOnlineStatus';
import { ErrorDetails, SnackBar } from 'types';
import { logDevError, logError } from 'utils';

// TODO this set of errors for network error was taken from RN
// It will be osed only for network errors so it can be so general for now
const NETWORK_STATUS_CODE = /(4|5)[0-9]{2,2}/;

type NetworkError = Error | ServerParseError | ServerError;

export const handleNetworkError = (
  networkError: NetworkError,
  snackbar: SnackBar,
  details?: ErrorDetails
) => {
  const { message } = networkError || {};
  const { isOnline } = isOnlineState.getState();

  logDevError('network', networkError, details);

  if (!isDev) {
    logError(networkError);
  }

  if (!isOnline && message.includes(ERRORS.FAILED_TO_FETCH)) {
    return;
  }

  // Don't show error when bad gateway mostly casused
  // by heartbeat query, not useful for user
  if (message?.includes?.('502')) {
    return;
  }

  if (NETWORK_STATUS_CODE.test(message)) {
    snackbar(ERRORS.GENERAL_ERROR);

    return;
  }

  snackbar(ERRORS.CONNECTION_ERROR);
};
