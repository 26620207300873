import React, {
  forwardRef,
  Fragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Fade from '@mui/material/Fade';
import { Divider } from '@mui/material';
import isNil from 'lodash/isNil';
import { clsx } from 'clsx';

import { ListItemButton, ListItemButtonProps } from 'components/List';
import { IconComponent } from 'components/Icons';
import { Popper, PopperProps } from 'components/Popper';
import { NavigationHeader } from './NavigationHeader';
import { useHover, usePrevious } from 'hooks';
import { NavigationLinkData } from './types';
import { AppProductType, Nullable } from 'types';
import NavigationToolitp from './NavigationTooltip';
import { entries } from 'utils';
import NavigationItemLabel from './NavigationItemLabel';

interface Links {
  [key: string]: NavigationLinkData[];
}

interface NavigationItemProps
  extends Omit<ListItemButtonProps, 'expanded' | 'sx'> {
  expanded?: boolean;
  icon?: IconComponent;
  isPopup?: boolean;
  label: ReactNode;
  nested?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  path?: string;
  showIcon?: boolean;
  sx?: ListItemButtonProps['sx'];
  title?: string;
}

export const NavigationItem = forwardRef<HTMLDivElement, NavigationItemProps>(
  (
    {
      className,
      expanded = true,
      icon: Icon,
      isPopup = false,
      nested,
      label,
      onClick,
      path,
      showIcon = true,
      sx,
      title,
    },
    ref
  ): JSX.Element => {
    const [showTooltip, setShowTooltip] = useState(false);
    const disableListeners = expanded || isPopup;

    const handleTooltipOpenState = (isOpen: boolean) => () =>
      setShowTooltip(isOpen);

    const handleClickItem = (event: React.MouseEvent<HTMLSpanElement>) => {
      handleTooltipOpenState(false)();

      if (onClick) {
        onClick(event);
      }
    };

    return (
      <NavigationToolitp
        disableListeners={disableListeners}
        onTooltipStateChange={handleTooltipOpenState}
        open={showTooltip}
        title={title}
      >
        <ListItemButton
          className={className}
          component={path ? NavLink : 'span'}
          expanded={expanded ? 1 : 0}
          nested={nested ? 1 : 0}
          onClick={handleClickItem}
          ref={ref}
          sx={sx}
          to={path || ''}
        >
          {showIcon && Icon ? <ListItemIcon>{<Icon />}</ListItemIcon> : null}
          <Fade in={expanded} unmountOnExit>
            <ListItemText
              disableTypography
              primary={label}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            />
          </Fade>
        </ListItemButton>
      </NavigationToolitp>
    );
  }
);

NavigationItem.displayName = 'NavigationItem';

interface NavigationPopperMenu
  extends Omit<PopperProps, 'children' | 'onClick'> {
  activeValue?: Nullable<NavigationLinkData['value']>;
  label?: ReactNode;
  links?: Links;
  onClick?: (item?: AppProductType) => () => void;
  showIcon?: boolean;
}

export const NavigationPopperMenu = forwardRef<
  HTMLDivElement,
  NavigationPopperMenu
>(
  (
    {
      activeValue,
      anchorEl,
      label,
      links,
      onClick,
      onClose,
      open,
      showIcon,
      placement,
    },
    ref
  ): JSX.Element => {
    const handleOnClick =
      (value?: AppProductType) => (event: Event | React.SyntheticEvent) => {
        if (onClick) {
          onClick(value)();
        } else {
          onClose?.(event);
        }
      };

    const groupedLinks = links ? entries(links) : [];
    const bottom = placement === 'bottom';

    return (
      <Popper
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        paperSx={{
          padding: 2,
          marginLeft: bottom ? 0 : 4,
          marginTop: bottom ? 2 : 0,
          minWidth: '224px',
          '& .MuiDivider-root': {
            my: 2,
          },
        }}
        placement={placement}
        ref={ref}
      >
        {label ? (
          <>
            <NavigationHeader className="popper-header">
              {label}
            </NavigationHeader>
            <Divider />
          </>
        ) : null}
        {groupedLinks.map(([key, linksGroup], i) => {
          const nonLastItem = i < groupedLinks.length - 1;

          return (
            <Fragment key={key}>
              <List sx={{ padding: 0 }}>
                {linksGroup.map(({ icon, label, path = '', locked, value }) => (
                  <NavigationItem
                    className={clsx({ locked })}
                    icon={icon}
                    key={label + path}
                    label={
                      <NavigationItemLabel
                        active={!isNil(activeValue) && activeValue === value}
                        label={label}
                        locked={locked}
                        withActiveState
                      />
                    }
                    onClick={locked ? undefined : handleOnClick(value)}
                    path={locked ? '' : path}
                    showIcon={showIcon}
                  />
                ))}
              </List>
              {nonLastItem ? <Divider /> : null}
            </Fragment>
          );
        })}
      </Popper>
    );
  }
);

NavigationPopperMenu.displayName = 'NavigationPopperMenu';

interface NavigationLinkProps extends Omit<NavigationItemProps, 'label'> {
  label: string;
  links?: Links;
}

const NavigationLink = ({
  expanded = true,
  icon: Icon,
  label,
  links,
  path,
  showIcon = true,
}: NavigationLinkProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [expand, setExpand] = useState(false);
  const [, isPopupHover, , setPopupRef] = useHover<HTMLDivElement>();
  const [linkRef, isLinkHover] = useHover<HTMLDivElement>();
  const isPopupOpen = Boolean(anchorEl);
  const prevExpanded = usePrevious(expanded);
  const prevIsLinkHover = usePrevious(isLinkHover);
  const prevIsPopupHover = usePrevious(isPopupHover);
  const noHover = !isPopupHover && !isLinkHover;
  const wasHover = prevIsPopupHover || prevIsLinkHover;

  const handleExpand = () => {
    setExpand(prev => !prev);
  };

  const handleOpen = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isPopupOpen && noHover && wasHover) {
      setAnchorEl(null);
    }
  }, [isPopupOpen, noHover, wasHover]);

  useEffect(() => {
    if (prevExpanded && !expanded && anchorEl) {
      setAnchorEl(null);
    }

    if (!expanded) {
      setExpand(false);
    }
  }, [anchorEl, prevExpanded, expanded]);

  const groupedLinks = links ? entries(links) : [];

  return (
    <>
      <NavigationItem
        expanded={expanded}
        icon={Icon}
        isPopup={isPopupOpen}
        label={
          <NavigationItemLabel label={label} withNestedItems={Boolean(links)} />
        }
        onClick={links ? (expanded ? handleExpand : handleOpen) : undefined}
        path={path}
        ref={links ? linkRef : undefined}
        showIcon={showIcon}
        title={expanded ? '' : label}
      />
      {!expanded ? (
        <NavigationPopperMenu
          anchorEl={anchorEl}
          label={label}
          links={links}
          onClose={handleClose}
          open={isPopupOpen}
          ref={setPopupRef}
        />
      ) : null}
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {groupedLinks.map(([key, linksGroup], index) => (
          <Fragment key={key}>
            <List disablePadding>
              {linksGroup.map(({ icon, label, path = '' }) => (
                <NavigationItem
                  icon={icon}
                  key={label + path}
                  label={label}
                  nested
                  path={path}
                  showIcon={false}
                />
              ))}
            </List>
            {index < groupedLinks.length - 1 ? <Divider /> : null}
          </Fragment>
        ))}
      </Collapse>
    </>
  );
};

export default NavigationLink;
