import React from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import SettingsContentLayout from './SettingsContentLayout';
import SettingsSectionTitle from './SettingsSectionTitle';
import { FormRadioGroup, RadioOption } from 'components/RadioGroup';
import { ThemeMode, useThemeMode } from 'theme';
import {
  AutoLayoutImage,
  DarkLayoutImage,
  LightLayoutImage,
} from 'components/Illustrations';
import SettingsUpdate from './SettingsUpdate';
import { entries } from 'utils';

const schema = Joi.object<SettingsThemeValues>({
  theme: Joi.string(),
});

const getThemeOptionImage = (themeMode: string) => {
  switch (themeMode) {
    case ThemeMode.Light:
      return <LightLayoutImage />;
    case ThemeMode.Dark:
      return <DarkLayoutImage />;
    case ThemeMode.Auto:
    default:
      return <AutoLayoutImage />;
  }
};

const themeOptions: RadioOption[] = entries(ThemeMode).map(
  ([label, value]) => ({
    label: value === ThemeMode.Auto ? 'Auto (System)' : label,
    value,
    image: getThemeOptionImage(value),
  })
);

type SettingsThemeValues = {
  theme: ThemeMode;
};

const SettingsTheme = (): JSX.Element => {
  const { themeMode, setThemeMode } = useThemeMode();
  const {
    control,
    getValues,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<SettingsThemeValues>({
    defaultValues: {
      theme: themeMode,
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const showSettingsUpdate = isDirty && getValues('theme') !== themeMode;

  const handleCancelUpdate = () => {
    reset();
  };

  const onSubmit = (data: SettingsThemeValues) => {
    setThemeMode(data.theme);
    reset(data);
  };

  return (
    <>
      <SettingsContentLayout>
        <SettingsSectionTitle
          subtitle="Choose a theme that suits your style and preferences."
          title="Theme"
        />
        <FormRadioGroup
          control={control}
          id="theme"
          name="theme"
          options={themeOptions}
        />
      </SettingsContentLayout>
      {showSettingsUpdate ? (
        <SettingsUpdate
          onCancel={handleCancelUpdate}
          onUpdate={handleSubmit(onSubmit)}
        />
      ) : null}
    </>
  );
};

export default SettingsTheme;
