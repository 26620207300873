import React from 'react';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  CONTACT_US,
  IContactUsData,
  IContactUsVar,
  ISupportTicket,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { EProductType } from '@quesmed/types-rn/models';
import {
  IUniversitiesData,
  IUniversitiesVar,
  UNIVERSITIES,
} from '@quesmed/types-rn/resolvers/query';
import { useLocation, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { Body, Overline } from 'components/Typography';
import { useSnackbar } from 'components/Snackbar';
import { Modal } from 'components/Modal';
import { AppProductType, Nullable, quesmedProductNames } from 'types';
import { useAuth } from 'Auth';
import { FormField } from 'components/TextField';
import { usePlatform } from 'context/PlatformContext';
import { KnowledgeLibraryRouterParams } from 'components/LearningMaterials';
import { useGetUser } from 'hooks';

const StyledBody = styled(Body)(({ theme: { spacing } }) => ({
  marginBottom: spacing(8),
}));

const StyledOverline = styled(Overline)(
  ({ theme: { palette, typography, spacing } }) => ({
    ...typography.button,
    color: palette.text.disabled,
    marginBottom: spacing(4),
  })
);

const StyledInputWrapper = styled(Box)(({ theme: { spacing } }) => ({
  '& .MuiFormControl-root:nth-of-type(1)': {
    marginBottom: spacing(6),
  },
}));

export interface FeedbackInput {
  subject: string;
  description: string;
}

const parseSelection = (
  product: Nullable<EProductType>,
  selection?: Nullable<KnowledgeLibraryRouterParams>
): string => {
  if (!selection) {
    return '';
  }

  const { chapterId, sectionId } = selection;

  if (!sectionId || !sectionId) {
    return '';
  }

  let result;

  switch (product) {
    case EProductType.OSCE:
    case EProductType.PACES:
    case EProductType.PLAB2:
      result = { osceType: sectionId, stationId: chapterId };
      break;
    default:
      result = { topicId: sectionId, conceptId: chapterId };
      break;
  }

  return JSON.stringify(result, null, 2);
};

const parseVideoSelection = (
  product: Nullable<EProductType>,
  selection?: Nullable<KnowledgeLibraryRouterParams>
): string => {
  if (!selection) {
    return '';
  }

  const { sectionId, videoId } = selection;

  if (!sectionId || !videoId) {
    return '';
  }

  let result;
  switch (product) {
    case EProductType.OSCE:
    case EProductType.PACES:
      result = { osceType: sectionId, videoId };
      break;
    default:
      result = { topicId: sectionId, videoId };
      break;
  }

  return JSON.stringify(result, null, 2);
};

const schema = Joi.object<FeedbackInput>({
  subject: Joi.string().required().max(150).messages({
    'string.empty': 'Subject is required',
    'string.max': 'Subject can include 150 characters only',
  }),
  description: Joi.string().required().max(2500).messages({
    'string.empty': 'Description is required',
    'string.max': 'Description can include 2500 characters only',
  }),
});

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
  isInvokedOnSubscriptions?: boolean;
}

const FeedbackModal = ({
  isInvokedOnSubscriptions = false,
  open,
  onClose,
}: FeedbackModalProps): JSX.Element => {
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm<FeedbackInput>({
    defaultValues: {
      subject: '',
      description: '',
    },
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useAuth();
  const { pathname, search } = useLocation();
  const {
    marksheetId,
    markId,
    questionId,
    todoId,
    cardId,
    sectionId,
    chapterId,
    osceMarksheetId,
    sessionId,
  } = useParams() || {};
  const { product } = usePlatform();
  const { user, loading: userDataLoading } = useGetUser(!isAuthenticated);
  const [contactUs, { loading: contactUsLoading }] = useMutation<
    IContactUsData,
    IContactUsVar
  >(CONTACT_US, {
    onCompleted: () => {
      reset();
      onClose();
      enqueueSnackbar('Message was sent!');
    },
  });

  const universitiesQueryOptions: QueryHookOptions<
    IUniversitiesData,
    IUniversitiesVar
  > = {
    skip: !isAuthenticated,
  };

  if (user?.universityId) {
    universitiesQueryOptions.variables = {
      id: user.universityId,
    };
  }

  const { data: universitiesData, loading: universitiesDataLoading } = useQuery<
    IUniversitiesData,
    IUniversitiesVar
  >(UNIVERSITIES, universitiesQueryOptions);

  const sendMessage = ({ subject, description }: FeedbackInput) => {
    if (!isValid || !isDirty) {
      return;
    }

    let trimmedUser;
    if (user) {
      trimmedUser = {
        id: user.id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        classYear: user.classYear,
        graduationYear: user.graduationYear,
        university: universitiesData?.universities[0].name,
      };
    }
    const recording = '';
    const message = `${description}

---
Product: ${quesmedProductNames[product as AppProductType]}
URL: ${pathname}${search}
KnowledgeLibrary: ${parseSelection(product, { sectionId, chapterId })}
VideoLibrary: ${parseVideoSelection(product, { sectionId, chapterId })}
marksheetId: ${marksheetId}
osceMarksheetId: ${osceMarksheetId}
sessionId: ${sessionId}
todoId: ${todoId}
markId: ${markId}
questionId: ${questionId}
cardId: ${cardId}
${
  isInvokedOnSubscriptions
    ? 'invoked in the subscriptions section on the settings modal'
    : ''
}
User: ${JSON.stringify(trimmedUser, null, 2)}`;
    const slack: ISupportTicket = {
      text: 'New Help Ticket Received',
      attachments: [
        {
          title: subject,
          title_link: recording,
          text: `${description}

---
<mailto:${user?.username}>`,
        },
      ],
    };
    const contactUsInput = {
      slack,
      subject,
      message,
    };
    contactUs({ variables: contactUsInput });
  };

  return (
    <Modal
      cancelLabel="Cancel"
      noPaddingY
      onClose={onClose}
      onSubmit={handleSubmit(sendMessage)}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={
        !isValid ||
        contactUsLoading ||
        userDataLoading ||
        universitiesDataLoading
      }
      submitLabel="Send message"
      title="Help & Support"
    >
      <StyledBody>
        We&apos;re here to help! If you have any problems or need assistance
        with our app, please send us a message using the form below. We&apos;ll
        reply directly to your email.
      </StyledBody>
      <StyledOverline>Did you know that?</StyledOverline>
      <StyledBody>
        For faster and more targeted support, open the Help & Support window
        from the area of the app or the question/flashcard/book entry where you
        need assistance. This will help us locate the specific area that
        you&apos;re referring to.
      </StyledBody>
      <StyledInputWrapper>
        <FormField
          control={control}
          fullWidth
          label="Subject"
          name="subject"
          type="string"
        />
        <FormField
          control={control}
          fullWidth
          label="Describe"
          multiline
          name="description"
          rows={5}
          type="string"
        />
      </StyledInputWrapper>
    </Modal>
  );
};

export default FeedbackModal;
