import React from 'react';
import { mdiTuneVariant } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const TuneVariantIcon = (props: IconProps) => (
  <Icon path={mdiTuneVariant} {...props} />
);

export default TuneVariantIcon;
