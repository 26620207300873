import { LabTabContent, LabValuesTable } from '../types';

const electrolytes: LabValuesTable = {
  tableHead: 'Electrolytes',
  tableData: [
    ['Sodium', '135 - 145 mmol/L'],
    ['Potassium', '3.5 - 5.3 mmol/L'],
    ['Chloride', '95 - 106 mmol/L'],
    ['Bicarbonate', '22 - 29 mmol/L'],
    ['Urea', '2.5 - 7.8 mmol/L'],
    ['Creatinine', '60 - 120 µmol/L'],
    ['Calcium', '2.2 - 2.6 mmol/L'],
    ['Magnesium', '0.7 - 1.0 mmol/L'],
    ['Phosphate', '0.8 - 1.5 mmol/L'],
    ['eGFR', '> 60 mL/min/1.73m2'],
  ],
};

const metabolic: LabValuesTable = {
  tableHead: 'Metabolic tests',
  tableData: [
    ['Fasting glucose', '3.5 - 5.5 mmol/L'],
    ['Glycated haemoglobin', '20 - 42 mmol/mol or 4-6%'],
    ['Total cholesterol', '< 5.0 mmol/L'],
    ['Triglycerides (fasting)', '< 2.3 mmol/L'],
    ['USerum osmolality', '285 - 295 mOsmol/kg'],
  ],
};

const liver: LabValuesTable = {
  tableHead: 'Liver function',
  tableData: [
    ['Albumin', '35 - 50 g/L'],
    ['Alanine\naminotransferase (ALT)', '10 - 50 IU/L'],
    ['Aspartate\naminotransferase (AST)', '10 - 40 IU/L'],
    ['Alkaline phosphatase\n(ALP)', '25 - 115 IU/L'],
    ['Bilirubin', '< 17 µmol/L'],
    ['Gamma glutamyl\n transferase (GGT)', '9 - 40 U/L'],
  ],
};

const cardic: LabValuesTable = {
  tableHead: 'Cardiac enzymes',
  tableData: [
    ['Creatine kinase', '(M) 25 - 200 U/L'],
    ['', '(F) 25 - 175 U/L'],
    // ['', '(African and \nAfrican-Caribbean)', '< 350 U/L'],
    ['Troponin T', '<14 ng/L'],
  ],
};

const haematinics: LabValuesTable = {
  tableHead: 'Haematinics',
  tableData: [
    ['Serum vitamin B12', '160 - 925 ng/L'],
    ['Serum folate', '3 - 15 μg/L'],
    ['Ferritin', '12 - 200 μg/L'],
    ['Iron', '(M) 14 - 31 μmol/L'],
    ['', '(F) 11 - 30 μmol/L'],
    ['Total iron binding capacity', '54 - 75 μmol/L'],
  ],
};

const endocrinology: LabValuesTable = {
  tableHead: 'Endocrinology',
  tableData: [
    ['Thyroid stimulating hormone', '0.3 - 4.2 mU/L'],
    ['Total T4', '60 - 150 nmol/L'],
    ['Free T4', '9 - 25 pmol/L'],
    ['Cortisol (9am)', '200 - 700 nmol/L'],
    // ['', '(midnight)', '< 140 nmol/L'],
    ['Parathyroid hormone', '1.6 - 8.5 pmol/L'],
    ['Vitamin D', '>50 nmol/L'],
    ['Prolactin', '(M) 90 - 320 IU/L'],
    ['', '(F) 100 - 500 IU/L'],
    ['Luteinising hormone', '1 - 11 IU/L (luteal)'],
    ['Follicle stimulating hormone', '2 - 8 IU/L (luteal)'],
    ['Renin', '1.2 - 4.5 pmol/mL/hr'],
    // ['', '(recumbent)', '1.1 - 2.7 pmol/mL/hr'],
    ['Aldosterone', '600 - 1200 pmol/L'],
    // ['', '(recumbent)', '100 - 500 pmol/L'],
    ['Testosterone', '(M) 9.9 - 27.8 nmol/L'],
    ['', '(F) 0.2 - 2.9 nmol/L'],
  ],
};

export const chem: LabTabContent = {
  tables: [electrolytes, metabolic, liver, cardic, haematinics, endocrinology],
};
